import React, { CSSProperties, useEffect, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from '@material-ui/core';
import { IProjectEvaluations } from '../Evaluation';
import { ICriterion } from '../../../Competition';
import BorderCard from '../../../../../common/BorderCard';
import ItemWithIcon from '../../ItemWithIcon';
import '../Evaluation.css';
import ScoreCard from '../../ScoreCard';

const baseText = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 'normal' as const,
  fontSize: 16,
  color: '#282828',
};
const safeText = {
  overflow: 'hidden',
  whiteSpace: 'nowrap' as const,
  textOverflow: 'ellipsis',
};
const styles = {
  root: {
    width: '100%',
  },
  header: {
    width: '100%',
    height: 48,
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0px 10px',
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerStart: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    gap: '0px 16px',
  },
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
  body: {
    width: '100%',
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'column' as const,
  },
  detailContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '0 10px',
  },
  detailTitle: {
    ...baseText,
    fontWeight: 800,
  },
  detailValue: baseText,
  scoreContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
  },
  scoreText: {
    ...baseText,
    fontWeight: 'bold' as const,
  },
};

const EvaluationTableMobile = ({ data, criteria, style }: Props) => {
  const [collapses, setCollapses] = useState<ICollapse[]>([]);

  useEffect(() => {
    setCollapses(data.map((item) => ({
      state: false,
      evaluations: item.evaluations.map(() => false),
    })));
  }, [data]);

  const handleParentClick = (index: number) => {
    setCollapses((state) => {
      const tmp = [...state];
      const currentState = tmp[index].state;
      tmp[index].state = !currentState;
      if (currentState) {
        tmp[index].evaluations = tmp[index].evaluations.map(() => false);
      }
      return tmp;
    });
  };

  const handleChildrenClick = (index: number, parentIndex: number) => {
    setCollapses((state) => {
      const tmp = [...state];
      const currentState = tmp[parentIndex].evaluations[index];
      tmp[parentIndex].evaluations[index] = !currentState;
      return tmp;
    });
  };

  return (
    <div style={{ ...styles.root, ...style }}>
      {data.map((item, i) => (
        <BorderCard
          key={item.id}
          style={styles.root}
          isTop={!i}
          isBottom={i === data.length - 1}
          onClick={() => handleParentClick(i)}
        >
          <div style={styles.header}>
            <div style={styles.headerStart}>
              <CaretRightOutlined
                rotate={collapses[i]?.state ? 90 : 0}
                style={{ ...styles.arrowIcon, color: collapses[i]?.state ? '#6755CE' : '#898989' }}
              />
              <div style={{ width: 200 }}>
                <ItemWithIcon
                  text={item.project.name}
                  icon={item.project.image}
                  link={`/${item.isStartup ? 'startups' : 'idea'}/${item.project.id}`}
                  textStyle={safeText}
                />
              </div>
            </div>
            <div className='evaluation-mobile-avg-head'>
              <ScoreCard value={item.total || '-'} />
            </div>
          </div>
          <Collapse in={collapses[i]?.state} timeout='auto' unmountOnExit>
            <div style={styles.body}>
              {!!item.project.pitchLink && (
                <div style={{ ...styles.detailContainer, marginBottom: 10 }}>
                  <div style={styles.detailTitle}>Pitch link:</div>
                  <div
                    style={{
                      ...styles.detailValue,
                      color: '#6755CE',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(item.project.pitchLink, '_blank');
                    }}
                    aria-hidden='true'
                  >
                    link
                  </div>
                </div>
              )}
              {criteria.map((criterion, j) => {
                const sum = item.evaluations
                  .filter((e) => e.isAccepted)
                  .reduce((acc, v) => {
                    const criterionScore = v.scores
                      .find((score) => score.criteriaId === criterion.id);
                    return acc + (criterionScore ? criterionScore.value : 0);
                  }, 0);
                return (
                  <BorderCard
                    key={criterion.id}
                    style={styles.scoreContainer}
                    isTop={!j}
                    isBottom={j === criteria.length - 1}
                  >
                    <div style={styles.detailTitle}>
                      {criterion.title}
                      :
                    </div>
                    <div style={styles.scoreText}>
                      {sum || '-'}
                    </div>
                  </BorderCard>
                );
              })}
              <div className='evaluation-mobile-avg-body'>
                <BorderCard
                  style={{ ...styles.scoreContainer, marginTop: 10 }}
                  isTop
                  isBottom
                >
                  <div style={styles.detailTitle}>Total:</div>
                  <ScoreCard value={item.total || '-'} />
                </BorderCard>
              </div>
              <div style={{ marginTop: 40 }}>
                {item.evaluations.map((ev, j) => (
                  <BorderCard
                    key={ev.id}
                    style={styles.root}
                    isTop={!j}
                    isBottom={j === item.evaluations.length - 1}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChildrenClick(j, i);
                    }}
                  >
                    <div style={styles.header}>
                      <div style={styles.headerStart}>
                        <CaretRightOutlined
                          rotate={collapses[i]?.evaluations[j] ? 90 : 0}
                          style={{ ...styles.arrowIcon, color: collapses[i]?.evaluations[j] ? '#6755CE' : '#898989' }}
                        />
                        <div style={{ width: 200 }}>
                          <ItemWithIcon
                            text={`${ev.user.firstName} ${ev.user.lastName}`}
                            icon={ev.user.profileImage}
                            link={`/entrepreneurs/${ev.user.uniqueTag}`}
                            textStyle={{ ...safeText, ...(!ev.isAccepted ? { color: '#dbdbdb' } : {}) }}
                          />
                        </div>
                      </div>
                      <div className='evaluation-mobile-avg-head'>
                        <ScoreCard value={ev.scores.length ? ev.average || '-' : '-'} isActive={ev.isAccepted} />
                      </div>
                    </div>
                    <Collapse in={collapses[i]?.evaluations[j]} timeout='auto' unmountOnExit>
                      <div style={styles.body}>
                        {criteria.map((criterion, k) => {
                          const score = ev.scores?.find((s) => s.criteriaId === criterion.id);
                          return (
                            <BorderCard
                              key={criterion.id}
                              style={styles.scoreContainer}
                              isTop={!k}
                              isBottom={k === criteria.length - 1}
                            >
                              <div style={styles.detailTitle}>
                                {criterion.title}
                                :
                              </div>
                              <div style={{ ...styles.scoreText, ...(!ev.isAccepted ? { color: '#dbdbdb' } : {}) }}>
                                {score ? score.value : '-'}
                              </div>
                            </BorderCard>
                          );
                        })}
                        <div className='evaluation-mobile-avg-body'>
                          <BorderCard
                            style={{ ...styles.scoreContainer, marginTop: 10 }}
                            isTop
                            isBottom
                          >
                            <div style={styles.detailTitle}>Average:</div>
                            <ScoreCard value={ev.scores.length ? ev.average || '-' : '-'} isActive={ev.isAccepted} />
                          </BorderCard>
                        </div>
                      </div>
                    </Collapse>
                  </BorderCard>
                ))}
              </div>
            </div>
          </Collapse>
        </BorderCard>
      ))}
    </div>
  );
};

interface ICollapse {
  state: boolean;
  evaluations: boolean[];
}

interface Props {
  data: IProjectEvaluations[];
  criteria: ICriterion[];
  style?: CSSProperties;
}

EvaluationTableMobile.defaultProps = {
  style: {},
};

export default EvaluationTableMobile;
