import React, { Component } from 'react'
import './Privacy.css'

class TermsAndConditions extends Component {
  render() {
    return (

      <div className="PrivacyPolicy">
        <div className="Content-container">
          <h1>GENERAL TERMS AND CONDITIONS</h1>
          <p>&nbsp;</p>
          <p><span>I.</span> <span>General information</span></p>
          <p>&nbsp;</p>
          <ol>
            <li><span>The website that is the subject of the present GTC (hereafter referred to as: Website) can be found at:</span></li>
          </ol>
          <p><span>www.pozi.io</span></p>
          <p>&nbsp;</p>
          <ol start={2}>
            <li><span>The operator of the Website:</span></li>
          </ol>
          <p><span>Name: Pozi Network Zrt.</span></p>
          <p><span>Registered seat: 3-5. B&eacute;csi St., H-1023 Budapest, Hungary</span></p>
          <p><span>Registration number: 01-10-141468</span></p>
          <p><span>Tax ID number: HU27188756&nbsp;</span></p>
          <p><span>Court of registry: Budapest-Capital Regional Court (Hungary)</span></p>
          <p>&nbsp;</p>
          <ol start={3}>
            <li><span>The Service Provider:</span></li>
          </ol>
          <p><span>The operator of the Website.</span></p>
          <p><span>We provide a platform where entrepreneurs can meet each other, investors, and prospective team members, mentors, advisors. Some of our users create profiles to raise awareness in the startup community or participate in innovation events, incubators or accelerators, or other related programs.</span></p>
          <p><span>Users of this website may open an account as a “Founder” (who are looking for advice and an introduction to potential investors), as an “Investor” (who must complete our accredited investor questionnaire).</span></p>
          <p><span>The platform is open and free to use, but registration is required, therefore we will display the information you have provided within your profile, idea, or project page. We cannot, however, guarantee that anyone who sees your Information does not distribute that information. We also cannot guarantee that there will never be a software bug or a hacker attack that allows unauthorized viewing of material.</span></p>
          <p>&nbsp;</p>
          <ol  start={4}>
            <li><span>User:</span></li>
          </ol>
          <p>&nbsp;</p>
          <p><span>The private person, legal entity, economic company without legal entity or other person or organisation that enters the Webpage and &ndash; if available &ndash; registers thereto, uses the service provided on the Website and uses the functions of the Website.</span></p>
          <p>&nbsp;</p>
          <p><span>II.</span> <span>General terms of use</span></p>
          <p>&nbsp;</p>
          <ol>
            <li><span>By visiting the Website and using any of the functions available thereon, the User accepts the terms of use set out herein.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={2}>
            <li><span>Any and all visual, written or other content found on the Website, as well as the structuring and layout thereof, moreover the trademarks, logos, characters, service marks, as well as any and all intellectual property displayed on the Website is protected by copyright, trademark law and other intellectual property legislation, which are the exclusive property of the Service Provider.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={3}>
            <li><span>The User is entitled to browse the Website, and to make private usage excerpts of its contents by way of printing or saving to hard drive. This entitlement is subject to the condition that User leaves the copyright and other legal proprietary notices intact, and that User leaves Pozi Network Zrt. displayed on the excerpts as legal proprietor thereof. The contents of the Website may not be used in any way, means or form that exceeds the fair use criteria set out in Act LXXVI of 1999 on copyright, and User shall comply with the regulations of the copyright act at all times when browsing or using the Website.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol  start={4}>
            <li><span>Any excerpts or copies of any part of the Website are prohibited from being sold or used for commercial purposes, said excerpts or copies may not be modified, and may not be used or adapted in any other works, publications or websites.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={5}>
            <li><span>Under no circumstances may content of the Website be construed or interpreted in such a way that would mean the granting of a license or permission of use to anyone regarding the trademarks or copyright-protected works displayed on the Website. User is expressly prohibited from (unlawfully) using the trademarks displayed on the Website or on any of the contents available on the Website, with exceptions set out in the present terms and conditions. We inform the Users moreover, that the Pozi Network Zrt. shall enforce its intellectual property rights to the fullest possible extent of the law.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={6}>
            <li><span>Any communication or material of a non-personal nature that are forwarded to the Website by the User by electronic mail or other means &ndash; including any data, inquiries, comments or recommendations or otherwise &ndash; do not constitute confidential information or information under legal protection, and shall be construed by the Pozi Network Zrt. as such. Any information or data forwarded to the Website or uploaded as a comment thereto constitutes the property of the Pozi Network Zrt., who shall be entitled to use them for any purpose &ndash; including, but not limited to reproduction, transfer, forwarding, transmission, dissemination to the public and publication. The Pozi Network Zrt. shall moreover be entitled to use the communicated thoughts, works, inventions, developments, ideas or concepts for any purpose (including product development, production and marketing). The party communicating the used information shall not be entitled to any remuneration. By forwarding said information, the communicating party warrants that they are the sole rights holder over the communicated material/content, that these materials/contents do not serve to offend, and that the usage thereof by the Pozi Network Zrt. does not infringe upon any third party rights or legal regulations. The Pozi Network Zrt. shall be under no obligation to use the information communicated by use of the Website.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={7}>
            <li><span>You are licensing to us the right to publish all Content you upload to Pozi.io, including any comments or other forum posts you may offer on the Site in order to provide the Services. Your Content will be viewable by other users of the Services and through third-party services and websites. You should only provide Content that you are comfortable sharing with others. You acknowledge that the Pozi Network Zrt. is not obligated to pay you or to cause any other party to pay you any compensation with respect to your activities on Pozi.io, or to feature or otherwise display your Content on any web page.</span></li>
          </ol>
          <p>&nbsp;</p>
          <p><span>III.</span> <span>Correctness, completeness, and actuality of data</span></p>
          <p>&nbsp;</p>
          <ol>
            <li><span>The Pozi Network Zrt. makes every reasonable effort to ensure the correctness, completeness and actuality of the data found on the Website, but accepts no liability for any damage whatsoever that may occur due to or be attributable to any incorrect, incomplete or out of date information or data. User may refer to the data and material on the Website at their own risk. User accepts that the following of any changes occurring to the materials, data, and information on the Website shall be their responsibility.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={2}>
            <li><span>The information, data and conditions displayed on the Website serve exclusively as general information, the Pozi Network Zrt. accepts no liability for any damage or expense whatsoever that may occur due to or be attributable to these being incorrect or incomplete. The Pozi Network Zrt. shall not be liable for any damages, losses or expenses that result from the use of the Website, the unavailability of the Website, the malfunction or service disruption thereof, nor from any unauthorised alterations of the data thereon, nor from any lateness of information being forwarded, from computer viruses, line- or system failures or any other similar reasons.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={3}>
            <li><span>The information on the Website regarding professional courses, classes and programs are also of a merely informational nature, including the participation fees and participation conditions, if applicable. The information regarding the participation fees and participation conditions professional courses, classes and programs are not exhaustive, the organiser is to be consulted regarding the above.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <p><span>IV.</span> <span>Automatically collected data</span></p>
          <p>&nbsp;</p>
          <p><span>When visiting the website, one or more cookies &ndash; small files containing a string of characters &ndash; may be sent to the User&rsquo;s computer, by which their browser may be uniquely identified. These cookies are only sent by the system to the User&rsquo;s computer upon visiting certain sub-pages, thus, these only contain the time and occurrence of the visit having taken place, with the Website storing no further information in them. Third party providers, including Google, use these cookies to store whether the User had already visited the advertisers&rsquo; websites, and use these to display advertisements on the websites of the partners of external providers &ndash; including Google.</span></p>
          <p>&nbsp;</p>
          <ol start={2}>
            <li><span>Cookies and their use are governed by a separate Cookie Policy, which is available on the Website.</span></li>
          </ol>
          <p>&nbsp;</p>
          <p><span>V.</span> <span>Personal Data Protection</span></p>
          <p>&nbsp;</p>
          <ol>
            <li><span>Visiting the Webpage is not tied to any registration and does not entail the User handing over any personal data. However, it is possible to contact the Service Provider via the Website, thus, should any personal data come into the possession of the Pozi Network Zrt., the data processors shall process those in accordance with the regulations of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation, hereafter as: GDPR), and with Act CXII of 2011 on the right to information self-determination and the freedom on information, and with the regulations of the Privacy Policy of the Company, governing the data processing of the Company regarding their online platform, which may be found on the Website under http://www.pozi.io/privacy-policy</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={2}>
            <li><span>The Pozi Network Zrt. shall process any data forwarded to them via the internet with the same level of care as if they were forwarded to them via any other means.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <p><span>VI.</span> <span>Warranty and limitation of liability</span></p>
          <p>&nbsp;</p>
          <ol>
            <li><span>User shall use the Website at their own, sole risk.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={2}>
            <li><span>The present Website is provided by the Pozi Network Zrt. &ldquo;As Is&rdquo; and &ldquo;As Available&rdquo;, accordingly, the Pozi Network Zrt. accepts no warranty &ndash; be it express, implied, based on legislation or otherwise (e. g. warranties on marketability, quality or suitability for a given purpose) &ndash; for the completeness, correctness, reliability, up-to-date nature, legal compliance of the Website, for any disruptions in availability, for any faults, errors or viruses, nor for the correctness or reliability of any advice or opinions stated on the Website. The Pozi Network Zrt. expressly waives any responsibility or connection for any such warranties or remarks.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={3}>
            <li><span>The Pozi Network Zrt. reserves the right to restrict, suspend or deny access to the present Website or any part or function thereof at any time of their choosing without any prior notice being provided.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol  start={4}>
            <li><span>The Pozi Network Zrt. and/or any third parties involved in the design, production or the making available of the present Website expressly reject any liability to the fullest possible extent allowed by applicable law regarding any direct, consequential, indirect, special or punitive damages, expenses, losses or obligations that may emerge in connection to the access to the Website or any other websites visited by way of a link found on the Website, or from the usage or the unavailability thereof, or from any changes to their contents, nor for any actions having been taken or not taken per any electronic mail sent by the User to the Pozi Network Zrt.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={5}>
            <li><span>The Pozi Network Zrt. and/or any third parties involved in the design, production, or the making available of the present Website are under no obligation to uphold or to maintain the material and services provided on the website, nor to conduct any fixes, actualization or updates thereon. Contents on the Website are subject to change without prior notice.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={6}>
            <li><span>The Pozi Network Zrt. accepts no liability for any damages or losses that may emerge in relation to the usage of or access to the Website, nor from any material being downloaded from the Website resulting in the Use&rsquo;s computer or any other persons&rsquo; property being infected by any computer viruses. Users shall download any and all material from the Website at their own sole risk.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={7}>
            <li><span>User shall, to the fullest extent afforded by applicable legislation, waive the right to assert any claim towards the Pozi Network Zrt. and their officers, directors, employees, subcontractors or programmers based on any usage of, or access to the Website.</span></li>
          </ol>
          <p>&nbsp;</p>
          <p><span>VII.</span> <span>Miscellaneous and closing provisions</span></p>
          <p>&nbsp;</p>
          <ol>
            <li><span>User is prohibited from engaging in any behavior held unilaterally by the Pozi Network Zrt. to be improper, and/or any behavior that is considered illegal or prohibited by any applicable laws.</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={2}>
            <li><span>The Pozi Network Zrt. is entitled to unilaterally amend the present legal notice at any time, in any way, of which they shall not be required to notify the Users in advance. Any such amendments shall enter into force upon having been published on the Website.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={3}>
            <li><span>By visiting the Website, Users accept the present GTC as legally binding towards their person.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol  start={4}>
            <li><span>Should any provision of the present GTC become void, this provision shall not affect any other provisions of the GTC.&nbsp;</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol start={5}>
            <li><span>The present GTC shall enter into force upon having been published on the Website.</span></li>
          </ol>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><span>Budapest, 1st of July 2020.</span></p>
          <p><br /><br /></p>
        </div>
      </div>
    );
  }
}

export default TermsAndConditions;