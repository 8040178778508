import moment from "moment";
import React, { useState, useEffect } from "react";



interface Props {
  data?: any
 }

 
const  UpcomingCompetition = ({data}:Props) => {

  const defaultImage = "/images/Pozi_cover.png"
  const openView = (uniqueTag: string) => {
    const win = window.open(`/competitions/${uniqueTag}`, "_blank");
    win.focus();
  }

  return (
    <div className="upcoming-card">
       <div className="dashboard-card-title">Upcoming competition</div>
       {data ?
            <div className="upcoming-container" onClick={()=> openView(data.tag)}>
                <div><img className="upcoming-cover" src={data.image ? data.image : defaultImage}/></div>
                <div className="upcoming-content">
                  <div className="upcoming-title">{data.name}</div>
                  <div>{data.shortDescription}</div>
                  <div>
                      {
                          data.location &&
                          <div className="location-duration">
                              <div className="location-duration-date">
                                <img src="/images/groups/date.svg" className="dashboard-img"/>
                                {moment(data.durationFrom).format("YYYY.MM.DD")}
                              </div>
                              <div className="upcoming-location">
                                <div>
                                <img src="/images/groups/place2.svg" className="dashboard-img"/>
                                <span> {data.location.country}{data.location.city && (", " + data.location.city)} </span>
                                </div>
                              </div>
                          </div>
                      }
                  </div>
                </div>
            </div>
            : "Nothing to show" }
    </div>
  );
};
export default UpcomingCompetition;
