import React from 'react';
import { Form, Input, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IAwardType, IProject } from '../Winners';
import Card from '../../../../../common/card/Card';

const { Option } = Select;

const styles = {
  root: {
    width: '100%',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 40,
    paddingBottom: 10,
    marginBottom: 20,
  },
  topContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    flexWrap: 'wrap' as const,
    gap: '20px 20px',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  selectorContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    gap: '0px 20px',
    flexWrap: 'wrap' as const,
  },
  selector: {
    width: 200,
    color: '#6755CE',
  },
  icon: {
    cursor: 'pointer',
    fontSize: 24,
    color: '#6755CE',
    height: '100%',
    marginLeft: 8,
  },
};

const WinnerInputCard = ({
  projectList, awardTypes, form, applicationFieldKey,
  awardFieldKey, descriptionFieldKey, onDelete, disabled,
}: Props) => (
  <Card style={styles.root}>
    <div style={styles.topContainer}>
      <DeleteOutlined
        style={styles.icon}
        onClick={() => {
          if (!disabled) {
            onDelete();
          }
        }}
      />
      <div style={styles.selectorContainer}>
        <Form.Item>
          {form.getFieldDecorator(applicationFieldKey, {
            rules: [{ required: true, message: 'This field is required.' }],
          })(
            <Select
              style={styles.selector}
              placeholder='Choose application...'
              disabled={disabled}
            >
              {projectList.map((project:any) => (
                <Option key={project.id} value={project.id}>({project.score}) {project.name}</Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator(awardFieldKey, {
            rules: [{ required: true, message: 'This field is required.' }],
          })(
            <Select
              style={styles.selector}
              placeholder='Choose award...'
              disabled={disabled}
            >
              {awardTypes.map((awardType) => (
                <Option key={awardType.id} value={awardType.id}>{awardType.name}</Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </div>
    </div>
    <Form.Item>
      {form.getFieldDecorator(descriptionFieldKey, {
        rules: [
          { required: true, message: 'This field is required.' },
          { min: 3, message: 'Description must be at least 3 characters.' },
          { max: 150, message: 'Description cannot be longer than 150 characters.' },
        ],
      })(
        <Input placeholder='Prize description' disabled={disabled} />,
      )}
    </Form.Item>
  </Card>
);

interface Props {
  projectList: IProject[];
  awardTypes: IAwardType[];
  form: WrappedFormUtils;
  applicationFieldKey: string,
  awardFieldKey: string,
  descriptionFieldKey: string,
  onDelete: () => void;
  disabled: boolean;
}

export default WinnerInputCard;
