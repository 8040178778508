import React, { CSSProperties } from 'react';

const styles = {
  title: {
    marginTop: 32,
    marginBottom: 10,
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    color: '#282828',
  },
  subtitle: {
    fontWeight: 300,
  },
};

const FormLabel = ({
  title, subtitle, style, subtitleStyle,
}: Props) => (
  <div style={{ ...styles.title, ...style }}>
    {title}
    {' '}
    {subtitle && (
      <span style={{ ...styles.subtitle, ...subtitleStyle }}>{subtitle}</span>
    )}
  </div>
);

interface Props {
  title: string;
  subtitle?: string;
  style?: CSSProperties;
  subtitleStyle?: CSSProperties;
}

FormLabel.defaultProps = {
  subtitle: '',
  style: {},
  subtitleStyle: {},
};

export default FormLabel;
