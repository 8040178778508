import React, { useState, useEffect } from "react";
import FeedList from "../../../common/FeedList"
import PostModal from "../../../NewsFeed/components/PostModal/PostModal"
import { message, Button } from "antd";
import { post } from "../../../../libs/utils/request";
import ConfirmationDialog from "../../../common/ConfirmationDialog";
import { PlusOutlined } from '@ant-design/icons';



const Announcements =({organization}:Props)=>{
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [editNews, setEditNews] = useState(null);
  const [selectedType, setSelectedType] = useState("All");
  const [idForDelete, setIdForDelete] = useState(null);
  const [feedListSize, setFeedListSize] = useState(600);


  const deletePost = async (id:any) => {
    if (id) {
      try {
        await post("/news/delete", { id: id });
        message.success("Post deleted.");
        setRefreshNeeded(true);
      } catch (error) {
        message.error("Failed to delete post.");
      }
    }
  };

  const styles = {
    root: {
      maxWidth: 700,
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: 74,
    },
    btnContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row' as const,
      justifyContent: 'center',
    },
    btn: {
      fontWeight: 'bold' as const,
      minWidth: 150,
      marginBottom: 50,
    },
  };

    return(
        <div className="announcements-main">
            <h2 className="organization-followers-title">Feed</h2>
            <div className="announcements">
                <div className="announcements-container">
                    <div>
                        {(organization.isMember || organization.isOwner) &&
                            <div style={styles.btnContainer}>
                                <Button
                                    style={styles.btn}
                                    type='primary'
                                    size='default'
                                    onClick={() => {
                                    setEditNews(null);
                                    setEditModalVisible(true);
                                    }}
                                >
                                <PlusOutlined />
                                Create new
                                </Button>
                            </div>
                        }
                    
                    </div>
                    <div>
                            <FeedList
                                newsGroupId={organization.newsGroup.id}
                                url="news/list"
                                selectedType={selectedType}
                                refreshNeeded={refreshNeeded}
                                setRefreshNeeded={setRefreshNeeded}
                                onEdit={(item) => {
                                setEditNews(item);
                                setEditModalVisible(true);
                                
                                }}
                                onDelete={(id) => {
                                deletePost(id)
                                setIdForDelete(id);
                                setDeleteDialogVisible(true);
                                }}
                                id='feedList'
                                feedListMaxWidth={feedListSize}
                            />
                    </div>
                    <PostModal
                        newsGroupId={organization.newsGroup.id}
                        visible={editModalVisible}
                        onOk={() => {
                        setEditModalVisible(false);
                        setRefreshNeeded(true);
                        }}
                        onCancel={() => setEditModalVisible(false)}
                        postUrl={`/news/post`}
                        patchUrl={`/news/edit`}
                        news={editNews}
                        v2
                    />
            </div>
        </div>
    </div>
    )
}

interface Props {
  organization:any;
}


export default Announcements