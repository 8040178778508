import React, { CSSProperties } from 'react';
import { Input } from 'antd';
import { FormikErrors } from 'formik';

const styles = {
  root: { width: '100%' },
  errorBox: { marginTop: 2, minHeight: 28 },
  errorText: {
    fontSize: 12,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    color: '#F5222D',
  },
  label: {
    fontSize: 14,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 800,
    color: '#262626',
  },
};

const FormikInput = ({
  name, value, onChange, onBlur, withError, error, placeholder, style, inputStyle, disabled, type, prefix, maxLength, autofocus, label, className, onPressEnter, textStyle
}: Props) => (
  <div style={{ ...styles.root, ...style }}>
    <label style={styles.label}>{label}</label>
    <Input
      autoComplete= "off"
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
      style={{ ...styles.root, ...inputStyle }}
      placeholder={placeholder}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
      prefix={prefix}
      maxLength={maxLength}
      autoFocus={autofocus ? true : false}
      className={className ? className : ''}
      onPressEnter={onPressEnter}
    />
    {withError && (
      <div style={styles.errorBox}>
        <div style={textStyle ? undefined : styles.errorText} className={textStyle ? textStyle : ""}>{error}</div>
      </div>
    )}
  </div>
);

interface Props {
  type: any,
  name: string,
  value: string,
  onChange: any,
  onBlur?: any,
  withError?: boolean,
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[],
  placeholder?: string;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  disabled?: boolean;
  prefix?:any;
  maxLength? :number;
  autofocus? : boolean
  label?:string
  className?: string;
  onPressEnter?: any;
  textStyle?: string;
}

FormikInput.defaultProps = {
  onBlur: null,
  withError: true,
  error: '',
  placeholder: '',
  style: {},
  inputStyle: {},
  disabled: false,
  type: '',
};

export default FormikInput;
