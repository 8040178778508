import { Carousel } from "antd";
import React from "react";

const partners = [
  { url: "https://nextblockexpo.com/", image: "/images/logos/nbx.png" },
  {
    url: "https://fi.co/",
    image: "/images/logos/founder-institute.png",
  },
  {
    url: "https://www.draperuniversity.com/",
    image: "/images/logos/DraperUniversity.png",
  },
  {
    url: "https://draperstartuphouse.com/",
    image: "/images/logos/draperstartuphouse.jpg",
  },
  {
    url: "https://startupworldcup.io/",
    image: "/images/logos/worldcup.png",
  },
  {
    url: "https://talcatran.com/",
    image: "/images/logos/TalCatran.png",
  },
  {
    url: "https://www.womenstartupcompetition.com/",
    image: "/images/logos/womenComp.png",
  },
  {
    url: "https://www.pegasustechventures.com/",
    image: "/images/logos/pegasus.png",
  },
  { url: "https://galaxis.xyz/#/", image: "/images/logos/Galaxis_black.png" },
  { url: "https://ether.cards/", image: "/images/logos/Ethercards.png" },
  {
    url: "https://events.withgoogle.com/ngof-pozi/",
    image: "/images/logos/newgen.png",
  },
  {
    url: "https://www.theconnecteast.com/",
    image: "/images/logos/connecteast.png",
  },
  {
    url: "https://www.camelcoding.com/",
    image: "/images/logos/camelcodingBW.png",
  },
  {
    url: "https://www.hackathonsinternational.com/",
    image: "/images/logos/hackathons.png",
  }, 
  { url: "https://ndv.hu/", image: "/images/logos/NDV_New.png" },
  
  { url: "https://www.meout.org/", image: "/images/logos/meoutBW.png" },
  { url: "https://www.gazduig.com/", image: "/images/logos/gazduigBW.png" },
  // {
  //   url: "https://kormany.hu/miniszterelnokseg",
  //   image: "/images/logos/miniszterelnoksegBW.png",
  // },
];

const carouselResponsiveSettings = [
  {
    breakpoint: 1850,
    settings: {
      slidesToShow: 8,
      slidesToScroll: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 1625,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 1422,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 1219,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 1016,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 2,
      initialSlide: 2,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const ImageSlider = () => {
  const CardView = () => {
    return partners.map((partner) => (
      <span className="partners-of-pozi-card">
        <a href={partner.url} target="_blank">
          <img src={partner.image} className="partners-of-pozi" alt="" />
        </a>
      </span>
    ));
  };

  return (
    <Carousel
      slidesToShow={9}
      infinite={true}
      draggable={true}
      autoplay={true}
      autoplaySpeed={2500}
      responsive={carouselResponsiveSettings}
    >
      {CardView()}
    </Carousel>
  );
};
export default ImageSlider;
