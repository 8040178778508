import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { Button } from 'antd';
import { Prompt } from 'react-router';
import { get } from '../../../../../libs/utils/request';
import { IIdea } from '../Ideas/Ideas';
import { IStartup } from '../Startups/Startups';
import WinnerCard from './components/WinnerCard';
import { Loading } from '../../../../common/Loading';
import { havePermission } from '../../Competition';
import WinnersInputModal from './components/WinnersInputModal';

const styles = {
  root: {
    maxWidth: 1200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 67,
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'space-between',
    gap: '20px 10px',
    marginBottom: 62,
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 30,
    color: '#000',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 30,
    color: '#000',
    marginTop: 18,
  },
  noResultText: {
    fontSize: 20,
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    marginTop: 72,
    color: '#282828',
  },
  btn: {
    fontWeight: 'bold' as const,
    minWidth: 150,
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center' as const,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
};

const convertData = (data: IWinnersResponse) => data.data.map((item) => ({
  ...item,
  application: {
    ...(item.application),
    startup: item.application.startup ? {
      ...(item.application.startup),
      userRating: item.application.startup.userRating.rating
        ? item.application.startup.userRating.rating : 0,
      interestGroups: item.application.startup.interests
        .map((int: any) => ({ id: int.id, name: int.name, icon: int.group.icon })),
    } : null,
    idea: item.application.idea ? {
      ...(item.application.idea),
      userRating: item.application.idea.userRating.rating
        ? item.application.idea.userRating.rating : 0,
      interestGroups: item.application.idea.interests
        .map((int: any) => ({ id: int.id, name: int.name, icon: int.group.icon })),
    } : null,
  },
}));

const Winners = ({ tag, userRoles }: Props) => {
  const [winners, setWinners] = useState([]);
  const [awardTypes, setAwardTypes] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setErrorMessage('');
        setLoading(true);
        const win = await get<IWinnersResponse>(`/competitions/${tag}/winners`);
        // console.log('WINNERS');
        // console.log(JSON.stringify(win.data, null, 2));
        setWinners(convertData(win));
        if (havePermission(['Organizer', 'Co-organizer'], userRoles)) {
          const awards = await get<IAwardType[]>(`/competitions/${tag}/winners/award-types`);
          const shortList = await get<IProjectList>(`/competitions/${tag}/winners/shortlisted`);
          setAwardTypes(awards);
          setProjectList(shortList.data);
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [tag, userRoles]);

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    window.onbeforeunload = modalVisible ? () => true : undefined;
    return () => {
      // eslint-disable-next-line no-undefined
      window.onbeforeunload = undefined;
    };
  }, [modalVisible]);

  const loadWinners = async (withLoading = true) => {
    try {
      setErrorMessage('');
      if (withLoading) {
        setLoading(true);
      }
      const win = await get<IWinnersResponse>(`/competitions/${tag}/winners`);
      setWinners(convertData(win));
      if (havePermission(['Organizer', 'Co-organizer'], userRoles)) {
        const awards = await get<IAwardType[]>(`/competitions/${tag}/winners/award-types`);
        const shortList = await get<IProjectList>(`/competitions/${tag}/winners/shortlisted`);
        setAwardTypes(awards);
        setProjectList(shortList.data);
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (errorMessage) {
    return (
      <>
        <div style={styles.errorText}>Failed to load winners:</div>
        <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
          {errorMessage}
        </div>
        <div
          style={styles.errorButtonText}
          onClick={() => loadWinners()}
          aria-hidden='true'
        >
          retry
        </div>
      </>
    );
  }

  return (
    <div style={styles.root}>
      <div style={styles.header}>
        <Prompt
          when={modalVisible}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <div>
          <div style={styles.title}>Awards</div>
          <div style={styles.subTitle}>Congratulations for the awarded teams!</div>
        </div>
        {havePermission(['Organizer', 'Co-organizer'], userRoles) && (
          <Button
            style={{ ...styles.btn, marginTop: 40 }}
            type='primary'
            size='large'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Edit
          </Button>
        )}
      </div>
      {!winners.length ? (
        <div style={styles.noResultText}>Nothing to show.</div>
      ) : (
        <Masonry
          breakpointCols={{ default: 2, 850: 1 }}
          className='startup-box-view'
          columnClassName='startup-box-view-item'
        >

          {winners.map((item: any) => {
            if (item.application.startup) {
              return (
                <WinnerCard
                  key={item.id}
                  awardName={item.awardType.name}
                  awardDescription={item.description}
                  isStartup
                  startup={item.application.startup}
                  onChange={() => loadWinners(false)}
                />
              );
            }
            return (
              <WinnerCard
                key={item.id}
                awardName={item.awardType.name}
                awardDescription={item.description}
                isStartup={false}
                idea={item.application.idea}
                onChange={() => loadWinners(false)}
              />
            );
          })}
        </Masonry>
      )}
      <WinnersInputModal
        winners={winners}
        visible={modalVisible}
        projectList={projectList}
        awardTypes={awardTypes}
        onOk={() => {
          setModalVisible(false);
          loadWinners();
        }}
        onCancel={() => setModalVisible(false)}
        tag={tag}
        initialCount={winners.length}
      />
    </div>
  );
};

export interface IAwardType {
  id: number;
  name: string;
}

interface IApplication {
  id: string;
  startup?: IStartup;
  idea?: IIdea;
}

export interface IWinner {
  id: string;
  description: string;
  awardType: IAwardType;
  application: IApplication;
}

interface IWinnersResponse {
  data: IWinner[];
}

export interface IProject {
  id: string;
  name: string;
  image: string;
}

interface IProjectList {
  data: IProject[];
}

interface Props {
  tag: string;
  userRoles: string[];
}

export default Winners;
