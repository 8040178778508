import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment-timezone';
import Card from '../../../../common/card/Card';
import { FormDateTimePicker } from '../../../../common/form/DateTimePicker';
import FormInput from '../../../../common/form/FormInput';
import { isFutureDateValidator } from '../../../NewCompetition';

const styles = {
  root: {
    borderRadius: 5,
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    marginLeft: 25,
    marginBottom: 35,
    maxWidth: 600,
  },
  shit: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
  },
  icon: {
    cursor: 'pointer',
    fontSize: 24,
    color: '#6755CE',
    height: '100%',
    marginLeft: 8,
  },
};

const ScheduleItem = ({
  form, dateFieldKey, nameFieldKey, onDelete, deletable, isEdit, createdAt,
}: Props) => (
  <Card style={styles.root}>
    <div style={styles.shit}>
      <div>
        <FormDateTimePicker
          form={form}
          fieldKey={dateFieldKey}
          required
          rules={[{ validator: isFutureDateValidator(isEdit ? moment(createdAt) : moment()) }]}
        />
      </div>
      {deletable && (
        <DeleteOutlined style={styles.icon} onClick={onDelete} />
      )}
    </div>
    <FormInput
      fieldKey={nameFieldKey}
      form={form}
      required
      placeholder='Description'
      rules={[
        { min: 3, message: 'Name must be at least 3 characters.' },
        { max: 100, message: 'Name cannot be longer than 100 characters.' },
      ]}
    />
  </Card>
);

interface Props {
  form: WrappedFormUtils;
  dateFieldKey: string;
  nameFieldKey: string;
  onDelete: any;
  deletable: boolean;
  isEdit: boolean;
  createdAt?: string;
}

ScheduleItem.defaultProps = {
  createdAt: null,
};

export default ScheduleItem;
