import React, { useEffect, useState } from "react";
import { MoreOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router";
import { Dropdown, Input, Menu, message, Modal } from "antd";
import Card from "../../common/card/Card";
import { ShareMenu2 } from "../../common/ShareMenu2";
import { hasPermission, isAdmin, user } from "../../../libs/utils/user";
import { deleteReq, post } from "../../../libs/utils/request";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import SectionButton from "./SectionButton";

const shareLinkBase = `${window.location.protocol}//${window.location.host}`;

const TitleCard = ({
  createdBy,
  name,
  tag,
  teamMember,
  organizer,
  onMessage,
  onAddPartner,
  partner,
}: Props) => {
  const [sections, setSections] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [deleteMessage,setDeleteMessage]= useState("");

  const { subPage } = useParams();
  const history = useHistory();

  useEffect(() => {
    const sectionUser = [
      { name: "About" },
      { name: "Feed", page: "feed" },
      { name: "Events", page: "events" },
      { name: "Competitions", page: "competitions" },
      { name: "Portfolio", page: "portfolio" },
      { name: "Followers", page: "followers" },
    ];
    const section = [{ name: "About" }];
    if (user()) setSections(sectionUser);
    else setSections(section);
  }, []);

  const deleteOrganization = async () => {
    try {
      await deleteReq(`/organizations/${tag}`,{password:confirmPassword});
      history.replace("/organizations");
      message.success('Organization deleted');
    } catch (error) {
      message.error("Failed to delete organization.");
    }
  };

  const deleteOrganizationReq = async () => {
    try {
      await post(`/organizations/${tag}/delete`,{message:deleteMessage});
      history.replace("/organizations");
      message.success('Message sent');
    } catch (error) {
      console.log(error)
      message.error("Failed to delete organization.");
    }
  };

  const settingConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value)
  }

  const settingDeleteMessage = (e: any) => {
    setDeleteMessage(e.target.value)
  }
  const userFromSS = JSON.parse(sessionStorage.getItem("user"));
  const editOrganizaion = () => {
    if (userFromSS.progress === 100) {
      history.push(`/edit-organization/${tag}`);
    }
    else{
      message.warning(`${userFromSS.progress}% of your profile is completed. To apply you need to get 100%`)
    }

  }

  const deleteOrg = () => {
    if (userFromSS.progress === 100) {
      setDeleteModalVisible(true)
    }
    else{
      message.warning(`${userFromSS.progress}% of your profile is completed. To apply you need to get 100%`)
    }
  }

  const getActionMenu = () => {
    const isOrganizer = user().id === createdBy;
    return (
      <Menu>
        {user() && !isOrganizer && (
          <Menu.Item>
            <div onClick={onMessage} aria-hidden="true">
              Send a message
            </div>
          </Menu.Item>
        )}
        {user() && isAdmin() && partner !== undefined && (
          <Menu.Item>
            <div onClick={onAddPartner} aria-hidden="false">
              {partner ? "Remove partner" : "Add partner"}
            </div>
          </Menu.Item>
        )}

        {(teamMember || organizer || isAdmin() ) && (
          <Menu.Item>
            <div
              onClick={() => {
                editOrganizaion()
              }}
              aria-hidden="true"
            >
              Edit organization
            </div>
          </Menu.Item>
        )}
        {(teamMember || organizer || isAdmin()) && (
          <Menu.Item>
            <div onClick={() => {deleteOrg()}} aria-hidden="true">
              Delete organization
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Card className="organizationpage-title-card">
      <div className="organizationpage-title-card-container">
        <div className="organizationpage-title-card-title">
          {name}
        </div>
        <div className="organizationpage-title-card-menu">
          <Dropdown
            overlay={<ShareMenu2 shareURL={`${shareLinkBase}/organizations/${tag}`} />}
            placement="bottomCenter"
            trigger={["click"]}
          >
            <ShareAltOutlined />
          </Dropdown>
          {user() && (
            <Dropdown overlay={getActionMenu()} placement="bottomLeft">
              <MoreOutlined />
            </Dropdown>
          )}
        </div>
      </div>
      <div className="organizationpage-title-card-button-container">
        {sections.map((section) => (
          <SectionButton
            key={section.name}
            name={section.name}
            isActive={section.page === subPage}
            onClick={() => {
              history.push(
                `/organizations/${tag}${section.page ? `/${section.page}` : ""}`
              );
            }}
          />
        ))}
      </div>
      {(organizer || isAdmin())&&<Modal
        visible={deleteModalVisible}
        onOk={()=>{
                    setDeleteModalVisible(false);
                    deleteOrganization();
        }}
        onCancel={() => setDeleteModalVisible(false)}
        title="Delete organization"
        okText='Delete'
        okType='danger'
      >
        <p>If you'd like to remove your organization please enter your password:</p>
        <Input.Password placeholder='password' onChange={settingConfirmPassword} />
      </Modal>}
     {(teamMember && !organizer && !isAdmin())&&
       <Modal
       visible={deleteModalVisible}
       onOk={()=>{
                   setDeleteModalVisible(false);
                   deleteOrganizationReq();
       }}
       onCancel={() => setDeleteModalVisible(false)}
       title="Delete organization"
       okText='Send message'
       okButtonProps={{disabled:deleteMessage.length<25}}
       okType='danger'
     >
       <p>If you'd like to remove your organization please leave a message and we will remove it:</p>
       <Input.TextArea placeholder="Please enter your message" onChange={settingDeleteMessage} />
     </Modal>
     }
    </Card>
  );
};

interface Props {
  name: string;
  tag: string;
  teamMember: boolean;
  organizer: boolean;
  onMessage: () => void;
  createdBy: string;
  onAddPartner: () => void;
  partner: boolean;
}

interface ISection {
  name: string;
  page?: string;
}

export default TitleCard;
