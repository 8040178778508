import React, { CSSProperties, useEffect, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from '@material-ui/core';
import { IProjectEvaluations } from '../Evaluation';
import { ICriterion } from '../../../Competition';
import BorderCard from '../../../../../common/BorderCard';
import ItemWithIcon from '../../ItemWithIcon';
import ScoreCard from '../../ScoreCard';

const PROJECT_WIDTH = 228;
const PITCH_WIDTH = 50;
const AVG_WIDTH = 60;
const baseText = {
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 'normal' as const,
  color: '#282828',
};
const safeText = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center' as const,
};
const styles = {
  root: {
    width: '100%',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    justifyContent: 'space-between',
    // backgroundColor: '#00F',
    gap: '0 8px',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrowHeader: {
    width: 30,
    height: 24,
  },
  startupHeader: {
    ...baseText,
    ...safeText,
    width: PROJECT_WIDTH,
    fontWeight: 900,
    paddingLeft: 28,
    textAlign: 'left' as const,
  },
  startupCol: {
    width: PROJECT_WIDTH,
  },
  pitchHeader: {
    ...baseText,
    ...safeText,
    fontWeight: 900,
    width: PITCH_WIDTH,
  },
  pitchCol: {
    ...baseText,
    ...safeText,
    width: PITCH_WIDTH,
  },
  pitchColLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  scoreHeader: {
    ...baseText,
    ...safeText,
    fontWeight: 900,
    width: 65,
  },
  scoreCol: {
    width: 60,
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  totalHeader: {
    ...baseText,
    ...safeText,
    fontWeight: 900,
    width: AVG_WIDTH,
  },
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
  scoreText: {
    ...baseText,
    fontWeight: 'bold' as const,
  },
};

const EvaluationTable = ({ data, criteria, style }: Props) => {
  const [collapses, setCollapses] = useState([]);

  useEffect(() => {
    const l = Array(data.length);
    for (let i = 0; i < data.length; i += 1) {
      l[i] = false;
    }
    setCollapses(l);
  }, [data.length]);

  const handleClick = (i: number) => {
    setCollapses((state) => {
      const tmp = [...state];
      tmp[i] = !tmp[i];
      return tmp;
    });
  };
  return (
    <div style={{ ...styles.root, ...style }}>
      <BorderCard isTop style={{ ...styles.row, paddingTop: 10, paddingBottom: 10 }}>
        <div style={styles.startupHeader}>Startup name</div>
        <div style={styles.pitchHeader}>Pitch link</div>
        <>
          {criteria.map((criterion) => (
            <div key={criterion.id} style={styles.scoreHeader}>
              {criterion.title}
            </div>
          ))}
        </>
        <div style={styles.totalHeader}>Total</div>
      </BorderCard>
      {data.map((item, i) => (
        <BorderCard
          key={item.id}
          isBottom={i === data.length - 1}
          onClick={() => handleClick(i)}
        >
          <div style={styles.row}>
            <div style={styles.startupCol}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0 10px',
                alignItems: 'center',
              }}
              >
                <CaretRightOutlined
                  rotate={collapses[i] ? 90 : 0}
                  style={{ ...styles.arrowIcon, color: collapses[i] ? '#6755CE' : '#898989' }}
                />
                <ItemWithIcon
                  text={item.project.name}
                  icon={item.project.image}
                  link={`/${item.isStartup ? 'startups' : 'idea'}/${item.project.uniqueTag}`}
                  textStyle={safeText}
                />
              </div>
            </div>
            <div
              style={{
                ...styles.pitchCol,
                ...(item.project.pitchLink ? styles.pitchColLink : {}),
              }}
              onClick={() => {
                if (item.project.pitchLink) {
                  window.open(item.project.pitchLink, '_blank');
                }
              }}
              aria-hidden='true'
            >
              {item.project.pitchLink ? 'link' : '-'}
            </div>
            <>
              {criteria.map((criterion) => {
                const sum = item.evaluations
                  .filter((e) => e.isAccepted)
                  .reduce((acc, v) => {
                    const criterionScore = v.scores
                      .find((score) => score.criteriaId === criterion.id);
                    return acc + (criterionScore ? criterionScore.value : 0);
                  }, 0);
                return (
                  <div key={criterion.id} style={styles.scoreCol}>
                    <div style={styles.scoreText}>
                      {sum || '-'}
                    </div>
                  </div>
                );
              })}
            </>
            <ScoreCard value={item.total || '-'} />
          </div>
          <Collapse in={!!collapses[i]} timeout='auto' unmountOnExit>
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              {item.evaluations.map((ev) => (
                <div style={styles.row} key={ev.id}>
                  <div style={{ ...styles.startupCol, paddingLeft: 40 }}>
                    <ItemWithIcon
                      text={`${ev.user.firstName} ${ev.user.lastName}`}
                      icon={ev.user.profileImage}
                      link={`/entrepreneurs/${ev.user.uniqueTag}`}
                      textStyle={{ ...safeText, ...(!ev.isAccepted ? { color: '#dbdbdb' } : {}) }}
                    />
                  </div>
                  <div style={styles.pitchCol} />
                  {criteria.map((criterion) => {
                    const score = ev.scores?.find((s) => s.criteriaId === criterion.id);
                    return (
                      <div key={criterion.id} style={styles.scoreCol}>
                        <div style={{ ...styles.scoreText, fontWeight: 400, ...(!ev.isAccepted ? { color: '#dbdbdb' } : {}) }}>
                          {score ? score.value : '-'}
                        </div>
                      </div>
                    );
                  })}
                  <ScoreCard value={ev.scores.length ? ev.average || '-' : '-'} isActive={ev.isAccepted} />
                </div>
              ))}
            </div>
          </Collapse>
        </BorderCard>
      ))}
    </div>
  );
};

interface Props {
  data: IProjectEvaluations[];
  criteria: ICriterion[];
  style?: CSSProperties;
}

EvaluationTable.defaultProps = {
  style: {},
};

export default EvaluationTable;
