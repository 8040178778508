import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router";
import { post } from "../app/libs/utils/request";



const AuthContext = React.createContext({
	token: "",
	isLoggedIn: false,
	login: (response) => { },
	logout: () => { },
});


const retrieveStoredToken = () => {
	const storedToken = localStorage.getItem("token");

	return storedToken;
};

export const AuthContextProvider = (props) => {
	const tokenData = retrieveStoredToken();
	let initialToken;

	if (tokenData) {
		initialToken = tokenData;
	}

	const [token, setToken] = useState(initialToken);
	const history = useHistory();
	const userIsLoggedIn = !!token;

	const logoutHandler = useCallback(async () => {
		try {
			await post('/logout');
		} catch (e) {
			console.log(e);
		} finally {
			setToken(null);
			localStorage.removeItem("token");
			sessionStorage.removeItem('user');
			window.location.replace('/login')
		}
	}, []);

	const loginHandler = (response) => {
		setToken(response.token);
		localStorage.setItem("token", response.token);
		sessionStorage.setItem('user', JSON.stringify(response));

		if (localStorage.getItem("url")) {
			window.location.replace(localStorage.getItem("url"));
			return;
		} else if (response.active && response.trial) {
			window.location.replace("/notActivated");
			return;
		} else if (!response.active && !response.trial) {
			window.location.replace("/trial-ended");
			return;
		}
		window.location.replace('/dashboard')
	};

	const contextValue = {
		token: token,
		isLoggedIn: userIsLoggedIn,
		login: loginHandler,
		logout: logoutHandler,
	};

	return (
		<AuthContext.Provider value={contextValue}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
