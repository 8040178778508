import React from 'react'
import {Button, message} from 'antd'
import {InterestGroupFilter} from './InterestGroupFilter'
import {get, post} from '../../libs/utils/request'
import {GroupCard} from "./GroupCard";
import {isAdmin} from "../../libs/utils/user";
import {Link} from "react-router-dom";
import { SearchBar } from '../common/SearchBar'
import './Groups.css'
import {Helmet} from 'react-helmet';

interface State {
  groups: any
  myGroups: any[] | null
  groupList: any[] | null
  typeFilter: any[]
  searchKeyword: string | null
}

export class Groups extends React.Component<any, State> {

  state: State = {
    groups: null,
    myGroups: null,
    groupList: null,
    typeFilter: [],
    searchKeyword: null
  }

  fetchedTypeFilter = '[]'

  async fetchMyGroups() {
    try {
      const myGroups: any = await get(`/groups/mygroups`)

      this.setState({
        myGroups
      })
    } catch (e) {
      message.error('Failed to fetch: My communities')
    }
  }

  async fetchGroups() {
    try {
      const groupList: any = await get(`/groups/list`, {
        typeFilter: this.state.typeFilter.map(i => i.id)
      })

      this.setState({
        groupList
      })
    } catch (e) {
      if(e.response.status === 403 && e.response.data.extendedUser)
        sessionStorage.setItem("user", JSON.stringify(e.response.data.extendedUser))
        if(!e.response.data.extendedUser.active && !e.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }
      message.warning('Coming soon')
    }
  }

  componentDidMount() {
    this.fetchGroups()
    this.fetchMyGroups()
  }

  async componentDidUpdate() {
    if (JSON.stringify(this.state.typeFilter) !== this.fetchedTypeFilter) {
      this.fetchedTypeFilter = JSON.stringify(this.state.typeFilter)
      if (this.state.searchKeyword) {
        this.search(this.state.searchKeyword)
      } else {
        this.fetchGroups()
      }
    }
  }

  async search(value: string) {
    try {
      const result: any = await post(`/groups/search/list`, {
        typeFilter: this.state.typeFilter.map(i => i.id),
        page: 1,
        count: 100,
        search: value
      })

      const groups: any = [
        ...result.byGroupname,
        ...result.byPosts.map((i:any) => i.group)
      ]

      const final: any = []

      for (const group of groups) {
        if (final.every((i:any) => i.id !== group.id)) {
          final.push(group)
        }
      }

      this.setState({
        searchKeyword: value,
        groups: final
      })
    } catch (e) {
      message.error('Failed to fetch: ' + value)
    }
  }

  searchValue: string = ''

  async onSearch(value: string) {
    this.searchValue = value

    if (!value && this.state.searchKeyword !== null) {
      this.setState({
        searchKeyword: null
      })
      return
    }

    await this.search(value)
  }

  onTypeFilter(typeFilter: any[]) {
    this.setState({typeFilter})
  }

  render() {
    const { myGroups, searchKeyword, groupList, groups } = this.state

    return <div className={'Content-container'}>
      <Helmet>
        <title>Groups | pozi.io</title>
      </Helmet>
      <div className={'Groups-header'}>
        <div style={{display: 'inline-block'}}>
          <h1>Explore the<br/>power of groups!</h1>
          <p>Search amongst the groups of the<br/>community and find connections, chat<br/>and collaborate with them!</p>
          <SearchBar onSearch={value => this.onSearch(value)} />
        </div>
        <div className={'Groups-typefilter'}>
          <InterestGroupFilter onChange={typeFilter => this.onTypeFilter(typeFilter)} />
        </div>
      </div>

      {!searchKeyword && myGroups && myGroups.length > 0 ? <div className={'GroupPage-groupList'}>
        <h1>My communities</h1>
        {myGroups.map((group,i) => <GroupCard key={`${group.id}_${i}`} {...group}/>)}
      </div> : null}

      {searchKeyword
        ? <div className={'Groups-SearchResults'}>
            <h1>"{searchKeyword}" was mentoined in the following groups</h1>
            {groups && groups.map((group:any,i:any) => <GroupCard key={`${group.id}_${i}`} {...group}/>)}
        </div>
        : <div className={'GroupPage-groupList'}>
            <h1>Top communities</h1>
            {groupList && groupList.map((group,i) => <GroupCard key={`${group.id}_${i}`} {...group}/>)}
          </div>
      }
      {isAdmin() ?
        <Link to={`/createGroup`}>
          <Button type={"primary"} size={"large"} style={{marginTop:'32px'}}>
            Create a group
          </Button>
        </Link> : null}
    </div>
  }
}