import React from 'react'
import {CreateNews} from "./CreateNews";
import {CreatePost} from "./CreatePost";
import {CreateEvent} from "./CreateEvent";
import {CreateBlog} from "./CreateBlog";
import {CreateJob} from "./CreateJob";
import {CreateAnnouncement} from "./CreateAnnouncement";
import {post} from "../../libs/utils/request";
import {message} from "antd";

interface Props {
  data: any
  onUpdate: (data: any) => void
}

export class NewsCardEdit extends React.Component<Props, any> {

  async onSubmit(data: any) {
    try {
      if (data.message) {
        data.message = data.message.trim()
      }
      if (data.description) {
        data.description = data.description.trim()
      }
      if (data.text) {
        data.text = data.text.trim()
      }
      const newData = await post(`/news/edit`, {
        id: this.props.data.id,
        data
      })
      message.success(`Post updated`)
      try {
        this.props.onUpdate(newData)
      } catch (e) {
        console.error(e)
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
      console.error(e)
    }
  }

  render() {
    const { type } = this.props.data
    return <div style={{ padding: '32px', paddingTop: '52px' }}>
      {type === 'news' ? <CreateNews data={this.props.data} edit={true} onSubmit={data => this.onSubmit(data)}/> : null}
      {type === 'post' ? <CreatePost data={this.props.data} edit={true} onSubmit={data => this.onSubmit(data)}/> : null}
      {type === 'event' ? <CreateEvent data={this.props.data} edit={true} onSubmit={data => this.onSubmit(data)}/> : null}
      {type === 'blog' ? <CreateBlog data={this.props.data} edit={true} onSubmit={data => this.onSubmit(data)}/> : null}
      {type === 'job' ? <CreateJob data={this.props.data} edit={true} onSubmit={data => this.onSubmit(data)}/> : null}
      {type === 'announcement' ? <CreateAnnouncement data={this.props.data} edit={true} onSubmit={data => this.onSubmit(data)}/> : null}
    </div>
  }
}