import React from "react"
import { MessageThread } from "./MessageInterface";
import moment from "moment";
import { Button } from "antd";
import { Loading } from "../common/Loading";

interface Props {
	open: boolean
	threads: MessageThread[] | null
	threadId: string
	onClick: (message: MessageThread) => void
	onModal: () => void
}

export function ago(sent: Date): string {
	const days = moment(Date.now()).diff(sent, "days")
	if (days === 0) {
		return moment(sent).format('HH:mm')
	}
	return moment(sent).fromNow()
}

export function MessageList({ open, threadId, threads, onClick, onModal }: Props) {
	return <div className={'MessageList' + (open ? ' open' : '')}>
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<h2>Messages</h2>
		</div>
		<div className={'Messages-boxShadow MessageList-box'}>
			<div style={{ padding: '12px', textAlign: 'center' }}>
				<Button
					onClick={() => onModal()}
					loading={false}
					type="primary"
					size="large"
					block
					style={{
						width: '200px'
					}}>New thread</Button>
			</div>
			<div className={'Messages-list Messages-uniqeScrollbar'}>
				{
					!threads || threads.length === 0
						? (
							!threads ? <Loading/> : <div style={{ textAlign: 'center', marginTop: '10%', color: '#444' }}>No messages yet</div>
						)
						: threads.map((thread, i) => {
							const users = thread.users.every(u => u.isYou)
								? [thread.users[0]]
								: thread.users.filter(u => !u.isYou)
	
							return (
								<div
									key={i +'_' + thread.read ? '1': '0'}
									className={'Message-element '+ (threadId === thread.id ? 'Message-selected' : '')}
									onClick={() => onClick(thread)}
								>
									<img src={users[0].profileImage || '/images/user.svg'} alt="" className={'Message-list-profile'} />
	
									<div className={thread.read ? undefined : 'Message-seen'}>
										<div className={'Message-head'}>
											<div>{users.length > 2
												? users[0].firstName + ' '+ users[0].lastName + '  (+' + (users.length - 1) + ')'
												: users.map(u => u.firstName + ' ' + u.lastName).join(', ')}</div>
											<div className={'Message-seen-radius'} />
										</div>
	
										<div className={`Message-textBox`}>
											<div className={'Message-text'}>{thread.lastMessage === undefined ? 'New thread' : thread.lastMessage}</div>
											<div style={{ display: 'inline-block' }}>
												{
													ago(thread.lastMessageDate)
												}
											</div>
										</div>
									</div>
								</div>
							)
						})
				}
			</div>
		</div>
	</div>;
}
