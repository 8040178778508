import React, { CSSProperties } from 'react';
import { Button } from 'antd';
import { IUser } from '../Competition';

const styles = {
  root: {
    width: 280,
    height: 180,
    padding: 16,
    borderRadius: 10,
    backgroundColor: '#FFF',
    boxShadow: '4px 4px 30px rgba(0, 0, 0, 0.09)',
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  },
  imageContainer: {
    minWidth: 96,
    width: 96,
    height: 96,
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: 16,
  },
  image: { width: '100%', height: '100%', objectFit: 'cover' as const },
  rightContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
  },
  nameContainer: {
    maxHeight: 40,
    overflow: 'scroll',
    overflowX: 'hidden' as const,
    overflowY: 'hidden' as const,
    paddingRight: 4,
  },
  name: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 800,
    color: '#000',
    textOverflow: 'ellipsis',
  },
  detail: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 300,
    color: '#000',
  },
  onLinerContainer: {
    maxHeight: 60,
    overflow: 'auto',
    overflowX: 'hidden' as const,
    paddingRight: 4,
  },
  btn: { width: 92, fontWeight: 'bold' as const },
};

const UserCard = ({ user, style }: Props) => {
  const sessionUser = sessionStorage.getItem('user');
  const sessionUserId = sessionUser ? JSON.parse(sessionUser).id : null;

  const navigate = () =>{
    window.open(user.id !== sessionUserId ?  `/entrepreneurs/${user.uniqueTag}` : `/profile`,'_blank')
  }

  return(
    <span style={{ ...styles.root, ...style }} >
    <div style={styles.imageContainer}>
      <img style={styles.image} src={user.profileImage ? `${user.profileImage}?w=96` : '/images/user.svg'} alt='' />
    </div>
    <div style={styles.rightContainer}>
      <div>
        <div className='schedule-scroll' style={styles.nameContainer}>
          <div className='user-name' style={styles.name}>{`${user.firstName} ${user.lastName}`}</div>
        </div>
        <div style={styles.detail}>{`${user?.role?.name ? user.role.name : 'N/A'}`}</div>
      </div>
      <div className='schedule-scroll' style={styles.onLinerContainer}>
        <div className='summary-description' style={styles.detail}>{user.oneLiner}</div>
      </div>
      <Button style={styles.btn} type='primary' size='small' onClick={()=>navigate()}>View</Button>
    </div>
  </span>
)
};

interface Props {
  user: IUser;
  style?: CSSProperties;
}

UserCard.defaultProps = {
  style: {},
};

export default UserCard;
