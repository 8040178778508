import React, { CSSProperties } from 'react';
import { CheckCircleOutlined, MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import '../../Event/Event.css';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  },
  imageContainer: { borderRadius: '50%', overflow: 'hidden' },
  image: { width: '100%', height: '100%', objectFit: 'cover' as const },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 500,
  },
  badge: {
    fontSize: 14,
    marginLeft: 8,
  },
};
const ItemWithIcon = ({
    icon, text, iconSize, gap, textStyle, withStatusIcon, status, link,
  }: Props) => {
    const getIcon = () => {
      switch (status) {
        case 'pending':
          return <QuestionCircleOutlined style={{ ...styles.badge, color: '#6755CE' }} />;
        case 'accepted':
          return <CheckCircleOutlined style={{ ...styles.badge, color: '#52c41a' }} />;
        case 'denied':
          return <MinusCircleOutlined style={{ ...styles.badge, color: '#F00' }} />;
        default:
          return <></>;
      }
    };
  
    const handleClick = (e: any) => {
      e.stopPropagation();
      if (link) {
        window.open(link, '_blank');
      }
    };
  
    return (
      <div style={styles.root}>
        <div style={{
          ...styles.imageContainer,
          minWidth: iconSize,
          width: iconSize,
          height: iconSize,
          marginRight: gap,
        }}
        >
          <img
            style={{
              ...styles.image,
              cursor: link ? 'pointer' : 'auto',
            }}
            src={icon ? `${icon}?w=28` : '/images/user.svg'}
            alt=''
            onClick={handleClick}
            aria-hidden='true'
          />
        </div>
        <div
          className={link ? 'card-name' : ''}
          onClick={handleClick}
          style={{
            ...styles.text,
            ...textStyle,
            cursor: link ? 'pointer' : 'auto',
          }}
          aria-hidden='true'
        >
          {text}
        </div>
        {withStatusIcon && getIcon()}
      </div>
    );
  };
  
  interface Props {
    icon: string;
    text: string;
    iconSize?: number;
    gap?: number;
    textStyle?: CSSProperties;
    withStatusIcon?: boolean;
    status?: 'pending' | 'accepted' | 'denied';
    link?: string;
  }
  
  ItemWithIcon.defaultProps = {
    iconSize: 28,
    textStyle: {},
    gap: 12,
    withStatusIcon: false,
    status: '',
    link: '',
  };
  
  export default ItemWithIcon;
  