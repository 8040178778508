import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { patch, post } from '../../../../libs/utils/request';
import { IFeed } from '../../../Competitions/Competition/components/Feed/Feed';
import ConfirmationDialog from '../../../common/ConfirmationDialog';
import PostForm from './components/PostForm';
import { user } from '../../../../libs/utils/user';

const styles = {
  root: { marginLeft: 16, marginRight: 16 },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 22,
    color: '#282828',
    marginBottom: 30,
  },
  input: {
    width: '100%',
  },
  inputLabel: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    color: '#282828',
    marginBottom: 10,
  },
  uploader: {
    maxWidth: 392,
    height: 140,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'dashed',
    borderWidth: 1,
    borderColor: '#282828',
    padding: 16,
  },
  uploadIcon: { fontSize: 32, marginBottom: 8 },
  uploaderText: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: 14,
    color: '#282828',
    textAlign: 'center' as const,
  },
  accentText: { fontWeight: 900, color: '#6755CE' },
  actionBtn: {
    fontWeight: 'bold' as const,
  },
  footer: {
    width: '100%',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
};

const POST_INIT = {
  message: '',
  // link: '',
};

const PostModal = ({
  visible, onOk, onCancel, postUrl, patchUrl, v2, news, group, newsGroupId
}: Props) => {
  const [cover, setCover] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [formInit, setFormInit] = useState(POST_INIT);
  // const [selectedType, setSelectedType] = useState('Post');
  const isEdit = !!news;



  useEffect(() => {
    if (visible) {
      if (news) {
        setCover(news.image?.url ? news.image.url : '');
        setFormInit({
          message: news.message ? news.message : '',
          // link: news.link ? news.link : '',
        });
      } else {
        setCover('');
        setFormInit(POST_INIT);
      }
    }
  }, [news, visible]);

  const handleCancel = () => {
    setCancelDialogVisible(true);
  };

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    try {
      const body: any = {
        data: {
          image: cover,
          message: values.message,
          link: values.link,
        },
      };
      if (group) {
        body.group = group;
      }
      if(newsGroupId){
        body.newsGroupId=newsGroupId;
      }
      if (isEdit) {
        if (v2) {
          body.type = 'newspost';
          body.id=news.id;
          await post(patchUrl, body);
        } else {
          body.id = news.id;
          await post(patchUrl, body);
        }
      } else {
        body.type = 'newspost';
        await post(postUrl, body);
      }
      onOk();
    } catch (error) {
      message.error(`Failed to ${isEdit ? 'edit' : 'create'} post.`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      footer={null}
      width={800}
      bodyStyle={styles.root}
      closable={!isSubmitting}
    >
      {/* <TypeSelector */}
      {/*  types={['Post', 'Event', 'Job']} */}
      {/*  value={selectedType} */}
      {/*  onChange={setSelectedType} */}
      {/*  style={{ marginBottom: 16 }} */}
      {/* /> */}

      <PostForm
        initialValue={formInit}
        title={isEdit ? 'Edit post' : 'Create post'}
        btnText={isEdit ? 'Edit post' : 'Publish post'}
        isSubmitting={isSubmitting}
        cover={cover}
        setCover={setCover}
        onCancel={handleCancel}
        onSubmit={onSubmit}
      />
      <ConfirmationDialog
        onOk={() => {
          setCancelDialogVisible(false);
          onCancel();
        }}
        onCancel={() => setCancelDialogVisible(false)}
        title='Cancel posting'
        description='Your unsaved modifications will be discarded. Are you sure you want to leave?'
        confirmBtnText='Yes'
        visible={cancelDialogVisible}
      />
    </Modal>
  );
};

interface Props {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  postUrl?: string,
  patchUrl?: string,
  v2?: boolean,
  news?: IFeed;
  group?: string;
  newsGroupId?: string;
}

PostModal.defaultProps = {
  postUrl: null,
  patchUrl: null,
  v2: false,
  news: null,
  group: null,
};

export default PostModal;
