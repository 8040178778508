import React, { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { Formik } from 'formik';
import FormikInput from '../../../../../common/form/FormikInput';
import BorderCard from '../../../../../common/BorderCard';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog';
import { post } from '../../../../../../libs/utils/request';
import { ICriterion } from '../../../Competition';

const styles = {
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 22,
    color: '#282828',
    marginBottom: 30,
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '0px 10px',
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  actionBtn: {
    fontWeight: 'bold' as const,
  },
  footer: {
    width: '100%',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
};

const formValidator = (count: number) => (values: any) => {
  const errors: any = {};
  const names: string[] = [];
  for (let i = 0; i < count; i += 1) {
    const name = values[`name_${i}`];
    if (!name) {
      errors[`name_${i}`] = 'This field is required.';
    } else if (names.includes(name)) {
      errors[`name_${i}`] = 'Please use unique criterion names.';
    } else {
      names.push(name);
    }
    if (!values[`description_${i}`]) {
      errors[`description_${i}`] = 'This field is required.';
    }
  }
  return errors;
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
const getFormByData = (criteria: ICriterion[]) => {
  const res = {};
  criteria.forEach((item, i) => {
    // @ts-ignore
    res[`name_${i}`] = item.title;
    // @ts-ignore
    res[`description_${i}`] = item.description;
  });
  return res;
};

const InputCriteriaModal = ({
  tag, visible, onOk, onCancel, criteria, evaluationStatus,
}: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [formInit, setFormInit] = useState(getFormByData(criteria));
  const nameDisabled = evaluationStatus !== 'INIT';

  useEffect(() => {
    setFormInit(getFormByData(criteria));
  }, [criteria]);

  const handleCancel = () => {
    setCancelDialogVisible(true);
  };

  const onSubmit = async (values: any) => {
    const res = [];
    for (let i = 0; i < criteria.length; i += 1) {
      res.push({
        title: values[`name_${i}`],
        description: values[`description_${i}`],
      });
    }
    try {
      setSubmitting(true);
      await post(`competitions/${tag}/evaluation/criteria`, res);
      onOk();
    } catch (error) {
      message.error('Failed to edit criteria.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      footer={null}
      width={800}
      closable={!isSubmitting}
    >
      <Formik
        enableReinitialize
        initialValues={formInit}
        validate={formValidator(criteria.length)}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }: any) => (
          <>
            <div style={styles.title}>Edit evaluation criteria</div>
            {Array.from(new Array(criteria.length).keys()).map((item) => (
              <BorderCard
                key={item}
                style={styles.inputRow}
                isTop={!item}
                isBottom={item === criteria.length - 1}
              >
                <div style={{ minWidth: 100 }}>
                  <FormikInput
                    name={`name_${item}`}
                    value={values[`name_${item}`]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors[`name_${item}`]}
                    disabled={nameDisabled || isSubmitting}
                  />
                </div>
                <div style={{ minWidth: 200, flexGrow: 1 }}>
                  <FormikInput
                    name={`description_${item}`}
                    value={values[`description_${item}`]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors[`description_${item}`]}
                    disabled={isSubmitting}
                  />
                </div>
              </BorderCard>
            ))}
            <div style={styles.footer}>
              <Button
                style={{ ...styles.actionBtn, color: '#6755CE' }}
                type='link'
                size='large'
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                style={{ ...styles.actionBtn, minWidth: 150 }}
                type='primary'
                size='large'
                onClick={() => handleSubmit()}
                loading={isSubmitting}
              >
                Edit
              </Button>
            </div>
          </>
        )}
      </Formik>
      <ConfirmationDialog
        onOk={() => {
          setCancelDialogVisible(false);
          onCancel();
        }}
        onCancel={() => setCancelDialogVisible(false)}
        title='Cancel criteria edition'
        description='Your unsaved modifications will be discarded. Are you sure you want to leave?'
        confirmBtnText='Yes'
        visible={cancelDialogVisible}
      />
    </Modal>
  );
};

interface Props {
  tag: string;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  criteria: ICriterion[];
  evaluationStatus: 'INIT' | 'OPEN' | 'CLOSED';
}

export default InputCriteriaModal;
