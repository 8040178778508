import { message } from 'antd';
import React, { useEffect } from 'react'
import { useParams } from "react-router";
import eventbus from '../../libs/eventbus';
import { post } from "../../libs/utils/request";
import { Loading } from '../common/Loading';


const ChangeEmail=()=>{
    const {email,resetToken}=useParams()

    useEffect(()=>{
        resetEmail()
    },[])

    const redirect=()=>{
        window.location.replace("/dashboard")
    }

    const resetEmail = async () => {
        await post('/reset-email',{email,resetToken}).then(()=>{
            message.success('Your email was changed successfully', 2)
            eventbus.notify(eventbus.events.logout);
            window.location.replace('/login')
        }).catch((err)=>{
            if(err.response.status===400){
                message.error("It's been already used or you changed your email again.", 2, redirect)
            }
            else if(err.response.status===406){
                message.error('Your email link expired.', 2, redirect)
            }
        })
    }

    return(<Loading/>);
}

export default ChangeEmail;