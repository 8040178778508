import React from 'react';
import DetailListItem from './DetailListItem';

const styles = {
  root: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    marginTop: 32,
    gap: '10px 10px',
    maxWidth:'800px',
    justifyContent:'start'
  },
};

const DetailList = ({
  location, duration, deadline, timezone, isOnline,fee
}: Props) => (
  <div style={styles.root}>
    <DetailListItem label='Location' value={location} icon='../../../../../../images/competitions/location.svg' />
    <DetailListItem label='Duration' value={duration} icon='../../../../../../images/competitions/duration.svg' />
    <DetailListItem label='Application deadline' value={deadline} icon='../../../../../../images/competitions/deadline.svg' />
    <DetailListItem label='Time zone' value={timezone} icon='../../../../../../images/competitions/timezone.svg' />
    <DetailListItem label="Event fee" value={fee===0?"Free":fee.toString()+" $"} icon='../../../../../../images/competitions/fee.png' />
    {isOnline ? <DetailListItem label='Event type' value='Online' icon='../../../../../../images/competitions/online.svg' />:
    <DetailListItem label='Event type' value='Offline' icon='../../../../../../images/competitions/online.svg' />}
  </div>
);

interface Props {
  isFree:string,
  fee:number,
  location: string,
  duration: string,
  deadline: string,
  timezone: string,
  isOnline: boolean,
}

export default DetailList;
