import React from 'react';
import RegisterWallModal from '../common/RegiserWallModal';

const InvitationRegistration = () => {
    return (
        <div>
            <RegisterWallModal visible={true} 
            onCancel={()=> window.location.replace('/profile')}
            title="Invititation"
            />
        </div>
    )
}

export default InvitationRegistration
