import React, { CSSProperties } from 'react';

const textBase = {
  fontSize: 15,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 300,
  color: '#000',
};

const styles = {
  root: {
    width: '100%',
    borderRadius: 7,
    boxShadow: '3.38462px 3.38462px 13.5385px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    cursor: 'pointer',
    backgroundColor: '#FFF',
  },
  image: {
    height: 260,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  content: {
    padding: 16,
  },
  title: {
    ...textBase,
    fontWeight: 800,
  },
  description: {
    ...textBase,
    marginTop: 4,
  },
  link: {
    ...textBase,
    color: '#606060',
    fontSize: 12,
    marginTop: 8,
    fontWeight: 550,
  },
};

const extractHostname = (url: string): string => url.split('/')[url.indexOf('//') > -1 ? 2 : 0].split(':')[0].split('?')[0];
const LinkCard = ({
  link, image, title, description, style,
}: Props) => (
  <div
    style={{ ...styles.root, ...style }}
    onClick={(e) => {
      e.stopPropagation();
      window.open(link, '_blank');
    }}
    aria-hidden='true'
  >
    <div style={{ ...styles.image, backgroundImage: `url(${image})` }} />
    <div style={styles.content}>
      <div style={styles.title}>{title}</div>
      <div style={styles.description}>{description}</div>
      <div style={styles.link}>{extractHostname(link)}</div>
    </div>
  </div>
);

interface Props {
  link: string;
  image: string;
  title: string;
  description: string;
  style?: CSSProperties;
}

LinkCard.defaultProps = {
  style: {},
};

export default LinkCard;
