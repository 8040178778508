import React, { useEffect, useState } from "react";
import { getBaseUrl, post } from "../../libs/utils/request";
import "./ImageToTextEditor.css";
import Quill from "quill";

const ImageToTextEditor = ({ value }: Props) => {
  const [description,setDescription] = useState("");

  var toolbarOptions = [
    ['bold', "italic", "underline", "strike", "image"], // toggled buttons
    ["blockquote", "code-block", "link"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    // [{ 'align': [] }],

    ["clean"], // remove formatting button
  ];
  var editor: Quill;;
  useEffect(()=>{
    editor = new Quill("#editor", {
      modules: {
        toolbar: toolbarOptions,
      },
      theme: "snow",
    });  

    editor.getModule('toolbar').addHandler('image', () => {
      selectLocalImage();
    });
  },[])

    function selectLocalImage() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();

      // Listen upload local image and save to server
      input.onchange = () => {
        const file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
          saveToServer(file);
        } else {
          console.warn('You could only upload images.');
        }
      };
    }


    function saveToServer(file: File) {
      const fd = new FormData();
      fd.append('photo', file);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${getBaseUrl()}/image`, true);
      xhr.onload = () => {
        if (xhr.status === 200) {
          // this is callback data: url
          const url = JSON.parse(xhr.responseText).url;
          insertToEditor(url);
        }
      };
      xhr.send(fd);
    }

    function insertToEditor(url: string) {
      // push image url to rich editor.
      const range = editor.getSelection();
      editor.insertEmbed(range.index, 'image', `${url}`);
    }

    useEffect(()=>{
      document.querySelector(".ql-editor").innerHTML = value;
  
    },[value])

    return (
      <>
        <div id="editor"></div>
      </>
    );
};

interface Props {
  value: string;
}
export default ImageToTextEditor;
