  import React, { useEffect, useState } from 'react';
import { deleteReq, get, post } from '../../libs/utils/request';
import './ProjectPage.css';
import Cover from '../Competitions/components/Cover';
import { useHistory, useLocation  , Prompt,} from 'react-router';
import { Loading } from '../common/Loading';
import {
  Formik, Form, Field, getIn, useField, FormikErrors, setIn, validateYupSchema,
} from 'formik';
import FormikTextArea from '../common/form/FormikTextArea';
import FormikInput from '../common/form/FormikInput';
import ImageUploader from '../common/ImageUploader';
import Select from 'react-select';
import { Button, Popconfirm, Checkbox, message } from 'antd';
import './NewProject.css';
import Team from '../Team/Team';
import Project from './Project';
import { LocationSelector } from '../common/form/LocationSelector/LocationSelector';
import { LocationSelector2 } from '../common/form/LocationSelector';
import * as Yup from 'yup';
import {Joi} from "express-validation"
import { user } from '../../libs/utils/user';

const NewProject = () => {
  const [drafts, setDrafts] = useState(null);
  const [allInterests, setAllinterest] = useState(null);
  const [projectData, setProjectData] = useState(null);

  const [isDraft, setIsDraft] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEditmode, setIsEditmode] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigationBlocked, setNavigationBlocked] = useState(true);
  const [canDelete, setCanDelete] = useState(false)

  const [logoToAdd, setLogoToAdd] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [teamMembers, setTeammembers] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [interestlist, setInteresList] = useState([]);
  const [errorResponse, setErrorResponse] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

  const history = useHistory();
  const location = useLocation();
  const IdfromRoute = location.state;

  const [formValue, setFormValues] = useState({
    name: '',
    subtitle: '',
    whatWeNeed: '',
    pitchLink: '',
    commentable: true,
    additionalInfo: '',
    highlights: {
      market: '',
      status: '',
      website: '',
      monthlyBurn: 0,
      capitalSeeking: 0,
      email: '',
      phone: '',
    },
    location: {
      placeId: '',
      description: '',
    },
    interests: [],
    details: {
      advanteges: '',
      businessLogic: '',
      businessModel: '',
      competitors: '',
      customer: '',
      customerProblem: '',
      market: '',
      marketingStrategy: '',
      product: '',
      salesStrategy: '',
      solution: '',
      summary: '',
    },
    teamMembers: [],
    financial: {
      year1: {
        financialyear: null,
        revenues: 0,
        expenses: 0,
        net: 0,
      },
      year2: {
        financialyear: null,
        revenues: 0,
        expenses: 0,
        net: 0,
      },
      year3: {
        financialyear: null,
        revenues: 0,
        expenses: 0,
        net: 0,
      },
      year4: {
        financialyear: null,
        revenues: 0,
        expenses: 0,
        net: 0,
      },
    },
  });
  const financeInputStyle = {
    width: '80px',
  };
  const locationInputStyle = {
    width: '100%',
  };

  const getInterestList = async () => {
    // load interests for editing if they are not already loaded
    if (!allInterests) {
      try {
        const allInterests = await get<any[]>('/interests');
        setAllinterest(allInterests);
      } catch (err) {
        // console.log(err);
      }
    }
  };

  // get draft | project by id what you get from the router
  const getDraftById = async (id?: string) => {
    try {
      let draftId;
      if(IdfromRoute===undefined){
        draftId= location.pathname.split('/');
        
      }
      // setLoading(true)
      const draft: any = await get<{ drafts: any }>(
        `/projects/draft/${IdfromRoute!==undefined?IdfromRoute:draftId[2]}`,
      );
      setCanDelete(user().id===draft.user.id)

      setFormValues(draft);
      if (draft.image) {
        setCoverImage(draft.image);
      }
      if (draft.logo) {
        setLogoToAdd(draft.logo);
      }

      if (draft.interests.length > 0) {
        setSelectedInterests(draft.interests.map((i:any) => i.id));
        setInteresList(draft.interests.map((interests:any) => ({
          label: interests.name,
          value: interests.id,
        })));
      }

      setTeamMemberList(draft.teamMembers);
    } catch (err) {
      // console.log('Error in get project');

      // console.log(err);
    }
  };

  const getProjectById = async (id?: string) => {
    try {
      let draft;
      if(IdfromRoute===undefined){
        draft= location.pathname.split('/');
        
      }
      const project: any = await get<{ projects: any }>(
        `/projects/${IdfromRoute!==undefined?IdfromRoute:draft[2]}`,
      );

      setCanDelete(user().id===project.user.id)

      if (project.logo) {
        setLogoToAdd(project.logo);
      }
      if (project.image) {
        setCoverImage(project.image);
      }

      if (project.interests.length > 0) {
        setSelectedInterests(project.interests.map((i:any) => i.id));
        setInteresList(project.interests.map((interests:any) => ({
          label: interests.name,
          value: interests.id,
        })));
      }

      setFormValues(project);
      setTeamMemberList(project.teamMembers);
    } catch (err) {
      // console.log(err);
    }
  };

  const deleteProject = async () => {
    await deleteReq(`/projects/${IdfromRoute}`);
    history.push('/profile');
  };

  const deleteDraft = async () => {
    await deleteReq(`projects/draft/${IdfromRoute}`);
    history.push('/profile');
  };

  // image && logo change
  const changeCover = (newImage: string) => {
    const url: string = coverImage;
    setCoverImage(newImage);
  };

  const onLogoChange = (url: string) => {
    setLogoToAdd(url);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    window.onbeforeunload = navigationBlocked ? () => true : undefined;
    return () => {
      // eslint-disable-next-line no-undefined
      window.onbeforeunload = undefined;
    };
  }, [navigationBlocked]);

  useEffect(() => {
    if (location.pathname.includes('edit-startup-draft')) {
      setIsDraft(true);
    }
    if (location.pathname.includes('new')) {
      setIsNew(true);
    }
    if (
      location.pathname.includes('edit-startup')
      && !location.pathname.includes('draft')
    ) {
      setIsEditmode(true);
    }
  }, [isEditing]);

  useEffect(() => {
    getInterestList();

    if (isDraft) {
      getDraftById();
    }
    if (isEditmode) {
      getProjectById();
    } else {
    }
  }, [isDraft, isEditmode]);

  // c
  const handleMultiChange = (options: any) => {
    if (options.length <= 5) {
      setInteresList(options);
    } else {
      options = options.splice(0, 5);
      setInteresList(options);
    }
    setSelectedInterests(options.map((i:any) => i.value));
  };

  const onSubmit = async (values: any, e: any) => {
    const proj = {
      ...values,
      teamMembers,
      image: coverImage,
      logo: logoToAdd,
      eventTags: [],
      interests: selectedInterests,
    };
    setNavigationBlocked(false)

    if (isDraft) {
      delete Object.assign(proj, { draftId: proj.id }).id;
    }

    try {
      const response: any = await post('/projects/', proj)
        .catch((err) => {
          setErrorResponse(err?.response?.data.details);
          if(err.response.status === 403){
            message.warning(`${profileProgress}% of your profile is completed. To write a comment you need to get at least 70%`)
          }
        });
      history.push(`/startups/${response.uniqueTag}`);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleDraft = async (type: string, values: any, e: any) => {
    // console.log(values, 'event');
    const draft = {
      ...values,
      teamMembers,
      image: coverImage,
      logo: logoToAdd,
      interests: selectedInterests,
      eventTags: [],

    };
    setNavigationBlocked(false)
    await post('/projects/draft/', draft);

    history.push('/startups');
  
  };

  const locationDisplay = (object:any) => {
    if (object.location && object.location.description) {
      return object.location.description;
    }
    if (object && object.location && object.location.city) {
      return `${object.location.country}, ${object.location.city}`;
    }
    if (object && object.location && !object.location.city) {
      return object.location.country;
    }
    return '';
  };

  const checkInitialValue = () => (false ? drafts : formValue);

  const errorMsg = errorResponse
    ? errorResponse.map((error) => Object.values(error).map((err) => (<li>{err}</li>),
    ))
    : null;

  const checkerrors = (errors:any) => {
    document.getElementById('errors').innerHTML = '';
    Object.values(errors).map((error:any) => {
      if (typeof (error) === 'object') {
        Object.values(error).map((err:any) => {
          if(typeof(err)==='object'){
          Object.values(err).map((e:any) => {
            document.getElementById('errors').append(e, '\n');
          })
          } else document.getElementById('errors').append(err, '\n');
        });
      } else document.getElementById('errors').append(error.toString(), '\n');
    });
    if (interestlist.length === 0) document.getElementById('errors').append('At least 1 category is required', '\n');
    if (teamMembers[0].title === '' || teamMembers[0].description === '') document.getElementById('errors').append('Fill your position in your team', '\n');
  };

  const checkDraftErrors = (errors:any, values:any) => {
    document.getElementById('errors').innerHTML = '';
    Object.keys(errors).map((error)=>{
      if(error==='name'&&values.name.length<=3){
        document.getElementById('errors').append('Please check your title', '\n');
      }
    })
  }

  const checkLink=(e:any)=>{
    if(e.target.value.length > 7 && !/^https?:\/\//.test(e.currentTarget.value)){
      e.currentTarget.value='http://'+e.currentTarget.value
    }
  }

  if (loading) {
    return (
      <div className='project-container'>
        <Loading />
      </div>
    );
  }
  

  return (

    <>
    <Prompt
    when={navigationBlocked}
    message='You have unsaved changes, are you sure you want to leave?'
    />  
      <div className='project-container'>
        <div className='project-container-inner'>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .min(3, 'Title must contain minimum 3 characters')
                .max(37, 'The title is longer than 37 characters')
                .required('Title is required'),
              subtitle: Yup.string()
                .min(3, 'Subtitle must contain minimum 3 characters')
                .max(100, 'The subitile is longer than 100 characters')
                .required('Subtitle is required'),
              pitchLink: Yup.string()
              .url(),
              additionalInfo: Yup.string()
                .url('Please enter a valid URL'),
              highlights: Yup.object().shape({
                email: Yup.string()
                  .test('test-email', 'Email is invalid', 
                    function(value) {
                     
                      const test = Joi.string().email({ tlds: {allow: false} }).required().validate(value);
                     
                      return test.error?false:true
                    })
                  .required('Email is required'),
                website: Yup.string()
                  .url('Please enter a valid URL'),
                monthlyBurn: Yup.number().test('len', 'Monthly net burn must be a valid number', val => typeof(val)==='number'),
                capitalSeeking: Yup.number().test('len', 'Capital seeking must be a valid number', val => typeof(val)==='number'),
                phone: Yup.string()
                  .matches(/^\+?[\d -\.\(\)]{3,70}$/, 'Please enter valid number'),
              }),
              details: Yup.object().shape({
                summary: Yup.string()
                  .required('Summary is required'),
              }),
              location: Yup.object().nullable().required().shape({
                placeId: Yup.string().required('Location is required'),
              }),
              financial:Yup.object().shape({
                year1:Yup.object().shape({
                  revenues: Yup.number().test('len', 'Revenue in first year must be a valid number', val => typeof(val)==='number'),
                  expenses: Yup.number().test('len', 'Expense in first year must be a valid number', val => typeof(val)==='number'),
                }),
                year2:Yup.object().shape({
                  revenues: Yup.number().test('len', 'Revenue in second year must be a valid number', val => typeof(val)==='number'),
                  expenses: Yup.number().test('len', 'Expense in second year must be a valid number', val => typeof(val)==='number'),
                }),
                year3:Yup.object().shape({
                  revenues: Yup.number().test('len', 'Revenue in third year must be a valid number', val => typeof(val)==='number'),
                  expenses: Yup.number().test('len', 'Expense in third year must be a valid number', val => typeof(val)==='number'),
                }),
                year4:Yup.object().shape({
                  revenues: Yup.number().test('len', 'Revenue in fourth year must be a valid number', val => typeof(val)==='number'),
                  expenses: Yup.number().test('len', 'Expense in fourth year must be a valid number', val => typeof(val)==='number'),
                })
              })

            })}
            enableReinitialize
            initialValues={
              checkInitialValue()
            }
            onSubmit={(values, e) => { onSubmit(values, e); }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              validateField,
            }) => (
              <div className='Project'>
                <Cover url={coverImage} onChange={changeCover} rootStyle={{borderBottomLeftRadius:0,borderBottomRightRadius:0}}/>
                <div className='logo-uploader'>
                  <ImageUploader
                    className='logo-uploader'
                    uploadText='Upload logo Recommended 139x139 '
                    images={logoToAdd}
                    type='single'
                    displayAllowed={false}
                    onChange={(url: string) => {
                      onLogoChange(url);
                    }}
                    showCropper = {true}
                    cropperData = {{width: 139 , height: 139 }}
                  />
                </div>
                <Form>
                  <div className='project-cover'>
                    <div className='title-inputs-logo'>
                      <div className='title-inputs'>
                        <FormikInput
                          name='name'
                          value={values.name}
                          onChange={(e:any)=>{
                            if(e.currentTarget.value.length<38)
                              handleChange(e)
                          }}
                          onBlur={handleBlur}
                          placeholder='Title(required)'
                          withError
                          error={errors.name && touched.name ? errors.name : ''}
                          autofocus
                        />
                        <FormikInput
                          name='subtitle'
                          value={values.subtitle}
                          onChange={(e:any)=>{
                            if(e.currentTarget.value.length<141)
                              handleChange(e)
                          }}
                          onBlur={handleBlur}
                          placeholder='Subtitle(required)'
                          withError
                          error={errors.subtitle && touched.subtitle ? errors.subtitle : ''}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='project-body edit-project-body '>
                    <div className='project-body-cards-edit'>
                      <div className='card highlights edit'>
                        <h1 className='card-title-editmode first-title'>
                          Startup highlights
                        </h1>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Pitch link</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/pitchlink3.svg'
                              alt=''
                            />
                            <FormikInput
                              name='pitchLink'
                              value={values.pitchLink}
                              onChange={(e:any)=>{
                                checkLink(e)
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              placeholder='Copy link here'
                              error={errors.pitchLink ? errors.pitchLink : ''}
                            />
                          </div>
                        </div>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Additional info</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/addInfo.svg'
                              alt=''
                            />
                            <FormikInput
                              name='additionalInfo'
                              value={values.additionalInfo}
                              onChange={(e:any)=>{
                                checkLink(e)
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              placeholder='Insert a link here'
                              error={errors.additionalInfo && touched.additionalInfo ? errors.additionalInfo : ''}
                            />
                          </div>
                        </div>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Market</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/market.svg'
                              alt=''
                            />
                            <Field
                              as='select'
                              className='select-box'
                              value={values.highlights.market}
                              name='highlights.market'
                            >
                              <option>Select your market</option>
                              <option value='B2C (Business-to-Consumer)'>B2C (Business-to-Consumer)</option>
                              <option value='B2B (Business-to-Business)'>B2B (Business-to-Business)</option>
                              <option value='C2B (Consumer-to-Business)'>C2B (Consumer-to-Business)</option>
                              <option value='C2C (Consumer-to-Consumer)'>C2C (Consumer-to-Consumer)</option>
                              <option value='B2G (Business-to-Government)'>B2G (Business-to-Government)</option>
                              <option value='D2C (Direct-to-Consumer)'>D2C (Direct-to-Consumer)</option>
                            </Field>
                          </div>
                        </div>

                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Status</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/status.svg'
                              alt=''
                            />
                            <Field
                              as='select'
                              className='select-box'
                              value={values.highlights.status}
                              name='highlights.status'
                            >
                              <option>Select startup's status</option>
                              <option value='Pre-seed'>Pre-seed</option>
                              <option value='Seed'>Seed</option>
                              <option value='Prototype'>Prototype</option>
                              <option value='Market ready'>Market ready</option>
                              <option value='Scale-up'>Scale-up</option>
                              <option value="Bootstrapping">Bootstrapping</option>
                            </Field>
                          </div>
                        </div>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Monthly net burn</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/monthlyBurn.svg'
                              alt=''
                            />
                            <FormikInput
                              name='highlights.monthlyBurn'
                              value={values.highlights.monthlyBurn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type='number'
                              placeholder='0'
                              prefix='$'
                              error={getIn(errors, 'highlights.monthlyBurn') && getIn(touched, 'highlights.monthlyBurn') ? getIn(errors, 'highlights.monthlyBurn') : ''}
                              />
                          </div>
                        </div>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Capital seeking</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/capitalSeeking.svg'
                              alt=''
                            />
                            <FormikInput
                              name='highlights.capitalSeeking'
                              value={values.highlights.capitalSeeking}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type='number'
                              placeholder='0'
                              prefix='$'
                              error={getIn(errors, 'highlights.capitalSeeking') && getIn(touched, 'highlights.capitalSeeking') ? getIn(errors, 'highlights.capitalSeeking') : ''}
                              />
                          </div>
                        </div>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Website</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/website.svg'
                              alt=''
                            />
                            <FormikInput
                              name='highlights.website'
                              value={values.highlights.website}
                              onChange={(e:any)=>{
                                checkLink(e)
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              placeholder='Website'
                              error={getIn(errors, 'highlights.website') && getIn(touched, 'highlights.website') ? getIn(errors, 'highlights.website') : ''}
                            />
                          </div>
                        </div>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight required-edit'>E-mail</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/email.svg'
                              alt=''
                            />
                            <FormikInput
                              name='highlights.email'
                              value={values.highlights.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='E-mail'
                              error={getIn(errors, 'highlights.email') && getIn(touched, 'highlights.email') ? getIn(errors, 'highlights.email') : ''}
                            />
                          </div>
                        </div>
                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight'>Phone</h1>
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/phone.svg'
                              alt=''
                            />
                            <FormikInput
                              type="text"
                              name='highlights.phone'
                              value={values.highlights.phone+""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Phone'
                              error={getIn(errors, 'highlights.phone') && getIn(touched, 'highlights.phone') ? getIn(errors, 'highlights.phone') : ''}
                            />
                          </div>
                        </div>

                        <div className='edit-highlight-item'>
                          <h1 className='item-title-highlight-location required-edit'>Location</h1>
                          <img className='powered-by-google-onepager-edit' src='/images/google/powered_by_google.png' alt='Powered by Google' />
                          <div className='inputs-field'>
                            <img
                              className='details-img'
                              src='/images/project_highlight_icons/country.svg'
                              alt=''
                            />

                            <LocationSelector2
                              name='location'
                              placeholder={locationDisplay(values)}
                              style={locationInputStyle}
                              value={locationDisplay(values)}
                              onChange={(e:any) => {
                                setFieldValue('location.placeId', (JSON.parse(e).placeId));
                                setFieldValue('location.description', (JSON.parse(e).description));
                              }}
                            />

                          </div>
                          {touched.location && errors.location
                            ? (
                              <div className='error-container-onepager-location'>
                                <div className='error-onepager'>Please add your location</div>
                              </div>
                            ) : ''}
                        </div>

                      </div>
                      <div className='card details'>
                        <h1 className='card-title-editmode'>Startup details</h1>
                        <div className='details-container'>

                          <div className='details-item'>
                            <h1 className='item-title required-edit'>
                              Summary (Elevator pitch)
                            </h1>
                            <FormikTextArea
                              name='details.summary'
                              value={values.details.summary}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Summary (Elevator pitch)'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              error={getIn(errors, 'details.summary') && getIn(touched, 'details.summary') ? getIn(errors, 'details.summary') : ''}
                              helper='A short, focused description of your project'
                              maxLength={140}
                            />
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Business model</h1>
                            <FormikTextArea
                              name='details.businessModel'
                              value={values.details.businessModel}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Business model'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Describe briefly, how you are planning on
                              generating revenue
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Customer problem</h1>
                            <FormikTextArea
                              name='details.customerProblem'
                              value={values.details.customerProblem}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Customer problem'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Say clearly and concisely what problem you have
                              chosen to solve
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Business logic</h1>
                            <FormikTextArea
                              name='details.businessLogic'
                              value={values.details.businessLogic}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Business logic'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Describe the major project milestones
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Solution</h1>
                            <FormikTextArea
                              name='details.solution'
                              value={values.details.solution}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Solution'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              State how you intend to solve the problem and
                              why people will switch from their current
                              solution
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Competitors</h1>
                            <FormikTextArea
                              name='details.competitors'
                              value={values.details.competitors}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Competitors'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Mention substitutes and / or existing solutions
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Product/Service</h1>
                            <FormikTextArea
                              name='details.product'
                              value={values.details.product}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Product/Service'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Introduce your product or service with your core
                              offering
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>
                              Competitive advantages
                            </h1>
                            <FormikTextArea
                              name='details.advanteges'
                              value={values.details.advanteges}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Competitive advantages'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Tell us what do you do better than the others
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Customer</h1>
                            <FormikTextArea
                              name='details.customer'
                              value={values.details.customer}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Customer'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Identify the customer served
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Sales strategy</h1>
                            <FormikTextArea
                              name='details.salesStrategy'
                              value={values.details.salesStrategy}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Sales strategy'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Tell us your plans for selling your product
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Market</h1>
                            <FormikTextArea
                              name='details.market'
                              value={values.details.market}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Market'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              Give an estimate of the total addressable market
                              (e.g. number of users)
                            </div>
                          </div>
                          <div className='details-item'>
                            <h1 className='item-title'>Marketing strategy</h1>
                            <FormikTextArea
                              name='details.marketingStrategy'
                              value={values.details.marketingStrategy}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Marketing strategy'
                              autoSize={{ minRows: 5, maxRows: 6 }}
                              maxLength={140}
                            />
                            <div className='area-subscription'>
                              What creative channels and tools do you plan to use?
                              Be specific. Paid facebook and google ads are not
                              interesting for us (except if you have $1M + free coupon)
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className='card interests'>
                        <h1 className='card-title-editmode' id='category-editmode'>Startup category:</h1>
                        <h4 className='card-subtitle-editmode-category'>
                        Select max 5 categories.
                        </h4>
                        {allInterests && (
                          <Select
                            name='interestList'
                            placeholder='Choose your category..'
                            value={interestlist}
                            options={allInterests.map((interest: any) => ({
                              label: interest.name,
                              value: interest.id,
                            }))}
                            onChange={handleMultiChange}
                            isMulti
                          />
                        )}
                        
                      </div>

                      <div className='card financial'>
                        <h1 className='card-title-editmode-financial'>Financial (USD)</h1>
                        <h4 className='card-subtitle-editmode-financial'>
                          With, or without investment
                        </h4>
                        <div className='financial-table-container'>
                          <div className='financial-table'>
                            <div className='row'>
                              <span className='title'>Years</span>
                              {Object.keys(formValue.financial).map(
                                (year: any, index) => (
                                  <span
                                    className='financial-row-edit'
                                    key={`${year}.financialyear`}
                                  >
                                    <FormikInput
                                      inputStyle={financeInputStyle}
                                      type='number'
                                      name={`financial.year${
                                        index + 1
                                      }.financialyear`}
                                      value={
                                        values.financial[year]?.financialyear
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder={`Year${index + 1}`}
                                    />
                                  </span>
                                ),
                              )}
                            </div>
                            <div className='row'>
                              <span className='title'>Revenues</span>
                              {Object.keys(formValue.financial).map(
                                (year: any, index) => (
                                  <span
                                    className='financial-row-edit'
                                    key={`${year}`}
                                  >
                                    <FormikInput
                                      inputStyle={financeInputStyle}
                                      type='number'
                                      name={`financial.year${
                                        index + 1
                                      }.revenues`}
                                      value={values.financial[year]?.revenues}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      prefix='$'
                                      error={getIn(errors, `financial.year${index + 1}.revenues`) && getIn(touched, `financial.year${index + 1}.revenues`) ? getIn(errors, `financial.year${index + 1}.revenues`) : ''}
                                      //  placeholder='0'
                                    />
                                  </span>
                                ),
                              )}
                            </div>
                            <div className='row'>
                              <span className='title'>Expenses</span>
                              {Object.keys(formValue.financial).map(
                                (year: any, index) => (
                                  <span
                                    className='financial-row-edit'
                                    key={`${year}`}
                                  >
                                    <FormikInput
                                      inputStyle={financeInputStyle}
                                      type='number'
                                      name={`financial.year${
                                        index + 1
                                      }.expenses`}
                                      value={values.financial[year]?.expenses}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder='year.expensens'
                                      prefix='$'
                                      error={getIn(errors, `financial.year${index + 1}.expenses`) && getIn(touched, `financial.year${index + 1}.expenses`) ? getIn(errors, `financial.year${index + 1}.expenses`) : ''}
                                    />
                                    <div>{year.expensens}</div>
                                  </span>
                                ),
                              )}
                            </div>
                            <div className='row'>
                              <span className='title'>Net</span>
                              {Object.keys(formValue.financial).map(
                                (year: any, index) => (
                                  <span
                                    className='financial-row'
                                    key={`${year}`}
                                  >
                                    <div>
                                      $
                                      {' '}
                                      {values.financial[year]?.revenues
                                        - values.financial[year]?.expenses}
                                    </div>
                                  </span>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card team-member-holder'>
                        <Team
                          teamMembers={teamMemberList || []}
                          isDraft={isDraft}
                          isEdit={isEditmode}
                          isNew={isNew}
                          Callback={setTeammembers}
                          project={formValue}
                        />
                      </div>
                      <div className='card awards'>
                        <h1 className='card-title-editmode'>What we need</h1>
                        <FormikTextArea
                          name='whatWeNeed'
                          value={values.whatWeNeed}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder=''
                          autoSize={{ minRows: 6, maxRows: 6 }}
                          maxLength={300}
                        />
                        <div className='area-subscription'>
                          We are looking for a mentor/investor/team
                          member/ investor or We need help with designing
                          our wireframe / We need help with validation
                        </div>
                      </div>

                      <div className='Project-checkboxes'>
                        <div>
                          <Checkbox
                            name='values.commentable'
                            value={values.commentable}
                            className='commentable-box'
                            checked={values.commentable}
                            onChange={() => {
                              setFieldValue('commentable', !values.commentable);
                            }}
                          >
                            Commentable
                          </Checkbox>
                        </div>

                        {!isNew && (
                          <div className='edit-onepager-delete'>
                            <Popconfirm
                              title='Are you sure delete this project?'
                              onConfirm={isDraft ? deleteDraft : deleteProject}
                              okText='Yes'
                              cancelText='No'
                            >
                              {canDelete&&<Button className='Delete-button' type='danger'>
                                {isDraft ? 'Delete draft' : 'Delete startup'}
                              </Button>}
                            </Popconfirm>
                          </div>
                        )}
                      </div>
                    </div>
                    <ul id='errors' className='errors-frombe-onepager' />
                  </div>
                  <div className='launch-buttons'>
                    {!isEditmode && (

                      <Button
                        id='savebutton'
                        size='large'
                        className='launch-btn'
                        onClick={(e: any) =>{
                          validateField('name')
                          handleDraft('savedraft', values, e);
                          checkDraftErrors(errors,values)
                        }}
                      >
                        Save Draft
                      </Button>
                    )}

                    <Button
                      size='large'
                      onClick={() => history.push('/startups')}
                      className='launch-btn'
                    >
                      Cancel
                    </Button>
                    <Button
                      type='primary'
                      size='large'
                      className='launch-btn'
                      onClick={(e: any) => { handleSubmit(e); checkerrors(errors); }}
                    >
                      {!isEditmode ? 'Launch' : 'Update'}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default NewProject;
