import React, { useEffect, useState } from 'react';
import { MoreOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';
import { Dropdown, Menu, message } from 'antd';
import Card from '../../common/card/Card';
import SectionButton from './SectionButton';
import { SubPageEnum } from './SubPageEnum';
import { havePermission } from '../Event/Event';
import { ShareMenu2 } from '../../common/ShareMenu2';
import { hasPermission, isAdmin, user } from '../../../libs/utils/user';
import { deleteReq } from '../../../libs/utils/request';
import ConfirmationDialog from '../../common/ConfirmationDialog';

const styles = {
  root: {
    marginTop: -80,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 42,
    paddingRight: 25,
    borderRadius: 15,
    maxWidth: 800,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'start',
    gap: '16px',
    marginTop: 18,
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 26,
    lineHeight: '29px',
  },
  actionBtnContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '16px 16px',
    marginRight: -13,
    marginLeft: 4,
  },
  moreIcon: {
    fontWeight: 900,
    fontSize: 31,
    maxHeight: 31,
    cursor: 'pointer',
    minWidth: 40,
  },
  actionMenuItem: {
    minWidth: 100,
  },
};

const shareLinkBase = `${window.location.protocol}//${window.location.host}`;

const TitleCard = ({
  name, tag, userRoles, onMessage,
  withdrawButtonVisible, onWithdraw, onGenerateSummary, onAddPin, pin, needRedirect
}: Props) => {
  const [sections, setSections] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { subPage } = useParams();
  const history = useHistory();

  useEffect(() => {
    const tmp: ISection[] = [{ name: 'Summary' }];
    if (user()  && !needRedirect) {
      tmp.push({ name: 'Participants', page: SubPageEnum.participants });
      tmp.push(
        { name: 'Feed', page: SubPageEnum.feed },
      );
    }
    if (user()  && needRedirect) {
      tmp.push(
        { name: 'Feed', page: SubPageEnum.feed },
      );
    }
    setSections(tmp);
  }, [userRoles]);

  const deleteEvent = async () => {
    try {
      await deleteReq(`/events/${tag}`);
      history.replace('/events');
    } catch (error) {
      message.error('Failed to delete event.');
    }
  };

  const getActionMenu = () => {
    const isOrganizer = havePermission(['Organizer', 'Co-organizer'], userRoles);
    return (
      <Menu>
        {(user() && !isOrganizer) && (
          <Menu.Item>
            <div style={styles.actionMenuItem} onClick={onMessage} aria-hidden='true'>
              Send a message
            </div>
          </Menu.Item>
        )}
        {(user() && isAdmin()) && (
          <>
          <Menu.Item>
            <div
              style={{cursor:"pointer", padding:'5px 12px', minWidth:'100px'}}
              onClick={onGenerateSummary}
              aria-hidden='true'
            >
              Generate report
            </div>
          </Menu.Item>
          <Menu.Item>
          <div
              style={{cursor:"pointer", padding:'5px 12px', minWidth:'100px'}}
              onClick={onAddPin}
            aria-hidden='true'
          >
            {!pin?"Pin":"Unpin"}
          </div>
        </Menu.Item>
        </>
        )}
        {(isOrganizer || withdrawButtonVisible) && <Menu.Divider />}
        {withdrawButtonVisible && (
          <Menu.Item>
            <div style={{ ...styles.actionMenuItem, color: '#F00' }} onClick={onWithdraw} aria-hidden='true'>
              Withdraw my application
            </div>
          </Menu.Item>
        )}
        {(isOrganizer || isAdmin()) && (
          <Menu.Item>
            <div
              style={styles.actionMenuItem}
              onClick={() => {
                history.push(`/edit-event/${tag}`);
              }}
              aria-hidden='true'
            >
              Edit event
            </div>
          </Menu.Item>
        )}
        {(isOrganizer || isAdmin()) && (
          <Menu.Item>
            <div style={{ ...styles.actionMenuItem, color: '#F00',textAlign:'start' }} onClick={() => setDeleteModalVisible(true)} aria-hidden='true'>
              Delete event
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Card className='title-card' style={styles.root}>
      <div style={styles.topContainer}>
        <div style={styles.title}>
          {name}
        </div>
        <div style={styles.actionBtnContainer}>
          <Dropdown overlay={<ShareMenu2 shareURL={`${shareLinkBase}/events/${tag}`} />} placement='bottomCenter' trigger={['click']}>
            <ShareAltOutlined style={styles.moreIcon} />
          </Dropdown>
          {user()&&<Dropdown overlay={getActionMenu()} placement='bottomLeft'>
            <MoreOutlined style={styles.moreIcon} />
          </Dropdown>}
        </div>
      </div>
      <div style={styles.bottomContainer}>
        {sections.map((section) => (
          <SectionButton
            key={section.name}
            name={section.name}
            isActive={section.page === subPage}
            onClick={() => {
              history.push(`/events/${tag}${section.page ? `/${section.page}` : ''}`);
            }}
          />
        ))}
      </div>
      <ConfirmationDialog
        visible={deleteModalVisible}
        onOk={() => {
          setDeleteModalVisible(false);
          deleteEvent();
        }}
        onCancel={() => setDeleteModalVisible(false)}
        title='Delete event'
        description='Are you sure you want to delete event? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />
    </Card>
  );
};

interface Props {
  name: string;
  tag: string;
  userRoles: string[];
  onMessage: () => void;
  withdrawButtonVisible?: boolean;
  onWithdraw?: () => void;
  onGenerateSummary?: () => void;
  onAddPin?:()=>void;
  pin?:boolean;
  needRedirect?: boolean;
}

interface ISection {
  name: string;
  page?: string;
}

export default TitleCard;
