import React, { CSSProperties, useState } from 'react';
import { MoreOutlined, StarFilled } from '@ant-design/icons';
import {
  Dropdown, Icon, Menu, message, Popover, Rate,
} from 'antd';
import { IFeed } from '../../../Competitions/Competition/components/Feed/Feed';
import OwnerBox from '../../../Competitions/components/OwnerBox';
import { post } from '../../../../libs/utils/request';
import { Comments } from '../../../comments/Comments';
import { hasPermission, user } from '../../../../libs/utils/user';
import { ShareMenu } from '../../../common/ShareMenu';
import ImageCard from './components/ImageCard';
import LinkCard from './components/LinkCard';
import ConfirmationDialog from '../../../common/ConfirmationDialog';
import './NewsCard.css'

const CARD_BORDER = '.5px solid #D1D1D1';
const baseText = {
  fontSize: 15,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 300,
  color: '#000',
};
const styles = {
  root: {
    backgroundColor: '#FFF',
    padding: 16,
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    gap: '20px 20px',
  },
  moreIcon: {
    fontWeight: 900,
    fontSize: 31,
    maxHeight: 31,
    cursor: 'pointer',
  },
  topCard: {
    border: CARD_BORDER,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    
  },
  card: {
    border: CARD_BORDER,

  },
  bottomCard: {
    border: CARD_BORDER,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  message: {
    ...baseText,
    marginTop: 15,
    overflowWrap: 'break-word' as const,
    wordWrap: 'break-word' as const,
    wordBreak: 'break-word' as const,
  },
  innerCard: {
    marginTop: 16,
  },
  socialTextBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    marginBottom: 16,
    marginTop: 20,
  },
  socialText: {
    ...baseText,
    fontSize: 15,
    fontWeight: 800,
    lineHeight: '19px',
    color: '#262626',
  },
  link: {
    ...baseText,
    color: '#6755CE',
    marginTop: 8,
    cursor: 'pointer',
    wordBreak: 'break-word' as const,
  },
  star: { color: '#6755CE', marginRight: 8, marginLeft: 2 },
  actionMenuItem: {
    minWidth: 100,
  },
};

const NewsCard = ({
  news, isTop, isBottom, onChange, style, withMenu, onEdit, onDelete, shareLink, shareDisabled,
}: Props) => {
  const [commentVisible, setCommentVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  const getCardStyle = () => {
    let res = isTop ? styles.topCard : {};
    if (isBottom) {
      res = { ...res, ...styles.bottomCard };
    }
    return { ...res, ...styles.card };
  };
  console.log
  const onRateChange = async (rate: number) => {
    try {
      await post('/rating/post', { rate, id: news.ratingGroup.id });
      onChange();
    } catch (err) {
      if(err.response.status === 403){
        message.warning(`${profileProgress}% of your profile is completed. To write a comment you need to get at least 70%`)
      }
      else{
       message.error('Rating failed');
      }
    }
  };

  const getActionMenu = () => {
    const isOwn = user()?.id === news.user.id;
    return (
      <Menu>
        {!isOwn && (
          <Menu.Item>
            <div style={styles.actionMenuItem} onClick={() => message.warning('Report not yet implemented.')} aria-hidden='true'>
              Report post
            </div>
          </Menu.Item>
        )}
        {isOwn && (
          <Menu.Item>
            <div style={styles.actionMenuItem} onClick={onEdit} aria-hidden='true'>
              Edit post
            </div>
          </Menu.Item>
        )}
        {(isOwn || hasPermission('admin')) && (
          <Menu.Item>
            <div style={{ ...styles.actionMenuItem, color: '#F00' }} onClick={()=>setDeleteDialogVisible(true)} aria-hidden='true'>
              Delete post
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const { ratingCount, averageRating } = news.ratingGroup;
  const { commentsCount } = news.commentGroup;
  const userRating = news?.ratingGroup.userRating || 0;

  return (
    <div className='news-card-box' style={{ ...styles.root, ...(getCardStyle()), ...style }} aria-hidden='true'>
      <div style={styles.header}>
        <OwnerBox owner={news.owner} user={news.user} createdAt={news.createdAt} hideTitle={true}/>
        {withMenu && (
          <Dropdown overlay={getActionMenu()} placement='bottomRight'>
            <MoreOutlined style={styles.moreIcon} />
          </Dropdown>
        )}
      </div>
      <div style={styles.message} dangerouslySetInnerHTML={{__html: news.messageToDisplay? news.messageToDisplay.replace(/(?:\r\n|\r|\n)/g, '<br />'):""}}></div>
      {news.link && (
        <>
          {(news.image || !news.linkedImage) ? (
            <div style={styles.link} onClick={() => window.open(news.link, '_blank')} aria-hidden='true'>
              {/* {news.link} */}
            </div>
          ) : (
            <LinkCard
              link={news.link}
              image={news.linkedImage.url}
              title={news.linkedTitle}
              description={news.linkedDescription}
              style={styles.innerCard}
            />
          )}
        </>
      )}
      {news.image && (
        <ImageCard image={news.image.url} style={styles.innerCard} />
      )}

      <div style={styles.socialTextBox}>
        <div style={{ ...styles.socialText, marginRight: 8 }}>
          {ratingCount ? (
            <>
              {averageRating}
              <StarFilled style={styles.star} />
              {`from ${ratingCount} users`}
            </>
          ) : '0 rating'}
        </div>
        <div style={styles.socialText}>{`${commentsCount} comment${commentsCount > 1 ? 's' : ''}`}</div>
      </div>

      <div
        className='Idea-actions'
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-hidden='true'
      >
        {
          user() ? (
            <Popover content={(
              <Rate
                className='Idea-action-rating'
                allowClear
                onChange={onRateChange}
                value={userRating || 0}
              />
            )}
            >
              <div className='Idea-action rate'>
                <Icon type='star' theme='filled' />
              </div>
            </Popover>
          ) : (
            <div className='Idea-action rate'>
              <Icon type='star' theme='filled' />
            </div>
          )
        }
        <div
          aria-hidden='true'
          className={`Idea-action comment${!user() ? ' disabled' : ''}`}
          onClick={() => setCommentVisible(!commentVisible)}
        >
          <Icon type='message' theme='filled' />
        </div>
        <Dropdown
          overlay={<ShareMenu shareURL={shareLink} />}
          trigger={['click']}
          placement='topCenter'
        >
          <div className={`Idea-action share ${shareDisabled && 'disabled'}`}>
            <Icon type='share-alt' />
          </div>
        </Dropdown>
      </div>
      {commentVisible && (
        <Comments
          style={{
            marginTop: '1.1em',
          }}
          postURL={`/comment/post/${news.commentGroup.id}`}
          commentable={true}
          commentsCount={commentsCount}
          source={`/comment/get/${news.commentGroup.id}`}
          onNewComment={() => {
            message.success('New comment');
          }}
          v2
          type={"feed"}
        />
      )}
      <ConfirmationDialog
        visible={deleteDialogVisible}
        onCancel={() => setDeleteDialogVisible(false)}
        onOk={() => {
          setDeleteDialogVisible(false);
          onDelete();
        }}
        title='Delete post'
        description='Are you sure you want to delete this post? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />


    </div>
  );
};

interface Props {
  news: IFeed;
  isTop: boolean;
  isBottom: boolean;
  onChange: () => void;
  style?: CSSProperties;
  withMenu: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  shareLink: string;
  shareDisabled: boolean;
}

NewsCard.defaultProps = {
  style: {},
  onEdit: null,
  onDelete: null,
  shareDisabled: false,
};

export default NewsCard;
