import React from 'react'
import {Input, DatePicker, Button} from 'antd'
import moment, {Moment} from 'moment'
import {PlusOutlined, InboxOutlined} from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import {getBaseUrl} from '../../libs/utils/request';

interface Props {
  data?: any | undefined
  edit?: undefined | true
  onSubmit: (data: any) => void
}

interface State {
  loading: boolean
}

export class CreateAnnouncement extends React.Component<Props, State> {

  image: string | null = null
  imageName: string | null = null
  title: string | null = null
  description: string | null = null
  buttonLink: string | null = null
  fromDate: Moment | null = null
  toDate: Moment | null = null

  state: State = {
    loading: false
  }

  constructor(props: any) {
    super(props)
    if (this.props.edit) {
      const { data } = this.props
      this.title = data.title || null
      this.description = data.description || null
      this.buttonLink = data.buttonLink || null
      this.fromDate = data.fromDate ? moment(data.fromDate) : null
      this.toDate = data.toDate ? moment(data.toDate) : null
      if (data.image && data.image.filename) {
        this.image = data.image.filename
      }
    }
  }

  render() {
    const isEdit = !!this.props.edit

    return <div className={'CreateAnnouncement'}>
      <div className={'CreateAnnouncement-spacer'} style={{
        gridColumn: '1',
        gridRow: '1 / 3 span'
      }}>
        <Dragger
          name={'photo'}
          multiple={false}
          showUploadList={false}
          action={`${getBaseUrl()}/image`}
          onChange={(info: any) => {
            const {status} = info.file
            if (status === 'done') {
              this.image = info.file.response.filename
              this.imageName = info.file.name
              this.forceUpdate()
            } else if (status === 'error') {
              this.image = null
              this.imageName = null
              this.forceUpdate()
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined/>
          </p>
          <p
            className="ant-upload-text">{this.imageName ? this.imageName : 'Click or drag file to this area to upload'}</p>
        </Dragger>
      </div>
      <Input
        defaultValue={this.title || ''}
        className={'CreateAnnouncement-spacer'}
        style={{
          gridColumn: '2 / 2 span',
          gridRow: '1'
        }}
        placeholder="Title"
        size="large"
        onChange={(e) => {
          this.title = e.target?.value
        }}
      />
      <DatePicker
        defaultValue={this.fromDate || null}
        className={'CreateAnnouncement-spacer CreateAnnouncement-DatePicker'}
        placeholder={'From'}
        style={{
          gridColumn: '4',
          gridRow: '1'
        }}
        onChange={(v) => this.fromDate = v}/>
      <DatePicker
        defaultValue={this.toDate || null}
        className={'CreateAnnouncement-spacer CreateAnnouncement-DatePicker'}
        placeholder={'To'}
        style={{
          gridColumn: '5',
          gridRow: '1'
        }}
        onChange={(v) => this.toDate = v}/>
      <Input
        defaultValue={this.description || ''}
        className={'CreateAnnouncement-spacer'}
        style={{
          gridColumn: '2 / span 4',
          gridRow: '2'
        }}
        placeholder="Description"
        size="large"
        onChange={(e) => {
          this.description = e.target?.value
        }}
      />

      <Input
        defaultValue={this.buttonLink || ''}
        className={'CreateAnnouncement-spacer'}
        style={{
          gridColumn: '2 / span 2',
          gridRow: '3'
        }}
        placeholder="Lead Button link"
        size="large"
        onChange={(e) => {
          this.buttonLink = e.target?.value
        }}
      />

      <Button
        loading={this.state.loading}
        className={'CreateAnnouncement-spacer'}
        style={{
          gridColumn: '4 / span 2',
          gridRow: '3'
        }}
        type="primary"
        size="large"
        onClick={async () => {
          this.setState({ loading: true })
          await this.props.onSubmit({
            image: this.image,
            title: this.title,
            description: this.description,
            buttonLink: this.buttonLink,
            fromDate: this.fromDate,
            toDate: this.toDate
          })
          this.setState({ loading: false })
        }}>{isEdit ? 'Edit' : [<PlusOutlined/>,'Create a new']}</Button>
    </div>
  }
}
