import React, { CSSProperties } from 'react';
import { Checkbox } from 'antd';
import './GeneralCheckBox.css';

const styles = {
  root: {
    margin: 0,
  },
};

const GeneralCheckbox = ({
  checked, onChange, text, style, textStyle,
}: Props) => (
  <Checkbox
    checked={checked}
    style={{ ...styles.root, ...style }}
    onChange={(e) => onChange(!!e.target.checked)}
  >
    <span style={textStyle}>
      {text}
    </span>
  </Checkbox>
);

interface Props {
  checked: boolean;
  onChange: any;
  text: string;
  style?: CSSProperties;
  textStyle?: CSSProperties;
}

GeneralCheckbox.defaultProps = {
  style: {},
  textStyle: {},
};

export default GeneralCheckbox;
