import React, { useEffect, useState } from 'react';
import { MoreOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';
import { Dropdown, Menu, message } from 'antd';
import Card from '../../../../../common/card/Card';
import SectionButton from './SectionButton';
import { SubPageEnum } from '../../../SubPageEnum';
import Competition, { havePermission, IApplicationType } from '../../../Competition';
import { ShareMenu2 } from '../../../../../common/ShareMenu2';
import { hasPermission, isAdmin, user } from '../../../../../../libs/utils/user';
import { deleteReq } from '../../../../../../libs/utils/request';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog';

const styles = {
  root: {
    marginTop: -80,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 42,
    paddingRight: 25,
    borderRadius: 15,
    maxWidth: 800,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'start',
    gap: '16px',
    marginTop: 18,
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 26,
    lineHeight: '29px',
  },
  actionBtnContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '16px 16px',
    marginRight: -13,
    marginLeft: 4,
  },
  moreIcon: {
    fontWeight: 900,
    fontSize: 31,
    maxHeight: 31,
    cursor: 'pointer',
    minWidth: 40,
  },
  actionMenuItem: {
    minWidth: 100,
  },
};

const shareLinkBase = `${window.location.protocol}//${window.location.host}`;

const WITH_JURY = true;

const TitleCard = ({
  name, tag, applicationTypes, userRoles, onMessage,
  withdrawButtonVisible, onWithdraw, evaluationStatus, onGenerateSummary, onAddPin, pin,
}: Props) => {
  const [sections, setSections] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { subPage } = useParams();
  const history = useHistory();
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

  useEffect(() => {
    const types = applicationTypes.map((item) => item.name);
    const tmp: ISection[] = [{ name: 'Summary' }];
    if (user()) {
      tmp.push({ name: 'Participants', page: SubPageEnum.participants });
      if (types.includes('Startup')) {
        tmp.push({ name: 'Startups', page: SubPageEnum.startups });
      }
      if (types.includes('Idea')) {
        tmp.push({ name: 'Ideas', page: SubPageEnum.ideas });
      }
      tmp.push(
        { name: 'Feed', page: SubPageEnum.feed },
        { name: 'Winners', page: SubPageEnum.winners },
      );
      if (WITH_JURY) {
        if (havePermission(['Organizer', 'Co-organizer'], userRoles)) {
          tmp.push({ name: 'Evaluation', page: SubPageEnum.evaluation });
        }
        if (havePermission(['Jury'], userRoles) && evaluationStatus !== 'INIT') {
          tmp.push({ name: 'Jury', page: SubPageEnum.jury });
        }
      }
    }
    setSections(tmp);
  }, [applicationTypes, evaluationStatus, userRoles]);

  const deleteCompetition = async () => {
    try {
      await deleteReq(`/competitions/${tag}`);
      history.replace('/competitions');
    } catch (error) {
      message.error('Failed to delete competition.');
    }
  };

  const getActionMenu = () => {
    const isOrganizer = havePermission(['Organizer', 'Co-organizer'], userRoles);
    return (
      <Menu>
        {(user() && !isOrganizer) && (
          <Menu.Item>
            <div style={styles.actionMenuItem} onClick={onMessage} aria-hidden='true'>
              Send a message
            </div>
          </Menu.Item>
        )}
        {(user() && isAdmin()) && (
          <>
          <Menu.Item>
            <div
              style={{cursor:"pointer", padding:'5px 12px', minWidth:'100px'}}
              onClick={onGenerateSummary}
              aria-hidden='true'
            >
              Generate report
            </div>
          </Menu.Item>
          <Menu.Item>
          <div
              style={{cursor:"pointer", padding:'5px 12px', minWidth:'100px'}}
              onClick={onAddPin}
            aria-hidden='true'
          >
            {!pin?"Pin":"Unpin"}
          </div>
        </Menu.Item>
        </>
        )}
        {(isOrganizer || withdrawButtonVisible) && <Menu.Divider />}
        {withdrawButtonVisible && (
          <Menu.Item>
            <div style={{ ...styles.actionMenuItem, color: '#F00' }} onClick={onWithdraw} aria-hidden='true'>
              Withdraw my application
            </div>
          </Menu.Item>
        )}
        {(isOrganizer || isAdmin()) && (
          <Menu.Item>
            <div
              style={styles.actionMenuItem}
              onClick={() => {
                history.push(`/edit-competition/${tag}`);
              }}
              aria-hidden='true'
            >
              Edit competition
            </div>
          </Menu.Item>
        )}
        {(isOrganizer || isAdmin()) && (
          <Menu.Item>
            <div style={{ ...styles.actionMenuItem, color: '#F00',textAlign:'start' }} onClick={() => setDeleteModalVisible(true)} aria-hidden='true'>
              Delete competition
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Card className='title-card' style={styles.root}>
      <div style={styles.topContainer}>
        <div style={styles.title}>
          {name}
        </div>
        <div style={styles.actionBtnContainer}>
          <Dropdown overlay={<ShareMenu2 shareURL={`${shareLinkBase}/competitions/${tag}`} />} placement='bottomCenter' trigger={['click']}>
            <ShareAltOutlined style={styles.moreIcon} />
          </Dropdown>
          <Dropdown overlay={getActionMenu()} placement='bottomLeft'>
            <MoreOutlined style={styles.moreIcon} />
          </Dropdown>
        </div>
      </div>
      <div style={styles.bottomContainer}>
        {sections.map((section) => (
          <SectionButton
            key={section.name}
            name={section.name}
            isActive={section.page === subPage}
            onClick={() => {
              history.push(`/competitions/${tag}${section.page ? `/${section.page}` : ''}`);
            }}
          />
        ))}
      </div>
      <ConfirmationDialog
        visible={deleteModalVisible}
        onOk={() => {
          setDeleteModalVisible(false);
          deleteCompetition();
        }}
        onCancel={() => setDeleteModalVisible(false)}
        title='Delete competition'
        description='Are you sure you want to delete competition? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />
    </Card>
  );
};

interface Props {
  name: string;
  tag: string;
  applicationTypes: IApplicationType[];
  userRoles: string[];
  onMessage: () => void;
  withdrawButtonVisible: boolean;
  onWithdraw: () => void;
  onGenerateSummary: () => void;
  onAddPin:()=>void;
  pin:boolean;
  evaluationStatus: 'INIT' | 'OPEN' | 'CLOSED';
}

interface ISection {
  name: string;
  page?: string;
}

export default TitleCard;
