import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Prompt } from 'react-router';
import { deleteReq, post } from '../../../../../libs/utils/request';
import { havePermission, ICompetition, IUser } from '../../Competition';
import '../../Competition.css';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';
import PostModal from '../../../../NewsFeed/components/PostModal/PostModal';
import FeedList from '../../../../common/FeedList';

const styles = {
  root: {
    maxWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 74,
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  btn: {
    fontWeight: 'bold' as const,
    minWidth: 150,
    marginBottom: 50,
  },
};

const Feed = ({ competition, tag, userRoles }: Props) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editNews, setEditNews] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    window.onbeforeunload = editModalVisible ? () => true : undefined;
    return () => {
      // eslint-disable-next-line no-undefined
      window.onbeforeunload = undefined;
    };
  }, [editModalVisible]);

  const deletePost = async () => {
    if (idForDelete) {
      try {
        await post(`/news/delete`,{id:idForDelete});
        setRefreshNeeded(true);
        message.success('Post deleted.');
      } catch (error) {
        message.error('Failed to delete post.');
      }
    }
  };
  const CreateNew = () => {
    setEditNews(null);
    setEditModalVisible(true);
  }

  return (
    <div style={styles.root}>
      <Prompt
        when={editModalVisible}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      {(!havePermission([], userRoles) && !havePermission(['Participant Pending'], userRoles)) && (
        <div style={styles.btnContainer}>
          <Button
            style={styles.btn}
            type='primary'
            size='default'
            onClick={() => profileProgress < 10 ? message.warning(`${profileProgress}% of your profile is completed. To create new post you need to get at least 10%`) : CreateNew()}
          >
            <PlusOutlined />
            Create new
          </Button>
        </div>
      )}
      <FeedList
        url={`news/list`}
        newsGroupId={competition.newsGroup.id}
        refreshNeeded={refreshNeeded}
        setRefreshNeeded={setRefreshNeeded}
        onEdit={(item) => {
          setEditNews(item);
          setEditModalVisible(true);
        }}
        onDelete={(id) => {
          setIdForDelete(id);
          setDeleteDialogVisible(true);
        }}
        shareDisabled
      />
      <PostModal
      newsGroupId={competition.newsGroup.id}
        visible={editModalVisible}
        onOk={() => {
          setEditModalVisible(false);
          setRefreshNeeded(true);
        }}
        onCancel={() => setEditModalVisible(false)}
        postUrl={`/news/post`}
        patchUrl={`/news/edit`}
        news={editNews}
        v2
      />
      <ConfirmationDialog
        visible={deleteDialogVisible}
        onCancel={() => setDeleteDialogVisible(false)}
        onOk={() => {
          setDeleteDialogVisible(false);
          deletePost();
        }}
        title='Delete post'
        description='Are you sure you want to delete this post? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />
    </div>
  );
};

interface IFeedImage {
  url: string;
}

interface IRatingGroup {
  id: string;
  averageRating: number;
  ratingCount: number;
  userRating?: number;
}

interface ICommentGroup {
  id: string;
  commentsCount: number;
}

interface IUserRating {
  rating: number;
}

export interface IFeed {
  organizer: any;
  id: string;
  image: IFeedImage;
  link: string;
  message: string;
  linkedImage: IFeedImage;
  linkedTitle: string;
  linkedDescription: string;
  createdAt: string;
  user: IUser;
  ratingGroup: IRatingGroup,
  commentGroup: ICommentGroup,
  userRating: IUserRating;
  uniqueTag: string;
  commentable:boolean;
  messageToDisplay:string;
  owner?: any;
}

interface Props {
  competition: ICompetition;
  tag: string;
  userRoles: string[];
}

export default Feed;
