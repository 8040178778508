import "../Dashboard.css";
import React, { useState, useEffect } from "react";
import { user } from '../../../libs/utils/user';
import ImageUploader2 from '../../common/ImageUploader2';
import { patch, post } from  '../../../libs/utils/request';
import { Formik } from 'formik';
import {Button, Form, Select, Input, DatePicker, Modal, message,} from 'antd';
import TextArea from "antd/lib/input/TextArea";

  const CreatePost = ({refreshNeeded, setRefreshNeeded, editNews}:Props) => {
  const [cover, setCover] = useState('')
  const [isSubmitting, setSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewText, setPreviewText] = useState("");



  const  postUrl='/news/post'
  const formInit = {
    message: '',
    // link: '',
  };

  const checkMaxLength = (e:string) => {
    if(e.length > 999) {
      message.warning(`The maximum length of characters is 1000.`)
    }
  }

  const onSubmit = async (values: any,e:any) => {
    const resetForm = e.resetForm
    setSubmitting(true);
    try {
      const body: any = {
        data: {
          ...values,
          image: cover,
        },
      };
      body.type = 'newspost';
      if(body.data.message.length <= 1000){
        await post(postUrl, body);
        setRefreshNeeded(true)
        setCover('')
      }
      else{
       message.warning(`The maximum length of characters is 1000.`)
      }
    } catch (error) {
      message.error(`Failed to post. `+error.response.data.message);
    } finally {
      setSubmitting(false);
      resetForm()
    }
  };

  const preViewImage = () =>{
    if(cover !== ""){
      setPreviewImage(cover)
      setPreviewVisible(true)
    }
  }
  const deleteImage = () =>{
      setCover("")
      setPreviewImage('')
  }


  return (
      <div  className="create-post">
        <div>
          <img className="user-profile-pic" src={user() && user().profileImage !== null ? user().profileImage   : '/images/user.svg'}/>
        </div>
        <div  className="create-post-container">
          <div>
          <Formik
            enableReinitialize
            initialValues={formInit}
            onSubmit={(values,e) => onSubmit(values,e)}
          >
              {({
                values,
                handleSubmit,
                handleChange,
                handleReset,
              }) => (
                <>
                  <TextArea
                    className="create-post-textarea"      
                    autoSize
                    maxLength={1000}      
                    name='message'
                    value={values.message}
                    onChange={(e) => {handleChange(e) ; checkMaxLength(e.target.value)}}
                    placeholder={`💬 Hey ${user() && user().firstName}! Share something inspiring with others.`} />
                    {
                        cover !== '' &&
                        <div className="post-image-preview-container">
                          <img className="post-image-preview" src={cover}/>
                        </div>
                    }
                    {
                      (cover !== "" && values.message ==='') &&
                      <div className="organization-interest-error">Message must not be empty!</div>
                    }
                  <Button className="create-post-button" onClick={() =>{ handleSubmit()}} type="primary">Share</Button>
                </>
              )}
            </Formik>
            <div className="share-post">
              {
                cover == "" ? (
                  <ImageUploader2
                  images={cover}
                  type='single'
                  onChange={setCover}
                  >
                    <div className="post-image-picker">
                      <img src="/images/picture.svg"/>
                      <div className="image-picker-text">
                       Photo
                      </div>
                    </div>
                  
                  </ImageUploader2>

                ):(
                  <div className="handle-image">
                  <div onClick={()=>{deleteImage()}}><img className="delete-image" src="./images/user_info_icons/trash-alt-solid.svg"/></div>
                  </div>
                  
                )
              }
                   
             </div>
             <Modal
              visible={previewVisible}
              footer={null}
              onCancel={()=>setPreviewVisible(false)}
              >
                <div>{formInit.message}</div>
              <img alt="image" style={{ width: "100%" }} src={previewImage} />
              </Modal>
          </div>
        </div>
      </div>
  );
};
export default CreatePost;

interface Props {
  refreshNeeded: boolean
  setRefreshNeeded: (b: boolean) => void;
  editNews: string
}
