import React, { useEffect, useState } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { get, post } from '../../../libs/utils/request';
import { Loading } from '../../common/Loading';
import { LinkedInLoginButton } from "react-social-login-buttons";
import styles from "./SocialButtons.module.css";


interface Props {
  onSuccesGetData: (data: any, provider: string) => void;
}


const LinkedInPage = ({ onSuccesGetData }: Props) => {
  const [profile, setProfile] = useState(undefined);
  const [loading, setloading] = useState(false);
  const { linkedInLogin } = useLinkedIn({
    clientId: '78vpi2jcy37jf5',
    redirectUri: `${window.location.origin}/linkedin`,
    
    onSuccess: (code) => {
      setloading(true)
      getUSerData(code)
    },
    onError: (error) => {
      console.log(error);
    },
    scope: "r_liteprofile r_emailaddress"
  });
  const getUSerData = async (code: any) => {
    try {
      const response = await get(`${process.env.REACT_APP_API_URL}/linkedin`, { code: code });
      setProfile(response);
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (profile) {
      onSuccesGetData(profile, "linkedin")
    }
  }, [profile])

  return (

    <div>
      {loading && <Loading />}
      <LinkedInLoginButton onClick={linkedInLogin} className={styles.social_btn} text="Sign up with Linkedin"/>
    </div>
  );
}
export default LinkedInPage