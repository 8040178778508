import React, { CSSProperties } from 'react';

const styles = {
  root: {
    width: '100%',
    borderRadius: 7,
    boxShadow: '3.38462px 3.38462px 13.5385px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  image: {
    height: "auto",
    width:"100%",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
};

const ImageCard = ({
  image, style,
}: Props) => (
  <div style={{ ...styles.root, ...style, textAlign:'center'}}>
    <img style={{ ...styles.image}} src={image } />
  </div>
);

interface Props {
  image: string;
  style?: CSSProperties;
}

ImageCard.defaultProps = {
  style: {},
};

export default ImageCard;
