import React, { useEffect, useState } from "react";
import { MoreOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router";
import { Dropdown, Input, Menu, message, Modal } from "antd";
import { deleteReq } from "../../libs/utils/request";
import { isAdmin, user } from "../../libs/utils/user";
import Card from "../common/card/Card";
import { ShareMenu2 } from "../common/ShareMenu2";
import "./article.css"

const shareLinkBase = `${window.location.protocol}//${window.location.host}`;

const TitleCard = ({
  createdBy,
  name,
  tag,
  subTitle,
  onAddPin,
  pin,
}: Props) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");

  const history = useHistory();

  const deleteArticle = async () => {
    try {
      await deleteReq(`/blog/${tag}`, { password: confirmPassword });
      history.replace("/blog");
      message.success("Article deleted");
    } catch (error) {
      message.error("Failed to delete article.");
    }
  };

  const settingConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const editArticle = () => {
    if (isAdmin()) {
      history.push(`/edit-article/${tag}`);
    } else {
      message.warning(`You can't edit this article!`);
    }
  };

  const deleteOrg = () => {
    if (isAdmin()) {
      setDeleteModalVisible(true);
    } else {
      message.warning(`You can't delete this article!`);
    }
  };
  const isOrganizer = user() && user().id === createdBy;

  const getActionMenu = () => {
    return (
      <Menu>
        {isAdmin() && <Menu.Item>
          <div
              style={{cursor:"pointer"}}
              onClick={onAddPin}
            aria-hidden='true'
          >
            {!pin?"Pin":"Unpin"}
          </div>
        </Menu.Item>}
        {(isOrganizer || isAdmin()) && (
          <Menu.Item>
            <div
              onClick={() => {
                editArticle();
              }}
              aria-hidden="true"
            >
              Edit article
            </div>
          </Menu.Item>
        )}
        {(isOrganizer || isAdmin()) && (
          <Menu.Item>
            <div
              onClick={() => {
                deleteOrg();
              }}
              aria-hidden="true"
            >
              Delete article
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Card className="organizationpage-title-card">
      <div className="organizationpage-title-card-container">
        <div className="organizationpage-title-card-title">{name}</div>
        <div className="organizationpage-title-card-menu">
          <Dropdown
            overlay={<ShareMenu2 shareURL={`${shareLinkBase}/blog/${tag}`} />}
            placement="bottomCenter"
            trigger={["click"]}
          >
            <ShareAltOutlined />
          </Dropdown>
          {user() && (
            <Dropdown overlay={getActionMenu()} placement="bottomLeft">
              <MoreOutlined />
            </Dropdown>
          )}
        </div>
      </div>
      <div className="organizationpage-title-card-subtitle">{subTitle}</div>{" "}
      {(isOrganizer || isAdmin()) && (
        <Modal
          visible={deleteModalVisible}
          onOk={() => {
            setDeleteModalVisible(false);
            deleteArticle();
          }}
          onCancel={() => setDeleteModalVisible(false)}
          title="Delete article"
          okText="Delete"
          okType="danger"
        >
          <p>
            If you'd like to remove your article please enter your
            password:
          </p>
          <Input.Password
            placeholder="password"
            onChange={settingConfirmPassword}
          />
        </Modal>
      )}
    </Card>
  );
};

interface Props {
  name: string;
  tag: string;
  createdBy: string;
  subTitle: string;
  onAddPin: () => void;
  pin: boolean;
}

export default TitleCard;
