import React from "react";
import Theme from "./Theme";
import "./Themes.css";

const Themes = ({ themes }: Props) => (
  <div className="category">
    <>
      <div className="category-title">Category</div>
      <div className="category-holder">
        {themes.map((item) => (
          <Theme key={item.id} theme={item} />
        ))}
      </div>
    </>
  </div>
);

export interface ITheme {
  id: string;
  name: string;
  icon: string;
}

interface Props {
  themes: ITheme[];
}

export default Themes;
