import React, { ReactElement, useState } from 'react';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router';
import RegisterWallModal from './RegiserWallModal';
// import { useState } from 'react-slick/node_modules/@types/react';

const styles = {
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 22,
    color: '#282828',
    marginBottom: 16,
  },
  description: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 16,
    color: '#282828',
    marginBottom: 16,
  },
  footer: {
    width: '100%',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
  actionBtn: {
    fontWeight: 'bold' as const,
  },
};

const LoginWallModal = ({
  visible, onCancel, title, description,
}: Props) => {
  const history = useHistory();
  const [registerModalVisible, setRegisterWallVisible] = useState(false)
  return (
    <>
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
    >
      {title && (
        <div style={styles.title}>{title}</div>
      )}
      {description && (
        <div style={styles.description}>{description}</div>
      )}
      <div style={styles.footer}>
        <Button
          style={{ ...styles.actionBtn, color: '#6755CE' }}
          type='link'
          size='large'
          onClick={() => {
            history.push('/login');
          }}
        >
          Log in
        </Button>
        <Button
          style={{ ...styles.actionBtn, minWidth: 150 }}
          type='primary'
          size='large'
          onClick={() => {
            setRegisterWallVisible(true)
            // history.push('/registration');
          }}
        >
          Sign up
        </Button>
      </div>
    </Modal>
     <RegisterWallModal
     title='Sign up for Pozi'
     description='For applying to this event, first, you need to sign up!'
     visible={registerModalVisible}
     onCancel={() => setRegisterWallVisible(false)}
   />
   </>
  );
};

interface Props {
  visible: boolean;
  onCancel: () => void;
  title?: string;
  description?: string | ReactElement;
}

LoginWallModal.defaultProps = {
  title: '',
  description: '',
};

export default LoginWallModal;
