import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Row, Col, Form, Input, DatePicker, Select, Checkbox } from 'antd';
import { WrappedFormUtils } from "antd/lib/form/Form"
const cities = require('../../../libs/cities/cities.json')
import ImageUploader from '../../common/ImageUploader'
import eventbus from "../../../libs/eventbus";
import moment from "moment"
import { FormLocationSelector } from '../../common/form/LocationSelector';

interface Props {
  onFinished: any,
  onImageChange: any,
  form: WrappedFormUtils,
  formValues: any,
  userImg?: string
}

interface State {
  citySelectData: any[]
}

class UserInfo extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      citySelectData: []
    }
  }


  componentDidMount() {
    eventbus.addListener(
      eventbus.events.validateRegistration,
      "UserInfo",
      () => {
        const success = this.props.form.validateFields((err: any, values: any) => {
          if (err) {
            return false
          }
          this.props.onFinished(values);
          return true
        })
        return success
      }
    )
    this.props.form.setFieldsValue(this.props.formValues)
  }

  componentWillUnmount() {
    eventbus.removeListener(eventbus.events.validateRegistration, "UserInfo")
  }

  passwordValidator = (rule: any, value: any, callback: any) => {
    try {
      const password = this.props.form.getFieldValue("password")
      if (value && password !== value) {
        throw new Error();
      } else {
        callback()
      }
    } catch (err) {
      callback(err);
    }
  }

  policyValidator = (rule: any, value: any, callback: any) => {
    try {
      const accepted = this.props.form.getFieldValue("policy")
      if (!accepted) {
        throw new Error();
      } else {
        callback()
      }
    } catch (err) {
      callback(err);
    }
  }

  disabledDate = (current: any) => {
    return current.year() < 1920 || current.year() > 2017
  }

  handleCitySearch = (value: string) => {
    // start search at min 3 characters
    if (value.length > 2) {
      this.setState({ citySelectData: cities.filter((city: any) => city.name.toLowerCase().includes(value.toLowerCase())) })
    } else {
      this.setState({ citySelectData: [] });
    }
  }

  cityFilterOption = (input: string, option: any) => {
    return option.props.children.toLowerCase().includes(input.toLowerCase())
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div className="step step-userinfo">
        <div className="step-title">
          Tell us something about you
        </div>

        <div className="step-userinfo-form">
          <Form>
            <Row type="flex" justify="space-between">
              <Col xs={24} md={7} className="step-userinfo-image">
                <ImageUploader
                  images={this.props.userImg}
                  type="single"
                  onChange={(url: any) => {
                    this.props.onImageChange(url)
                  }}
                />
              </Col>

              <Col xs={24} md={{ span: 7, offset: 1 }} className="step-userinfo-inputs">
                <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'Please input your first name!' }],
                    validateTrigger: "onSubmit"
                  })(
                    <Input
                      prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/real_name.svg" alt="" />}
                      placeholder="First name"
                    />,
                  )}
                </Form.Item>

                <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Please input your last name!' }],
                    validateTrigger: "onSubmit"
                  })(
                    <Input
                      prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/real_name.svg" alt="" />}
                      placeholder="Last name"
                    />,
                  )}
                </Form.Item>
                {/* <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input your name!' }],
                    validateTrigger: "onSubmit"
                  })(
                    <Input
                      prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/real_name.svg" alt="" />}
                      placeholder="Full name"
                    />,
                  )}
                </Form.Item> */}

                <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email address!' }],
                    validateTrigger: "onSubmit"
                  })(
                    <Input
                      prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/email.svg" alt="" />}
                      placeholder="Email"
                    />,
                  )}
                </Form.Item>

                <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('birthday', {
                    rules: [{ type: 'object', required: false, message: 'Please select date!' }]
                  })(<DatePicker
                    placeholder="  Birth date"
                    suffixIcon={<img src="/images/user_info_icons/birth_date.svg" alt="" />}
                    style={{ width: "100%" }}
                    disabledDate={this.disabledDate}
                    defaultPickerValue={moment("19900101")}
                  />)}
                </Form.Item>

                <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                    validateTrigger: "onSubmit"
                  })(
                    <Input
                      prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/password.svg" alt="" />}
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </Form.Item>

                <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('password2', {
                    rules: [{ required: true, message: 'Please input your Password!' }, { validator: this.passwordValidator, message: "It has to be the same as above" }],
                    validateTrigger: "onSubmit"
                  })(
                    <Input
                      prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/password.svg" alt="" />}
                      type="password"
                      placeholder="Password again"
                    />,
                  )}
                </Form.Item>

                <div style={{
                  display: 'flex',
                  flexDirection: 'row' as const,
                  alignItems: 'center',
                  marginTop: 32,
                  marginBottom: 10,
                  gap: '10px 10px',
                }}
                >
                  <div
                    className='location-selector'
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 900,
                      fontStyle: 'normal',
                      fontSize: 18,
                      lineHeight: '22px',
                      color: '#282828',
                    }}
                  >
                    Your city
                  </div>
                  <img
                    style={{
                      maxHeight: 12,
                    }}
                    src='/images/google/powered_by_google.png'
                    alt='Powered by Google'
                  />
                </div>
                <FormLocationSelector
                  fieldKey='city'
                  form={this.props.form}
                  style={{ color: '#6755CE' }}
                  types={['(cities)']}
                  required
                />
              </Col>

              <Col xs={24} md={{ span: 7, offset: 1 }} className="step-userinfo-intro">
                <Form.Item>
                  {getFieldDecorator('bio', {
                    rules: [{ required: true }],
                  })(
                    <Input.TextArea
                      placeholder="Short intro. Example: Hi, I’m Nelson, but my friends call me Big Head. I’m a tech evangelist, I can help you with your demo, 'cause I already watched the Facebook movie, A.I., The Net, and half of Tron. I also worked on projects like PiperChat and Nip Alert. And I was also on the cover of Wired. And actually inside too."
                      maxLength={480}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className="step-userinfo-oneliner">
              <Col span={24}>
                <Form.Item className="step-userinfo-input">
                  {getFieldDecorator('oneLiner', {
                    rules: [{ required: true }],
                  })(
                    <Input
                      prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/actual_oneliner.svg" alt="" />}
                      placeholder="About you in one line"
                      maxLength={140}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row className="step-userinfo-policy">
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('policy', {
                    rules: [{ validator: this.policyValidator, message: "You have to accept to continue" }],
                    initialValue: false,
                    valuePropName: 'checked'
                  })(
                    <Checkbox>By continuing, I agree to Pozi.io <a href="/terms-and-conditions" target="_blank">Terms of Service</a> and acknowledge our <a href="/privacy-policy" target="_blank">Privacy Policy</a>, including the site cookie policy.</Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    )
  }
}

const UserInfoWithForm = Form.create<Props>({ name: 'reg' })(UserInfo);
export default UserInfoWithForm