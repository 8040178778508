import React from "react";
import "./FooterNew.css"

const FooterNew = () => {

    return(
        <div className="footer-main-container">
            <div className="footer-upper-box">
                <div className="footer-logo-box">
                    <img src="/images/bmeus_index_page/logo.png"></img>
                    <div className="footer-upper-desc">Free and open platform for startups and innovators.</div>
                </div>
                {/* <div className="footer-links">
                    <h3>FOR YOU</h3>
                    <div><a href="/rss">RSS feed</a></div>
                    <div><a href="/blog">Learn more</a></div>
                    <div><a href="/startups">Create your startup page</a></div>
                    <div><a href="/events">Apply for events</a></div>
                    <div><a href="/competitions">Apply for competitions</a></div>
                </div> */}
                {/* <div className="footer-links">
                    <h3>SERVICES</h3>
                    <div><a href="/packages/organizations">Packages</a></div>
                    <div><a href="/packages/organizations">Change your plan</a></div>
                    <div><a href="/organizations">Organization page</a></div>
                    <div><a href="/events">Event tool</a></div>
                    <div><a href="/competitions">Competition tool</a></div>
                </div> */}
                <div className="footer-links">
                    <h3>TOOLS</h3>
                    <div><a href="/startups">Create your startup page</a></div>
                    <div><a href="/events">Apply for event</a></div>
                </div>
                <div className="footer-links">
                    <h3>OUR COMPANY</h3>
                    <div><a href="mailto:partnership.us3@bme.hu">Partnerships</a></div>
                    <div><a href="mailto:media.us3@bme.hu">Media</a></div>
                    {/* <div><a href="/privacy-policy">Privacy Policy</a></div>
                    <div><a href="/terms-and-conditions">Terms & Conditions</a></div>
                    <div><a href="/organizations/pozi">Legal information</a></div> */}
                </div>
            </div>
            <div className="footer-bottom-box">
                <div className="footer-bottom-box-desc">
                    © Pozi Network Plc. All rights reserved.
                </div>
                <div className="footer-bottom-box-desc">
                    <a className="footer-bottom-box-desc" href="mailto:hellous3@bme.hu">Let’s get in contact with us!</a>
                </div>
                <div className="footer-social-icons">
                    <a href="https://www.facebook.com/BMEUniversitySpinoffStartupStudio" target="_blank"><img src="/images/social_icons/white/Facebook.svg"></img></a>
                    <a href="https://www.linkedin.com/company/bmeus3/" target="_blank"><img src="/images/social_icons/white/LinkedIn.svg"></img></a>
                    {/* <a href="https://www.instagram.com/pozi.io/" target="_blank"><img src="/images/social_icons/white/Instagram.svg"></img></a> */}
                    {/* <a><img src="/images/social_icons/white/Discord.svg"></img></a> */}
                    {/* <a href="https://twitter.com/hello_pozi" target="_blank"><img src="/images/social_icons/white/Twitter.svg"></img></a> */}
                </div>
            </div>
        </div>
    )
}

export default FooterNew;