import React from "react";
import { Upload, Icon, Modal } from "antd";
import { getBaseUrl } from "../../libs/utils/request";
import ImageCropper from "./ImageCropper";
import { resolve } from "dns";
import styles from './ImageCropper.module.css'

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

interface Props {
  images: string | string[];
  type: "single" | "list";
  onchange: any;
  displayAllowed?: boolean;
  showCropper?: boolean;
  cropperData?: {}
  cropType?: string
}
var promiseResolve: any, promiseReject: any;
class ImageUploader extends React.Component<any, any> {
  state: any = {
    previewVisible: false,
    previewImage: "",
    fileList: [],
    errorMessage: "",
    showCropperModal: false,
    cropperSrc: "",
  };

  toFileList = (urlOrList: any) => {
    //console.log("toFileList");
    //console.log(urlOrList);
    if (this.props.type === "single") {
      // convert url string to one element array
      return [
        {
          uid: urlOrList.slice(-10, -4),
          name: "unknown",
          status: "done",
          url: urlOrList,
        },
      ];
    } else if (this.props.type === "list") {
      // convert url list to array of objects
      return urlOrList.map((url: any) => {
        return {
          uid: url.slice(-10, -4),
          name: "unknown",
          status: "done",
          url: url,
        };
      });
    }
  };

  toUrlOrList = (fileList: any) => {
    if (this.props.type === "single") {
      // return url string, if fileList is empty return undefined
      if (!fileList.length) {
        return undefined;
      }
      return fileList[0].url || fileList[0].response?.url;
    } else if (this.props.type === "list") {
      // return list of url strings
      return fileList.map((file: any) => file.url || file.response?.url);
    }
  };

  componentWillReceiveProps(newProps: any) {
    this.setState({
      fileList: newProps.images ? this.toFileList(newProps.images) : [],
      errorMessage: undefined,
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file, fileList }: any) => {
    const imageUrls = this.toUrlOrList(fileList);
    fileList = fileList.map((item: any) => ({
      ...item,
      uid: Date.now(),
    }));
    this.setState({ fileList });
    if (file.response) {
      this.props.onChange(imageUrls);
    }
    if (
      file.response &&
      this.props.showCropper &&
      this.props.type === "single"
    ) {
    }
  };
  setError = (message: string) => {
    this.setState({ errorMessage: message });
  };

  beforeUpload = (file: any): Promise<void> => {
    return new Promise(async (resolve: any, reject) => {
      if (file.size / 1024 / 1024 < 10) {
        this.setError(undefined);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.setState({
            cropperSrc: reader.result,
            showCropperModal: true,
          });
        };
        var promise = new Promise(function (resolve, reject) {
          promiseResolve = resolve;
          promiseReject = reject;
        });
        let blob: any = await promise;
        file = new File([blob], "jpeg");

        resolve(file);
      } else {
        this.setError("Image must be smaller than 10MB!");
        reject();
      }
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.fileList.length < prevState.fileList.length) {
      const newList = this.state.fileList;
      this.props.onChange(this.toUrlOrList(newList));
    }
  }

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const maxFiles = this.props.maxFiles || 1;
    const acceptedFormats = this.props.acceptedFormats || ".jpg,.png";
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">
          {this.props.uploadText || "Upload picture"}
        </div>
      </div>
    );
    return (
      <div className="ImageUploader">
        <div className="clearfix">
          <Upload
            name="photo"
            listType="picture-card"
            action={`${getBaseUrl()}/image`}
            accept={acceptedFormats}
            fileList={fileList}
            beforeUpload={this.beforeUpload}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
          >
            {fileList.length >= maxFiles ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="image" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </div>
        {this.props.displayAllowed && (
          <div className="ImageUploader-info">
            {`Allowed: ${acceptedFormats.replace(/,/g, " ")}`}
          </div>
        )}
        {this.state.errorMessage && (
          <div className="ImageUploader-error">
            {`Error: ${this.state.errorMessage}`}
          </div>
        )}
        {this.props.showCropper && (
          <Modal
          className={styles.modal}
            closable={false}
            visible={this.state.showCropperModal}
            footer={null}
          >
            <ImageCropper
              src={this.state.cropperSrc}
              Cancel={() =>
                this.setState({
                  showCropperModal: false,
                })
              }
              onSave={(file) => {
                promiseResolve(file);
              }}
              cropType= {this.props.cropType}
              cropperData = {this.props.cropperData}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default ImageUploader;
