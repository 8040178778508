import React, { useState } from 'react';
import {
  Button, Input, message, Modal,
} from 'antd';
import { useHistory } from 'react-router';
import { IUser } from './Competition';
import { post } from '../../../libs/utils/request';

const styles = {
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 20,
    color: '#282828',
    marginBottom: 20,
  },
  input: {
    width: '100%',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    marginTop: 20,
  },
  btn: {
    fontWeight: 'bold' as const,
    minWidth: 150,
  },
};

const MessageModal = ({
  visible, users, onCancel, competitionName,
}: Props) => {
  const [value, setValue] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  const history = useHistory();

  const onSubmit = async () => {
    try {
      if (value) {
        const k: any = await post(
          '/message/threads/new',
          { users: users.map((item) => item.id), text: `Message from ${competitionName} Competition:\n${value}` },
        );
        if (k.threadId) {
          history.push(`/messages/thread/${k.threadId}`);
        } else {
          history.push('/messages');
        }
      }
    } catch (error) {
      message.error('Failed to send message.');
      setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      destroyOnClose
    >
      <div>
        <div style={styles.title}>Send message to organisers</div>
        <Input.TextArea
          autoSize={{ minRows: 10, maxRows: 16 }}
          placeholder='Type your message...'
          style={styles.input}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div style={styles.btnContainer}>
          <Button
            style={styles.btn}
            type='primary'
            size='large'
            onClick={onSubmit}
            loading={isSubmitting}
            disabled={!value}
          >
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  visible: boolean;
  users: IUser[];
  onCancel: () => void;
  competitionName: string;
}

export default MessageModal;
