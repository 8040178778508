import { Button } from "antd";
import { Form, Formik, FormikConsumer } from "formik";
import React, { useEffect, useState } from "react";
import FormikInput from "../common/form/FormikInput";
import FormikTextArea from "../common/form/FormikTextArea";
import { UserSearch } from "../User/UserSearch";
import "./AddNewMember.css";

interface Props {
  onSubmit: (values: { description: string; title: string; user: any }) => void;
  showCreator?: boolean;
}
const errorStyle = {};
const AddNewMember = ({ onSubmit, showCreator }: Props) => {
  const [userData, setUserData] = useState(undefined);
  const [isValid, setisValid] = useState(true);

  const [formValue, setFormValues] = useState({
    user: userData,
    title: "",
    description: "",
  });
  const submitForm = (values: any) => {
    onSubmit({
      user: userData[0],
      title: values.title,
      description: values.description,
    });
  };
  const validateForm = (values: any) => {
    const errors: any = {};
    if (userData === undefined) {
      errors.user = "This field is required";
      setisValid(false);
    }
    if (values.title.length === 0) {
      errors.title = "This field is required";
      setisValid(false);
    }
    if (values.description.length === 0) {
      errors.description = "This field is required";
      setisValid(false);
    } else setisValid(true);
    return errors;
  };

  return (
    <div>
      <Formik
        validate={validateForm}
        initialValues={formValue}
        onSubmit={(values, action) => {
          if (isValid) {
            submitForm(values);
            action.resetForm({
              values: {
                user: undefined,
                title: "",
                description: "",
              },
            });
            setUserData(undefined);
          }
        }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          validateForm,
        }) => (
          <>
            <Form className="form">
              <div className="add-new-userSearch">
                <UserSearch
                  multiple={false}
                  onChange={(event) => {
                    setUserData(event);
                  }}
                  value={userData}
                />
                {errors.user && !isValid && (
                  <div className="error">{errors.user}</div>
                )}
              </div>
              <div className="add-new-inputField">
                <FormikInput
                  value={values.title}
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={"Role"}
                  error={!isValid ? errors.title : ""}
                  withError={isValid ? false : true}
                  maxLength={28}
                />
              </div>
              <div className="add-new-textarea">
                <FormikTextArea
                  value={values.description}
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="What are your teammate's responsibilities?"
                  error={!isValid ? errors.description : ""}
                  autoSize={false}
                  maxLength={100}
                />
              </div>
              <Button
                type="primary"
                size="large"
                className="add-new-member-button"
                onClick={(e: any) => {
                  validateForm();
                  handleSubmit(e);
                }}
              >
                <span>Add</span>
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddNewMember;
