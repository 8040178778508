import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { INews } from '../NewsFeed/NewsFeed';
import { get } from '../../libs/utils/request';
import { IFeed } from '../Competitions/Competition/components/Feed/Feed';
import { Loading } from './Loading';
import NewsCard from '../NewsFeed/components/NewsCard/NewsCard';
import NoResult from './NoResult';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    overflow: 'auto',
  },
};

const getTypeByName = (name: string) => {
  switch (name) {
    case 'Post': return 'newspost';
    case 'Event': return 'event';
    case 'Job': return 'job';
    default: return '';
  }
};

const shareLinkBase = `${window.location.protocol}//${window.location.host}`;

const FeedList = ({
  url, selectedType, refreshNeeded, setRefreshNeeded, onEdit, onDelete, groupId, shareDisabled, id, feedListMaxWidth, newsGroupId
}: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [feed, setFeed] = useState<INews[]>([]);
  const [totalCount, setTotalCount] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const params: any = {
          currentPage: 1,
          limit,
        };
        if (selectedType !== 'All') {
          params.type = getTypeByName(selectedType);
        }
        if (groupId) {
          params.group = groupId;
        }
        if(newsGroupId){
          params.newsGroupId=newsGroupId;
        }
        const tmp = await get<{ data: INews[], pagination: any }>(url, params);
        if (tmp.data) {
          setFeed(tmp.data);
        } else {
          setFeed([]);
        }
        setTotalCount(tmp.pagination.totalCount);
        if (limit !== tmp.pagination.limit) {
          setLimit(tmp.pagination.limit);
        }
        setCurrentPage(1);
        if (refreshNeeded) {
          setRefreshNeeded(false);
        }
      } catch (error) {
        message.error('Please log in');
      } finally {
        setLoading(false);
      }
    })();
  }, [limit, selectedType, url, refreshNeeded, setRefreshNeeded, groupId]);

  const getNextPage = (page: number) => {
    if (page === currentPage + 1) {
      (async () => {
        try {
          const params: any = {
            currentPage: page,
            limit,
          };
          if (selectedType !== 'All') {
            params.type = getTypeByName(selectedType);
          }
          if(newsGroupId){
            params.newsGroupId=newsGroupId;
          }
          if (groupId) {
            params.group = groupId;
          }
          const tmp = await get<{ data: INews[], pagination: any }>(url, params);
          if (tmp.data) {
            setFeed([...feed, ...tmp.data]);
          }
          setTotalCount(tmp.pagination.totalCount);
          if (limit !== tmp.pagination.limit) {
            setLimit(tmp.pagination.limit);
          }
          setCurrentPage(page);
        } catch (error) {
          message.error('Failed to fetch next page.');
        }
      })();
    }
  };

  const getFeed = async (page = 1) => {
    try {
      const params: any = {
        currentPage: page,
        limit,
      };
      if (selectedType !== 'All') {
        params.type = getTypeByName(selectedType);
      }
      if (groupId) {
        params.group = groupId;
      }
      if(newsGroupId){
        params.newsGroupId=newsGroupId;
      }
      const tmp = await get<{ data: IFeed[], pagination: any }>(url, params);
      if (tmp.data) {
        const list = [...feed];
        const index = list.findIndex((item) => item.id === tmp.data[0].id);
        if (index !== -1) {
          for (let i = 0; i < tmp.data.length && i < list.length; i += 1) {
            list[index + i] = tmp.data[i];
          }
        }
        setFeed(list);
      }
      setTotalCount(tmp.pagination.totalCount);
      if (limit !== tmp.pagination.limit) {
        setLimit(tmp.pagination.limit);
      }
    } catch (error) {
      message.error('Failed to fetch feed.');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={styles.root}>
      <InfiniteScroll
        pageStart={1}
        loadMore={getNextPage}
        hasMore={feed.length < totalCount}
        loader={<Loading />}
        initialLoad={false}
        style={{
          width: "100%",
          // maxWidth: feedListMaxWidth || 600,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
        threshold={500}
        useWindow = {id ? false : true}
        getScrollParent={() => document.getElementById(`${id}`)}
        
      >
        {!feed.length && <NoResult />}
        {feed.map((item, i) => (
          <NewsCard
            key={item.id}
            news={item}
            isTop={!i}
            isBottom={i === feed.length - 1}
            onChange={() => getFeed(Math.floor(i / limit) + 1)}
            withMenu
            onEdit={() => onEdit(item)}
            onDelete={() => onDelete(item.id)}
            shareLink={`${shareLinkBase}/news/${item.uniqueTag}`}
            shareDisabled={shareDisabled}
            // style={{ marginBottom: 20 }}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  url: string,
  selectedType?: string;
  refreshNeeded: boolean,
  setRefreshNeeded: (b: boolean) => void;
  onEdit?: (news: INews) => void;
  onDelete?: (id: string) => void;
  groupId?: string;
  shareDisabled?: boolean,
  id? : any
  feedListMaxWidth?: number;
  newsGroupId?: string;
}

FeedList.defaultProps = {
  selectedType: 'All',
  onEdit: null,
  onDelete: null,
  groupId: null,
  shareDisabled: false,
};

export default FeedList;
