import React from 'react'
import {Button, Input, Select} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { PlusOutlined } from '@ant-design/icons'

interface Props {
  data?: any | undefined
  edit?: undefined | true
  onSubmit: (data: any) => void
}

interface State {
  loading: boolean
}

export class CreateNews extends React.Component<Props, State> {

  message: string | null = null
  link: string | null = null
  hashtags: string[] = []

  state: State = {
    loading: false
  }

  constructor(props: any) {
    super(props)
    if (this.props.edit) {
      const { data } = this.props
      this.message = data.message || null
      this.link = data.link || null
      this.hashtags = data.hashtags || []
    }
  }

  render() {
    const isEdit = !!this.props.edit

    return <div className={'CreateNews'}>
      <TextArea
        defaultValue={this.message || ''}
        className={'CreateNews-spacer'}
        style={{gridArea: 'a'}}
        onChange={(e) => {
          this.message = e.target?.value
        }}
        placeholder="Message"
        autoSize={{minRows: 1}}
      />

      <Input
        defaultValue={this.link || ''}
        className={'CreateNews-spacer'}
        style={{gridArea: 'b'}}
        placeholder="Link"
        size="large"
        onChange={(e) => {
          this.link = e.target?.value
        }}
      />

      <Select
        defaultValue={this.hashtags || []}
        className={'CreateNews-spacer'}
        mode="tags"
        style={{width: '100%', gridArea: 'c'}}
        placeholder="Hashtags"
        onChange={(hashtags: string[]) => {
          this.hashtags = hashtags
        }}
      />

      <Button
        loading={this.state.loading}
        className={'CreateNews-spacer'}
        style={{gridArea: 'd'}}
        type="primary"
        size="large"
        onClick={async () => {
          this.setState({ loading: true })
          await this.props.onSubmit({
            message: this.message,
            link: this.link,
            hashtags: this.hashtags
          })
          this.setState({ loading: false })
        }}>{isEdit ? 'Edit' : [<PlusOutlined/>,'Create a new']}</Button>
    </div>
  }
}
