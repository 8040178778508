import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Pagination, message } from 'antd';
import Masonry from 'react-masonry-css';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { get } from '../../libs/utils/request';
import './Competition/Competition.css';
import { Loading } from '../common/Loading';
import { user } from '../../libs/utils/user';
import { CompetitionCard } from '../CardView/ProjectCard';
import AutoSearchInput from '../common/AutoSearchInput';
import ErrorMessage from '../common/ErrorMessage';
import DraftsCard from './DraftsCard';

const styles = {
  root: {
    width: '100%',
    paddingTop: 60,
    paddingBottom: 80,
    paddingLeft: 20,
    paddingRight: 20,
  },
  content: {
    maxWidth: 1200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 80,
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    flexWrap: 'wrap' as const,
    gap: '16px 16px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 30,
    fontWeight: 900,
    color: '#000',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  submitBtn: { fontWeight: 'bold' as const, minWidth: 150, marginTop: 16 },
};

const Competitions = () => {
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [competitions, setCompetitions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [drafts, setDrafts] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0


  const [limit, setLimit] = useState(20);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      setErrorMessage('');
      setLoading(true);
      try {
        const page= localStorage.getItem('compPage')
        const params: any = {
          currentPage:page,
          limit,
        };
        if (search) {
          params.search = search;
        }

        if (user()) {
          const draftsTmp = await get<{ drafts: any[] }>('competitions/draft/all');
          setDrafts(draftsTmp.drafts);
        }
        const tmp = await get<{ data: any[], pagination: any }>('competitions', params);
        // console.log('COMPETITION');
        // console.log(JSON.stringify(tmp, null, 2));
        setCompetitions(tmp.data);
        setTotalCount(tmp.pagination.totalCount);
        if (currentPage !== tmp.pagination.currentPage) {
          setCurrentPage(parseInt(page));
        }
        if (limit !== tmp.pagination.limit) {
          setLimit(tmp.pagination.limit);
        }
      } catch (error) {
        if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')}
      }else if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, limit, search]);

  const getCompetitions = async (withLoading = true) => {
    setErrorMessage('');
    if (withLoading) {
      setLoading(true);
    }
    try {
      const page= localStorage.getItem('compPage')
      const params: any = {
        currentPage:page,
        limit,
      };
      if (search) {
        params.search = search;
      }
      const draftsTmp = await get<{ drafts: any[] }>('competitions/draft/all');
      setDrafts(draftsTmp.drafts);
      const tmp = await get<{ data: any[], pagination: any }>('competitions', params);
      setCompetitions(tmp.data);
      setTotalCount(tmp.pagination.totalCount);
      if (currentPage !== tmp.pagination.currentPage) {
        window.location.replace(`/competitions/page=${currentPage}`)
        setCurrentPage(parseInt(page));
      }
      if (limit !== tmp.pagination.limit) {
        setLimit(tmp.pagination.limit);
      }
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      else if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onAddNew = () => {
    history.push('/new-competition');
  };

  const onPageChange=(page:number)=>{
    setCurrentPage(page);
    localStorage.setItem('compPage',page.toString())
    window.location.reload()
  }

  const renderPaginationButtons = (marginTop = 0) => (
    <div style={{ ...styles.paginationContainer, marginTop }}>
      <Pagination
        current={currentPage}
        defaultPageSize={40}
        pageSize={limit}
        onChange={onPageChange}
        total={totalCount}
        style={{ marginTop }}
      />
    </div>
  );

  const renderWithPager = (content: ReactElement) => (
    <>
      {renderPaginationButtons(16)}
      {isLoading ? <Loading /> : content}
      {renderPaginationButtons(42)}
    </>
  );

  const renderPagination = () => {
    if (errorMessage) {
      return (
        <ErrorMessage
          title='Failed to load competitions.'
          message={errorMessage}
          withRetry
          onRetry={() => getCompetitions()}
        />
      );
    }
    if (!competitions.length) {
        return <div className="no-result">
          We didn't found anything. Maybe it's a new competition?
        </div>
    }
    return renderWithPager(
      <Masonry
        breakpointCols={{ default: 2, 850: 1 }}
        className='startup-box-view'
        columnClassName='startup-box-view-item'
      >
        {competitions.map((competition: any) => (
          <CompetitionCard
            leadButtonText={competition.leadButtonText}
            key={competition.tag}
            competition={competition}
            onChange={() => getCompetitions()}
            onClick={() => {
              history.push(`/competitions/${competition.tag}`);
            }}
          />
        ))}
      </Masonry>,
    );
  };

  return (
    <div style={styles.root}>
      <div style={styles.content}>
        <Helmet>
          <title>Competitions | pozi.io</title>
        </Helmet>
        <div style={styles.titleContainer}>
          <div>
            <div style={styles.title}>Competitions</div>
            {!!user() && user().active && (
              <Button style={styles.submitBtn} type='primary' size='large' onClick={()=>profileProgress < 100 ? message.warning(`${profileProgress}% of your profile is completed. To create competition you need to get 100%`) : onAddNew()}>
                Create new
              </Button>
            )}
          </div>
          <div style={{ minWidth: 280 }}>
            <AutoSearchInput
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
              style={{ width: '100%' }}
              placeholder='Type something...'
            />
          </div>
        </div>
        {!!drafts.length && (
          <DraftsCard type={'competition'} drafts={drafts} collapsed={collapsed} setCollapsed={setCollapsed} />
        )}
        {renderPagination()}
      </div>
    </div>
  );
};

export default Competitions;
