import React from 'react'
import { CommentBlockElement } from "./CommentBlockElement";
import { CommentHead2 } from "./CommentHead2";
import { CommentData } from "./Comments";

interface Props {
  comment: {
    comment: CommentData
    thread: CommentData[]
  }
  patchURL: (id: string) => string
  postURL: string
  onNewComment?: () => void
  deleteURL:(id:string)=>string
  type?:string;
  source:string;
  onDeleteComment:()=>void
}

interface State {
  reply: CommentData | null
}

export class CommentBlock extends React.Component<Props, State> {

  state: State = {
    reply: null
  }

  setReply(comment: CommentData) {
    this.setState((prevState: State) => ({
      reply: prevState.reply && prevState.reply.id === comment.id ? null : comment
    }))
  }

  render() {
    return <div className={'CommentBlock'}>
      <CommentBlockElement
        onReply={() => {
          this.setReply(this.props.comment.comment)
        }}
        comment={this.props.comment.comment}
        patchURL={this.props.patchURL}
        deleteURL={this.props.deleteURL}
        source={this.props.source} 
        onDeleteComment={this.props.onDeleteComment}
        />
      {this.props.comment.thread.map(c => <CommentBlockElement
        comment={c}
        style={{
          paddingLeft: '44px'
        }}
        onReply={() => {
          this.setReply(c)
        }}
        patchURL={this.props.patchURL}
        deleteURL={this.props.deleteURL}
        type={this.props.type}
        source={this.props.source}
        onDeleteComment={this.props.onDeleteComment}
        />)}
      {this.state.reply === null
        ? null
        : <CommentHead2
          style={{
            paddingLeft: '44px'
          }}
          postURL={this.props.postURL}
          reply={this.state.reply}
          onNewComment={this.props.onNewComment}
          placeholder={`Reply to ${this.state.reply.user.firstName} ${this.state.reply.user.lastName}`} 
          type={this.props.type}
          />}
    </div>
  }
}