import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import BorderCard from '../../../common/BorderCard';
import { ICriterion } from '../Competition';

const baseText = {
  fontSize: 16,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#282828',
};
const styles = {
  header: {
    padding: 10,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px 20px',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    alignItems: 'center',
    gap: '10px 20px',
  },
  headerTitle: {
    ...baseText,
    fontSize: 18,
    fontWeight: 900,
  },
  headerDescription: {
    ...baseText,
    fontStyle: 'italic',
  },
  body: {
    marginTop: 20,
    paddingLeft: 28,
    paddingRight: 28,
  },
  criteriaRow: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '0px 10px',
    marginBottom: 10,
  },
  criteriaName: {
    ...baseText,
    fontWeight: 900,
  },
  criteriaDescription: baseText,
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
};

const TITLE = 'Evaluation Method';
const DESCRIPTION = 'For each project, please grade on the following criteria a scale of 1 to 5 for each category.';

const MethodologyCard = ({ criteria, editable, onEdit }: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleEditClicked = (e: any) => {
    e.stopPropagation();
    if (onEdit) {
      onEdit();
    }
  };

  return (
    <BorderCard isTop isBottom onClick={() => setCollapsed(!collapsed)}>
      <div style={styles.header}>
        <div style={styles.headerText}>
          <CaretRightOutlined
            rotate={collapsed ? 90 : 0}
            style={{ ...styles.arrowIcon, color: collapsed ? '#6755CE' : '#898989' }}
          />
          <div style={styles.headerTitle}>{TITLE}</div>
          <div style={styles.headerDescription}>{DESCRIPTION}</div>
        </div>
        {editable && (
          <Button style={{ fontWeight: 'bold', color: '#6755CE' }} type='link' size='large' onClick={handleEditClicked}>
            Edit
          </Button>
        )}
      </div>
      <Collapse in={collapsed} timeout='auto' unmountOnExit>
        <div style={styles.body}>
          {criteria.map((item: ICriterion) => (
            <div key={item.title} style={styles.criteriaRow}>
              <div style={styles.criteriaName}>
                {item.title}
                :
              </div>
              <div style={styles.criteriaDescription}>{item.description}</div>
            </div>
          ))}
        </div>
      </Collapse>
    </BorderCard>
  );
};

interface Props {
  criteria: ICriterion[];
  editable?: boolean;
  onEdit?: () => void;
}

MethodologyCard.defaultProps = {
  editable: false,
  onEdit: null,
};

export default MethodologyCard;
