import React, { useEffect, useState } from 'react';
import { Button, Timeline } from 'antd';
import { Moment } from 'moment';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import ScheduleDot from '../../../common/ScheduleDot';
import ScheduleItem from './components/ScheduleItem';

const sorter = (a: IScheduleItem, b: IScheduleItem) => {
  if (a.date < b.date) {
    return -1;
  }
  return a.date > b.date ? 1 : 0;
};

const getFieldKeys = (n: number): string[] => {
  const res = [];
  for (let i = 0; i < n; i += 1) {
    res.push(`schedule_date_${i}`, `schedule_name_${i}`);
  }
  return res;
};

const isScheduleValid = (form: WrappedFormUtils, fieldKeys: string[], callback: any) => {
  form.validateFields(fieldKeys, (errors) => {
    callback(!errors);
  });
};

const getScheduleCount = (form: WrappedFormUtils): number => {
  let n = 0;
  while (form.getFieldValue(`schedule_name_${n}`)) {
    n += 1;
  }
  return n;
};

export const sortSchedule = (form: WrappedFormUtils, n = -1): void => {
  const count = n === -1 ? getScheduleCount(form) : n;
  let tmp: IScheduleItem[] = [];
  for (let i = 0; i < count; i += 1) {
    tmp.push({
      date: form.getFieldValue(`schedule_date_${i}`),
      name: form.getFieldValue(`schedule_name_${i}`),
    });
  }
  tmp = tmp.sort(sorter);
  for (let i = 0; i < count; i += 1) {
    form.setFieldsValue({
      [`schedule_date_${i}`]: tmp[i].date,
      [`schedule_name_${i}`]: tmp[i].name,
    });
  }
};

const Schedule = ({
  form, initialCount, isEdit, createdAt,
}: Props) => {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  const addItem = async () => {
    isScheduleValid(form, getFieldKeys(count), (result: boolean) => {
      if (result) {
        sortSchedule(form, count);
        setCount(count + 1);
      }
    });
  };

  const deleteItem = (index: number) => {
    if (count > 3) {
      for (let i = index; i < count - 1; i += 1) {
        form.setFieldsValue({
          [`schedule_date_${i}`]: form.getFieldValue(`schedule_date_${i + 1}`),
          [`schedule_name_${i}`]: form.getFieldValue(`schedule_name_${i + 1}`),
        });
      }
      setCount(count - 1);
    }
  };

  return (
    <>
      <Timeline style={{ marginLeft: 12 }}>
        {Array.from(Array(count).keys()).map((i) => (
          <Timeline.Item key={i.toString()} dot={<ScheduleDot index={i + 1} />}>
            <ScheduleItem
              form={form}
              dateFieldKey={`schedule_date_${i}`}
              nameFieldKey={`schedule_name_${i}`}
              onDelete={() => deleteItem(i)}
              deletable={count > 3}
              isEdit={isEdit}
              createdAt={createdAt}
            />
          </Timeline.Item>
        ))}
      </Timeline>
      <Button style={{ fontWeight: 'bold' }} type='primary' size='large' onClick={addItem}>Add item</Button>
    </>
  );
};

export interface IScheduleItem {
  name: string;
  date: Moment;
}

interface Props {
  form: WrappedFormUtils;
  initialCount?: number;
  isEdit: boolean;
  createdAt?: string;
}

Schedule.defaultProps = {
  initialCount: 3,
  createdAt: null,
};

export default Schedule;
