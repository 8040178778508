const listeners = new Map<string, Map<string, {callback: Function, message: string}>>()

export const events = {
  event: "event",
  logout: "logout",
  languageChanged: "languageChanged",
  validateRegistration: "validateRegistration",
  messagesCountRefresh: "messagesCountRefresh"
}

export function addListener(event: string, listenerClass: string, callback: Function, message?: string) {
  if (!listeners.has(event)) {
    const callbacks = new Map<string, {callback: Function, message: string}>()
    callbacks.set(listenerClass, {callback, message})
    listeners.set(event, callbacks)
  } else {
    const callbacks: Map<string, {callback: Function, message: string}> = listeners.get(event)
    callbacks.set(listenerClass, {callback, message})
    listeners.set(event, callbacks)
  }
}

export function removeListener(event: string, listenerClass: string) {
  if (listeners.has(event)) {
    const callbacks: Map<string, {callback: Function, message: string}> = listeners.get(event)
    if (callbacks.has(listenerClass)) {
      callbacks.delete(listenerClass)
    }
  }
}

export function notify(event: string, data?: any) {
  if (listeners.has(event)) {
    const callbacks: Map<string, {callback: Function, message: string}> = listeners.get(event)
    callbacks.forEach((callback) => {
      callback.callback(data)
    })
  }
}

export async function validate(event: string): Promise<{ok: boolean, message: string}> {
  if (listeners.has(event)) {
    const callbacks: Map<string, {callback: Function, message: string}> = listeners.get(event)
    let successful = true
    let message = undefined
    const functions = Array.from(callbacks.values())
    for (const callback of functions) {
      const success = await callback.callback()
      if (!success) {
        successful = false
        message = callback.message
      }
    }

    return {ok: successful, message}
  } else {
    return {ok: false, message: undefined}
  }
}

export default {
  addListener: addListener,
  removeListener: removeListener,
  notify: notify,
  events: events,
  validate: validate,
}
