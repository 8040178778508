import * as React from 'react';
// import * as ReactDOM from 'react-dom';
import {
  Route, BrowserRouter as Router, Redirect, Switch, useParams, useHistory,
} from 'react-router-dom';

import 'antd/dist/antd.css';
import './App.css';
import './fonts/Fonts.css';
import { message } from 'antd';
import MainFrame from './MainFrame';
import Registration from './components/Registration/Registration';
import Login from './components/Login/NewLogin';
import Ideas from './components/Idea/Ideas';
import IdeaPage from './components/Idea/IdeaPage';
import Projects from './components/Project/Projects';
import Project from './components/Project/Project';
import ProfilePage from './components/User/ProfilePage';

import { NewsCardPage } from './components/News/NewsCardPage';
import CompetitionsNewsCardPage from './components/Competitions/NewsCardPage';
import IndexPage from './components/IndexPage/IndexPage2';
import ActivationPage from './components/Registration/ActivationPage';
import NotActivatedPage from './components/Registration/NotActivatedPage';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Messages from './components/Messages/Messages';
import TermsAndConditions from './components/PrivacyPolicy/Terms';
import { ResetPassword } from './components/ResetPassword/ResetPassword';
import ForgottenPassword from './components/ForgottenPassword/ForgottenPassword';
import { Users } from './components/User/Users';
// import { ErrorHandling } from './components/common/ErrorHandling';
import InvitationPage from './components/Registration/InvitationPage';
import InvitationsPage from './components/Registration/InvitationsPage';
import { Groups } from './components/Groups/Groups';
import { GroupPage } from './components/Groups/GroupPage';
import { CreateGroup } from './components/Groups/CreateGroup';
import { hasPermission} from './libs/utils/user';
import NewCompetition from './components/Competitions/NewCompetition';
import Competitions from './components/Competitions/Competitions';
import Competition from './components/Competitions/Competition/Competition';
import NewEvent from './components/Events/NewEvent';
import Events from './components/Events/Events';
import Event from './components/Events/Event/Event'
import NewProject from './components/Project/NewProject';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import NewRegistration from './components/Registration/NewRegistration';
import EndedTrialPeriod from './components/Registration/EndedTrialPeriod';
import NewOrganization from './components/Organization/NewOrganization';
import Organizations from './components/Organization/Organizations';
import OrganizationPage from './components/Organization/OrganizationPage';
import InvitationRegistration from './components/Registration/InvitationRegistration';
import Dashboard from './components/Dashboard/Dashboard';
import ChangeEmail from './components/User/ChangeEmail';
import { useContext, useEffect } from 'react';
import AuthContext from '../store/AuthContext';
import eventbus from './libs/eventbus';
import RssFeed from './components/IndexPage/RssFeed';
import { get } from './libs/utils/request';
import Articles from './components/Blog/Articles';
import Article from './components/Blog/Article';
import NewArticle from './components/Blog/NewArticle';
import Sales from './components/Sales/Sales';
import HomePage from './components/IndexPage/HomePage';




export function GetComponent({ component, permissions }: any) {
  const params = useParams();
  if (permissions && !permissions.some((p: any) => hasPermission(p))) {
    message.error('Access denied', 5);
    return <Redirect to={{ pathname: '/' }} />
  }
  const match = { params };
  return component({ match });
}


const PrivateRoute = ({ component, ...rest }: any) => {
  const authContext = useContext(AuthContext);
  const routeComponent = (props: any) => {
    if(authContext.isLoggedIn){
      if(component!==null){
        return( React.createElement(component, props))
      }else{
        return( <Redirect to='/' />)
      }
    }else{
      return( <Redirect to={{ pathname: '/login' }} />)
  }};
    return <Route {...rest} render={routeComponent} />;
};
const Routes = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  window.addEventListener('storage', () => {
    if (authContext.isLoggedIn)
      if ((sessionStorage.getItem('user') === null || localStorage.getItem('token') === null)) {
        authContext.logout()
      }
  })

  useEffect(()=>{
    if(localStorage.getItem("token")!==null || sessionStorage.getItem('user')==null){
      get('/me').then((res)=>sessionStorage.setItem('user',JSON.stringify(res)))
    }
  },[])
  


  eventbus.addListener(eventbus.events.logout, 'Routes', async () => {
    if(authContext.isLoggedIn)
    authContext.logout();
  });
  return (
    <Router>
      <Switch>
        <MainFrame>
          <Switch>
            {/* can see without login */}
            <Route exact path='/' component={HomePage} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgottenPassword' component={ForgottenPassword} />
            <Route exact path='/registration' component={NewRegistration} />
            <Route exact path='/invitation-registration' component={InvitationRegistration} />
            <Route exact path='/ideas' component={Ideas} />
            <Route exact path='/idea/:uniqueTag' component={IdeaPage} />
            <Route exact path='/startups' component={Projects} />
            <Route exact path='/competitions' component={Competitions} />
            <Route exact path='/competitions/:tag'><Competition /></Route>
            <Route exact path='/competitions/:tag/feed/:id'><CompetitionsNewsCardPage /></Route>
            <Route exact path='/competitions/:tag/:subPage'><Competition /></Route>
            <Route exact path='/events'><Events /></Route>
            <Route exact path='/events/:tag'><Event /></Route>
            <Route exact path='/startups/:uniqueTag' component={Project} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
            <Route exact path="/organizations" component={Organizations} />
            <Route exact path="/organizations/:tag" ><OrganizationPage /></Route>
            <Route exact path="/packages/organizations" ><Sales /></Route>
            <Route exact path='/organizations/:tag/:subPage'><OrganizationPage /></Route>
            <Route exact path="/linkedin" component={LinkedInCallback} />
            <Route exact path='/rss'><RssFeed/></Route>
            <Route exact path='/resetPassword/:resetToken/:email' component={ResetPassword} />
            <Route exact path='/invitation/:data' component={InvitationPage} />
            <Route exact path='/blog' component={Articles} />
            <Route exact path='/blog/:tag'><Article /></Route>
            <Route exact path='/activate/:data' component={ActivationPage} />

          {/* <Route exact path='/dashboard' component={Dashboard} /> */}
          <Route exact path='/old-reg' component={Registration} />
            {/* <Route exact path='/edit-competition-draft/:draftId'><NewCompetition isEdit isDraft /></Route> */}
            <PrivateRoute exact path='/edit-competition-draft/:draftId' component={() => (<NewCompetition isEdit isDraft />)} />
            {/* <Route exact path='/edit-startup-draft/:draftId' component={NewProject} /> */}
            <PrivateRoute exact path='/edit-competition-draft/:draftId' component={NewProject} />
            {/* <Route exact path='/new-startup' component={NewProject} /> */}
            <PrivateRoute exact path='/new-startup' component={NewProject} />
            {/* <Route exact path='/edit-startup/:projectId' component={NewProject} /> */}
            <PrivateRoute exact path='/edit-startup/:projectId' component={NewProject} />
            {/* <Route exact path='/edit-competition/:tag'><NewCompetition isEdit /></Route> */}
            <PrivateRoute exact path='/edit-competition/:tag' component={() => (<NewCompetition isEdit />)} />
            {/* <Route exact path='/new-competition'><NewCompetition /></Route> */}
            <PrivateRoute exact path='/new-competition' component={() => <NewCompetition isCreating />} />
            {/* <Route exact path='/edit-startup-draft/:draftId'>component={ProjectPage}  /></Route>  */}
            <PrivateRoute exact path='/edit-startup-draft/:draftId' component={NewProject} />

            <PrivateRoute exact path='/news/:id' component={NewsCardPage} />
            {/* <Route exact path='/new-event'><NewEvent /></Route> */}
            <PrivateRoute exact path='/new-event' component={() => <NewEvent isCreating />} />
            {/* <Route exact path='/edit-event/:tag'><NewEvent isEdit /></Route> */}
            <PrivateRoute exact path='/edit-event/:tag' component={() => <NewEvent isEdit />} />
            {/* <Route exact path='/edit-event-draft/:draftId'><NewEvent isEdit isDraft /></Route> */}
            <PrivateRoute exact path='/edit-event-draft/:draftId' component={() => (<NewEvent isDraft isEdit />)} />
            {/* <Route exact path='/events/:tag/:subPage'><Event /></Route> */}
            <PrivateRoute exact path='/events/:tag/:subPage' component={() => (< Event/>)} />
            {/* <Route exact path='/users' component={Users} /> */}
            <PrivateRoute exact path='/users' component={Users} />
            <PrivateRoute exact path='/createGroup' component={() => (<GetComponent component={(props: any) => <CreateGroup {...props} />} permissions={['admin']} />)} />
            <PrivateRoute exact path='/groups_coming-soon' component={() => (<GetComponent component={(props: any) => <Groups {...props} />} />)} />
            <PrivateRoute exact path='/group/:id' component={() => (<GetComponent component={(props: any) => <GroupPage {...props} />} />)} />

            {/* <Route exact path='/entrepreneurs/:id' component={ProfilePage} /> */}
            <PrivateRoute exact path='/entrepreneurs/:id' component={ProfilePage} />
            {/* <Route exact path='/profile' component={ProfilePage} /> */}
            <PrivateRoute exact path='/profile' component={ProfilePage} />
            {/* <Route exact path='/activate/:data' component={ActivationPage} /> */}
            {/* <Route exact path='/invitation/:data' component={InvitationPage} /> */}
            
            {/* <Route exact path='/invitations' component={InvitationsPage} /> */}
            <PrivateRoute exact path='/invitations' component={InvitationsPage} />
            {/* <Route exact path='/notActivated' component={NotActivatedPage} /> */}
            <PrivateRoute exact path='/notActivated' component={NotActivatedPage} />

            {/* <Route exact path='/messages' component={Messages} /> */}
            <PrivateRoute exact path='/messages' component={Messages} />
            {/* <Route exact path='/messages/user/:userId' component={Messages} /> */}
            <PrivateRoute exact path='/messages/user/:userId' component={Messages} />
            {/* <Route exact path='/messages/thread/:threadId' component={Messages} /> */}
            <PrivateRoute exact path='/messages/thread/:threadId' component={Messages} />
            
            

            {/* <Route exact path='/dashboard' component={Dashboard}></Route> */}
            <PrivateRoute exact path='/dashboard' component={Dashboard} />

            {/* <Route exact path="/trial-ended" component={EndedTrialPeriod} /> */}
            <PrivateRoute exact path="/trial-ended" component={EndedTrialPeriod} />
            {/* <Route exact path='/new-organization'><NewOrganization /></Route> */}
            <PrivateRoute exact path='/new-organization/:tag' component={() => (<NewOrganization isCreating />)} />
            {/* <Route exact path='/edit-organization-draft/:draftId'><NewOrganization isEdit isDraft /></Route> */}
            <PrivateRoute exact path='/edit-organization-draft/:draftId' component={() => (<NewOrganization isEdit isDraft />)} />
            {/* <Route exact path='/edit-organization/:tag'><NewOrganization isEdit /></Route> */}
            <PrivateRoute exact path='/edit-organization/:tag' component={() => (<NewOrganization isEdit />)} />
            {/* <Route exact path='/change-email/:resetToken/:email' component={ChangeEmail} /> */}
            <PrivateRoute exact path='/change-email/:resetToken/:email' component={ChangeEmail} />

            <PrivateRoute exact path='/new-article' component={() => <NewArticle isCreating />} />
            {/* <Route exact path='/edit-event/:tag'><NewEvent isEdit /></Route> */}
            <PrivateRoute exact path='/edit-article/:tag' component={() => <NewArticle isEdit />} />
            {/* <Route exact path='/edit-event-draft/:draftId'><NewEvent isEdit isDraft /></Route> */}
            <PrivateRoute exact path='/edit-article-draft/:draftId' component={() => (<NewArticle isDraft isEdit />)} />
            <Redirect to="/" />

          </Switch>
        </MainFrame>
      </Switch>
    </Router>
  )
};

export default Routes;

// ErrorHandling.init();
//
// ReactDOM.render(
//   <Routes />,
//   document.getElementById('root'),
// );
