import { Button } from "antd";
import React from "react";

const SectionButton = ({ name, isActive, onClick }: Props) => (
  <Button
    style={{
      // color: isActive ? "white" : user() ? "var(--primary-blue)" : "white",
      color: '#FFF',
      // backgroundColor:isActive ? "var(--primary-blue)" : user() ? "#F2F0FF" : "#B8B8B8"
      backgroundColor: isActive ? '#6755CE' : '#898989'
    }}
    className="organizationpage-section-buttons"
    // onClick={user() && onClick}
    onClick={onClick}
    aria-hidden="true"
  >
    {name}
  </Button>
);

interface Props {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

export default SectionButton;
