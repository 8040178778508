import React, { CSSProperties } from 'react';
import { Select } from 'antd';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const timezones = require('../../../../libs/timezones/timezones.json');

const styles = {
  root: { width: '100%' },
};

export const TimezoneSelector = ({ value, onChange, style }: Props) => {
  const initialValue = Object.entries(timezones).find((entry) => entry[1] === value);
  return (
    <Select
      onChange={(v: string) => {
        onChange(timezones[v]);
      }}
      style={{ ...styles.root, ...style }}
      value={initialValue ? initialValue[0] : ''}
    >
      {Object.keys(timezones).map((item) => (
        <Option key={`${item}`}>{item}</Option>
      ))}
    </Select>
  );
};

interface Props {
  value?: string,
  onChange: any;
  style?: CSSProperties;
}

TimezoneSelector.defaultProps = {
  value: '',
  style: {},
};
