import React from "react"
import { post } from "../../libs/utils/request"
import eventbus from "../../libs/eventbus"
import { message } from "antd";

interface Props {
  match: any
  history: any
}

class ActivationPage extends React.Component<Props> {

  async componentDidMount() {
    if (this.props.match.params.data) {
      try {
        const user = await post('/user/activate', { data: this.props.match.params.data })
        message.success('Activation was successful')
        if (user) {
          await sessionStorage.setItem('user', JSON.stringify(user))
          this.props.history.push('/profile')
        } else {
          this.props.history.push('/login')
        }
      } catch (error) {
        console.error(error)
        const details = error.response ? ': ' + error.response.status + ' ' + error.response.data : ''
        console.error(`Activation request failed${details}`)
        message.error(`Activation request failed${details}`)
        eventbus.notify(eventbus.events.logout)
        this.props.history.push('/login')
      }
    } else {
      this.props.history.push('/login')
    }
  }

  render() {
    return <div />
  }

}

export default ActivationPage
