import React from "react"
import { post } from "../../libs/utils/request"
import { Button, Input, Pagination, Select, message } from "antd"
import User from "../../user/User"
import { textOverflow } from "../../libs/utils/textUtils"
import { Role } from "../Registration/Registration"
import { RoleFilter } from "./RoleFilter"
import './Users.css'
import { SearchOutlined } from '@ant-design/icons';
import { Loading } from "../common/Loading";
import { InvitationModal } from "../Registration/InvitationModal";
import { Helmet } from 'react-helmet';
const { Option } = Select;

const suffix = (
	<div style={{
		background: 'var(--secondary-blue)',
		borderRadius: '100%',
		height: '50px',
		width: '50px',
		textAlign: 'center',
		paddingTop: '15px',
		marginLeft: '2px',
		position: 'absolute'
	}}>
		<SearchOutlined
			style={{
				fontSize: 18,
				color: '#fff',
			}}
		/>
	</div>
);

interface State {
	rolesFilter: Role[]
	userList: User[]
	page: number
	count: number | null
	roles: Role[] | null
	loading: boolean
	invitationModal: boolean
}

let pageSize = 40;

export class Users extends React.Component<any, State> {

	searchValue: string = ''

	state: State = {
		rolesFilter: [],
		userList: [],
		page: 1,
		roles: null,
		loading: false,
		invitationModal: false,
		count: null,
	}

	componentDidMount() {
		this.onPage()
	}

	fetchId: number = -1
	getUsers = async (page: number, search: string | undefined = undefined) => {
		const { rolesFilter } = this.state
		this.fetchId += 1
		const fetchId = this.fetchId

		const { data: userList, count } = await post<any>('/user/list', {
			page,
			count: pageSize,
			search: search ? search.toLowerCase() : undefined,
			rolesFilter: rolesFilter.map(r => r.id)
		})

		if (fetchId === this.fetchId) {
			this.setState({
				userList,
				loading: false,
				count
			})
		}
	}

	async onPage(page: number | undefined = undefined) {
		try {
			this.setState({
				page: page === undefined ? this.state.page : page,
				loading: true
			})

			await this.getUsers(page === undefined ? this.state.page : page, undefined)
		} catch (e) {
			console.error(e)
		}
	}

	openEntrepreneurs = (uniqueTag:string) => {
		window.open("/entrepreneurs/" + uniqueTag)

	}

	openMyProfile = () =>{
		window.open("/profile")
	}

	onRoleFilter(rolesFilter: Role[]) {
		this.setState({ rolesFilter })
		this.onPage(1)
	}

	async onSearch(value: string) {
		this.searchValue = value
		try {
			this.setState({
				loading: true
			})

			await this.getUsers(1, value)
		} catch (err) {
			console.error(err)
		}
	}

	render() {
		const { userList } = this.state
		const userFromStorage = sessionStorage.getItem('user')
        const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  
		return (
			<div className="Content-container">
				<Helmet>
					<title>Users | pozi.io</title>
				</Helmet>
				<div className={'Users-header'}>
					<div style={{ display: 'inline-block' }}>
						<h1>Widen your network!</h1>
						<Button
							onClick={() =>  
								this.setState({ invitationModal: true })}
							loading={false}
							type="primary"
							size="large"
							block
							style={{
								width: '200px',
								marginBottom: '50px',
							}}>Invite</Button>
						<p>Search amongst the members of the community and find connections, chat, and collaborate with them!</p>
						<Input
							className={'Users-search'}
							placeholder={'Type a name you like...'}
							// @ts-ignore
							onChange={e => this.onSearch(e.target?.value)}
							suffix={suffix}
							addonAfter={null}
						/>
					</div>
					<div className={'Users-rolefilter'}>
						<RoleFilter onChange={rolesFilter => this.onRoleFilter(rolesFilter)} />
					</div>
				</div>
				<div className={'Users-list'}>
					<div style={{
						textAlign: 'center',
						paddingBottom: '60px',
						display: 'flex',
						justifyContent: 'space-between'
					}}>
						<div style={{ width: '120px' }} />
						<div>
							<Pagination
								pageSize={pageSize}
								current={this.state.page}
								onChange={(page) => {
									this.onPage(page)
								}}
								total={this.state.count || pageSize} />
						</div>
						<div>
							<Select defaultValue={40} style={{ width: 120 }} onChange={(page: any) => {
								pageSize = page
								this.onPage()
							}}>
								<Option value={40}>40</Option>
								<Option value={100}>100</Option>
							</Select>
						</div>
					</div>
					<div>
						{this.state.loading && userList.length === 0
							? <Loading />
							: null}
						{userList.length === 0 && this.state.rolesFilter.length > 0 && !this.state.loading
							? <div className={'Users-empty-state'}>{
								this.state.rolesFilter.length > 1 ? 'No users in these roles yet' : 'No users in this role yet'
							}</div>
							: null}
						{userList.map((u, i) => {
							return <div className={'Users-box'} key={i + u.id}>
								<div>
									<div className={'left'}>
										<div className="Users-image">
											<div className="Users-image-main" style={{
												backgroundImage: `url(${u.profileImage || "/images/user.svg"
													} )`,
											}}>
											
											</div>
											<div className="Users-image-role">
												<img src={u?.role?.icon} alt="" />
											</div>
										</div>
									</div>
									<div className={'right'}>
										<div>
											<div style={{
												fontWeight: 900
											}}>{u.firstName + ' ' + u.lastName}</div>
											<div className={'Users-role'}>{u?.role?.name/*TODO: check: is it right?*/}</div>
											<div className={'Users-bio'}>{textOverflow(u.oneLiner, 60)/*TODO: check: is it right?*/}</div>
										</div>
										<Button
											onClick={() => JSON.parse(userFromStorage).id ===  u.id ? this.openMyProfile() : this.openEntrepreneurs(u.uniqueTag)}
											loading={false}
											type="primary"
											size="large"
											block
											style={{
												width: '120px',
												height: '32px'
											}}>View</Button>
									</div>
								</div>
							</div>
						})}
					</div>
				</div>
				<div style={{ width: '120px' }} />
				<div style={{
					textAlign: 'center',
					paddingBottom: '60px',
					display: 'flex',
					justifyContent: 'center'
				}}>
					<div style={{ width: '120px' }} />
					<div>
						<Pagination
							pageSize={pageSize}
							current={this.state.page}
							onChange={(page) => {
								this.onPage(page)
							}}
							total={this.state.count || pageSize} />
					</div>

				</div>
				<InvitationModal onClose={() => {
					this.setState({
						invitationModal: false
					})
				}} open={this.state.invitationModal} onInvitations={() => { }} />
			</div>
		)
	}
}
