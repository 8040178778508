import "../Dashboard.css";
import React, { useState, useEffect } from "react";
import {Progress} from "antd";


const Dashboard = () => {
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress = userFromStorage ? JSON.parse(userFromStorage).progress : 0  

    const openView = () => {
      const win = window.open(`/profile`, "_blank");
      win.focus();
    }
  
  return (
    <div className="Profile-progress">
       <h1 className="progress-bar-title">Set up your Pozi</h1>
       <div className="progress-bar-percantage">
            <div className="progress-percentage">{profileProgress}%</div>
            <p className="completed-text">completed of your profile</p>
       </div>
        <Progress
            className="progress-bar"
            percent={profileProgress}
            showInfo={false}
            strokeColor={"var(--primary-blue)"}
        />
        <a className="progress-complite-now" onClick={openView}>
          <div className="progress-edit-holder">
          <img className="progress-edit-img" src="/images/groups/edit.svg" alt=""/>
            <span className="progress-edit-text">Complete now</span>
          </div>
        </a>
     </div>
  );
};

export default Dashboard;
