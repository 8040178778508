import React from 'react';

const styles = {
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center' as const,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
};

const ErrorMessage = ({
  title, message, withRetry, onRetry,
}: Props) => {
  const handleRetry = () => {
    if (withRetry && onRetry) {
      onRetry();
    }
  };

  return (
    <>
      <div style={styles.errorText}>{title}</div>
      <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
        {message}
      </div>
      {withRetry && (
        <div
          style={styles.errorButtonText}
          onClick={handleRetry}
          aria-hidden='true'
        >
          retry
        </div>
      )}
    </>
  );
};

interface Props {
  title: string;
  message: string;
  withRetry?: boolean;
  onRetry?: () => void;
}

ErrorMessage.defaultProps = {
  withRetry: false,
  onRetry: null,
};

export default ErrorMessage;
