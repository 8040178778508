import React from 'react';
import FormikInput from '../../../../../common/form/FormikInput';

const styles = {
  root: { width: 28 },
  input: {
    padding: 0,
    textAlign: 'center' as const,
    fontSize: 16,
    fontWeight: 'bold' as const,
    color: '#282828',
  },
};

const ScoreInput = ({
  name, value, onChange, onBlur, disabled, min, max, unsaved, error,
}: Props) => {
  const handleChange = (e: any) => {
    const s = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (s.length < 3) {
      if (s.length) {
        const num = Number.parseInt(s, 10);
        if (!Number.isNaN(num) && num >= min && num <= max) {
          if(regex.test(e.target.value))
            onChange(e);
        }
      } else {
        onChange(e);
      }
    }
  };

  const getInputStyle = () => {
    if (error) {
      return { ...styles.input, borderColor: '#00F', borderWidth: 1.8 };
    }
    if (unsaved) {
      return { ...styles.input, borderColor: '#00F', borderWidth: 1.8 };
    }
    return styles.input;
  };

  return (
    <FormikInput
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      withError={false}
      style={styles.root}
      inputStyle={getInputStyle()}
      disabled={disabled}
    />
  );
};

interface Props {
  name: string,
  value: string,
  onChange: any,
  onBlur: any,
  disabled?: boolean;
  min?: number;
  max?: number;
  unsaved?: boolean;
  error?: boolean;
}

ScoreInput.defaultProps = {
  disabled: false,
  min: 1,
  max: 5,
  unsaved: false,
  error: false,
};

export default ScoreInput;
