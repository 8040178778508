import React from "react"
import { Helmet } from 'react-helmet';
import "../Project/ProjectPage.css"
import { get } from '../../libs/utils/request'
import { Idea } from "./Ideas"
import IdeaCard from './IdeaCard'
import { Loading } from "../common/Loading"
import { ProjectTeam } from "../Project/ProjectTeam";
import { Link } from "react-router-dom";

interface Props {
  match: any //TODO any
}

interface State {
  idea: Idea | null
  loading: boolean
}

class IdeaPage extends React.Component<Props, State> {

  state: State = {
    idea: {
      id: "",
      createdAt: "",
      name: "",
      details: "",
      image: "",
      shareCount: 0,
      user: {
        firstName: '',
        lastName: '',
        role: {},
        interestGroups: [],
        interests: [],
        skills: [],
        uniqueTag: '',
        location: null,
        showAge: null,
      },
      memberRequest: [],
      comments: 0,
      interests: [],
      shareable: false,
      commentable: false,
      rating: { average: 0, count: 0 },
      eventTags: [],
      userRating: 0,
      pitchLink: '',
      teamMembers:[],
      isMember:false,
      commentGroup:{}
    },
    loading: false
  }

  componentDidMount() {
    this.getIdea()
  }

  getIdea = async (uniqueTag?: string) => {
    try {
      this.setState({ loading: true })
      const idea = await get<Idea>(`/ideas/${uniqueTag || this.props.match.params.uniqueTag}`)
      this.setState({ idea, loading: false })
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      if(this.state.idea.name===""){
        window.location.replace('/dashboard')
      }
      //message.error(e.response?.data?.message || 'Network error')
      this.setState({ loading: false })
      console.error(error)
    }
  }

  render() {
    const { idea } = this.state
    return (
      <div className="Content-container">
        <Helmet>
          <title>{`${this.state.idea.name} | entrepreneur idea | pozi.io`}</title>
        </Helmet>
        {this.state.loading
          ? <Loading />
          : <div className="IdeaPage" style={{ maxWidth: 500, margin: "100px auto" }}>
            <IdeaCard
              idea={idea}
              callback={this.getIdea}
            />
          </div>}
      </div>
    )
  }
}

export default IdeaPage
