import React from 'react';

const styles = {
  root: {
    marginLeft: 10,
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 900,
    color: '#282828',
  },
};

const ScheduleItem = ({ title, date }: Props) => (
  <div style={styles.root}>
    <div style={styles.title}>{title}</div>
    <div style={{ ...styles.title, fontWeight: 300 }}>{date}</div>
  </div>
);

interface Props {
  title: string;
  date: string;
}

export default ScheduleItem;
