import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import Card from '../../common/card/Card';
import FormOnAfterInput from '../../common/form/FormOnAfterInput';

const styles = {
  root: {
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 10,
    paddingRight: 40,
    paddingLeft: 40,
    maxWidth: 594,
    marginLeft: 47,
    marginTop: -71,
    position: 'relative' as const,
  },
  content: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '29px',
    marginBottom: 13,
  },
  input: {
    fontWeight: 900,
    fontSize: 16,
    color: '#282828',
  },
};

const InputTitleCard = ({ form, fieldKey, onAfterTyping }: Props) => {
  const afterTypingFunc = async (value: any) => {
    await onAfterTyping(value);
  };

  return (
    <Card style={styles.root}>
      <div style={styles.content}>
        <div style={styles.title}>Choose a cool title for your Event!</div>
        <FormOnAfterInput
          form={form}
          fieldKey={fieldKey}
          placeholder='Title (required)'
          style={styles.input}
          required
          onAfterTyping={afterTypingFunc}
          rules={[
            { min: 3, message: 'Title must be at least 3 characters.' },
            { max: 50, message: 'Title cannot be longer than 50 characters.' },
          ]}
          initialValue=''
        />
      </div>
    </Card>
  );
};

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  onAfterTyping: any;
}

export default InputTitleCard;
