import React, { CSSProperties, useEffect, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { CaretRightOutlined } from '@ant-design/icons';
import ItemWithIcon from '../../ItemWithIcon';
import { IProjectEvaluation } from '../Jury';
import { ICriterion } from '../../../Competition';
import BorderCard from '../../../../../common/BorderCard';
import ScoreInput from './ScoreInput';

const baseText = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 'normal' as const,
  fontSize: 16,
  color: '#282828',
};
const safeText = {
  overflow: 'hidden',
  whiteSpace: 'nowrap' as const,
  textOverflow: 'ellipsis',
};
const styles = {
  root: {
    width: '100%',
  },
  header: {
    width: '100%',
    height: 48,
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0px 10px',
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerStart: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    gap: '0px 16px',
  },
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
  body: {
    width: '100%',
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'column' as const,
    // gap: '8px 8px',
  },
  detailContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '0 10px',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
  },
  detailTitle: {
    ...baseText,
    fontWeight: 800,
  },
  detailValue: baseText,
  btn: {
    fontWeight: 'bold' as const,
  },
  avgBodyContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    marginTop: 16,
  },
  avgBox: {
    ...baseText,
    width: 60,
    backgroundColor: '#6755CE',
    borderRadius: 5,
    color: '#FFF',
    fontWeight: 900,
    paddingTop: 4,
    paddingBottom: 4,
    textAlign: 'center' as const,
  },
  submittedText: {
    ...baseText,
    fontWeight: 'bold' as const,
  },
};

const calculateAvg = (scores: number[]): number => Math.round((scores
  .reduce((acc, k) => acc + k, 0)
  / Math.max(scores.length, 1)) * 100) / 100;

const JuryTableMobile = ({
  data, criteria, values, handleChange, handleBlur, savedValues, formDisabled, style,
}: Props) => {
  const [collapses, setCollapses] = useState([]);
  const [averages, setAverages] = useState<any>({});

  useEffect(() => {
    const l = Array(data.length);
    for (let i = 0; i < data.length; i += 1) {
      l[i] = false;
    }
    setCollapses(l);
  }, [data.length]);

  useEffect(() => {
    const avgs: any = {};
    for (let i = 0; i < data.length; i += 1) {
      const nums = [];
      for (let j = 0; j < criteria.length; j += 1) {
        const v = values[`${data[i].id}_${criteria[j].id}`];
        if (v && v !== '') {
          const k = Number.parseInt(v, 10);
          if (!Number.isNaN(k)) {
            nums.push(k);
          }
        }
      }
      avgs[data[i].id] = calculateAvg(nums);
    }
    setAverages(avgs);
  }, [criteria, data, values]);

  const handleClick = (i: number) => {
    setCollapses((state) => {
      const tmp = [...state];
      tmp[i] = !tmp[i];
      return tmp;
    });
  };

  const isChanged = (key: string, value: string): boolean => {
    if (typeof value !== 'string') {
      return false;
    }
    const sv = savedValues[key];
    return sv ? value !== sv : !!value.length;
  };

  return (
    <div style={{ ...styles.root, ...style }}>
      {data.map((item, i) => (
        <BorderCard
          key={item.id}
          style={styles.root}
          isTop={!i}
          isBottom={i === data.length - 1}
          onClick={() => handleClick(i)}
        >
          <div style={styles.header}>
            <div style={styles.headerStart}>
              <CaretRightOutlined
                rotate={collapses[i] ? 90 : 0}
                style={{ ...styles.arrowIcon, color: collapses[i] ? '#6755CE' : '#898989' }}
              />
              <div style={{ width: 200 }}>
                <ItemWithIcon
                  text={item.project.name}
                  icon={item.project.image}
                  link={`/${item.isStartup ? 'startups' : 'idea'}/${item.project.id}`}
                  textStyle={safeText}
                />
              </div>
            </div>
            <div className='jury-mobile-avg-head' style={styles.avgBox}>
              {item.isSubmitted ? item.average : averages[item.id] || '-'}
            </div>
          </div>
          <Collapse in={!!collapses[i]} timeout='auto' unmountOnExit>
            <div style={styles.body}>
              {!!item.project.pitchLink && (
                <div style={{ ...styles.detailContainer, marginBottom: 10 }}>
                  <div style={styles.detailTitle}>Pitch link:</div>
                  <div
                    style={{
                      ...styles.detailValue,
                      color: '#6755CE',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(item.project.pitchLink, '_blank');
                    }}
                    aria-hidden='true'
                  >
                    link
                  </div>
                </div>
              )}
              {criteria.map((criterion, j) => {
                const id = `${item.id}_${criterion.id}`;
                const score = item.scores?.find((s) => s.criteriaId === criterion.id);
                return (
                  <BorderCard
                    key={id}
                    style={styles.inputContainer}
                    isTop={!j}
                    isBottom={j === criteria.length - 1}
                  >
                    <div style={styles.detailTitle}>
                      {criterion.title}
                      :
                    </div>
                    {item.isSubmitted ? (
                      <div style={styles.submittedText}>
                        {score ? score.value : 'N/A'}
                      </div>
                    ) : (
                      <ScoreInput
                        name={id}
                        value={values[id]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        unsaved={isChanged(id, values[id])}
                        disabled={formDisabled}
                      />
                    )}
                  </BorderCard>
                );
              })}
              <div style={styles.avgBodyContainer}>
                <div className='jury-mobile-avg-body' style={styles.avgBox}>
                  {item.isSubmitted ? item.average : averages[item.id] || '-'}
                </div>
              </div>
            </div>
          </Collapse>
        </BorderCard>
      ))}
    </div>
  );
};

interface Props {
  data: IProjectEvaluation[];
  criteria: ICriterion[];
  values: any;
  handleChange: any;
  handleBlur: any;
  savedValues: any;
  formDisabled: boolean;
  style?: CSSProperties;
}

JuryTableMobile.defaultProps = {
  style: {},
};

export default JuryTableMobile;
