import {
  Button, Dropdown, Form, Icon, Menu, message, Popover, Rate, Tooltip,
} from 'antd';
import './ProjectPage.css';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import { get, post } from '../../libs/utils/request';
import { hasPermission, user } from '../../libs/utils/user';
import User from '../../user/User';
import { Loading } from '../common/Loading';
import { Interest } from '../Registration/Registration';
import GenerateSummaryModal from './GeneratePdf';
import ProjectHighlights from './ProjectHighlights';
import ProjectDetails from './ProjectDetails';
import { ProjectTeam } from './ProjectTeam';
import { MemberRequest } from './MemberRequest';
import { Comments } from '../comments/Comments';
import ApplicationModal from '../Events/components/ApplicationModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Team from '../Team/Team';
import Award from './Award';

const styles = {
  btn: {
    width: '151px',
    height: 32,
    marginRight: 24,
    fontWeight: 900,
  },
  btnWithdraw: {
    width: '170px',
    background: '#fff',
    color: '#7c6de4',
    marginRight: 24,
    height: 32,
    fontWeight: 900,
    padding: '0',
  },
  teamMemberPhoto: {
    width: '44px',
    height: '44px',
    marginRight: '9px',
    borderRadius: '50%',
  },
};

const Project = () => {
  const [project, setProject] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [commentVisible, setCommentVisible] = useState(true);
  const [joined, setJoined] = useState(false);
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  const { uniqueTag } = useParams();
  const history = useHistory();

  const getProject = async () => {
    try {
      setLoading(true);
      const projectData = await get<Project>(`/projects/${uniqueTag}`);
      setProject(projectData);
      setLoading(false);
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      if(project===null){
        history.push('/dashboard')
      }
    }
    

    // if(!!user()){
    //     if (projectData.teamMembers.length > 0) {
    //       const isMember = projectData.teamMembers.map((member: any) => member.user.id === user().id)
    //       isMember.map((member:any) => { member === true ? setTeamMate(true) : '' })
    //     }
    //   }
  };

  useEffect(() => {
    getProject();
  }, [uniqueTag]);

  const increaseShare = async () => {
    await post(`/projects/${project.id}/share`);
    getProject();
  };

  const toggleComments = () => {
    setCommentVisible(!commentVisible);
  };

  const onJoin = async () => {
    try {
      await post(`/projects/${project.id}/join`);
      message.success('Request sent.');
      getProject();
    } catch (err) {
      // console.log(err);
    
    }
  };
  const onWithdraw = async () => {
    try {
      await post(`/projects/${project.id}/withdraw`);
      message.success('Request sent.');
      getProject();
    } catch (err) {
      // console.log(err);
    }
  };
  const onLeave = async () => {
    try {
      await post(`/projects/${project.id}/withdraw`);
      message.success('Request sent.');
      getProject();
    } catch (err) {
      // console.log(err);
    }
  };

  const onRateChange = async (rating: number) => {
    try {
      if (project.ratingGroup) {
        await post('/rating/post', { rate: rating, id: project.ratingGroup.id });
      }
      getProject();
    } catch (err) {
      if(err.response.status === 403){
        message.warning(`${profileProgress}% of your profile is completed. To write a comment you need to get at least 70%`)
      }
      else{
       message.error('Rating failed');
      }
    }
  };

  const loggedIn = !!user();
  const shareLinkBase = `${window.location.protocol}//${window.location.host}`;
  const shareMenu = (
    <Menu>
      <Menu.Item onClick={increaseShare}>
        <FacebookShareButton url={`${shareLinkBase}/startups/${project ? project.uniqueTag : uniqueTag}`}>
          <div className='share-menu-text'>share on Facebook</div>
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item onClick={increaseShare}>
        <LinkedinShareButton url={`${shareLinkBase}/startups/${project ? project.uniqueTag : uniqueTag}`}>
          <div className='share-menu-text'>share on LinkedIn</div>
        </LinkedinShareButton>
      </Menu.Item>
      <Menu.Item onClick={increaseShare}>
        <TwitterShareButton url={`${shareLinkBase}/startups/${project ? project.uniqueTag : uniqueTag}`}>
          <div className='share-menu-text'>share on Twitter</div>
        </TwitterShareButton>
      </Menu.Item>
      <CopyToClipboard
        text={`${shareLinkBase}/startups/${project ? project.uniqueTag : uniqueTag}`}
        onCopy={() => {
          message.success('Link copied to clipboard');
        }}
      >
        <Menu.Item onClick={increaseShare}>
          <div className='share-menu-text'>copy link</div>
        </Menu.Item>
      </CopyToClipboard>
    </Menu>
  );

  return (
    <>
      {!project ? (
        <Loading />
      ) : (
        <div className='project-container'>
          <Helmet>
            {project && (
              <title>{`${project.name} | startup one-pager | pozi.io`}</title>
            )}
          </Helmet>
          {!errorMessage && (
            <div className='project-container-inner'>
              <Form>
                <div className='Project'>
                  <div
                    className='project-cover'
                    style={{
                      backgroundImage: `url(${project.image
                        ? `${project.image}?w=1100&mode=1`
                        : '/images/profile-background.png'
                        })`,
                    }}
                  >
                    <div className='project-cover-overlay' />
                    <div className='project-cover-title-container'>
                      <div>
                        <div className='project-cover-title'>{project.name}</div>
                        <div className='project-cover-subtitle'>{project.subtitle}</div>
                      </div>
                    </div>
                  </div>
                  <div className='logo-image-profile-container'>
                    <div className='logo-image-logo' style={{ backgroundImage: `url(${project.logo || '/images/onePagerLogo.png'})` }} />
                  </div>
                  <div className='project-body'>
                    <div className='project-body-cards'>
                      <div className='card highlights'>
                        <h3 className='card-title first-title'>Startup highlights</h3>
                        {/* <div className="details-mobile">
                          <div className="logo-image-profile-container-mobile">
                            <div className="logo-image-logo" style={{backgroundImage:`url(${ project.logo || "/images/onePagerLogo.png"})`}}></div>
                          </div>
                        </div> */}
                        <ProjectHighlights project={project} />
                      </div>
                      <div className='card details'>
                        {/* <div className="logo-image-profile-container">
                            <div className="logo-image-logo" style={{backgroundImage:`url(${ project.logo || "/images/onePagerLogo.png"})`}}></div>
                        </div> */}
                        <h3 className='card-title' style={{ marginBottom: 34 }}>Startup details</h3>
                        <div className='details-inner'>
                          <ProjectDetails project={project} />
                        </div>
                      </div>

                      <div className='card interests'>
                        {project.eventTags.length > 0 ? <></> : null}
                        <div className='interests-display'>
                          <h3
                            className='card-title'
                            style={{ paddingRight: 0 }}
                          >
                            Category
                          </h3>
                          <div className='interests-icons'>
                            {project.interests.map((interest: any) => (
                              <Tooltip title={interest.name} style={{ display: 'flex' }}>
                                <p>
                                  <img
                                    key={interest.id}
                                    className='startup-interest-icon'
                                    src={interest.icon}
                                    alt='interest icon'
                                  />
                                  <div
                                    style={{
                                      fontSize: '15px',
                                      display: 'inline-block',
                                      marginRight: 15,
                                    }}
                                  >
                                    {interest.name}
                                  </div>

                                </p>
                              </Tooltip>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className='card financial'>
                        <h3 className='card-title' style={{ marginBottom: 0 }}>Financial (USD)</h3>
                        <h4 className='card-subtitle'>
                          With, or without investment
                        </h4>
                        <div className='financial-table-container'>
                          <div className='financial-table' style={project.interests.length > 4 ? { marginTop: '11%' } : { marginTop: 0 }}>
                            <div className='header row'>
                              {/* <div style={{width:"25%"}}></div> */}
                              <span className='title'>Years</span>
                              {Object.keys(project.financial).map(
                                (year: any, index) => (
                                  <span key={`${year}.financialyear`}>
                                    <div className='financial-item-year'>
                                      {project.financial[year].financialyear}
                                    </div>
                                  </span>
                                ),
                              )}
                            </div>
                            <div className='row'>
                              <span className='title'>Revenues</span>
                              {Object.keys(project.financial).map(
                                (year: any) => (
                                  <span
                                    className='finance-item'
                                    key={`${year}.revenues`}
                                  >
                                    <div>
                                      {`$${project.financial[
                                        year
                                      ].revenues.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}`}
                                    </div>
                                  </span>
                                ),
                              )}
                            </div>
                            <div className='row'>
                              <span className='title'>Expenses</span>
                              {Object.keys(project.financial).map(
                                (year: any) => (
                                  <span
                                    className='finance-item'
                                    key={`${year}.expenses`}
                                  >
                                    <div>
                                      {`$${project.financial[
                                        year
                                      ].expenses.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}`}

                                    </div>
                                  </span>
                                ),
                              )}
                            </div>
                            <div className='row'>
                              <span className='title'>Net</span>
                              {Object.keys(project.financial).map(
                                (year: any) => (
                                  <span
                                    key={`${year}.net`}
                                    style={{ paddingTop: 16 }}
                                  >
                                    <div>
                                      {`$${(project.financial[year].revenues
                                      - project.financial[year].expenses).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") 
                                      }`}

                                    </div>
                                  </span>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='project-team-container'>
                        <div className='card team'>
                          <div className='member-container'>
                            <Team
                              teamMembers={project.teamMembers}
                              isDraft={false}
                              isEdit={false}
                              project={project}
                              loggedIn={loggedIn}
                              joined={joined}
                              onJoin={onJoin}
                              onLeave={onLeave}
                              onWithdraw={onWithdraw}
                            />

                          </div>
                        </div>
                      </div>

                      {project.memberRequests ? <MemberRequest project={project} onClick={getProject} /> : null}

                      <div style={project.whatWeNeed === '' ? { display: 'none' } : { display: 'block' }} className='card awards'>
                        <h3 className='card-title'>What we need</h3>
                        <div className='details-inner-whatweneed'>
                          <div>{project.whatWeNeed}</div>
                        </div>
                      </div>

                      {project.awards.length > 0 ? (
                       <div className="ProfilePage-card awards">
                          <h3 className='card-title'>Awards</h3>
                          <div className="card-awards">
                            {project.awards.map((award: any) => (
                             <Award award={award}/>
                            ))}
                          </div>
                        </div>
                      ) : ''}

                    </div>
                  </div>
                </div>

                {(loggedIn && project.user.id === user().id || project.isMember)
                  && (
                    <div className='project-buttons'>
                      <Button
                        type='primary'
                        size='large'
                        onClick={() => {
                          history.push({
                            pathname: `/edit-startup/${project.id}`,
                            state: project.id,
                          });
                        }}
                      >
                        Edit
                      </Button>

                    </div>
                  )}
              </Form>
              <div className='project-social'>
                <div className='project-social-info'>
                  <div className='Idea-social-rating'>
                    {Math.round(project.ratingGroup.averageRating * 10) / 10}
                    {' '}
                    <Icon type='star' theme='filled' />
                    {' '}
                    from
                    {' '}
                    {project.ratingGroup.ratingCount}
                    {' '}
                    rating
                  </div>
                  <div className='project-social-comments'>
                    {`${project.comments} comments`}
                  </div>
                  <div className='project-social-shares'>
                    {`${project.shareCount} shares`}
                  </div>
                </div>
                <div className='project-actions'>
                  {loggedIn ? (
                    <Popover
                      content={
                        (
                          <Rate
                            className='Idea-action-rating'
                            allowClear
                            onChange={onRateChange}
                            value={project.ratingGroup.userRating || 0}
                          />
                        )
                      }
                    >
                      <div className='Idea-action rate'>
                        <Icon type='star' theme='filled' />
                      </div>
                    </Popover>
                  ) : null}
                  {loggedIn ? (
                    <div
                      className={`Idea-action comment ${!project.commentable && 'disabled'
                        }`}
                      onClick={toggleComments}
                    >
                      <Icon type='message' theme='filled' />
                    </div>
                  ) : null}
                  <Dropdown
                    overlay={shareMenu}
                    trigger={['click']}
                    placement='topCenter'
                  >
                    <div className='Idea-action share'>
                      <Icon type='share-alt' />
                    </div>
                  </Dropdown>
                </div>
                {loggedIn && commentVisible && (
                  <Comments
                    postURL={`/comment/post/${project.commentGroup.id}`}
                    commentsCount={project.comments}
                    source={`/comment/get/${project.commentGroup.id}`}
                    commentable={project.commentable}
                    onNewComment={() => {
                      getProject();
                    }}
                    type='projects'
                  />
                )}
              </div>
            </div>
          )}

        </div>

      )}
    </>
  );
};
type ProjectHighlights = { [key: string]: string };

type ProjectDetails = { [key: string]: string };

type ProjectTeamMember = {
  id: string;
  title: string;
  description: string;
  user: User;
};

type FinancialYear = {
  revenues: number;
  expenses: number;
  net: number;
  financialyear: any;
};
type Award = {
  awardType: string;
  competitionName: string;
  description: string;
  competitionTag: string;
};
type Financial = { [key: string]: FinancialYear };

type Project = {
  id: string;
  awards: Award[];
  updatedAt: any;
  name: string;
  subtitle: string;
  highlights: ProjectHighlights;
  details: ProjectDetails;
  teamMembers: ProjectTeamMember[];
  financial: Financial;
  commentable: boolean;
  comments: number;
  description: string;
  shareCount: number;
  user: any; // TODO any
  eventTags: any[];
  interests: Interest[];
  image: string;
  logo: string;
  rating: { average: number; count: number };
  userRating: number;
  steps: any;
  pitchLink: string;
  uniqueTag: string;
  version: number;
  whatWeNeed: string;
  memberRequests: [];
  requestPending: boolean;
  additionalInfo: string;
  isMember: boolean;
  location: any;
  country: string;
  commentGroup: any;
  canJoin: boolean;
  canLeave: boolean;
  canCancel: boolean;
};

export default Project;
