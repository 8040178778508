import React from 'react';

const styles = {
  root: {
    borderRadius: '50%',
    backgroundColor: '#282828',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 900,
    color: '#FFFFFF',
    fontStyle: 'normal',
  },
};

const ScheduleDot = ({ index, size, fontSize }: Props) => (
  <div style={{ ...styles.root, width: size, height: size }}>
    <div style={{ ...styles.text, fontSize }}>
      {index}
    </div>
  </div>
);

interface Props {
  index: number;
  size?: number;
  fontSize?: number;
}

ScheduleDot.defaultProps = {
  size: 36,
  fontSize: 14,
};

export default ScheduleDot;
