import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import "./DisplayTeamMember.css";

interface Props {
  member: any;
  i: number;
  createdAt?: any;
}

const DisplayTeamMember = ({ member, i, createdAt }: Props) => {
  const sessionUser = sessionStorage.getItem('user');
  const sessionUserId = sessionUser ? JSON.parse(sessionUser).id : null;
  if(member.user == null){
    member.user = member;
  }
  return (
    <div className="displayed-member-holder">
      <div className="display-team-header">
        <div className="profile-img" style={{ backgroundImage: `url(${member.user.profileImage || "/images/user.svg"} )` }}>
        </div>
        <div className="member-text">
          <div className="member-text-name">
            {member.user && (
              <Link to={sessionUserId !== member.user.id ? `/entrepreneurs/${member.user.uniqueTag}` : `/profile`}>
                {member.user.firstName + ' ' + member.user.lastName}
              </Link>
            )}
          </div>
          <div className="member-text-title">{member.title} {!!createdAt && ` - ${moment(createdAt).fromNow()}`}</div>
        </div>
      </div>
      <div className="member-text-desc">{member.description}</div>
    </div>
  );
};
export default DisplayTeamMember;
