import React from "react";
import { get, post } from "../../libs/utils/request";
import { Button, message } from "antd";
import moment from "moment";
import User from "../../user/User";

interface Props {
  match?: any; // TODO: any
  history?: any;
}

class EndedTrialPeriod extends React.Component<Props> {
  async check() {
    try {
      const user = await get<any>("/me");
      this.setState({ user: user });
      if (user.active) {
        await sessionStorage.setItem("user", JSON.stringify(user));
        this.props.history.push("/profile");
      }
    } catch (e) {}
  }

  async resendEmail() {
    try {
      const response = await post<any>("/register/resendEmail");
      message.success(response.message);
    } catch (e) {
      message.error(e.response?.data?.message || "Network error");
    }
  }

  timer: any = null;

  startTimer() {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.check();
    }, 5000);
  }

  onBlur = () => {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
  };

  onFocus = () => {
    this.check();
    this.startTimer();
  };

  componentWillUnmount() {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("focus", this.onFocus);
  }

  async componentDidMount() {
    this.startTimer();
    window.addEventListener("blur", this.onBlur);
    window.addEventListener("focus", this.onFocus);
  }

  render() {
    return (
      <div className="Content-container">
        <div
          className="NotActivatedPage"
          style={{ paddingTop: 150, textAlign: "left" }}
        >
          <h1 style={{ fontSize: 30, fontWeight: 900 }}>TRIAL PERIOD ENDED</h1>
          <p style={{ fontSize: 18, fontWeight: 300, marginTop: 32 }}>
            You ran out of the trial period and your account is not activated.
          </p><p style={{ fontSize: 18, fontWeight: 300}}>
            Without activation, you are not able to access the features of POZI.io. 
          </p><p style={{ fontSize: 18, fontWeight: 300}}>
              We still can activate your profile. Follow the guidelines:
          </p>
          <p style={{ fontSize: 18, fontWeight: 300, marginTop: 32  }}>
            Click on the "Please activate my profile" button.
            </p><p style={{ fontSize: 18, fontWeight: 300}}>
              After that check your Inbox (Spam folders).
            </p><p style={{ fontSize: 18, fontWeight: 300}}>
            You will receive a system
            notification in email with the activation link.
            <br />
            <br />
            <p className="trial-ended-button-container">
              <Button
              style={{width:"fit-content"}}
                onClick={() => {
                  this.resendEmail();
                }}
                type="primary"
              >
                Please activate my profile
              </Button>
            </p>
          </p>
          <p style={{ fontSize: 18, fontWeight: 300 }}>
            If you're facing issues with the process, write to us at
            hello@pozi.io.
            </p><p style={{ fontSize: 18, fontWeight: 300}}>
              And our support team will get in touch with you in 48 hours.
          </p>
        </div>
      </div>
    );
  }
}

export default EndedTrialPeriod;
