import React from 'react';
import { get } from '../../libs/utils/request';
import { CommentBlock } from './CommentBlock';
import { Loading } from '../common/Loading';
import { CommentHead2 } from './CommentHead2';
import './Comments.css';
import User from '../../user/User';

export type CommentData = {
  patchURL: string
  id: string
  message: string
  user: User
  replyTo: null | string/* comment id */
  edited: boolean
  createdAt: string
  updatedAt: string
}

interface Props {
  commentsCount: number
  source: string
  postURL: string
  onNewComment ?: () => void
  style ?: any
  v2 ?: boolean
  commentable:boolean;
  type?:string;
}

interface State {
  comments: {
    comment: CommentData
    thread: CommentData[]
  }[]
  loading: boolean
}

export interface ICommentGroup {
  id: string;
  commentsCount: number;
  commentable: boolean;
}

export class Comments extends React.Component<Props, State> {
  state: State = {
    comments: [],
    loading: true,
  }

  constructor(props: Props) {
    super(props);
    this.init();
  }

  formatComments(commentsData: CommentData[]): {
    comment: CommentData
    thread: CommentData[]
  }[] {
    let comments: {
      comment: CommentData
      thread: CommentData[]
    }[] = [];

    for (const comment of commentsData) {
      if (!comment.replyTo) {
        comments.push({
          comment,
          thread: [],
        });
      }
    }

    let otherComments: CommentData[] = [];

    for (const comment of commentsData) {
      if (comment.replyTo) {
        const main = comments.find((i) => i.comment.id === comment.replyTo);
        if (main) {
          main.thread.push(comment);
        } else {
          otherComments.push(comment);
        }
      }
    }

    otherComments = otherComments.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());

    let stop = false;
    while (otherComments.length > 0 && !stop) {
      stop = true;
      for (let k = 0; k < otherComments.length; k++) {
        const block = comments.find((i) => i.thread.some((j) => j.id === otherComments[k].replyTo));
        if (block) {
          block.thread.push(otherComments[k]);
          otherComments.splice(k, 1);
          k -= 1;
          stop = false;
        }
      }
    }

    comments = comments.sort((a, b) => new Date(a.comment.createdAt).getTime() - new Date(b.comment.createdAt).getTime());
    for (const comment of comments) {
      comment.thread = comment.thread.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    }

    return comments;
  }

  async init() {
    this.setState({ loading: true });
    try {
      const response = await get<CommentData[]>(this.props.source);

      this.setState({
        comments: this.formatComments(response),
        loading: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.commentsCount !== prevProps.commentsCount) {
      this.init();
    }
  }

  render() {
    return (
      <div
        style={{
          marginTop: '3em',
          ...this.props.style,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-hidden='true'
      >
        <h3 style={{
          fontSize: '21px',
          fontWeight: 900,
          marginBottom: '1.5em',
        }}
        >
          Comments
        </h3>
        {this.props.commentable&&<CommentHead2
          postURL={this.props.postURL}
          onNewComment={async () => {
            try {
              this.props.onNewComment();
              this.setState({
                comments: this.formatComments(await get<CommentData[]>(this.props.source)),
              });
            } catch (e) {
              // not handled error because it will be not displayed
            }
          }}
          type={this.props.type}
        />}
        {this.state.loading
          ? <Loading />
          : this.state.comments.map((comment) => (
            <CommentBlock
              key={comment.comment.id}
              comment={comment}
              postURL={this.props.postURL}
              onNewComment={() => {
                this.init();
              }}
              onDeleteComment={()=>
              this.init()
            }
              patchURL={(id) => (`/comment/change/${id}`
                )}
              deleteURL={(id)=>(`comment/delete/${id}` 
              )}
              type={this.props.type}
              source={this.props.source}
            />
          ))}
      </div>
    );
  }
}
