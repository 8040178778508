import React, { useEffect, useState } from 'react';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { LoginSocialGoogle, IResolveParams } from 'reactjs-social-login';
import styles from "./SocialButtons.module.css";

interface Props {
    onSuccesGetData: (data:any, provider:string) => void;
}

const Google = ({onSuccesGetData}:Props) => {

    const google_client_id2 = "672994015301-1dd5h5r2cjgvfp6grt3scmo604unp0mt.apps.googleusercontent.com";
    const [profile, setProfile] = useState<any>()

    useEffect(() => {
        if (profile) {
            onSuccesGetData(profile, "google")
        }
    }, [profile])
    return (
        <div>
            <LoginSocialGoogle
                client_id={google_client_id2}
                redirect_uri={window.location.origin}
                onResolve={({ provider, data }: IResolveParams) => {
                    setProfile(data)
                }}
                onReject={(err) => {
                    console.log(err)
                }}
            >
                <GoogleLoginButton text="Sign up with Google" className={styles.social_btn}/>
            </LoginSocialGoogle>
        </div>
    )
}

export default Google
