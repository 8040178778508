import React from 'react';

const detailsHelper: any = {
  summary: {
    title: 'Summary (Elevator pitch)',
    required: true,
    placeholder: 'A short, focused description of your project',
  },
  businessModel: {
    title: 'Business model',
    placeholder: 'Describe briefly, how you are planning on generating revenue',
  },
  customerProblem: {
    title: 'Customer problem',
    placeholder:
        'Say clearly and concisely what problem you have chosen to solve',
  },
  businessLogic: {
    title: 'Business logic',
    placeholder: 'Describe the major project milestones',
  },
  solution: {
    title: 'Solution',
    placeholder:
        'State how you intend to solve the problem and why people will switch from their current solution',
  },
  competitors: {
    title: 'Competitors',
    placeholder: 'Mention substitutes and / or existing solutions',
  },
  product: {
    title: 'Product/Service',
    placeholder: 'Introduce your product or service with your core offering',
  },
  advanteges: {
    title: 'Competitive advantages',
    placeholder: 'Tell us what do you do better than the others',
  },
  customer: {
    title: 'Customer',
    placeholder: 'Identify the customer served',
  },
  salesStrategy: {
    title: 'Sales strategy',
    placeholder: 'Tell us your plans for selling your product',
  },
  market: {
    title: 'Market',
    placeholder:
        'Give an estimate of the total addressable market (e.g. number of users)',
  },
  marketingStrategy: {
    title: 'Marketing strategy',
    placeholder:
        'What creative channels and tools do you plan to use? Be specific. Paid facebook and google ads are not interesting for us (except if you have $1M + free coupon)',
  },
};

interface Props{
    project:any;
}

const ProjectDetails = (props:Props) => {
  const {
    project,
  } = props;

  const display = (value:any, type:string) => <div>{value}</div>;

  return (
    <>
      {Object.keys(detailsHelper).map((key) => (
        <>
          {project.details[key] && (
            <div className='detail responsive-detail'>
              <div>
                <div className='detail-title'>
                  {project.details[key] ? detailsHelper[key].title : null}
                </div>
                <div className='detail-desc'>
                  {display(project.details[key], detailsHelper[key].type)}
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default ProjectDetails;
