import {Input} from 'antd'
import React from 'react'
import {SearchOutlined} from '@ant-design/icons'

const suffix = (
  <div style={{
    background: 'var(--secondary-blue)',
    borderRadius: '100%',
    height: '50px',
    width: '50px',
    textAlign: 'center',
    paddingTop: '15px',
    marginLeft: '2px',
    position: 'absolute'
  }}>
    <SearchOutlined
      style={{
        fontSize: 18,
        color: '#fff',
      }}
    />
  </div>
)

interface Props {
  onSearch: (value: string) => void
  style?: any
}

export class SearchBar extends React.Component<Props, any> {
  render() {
    return <Input
      style={{
        width: '60%',
        marginLeft: '20%',
        marginTop: '20px',
        marginBottom: '20px',
        ...this.props.style
      }}
      placeholder={'Type a keyword you like...'}
      // @ts-ignore
      onPressEnter={e => this.props.onSearch(e.target?.value)}
      suffix={suffix}
      addonAfter={null}
    />
  }
}