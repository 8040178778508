import React, { ReactElement, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, message, Pagination } from 'antd';
import { Prompt } from 'react-router';
import MethodologyCard from '../MethodologyCard';
import { ICriterion } from '../../Competition';
import JuryTable from './components/JuryTable';
import { get, post } from '../../../../../libs/utils/request';
import { Loading } from '../../../../common/Loading';
import JuryTableMobile from './components/JuryTableMobile';
import './Jury.css';
import AutoSearchInput from '../../../../common/AutoSearchInput';
import SortSelector, { ISorter } from '../SortSelector';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';

const styles = {
  root: {
    maxWidth: 1200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 67,
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    marginTop: 60,
  },
  selectorContainer: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    gap: '0 10px',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center' as const,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
  noResultText: {
    fontSize: 20,
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    marginTop: 72,
    color: '#282828',
  },
  saveBtnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    marginTop: 40,
  },
  submitBtnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    marginTop: 64,
  },
  btn: { fontWeight: 'bold' as const, minWidth: 150 },
};

const SORTERS: ISorter[] = [
  { value: 'name_A', text: 'Name (A to Z)' },
  { value: 'name_D', text: 'Name (Z to A)' },
  { value: 'avg_D', text: 'Average (Highest)' },
  { value: 'avg_A', text: 'Average (Lowest)' },
];

const getSortParams = (value: string) => {
  const r = value.split('_');
  if (r.length === 2) {
    return { sort: r[0], order: r[1] === 'A' ? 'ASC' : 'DESC' };
  }
  return {};
};

const convertResponse = (response: IProjectEvaluationResponse): IProjectEvaluation => {
  const isStartup = !!response.startup;
  const res: any = {
    id: response.id,
    project: isStartup ? response.startup : response.idea,
    isStartup,
  };
  if (response.evaluation) {
    res.isSubmitted = response.evaluation.isSubmitted;
    res.average = response.evaluation.average;
    res.scores = response.evaluation.scores.map((item) => ({
      criteriaId: item.evaluationCriteria.id,
      value: item.value,
    }));
  } else {
    res.isSubmitted = false;
    res.scores = [];
  }
  return res;
};

const Jury = ({ tag, criteria, evaluationStatus }: Props) => {
  const [data, setData] = useState<IProjectEvaluation[]>([]);
  const [formInit, setFormInit] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(50);
  const [search, setSearch] = useState('');

  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [formChanged, setFormChanged] = useState(false);
  const [sortBy, setSortBy] = useState('nameAsc');
  const [incompDialogVisible, setIncompDialogVisible] = useState(false);
  const [incompBody, setIncompBody] = useState<ReactElement>(<></>);
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const [valuesToSubmit, setValuesToSubmit] = useState(null);

  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

  const isClosed = evaluationStatus === 'CLOSED';

  useEffect(() => {
    const initFormData: any = {};
    data
      .filter((item) => !item.isSubmitted)
      .forEach((item) => {
        const { id } = item;
        if (Array.isArray(item.scores)) {
          item.scores.forEach((score) => {
            initFormData[`${id}_${score.criteriaId}`] = `${score.value}`;
          });
        }
      });
    setFormInit(initFormData);
  }, [data]);

  useEffect(() => {
    (async () => {
      setErrorMessage('');
      setLoading(true);
      try {
        const params: any = { currentPage, limit, ...(getSortParams(sortBy)) };
        if (search) {
          params.search = search;
        }
        const tmp = await get<{ data: IProjectEvaluationResponse[], pagination: any }>(`competitions/${tag}/evaluation/my-evaluation`, params);
        // console.log(JSON.stringify(tmp.data, null, 2));
        if (tmp.data) {
          setData(tmp.data.map((item) => convertResponse(item)));
        } else {
          setData([]);
        }
        const { pagination } = tmp;
        setTotalCount(pagination.totalCount);
        if (currentPage !== pagination.currentPage) {
          setCurrentPage(pagination.currentPage);
        }
        if (limit !== pagination.limit) {
          setLimit(pagination.limit);
        }
        setFormChanged(false);
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, limit, search, sortBy, tag]);

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    window.onbeforeunload = formChanged ? () => true : undefined;
    return () => {
      // eslint-disable-next-line no-undefined
      window.onbeforeunload = undefined;
    };
  }, [formChanged]);

  const reloadData = async (withLoading = true) => {
    setErrorMessage('');
    if (withLoading) {
      setLoading(true);
    }
    try {
      const params: any = { currentPage, limit, ...(getSortParams(sortBy)) };
      if (search) {
        params.search = search;
      }
      const tmp = await get<{ data: IProjectEvaluationResponse[], pagination: any }>(`competitions/${tag}/evaluation/my-evaluation`, params);
      // console.log(JSON.stringify(tmp.data, null, 2));
      if (tmp.data) {
        setData(tmp.data.map((item) => convertResponse(item)));
      } else {
        setData([]);
      }
      const { pagination } = tmp;
      setTotalCount(pagination.totalCount);
      if (currentPage !== pagination.currentPage) {
        setCurrentPage(pagination.currentPage);
      }
      if (limit !== pagination.limit) {
        setLimit(pagination.limit);
      }
      setFormChanged(false);
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const saveScores = async (values: any, isSubmit: boolean) => {
    const isChanged = (key: string, value: any): boolean => {
      const sv = formInit[key];
      return sv ? value !== sv : !!value.length;
    };
    const result: any[] = [];
    Object.entries(values)
      .filter(([key]) => key !== 'submitType')
      .forEach(([key, value]) => {
        if (isChanged(key, value)) {
          const [applicationId, criteriaId] = key.split('_');
          const app = result.find((item) => item.applicationId === applicationId);
          const score: any = { criteriaId };
          if (value) {
            score.value = value;
          }
          if (app) {
            app.scores.push(score);
          } else {
            result.push({ applicationId, scores: [score] });
          }
        }
      });
    try {
      if (isSubmit) {
        await post(`competitions/${tag}/evaluation/submit`, result);
        message.success('Evaluation submitted.');
      } else {
        await post(`competitions/${tag}/evaluation/save`, result);
        message.success('Scores saved.');
      }
      reloadData(false);
    } catch (error) {
      if (isSubmit) {
        if (error.response?.data?.message) {
          if (error.response.data.message === 'There is nothing to submit') {
            message.warning('There is nothing to submit.');
          } else {
            const list = error.response.data.applications;
            if (list && Array.isArray(list) && list.length) {
              setIncompBody(
                <>
                  <div>
                    {'Failed to submit, because you have incomplete '
                    + 'evaluations. Please, finish them first:'}
                  </div>
                  <ul style={{ marginTop: 16 }}>
                    {list.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </>,
              );
              setIncompDialogVisible(true);
            } else {
              message.error('Failed to submit evaluations.');
            }
          }
        } else {
          message.error('Failed to submit evaluations.');
        }
      } else {
        message.error('Failed to save scores.');
      }
    }
  };

  const submitForm = async (values: any) => {
    if (values.submitType === 'save') {
      await saveScores(values, false);
    } else {
      setValuesToSubmit(values);
      setSubmitDialogVisible(true);
    }
  };

  const handleFormChange = (values: any, savedValues: any) => {
    let isChanged = false;
    const v = Object.entries(values).filter(([key]) => key !== 'submitType');
    for (let i = 0; i < v.length && !isChanged; i += 1) {
      const [key, value] = v[i];
      const saved = savedValues[key];
      if (saved) {
        if (saved !== value) {
          isChanged = true;
        }
      } else if (value) {
        isChanged = true;
      }
    }
    if (formChanged !== isChanged) {
      setFormChanged(isChanged);
    }
  };

  const validateForm = (values: any) => {
    handleFormChange(values, formInit);
    return {};
  };

  const renderPaginationButtons = (marginTop = 0) => (
    <div style={{ ...styles.paginationContainer, marginTop }}>
      <Pagination
        current={currentPage}
        defaultPageSize={40}
        pageSize={limit}
        onChange={setCurrentPage}
        total={totalCount}
        style={{ marginTop }}
        disabled={formChanged}
      />
    </div>
  );

  const renderWithPager = (content: ReactElement) => (
    <>
      {renderPaginationButtons(16)}
      {isLoading ? <Loading /> : content}
      {renderPaginationButtons(32)}
    </>
  );

  const renderPagination = (values: any,
    handleChange: any,
    handleBlur: any,
    saveBtn: ReactElement) => {
    if (errorMessage) {
      return (
        <>
          <div style={styles.errorText}>Failed to load jury page:</div>
          <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
            {errorMessage}
          </div>
          <div
            style={styles.errorButtonText}
            onClick={() => reloadData(true)}
            aria-hidden='true'
          >
            retry
          </div>
        </>
      );
    }
    if (!data.length) {
      return <div style={styles.noResultText}>Nothing to show.</div>;
    }
    return renderWithPager(
      <>
        <div className='jury-table-desktop'>
          <JuryTable
            data={data}
            criteria={criteria}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            savedValues={formInit}
            formDisabled={isClosed}
          />
        </div>
        <div className='jury-table-mobile'>
          <JuryTableMobile
            data={data}
            criteria={criteria}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            savedValues={formInit}
            formDisabled={isClosed}
            style={{ marginTop: 40 }}
          />
        </div>
        <div style={styles.saveBtnContainer}>
          {saveBtn}
        </div>
      </>,
    );
  };

  return (
    <div style={styles.root}>
      <Prompt
        when={formChanged}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <MethodologyCard criteria={criteria} />
      <div style={styles.titleContainer}>
        <div style={{ minWidth: 280 }}>
          <AutoSearchInput
            onSearch={(value) => {
              setSearch(value);
              setCurrentPage(1);
            }}
            style={{ width: '100%' }}
            placeholder='Type something...'
            disabled={formChanged}
          />
          <div style={styles.selectorContainer}>
            <SortSelector
              value={sortBy}
              onChange={setSortBy}
              options={SORTERS}
              disabled={formChanged}
            />
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={formInit}
        validate={validateForm}
        onSubmit={(values)=>{ profileProgress < 10 ?  message.warning(`${profileProgress}% of your profile is completed. To evoluate you need to get at least 10%`) : submitForm(values)}}
      >
        {({
          values,
          // errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            {renderPagination(values, handleChange, handleBlur, (
              <Button
                style={styles.btn}
                type='primary'
                onClick={() => {
                  setFieldValue('submitType', 'save');
                  handleSubmit();
                }}
                disabled={!formChanged}
              >
                Save
              </Button>
            ))}
            {!errorMessage && (
              <div style={styles.submitBtnContainer}>
                <Button
                  style={styles.btn}
                  type='primary'
                  size='large'
                  onClick={() => {

                    setFieldValue('submitType', 'submit');
                    handleSubmit();
                  }}
                  disabled={evaluationStatus !== 'OPEN'}
                >
                  Submit your evaluation form!
                </Button>
              </div>
            )}
          </>
        )}
      </Formik>
      <ConfirmationDialog
        visible={incompDialogVisible}
        onOk={() => {
          setIncompDialogVisible(false);
        }}
        onCancel={() => setIncompDialogVisible(false)}
        title='Incomplete evaluations'
        description={incompBody}
        confirmBtnText='Ok'
        confirmBtnType='primary'
      />
      <ConfirmationDialog
        visible={submitDialogVisible}
        onOk={() => {
          setSubmitDialogVisible(false);
          saveScores(valuesToSubmit, true);
        }}
        onCancel={() => setSubmitDialogVisible(false)}
        title='Submit evaluations'
        description='Are you sure you want to submit your evaluations. Submitted evaluations can not be modified later.'
        confirmBtnText='Submit'
        confirmBtnType='primary'
      />
    </div>
  );
};

interface IProjectResponse {
  id: string,
  name: string,
  image: string,
  pitchLink: string,
  uniqueTag:string,
}

interface IEvaluationCriteriaResponse {
  id: string,
}

interface IScoreResponse {
  id: string;
  value: number;
  evaluationCriteria: IEvaluationCriteriaResponse;
}

interface IEvaluationResponse {
  id: string,
  average: string,
  isSubmitted: boolean,
  scores: IScoreResponse[];
}

interface IProjectEvaluationResponse {
  id: string,
  startup?: IProjectResponse,
  idea?: IProjectResponse,
  evaluation: IEvaluationResponse,
}

interface IScore {
  criteriaId: string;
  value: number;
}

export interface IProjectEvaluation {
  id: string;
  project: IProjectResponse;
  isStartup: boolean;
  isSubmitted: boolean,
  average: string,
  scores: IScore[];
}

interface Props {
  tag: string;
  criteria: ICriterion[];
  evaluationStatus: 'INIT' | 'OPEN' | 'CLOSED';
}

export default Jury;
