import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from '@material-ui/core';
import Masonry from 'react-masonry-css';
import { useHistory } from 'react-router';
import DraftCard from '../../Competitions/DraftCard';
import BorderCard from '../../common/BorderCard';

const baseText = {
  fontSize: 16,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#282828',
};
const styles = {
  header: {
    padding: 10,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    alignItems: 'center',
    gap: '10px 20px',
  },
  headerTitle: {
    ...baseText,
    fontSize: 18,
    fontWeight: 900,
  },
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
  root: {
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 80,
    marginBottom: 80,
  },
};

const DraftsCard = ({ drafts, collapsed, setCollapsed, title }: Props) => {
  const history = useHistory();
  return (
    <BorderCard
      isTop
      isBottom
      style={styles.root}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div style={styles.header}>
        <CaretRightOutlined
          rotate={collapsed ? 90 : 0}
          style={{ ...styles.arrowIcon, color: collapsed ? '#6755CE' : '#898989' }}
        />
        <div style={styles.headerTitle}>{title ? title : "Your drafts"}</div>
      </div>
      <Collapse in={collapsed} timeout='auto' unmountOnExit>
        <Masonry
          breakpointCols={{ default: 2, 850: 1 }}
          className='startup-box-view'
          columnClassName='startup-box-view-item'
        >
          {drafts.map((draft) => (
            <DraftCard
              key={draft.id}
              draft={draft}
              onClick={() => history.push(`/edit-event-draft/${draft.id}`)}
              style={{ marginBottom: 40 }}
            />
          ))}
        </Masonry>
      </Collapse>
    </BorderCard>
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  drafts: any[];
  collapsed: boolean;
  setCollapsed: (v: boolean) => void;
  title?: string
}

export default DraftsCard;
