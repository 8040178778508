import React, { CSSProperties, useEffect, useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const styles = {
  root: { width: '100%' },
};
const suffix = (
	<div style={{
		background: 'var(--secondary-blue)',
		borderRadius: '100%',
		height: '50px',
		width: '50px',
		textAlign: 'center',
		paddingTop: '15px',
		marginLeft: '2px',
		position: 'absolute'
	}}>
		<SearchOutlined
			style={{
				fontSize: 18,
				color: '#fff',
			}}
		/>
	</div>
);
const AutoSearchInput = ({
  placeholder, style, size, onSearch, delay, minLength, disabled,
}: Props) => {
  const [value, setValue] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(async () => {
      if (value.length === 0 || value.length >= minLength) {
        await onSearch(value);
      }
    }, delay));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      value={value}
      style={{ ...styles.root, ...style }}
      placeholder={placeholder}
      onChange={(e) => setValue(e.target.value)}
      allowClear
      size={size}
      disabled={disabled}
      suffix={suffix}
    />
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  placeholder?: string;
  style?: CSSProperties;
  onSearch: (value: string) => void;
  delay?: number;
  minLength?: number;
  size?: 'small' | 'default' | 'large';
  disabled?: boolean,
}

AutoSearchInput.defaultProps = {
  placeholder: '',
  style: {},
  delay: 200,
  minLength: 3,
  size: 'large',
  disabled: false,
};

export default AutoSearchInput;
