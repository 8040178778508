import React from 'react'
import {Link} from 'react-router-dom'
import './GroupCard.css'

export class GroupCard extends React.Component<any, any> {
  render() {
    return <Link to={`/group/${this.props.uniqueTag}`}>
      <div className={'GroupCard'} style={{backgroundImage: 'url(' + this.props.image + ')'}}>
        <div style={{display: 'block', position: 'relative', width: '100%'}}>
          <div className={'GroupCard-membersCount'}>
            {this.props.membersCount}
          </div>
          <div className={'GroupCard-bottom'}>
            {this.props.name}
          </div>
        </div>
      </div>
    </Link>
  }
}
