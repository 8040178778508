import React, { ReactElement, useEffect, useState } from 'react';
import { Button, message, Pagination } from 'antd';
import MethodologyCard from '../MethodologyCard';
import InputCriteriaModal from './components/InputCriteriaModal';
import { get, post } from '../../../../../libs/utils/request';
import { ICriterion } from '../../Competition';
import JuryMembersCard from './components/JuryMembersCard';
import { Loading } from '../../../../common/Loading';
import ErrorMessage from '../../../../common/ErrorMessage';
import EvaluationTable from './components/EvaluationTable';
import EvaluationTableMobile from './components/EvaluationTableMobile';
import './Evaluation.css';
import AutoSearchInput from '../../../../common/AutoSearchInput';
import SortSelector, { ISorter } from '../SortSelector';
import NoResult from '../../../../common/NoResult';

const styles = {
  root: {
    maxWidth: 1200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 67,
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    marginTop: 60,
  },
  selectorContainer: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    gap: '0 10px',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    marginTop: 64,
  },
  btn: { fontWeight: 'bold' as const, minWidth: 150 },
};

const SORTERS: ISorter[] = [
  { value: 'name_A', text: 'Name (A to Z)' },
  { value: 'name_D', text: 'Name (Z to A)' },
  { value: 'score_D', text: 'Score (Highest)' },
  { value: 'score_A', text: 'Score (Lowest)' },
];

const getSortParams = (value: string) => {
  const r = value.split('_');
  if (r.length === 2) {
    return { sort: r[0], order: r[1] === 'A' ? 'ASC' : 'DESC' };
  }
  return {};
};

const convertResponse = (response: IProjectEvaluationsResponse): IProjectEvaluations => {
  const isStartup = !!response.startup;
  const res: any = {
    id: response.id,
    project: isStartup ? response.startup : response.idea,
    isStartup,
    total: response.score,
  };
  if (response.evaluations) {
    res.evaluations = response.evaluations.map((item) => ({
      ...item,
      scores: item.scores.map((score) => ({
        criteriaId: score.evaluationCriteria.id,
        value: score.value,
      })),
    }));
  } else {
    res.evaluations = [];
  }
  return res;
};

const Evaluation = ({
  tag, criteria, evaluationStatus, onChange,
}: Props) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [membersError, setMembersError] = useState('');
  const [membersLoading, setMembersLoading] = useState(true);
  const [juries, setJuries] = useState<IJury[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(50);
  const [search, setSearch] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState<IProjectEvaluations[]>([]);
  const [sortBy, setSortBy] = useState('name_A');

  useEffect(() => {
    (async () => {
      setMembersError('');
      setMembersLoading(true);
      try {
        const tmpJuries = await get<{ data: IJury[] }>(`/competitions/${tag}/evaluation/juries`);
        if (tmpJuries?.data) {
          setJuries(tmpJuries.data);
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setMembersError(error.response.data.message);
        } else {
          setMembersError(error.message);
        }
      } finally {
        setMembersLoading(false);
      }
    })();
  }, [tag]);

  useEffect(() => {
    (async () => {
      setErrorMessage('');
      setLoading(true);
      try {
        const params: any = { currentPage, limit, ...(getSortParams(sortBy)) };
        if (search) {
          params.search = search;
        }
        const tmp = await get<{ data: IProjectEvaluationsResponse[], pagination: any }>(`competitions/${tag}/evaluation`, params);
        // console.log(JSON.stringify(tmp.data, null, 2));
        if (tmp.data) {
          setData(tmp.data.map((item: any) => convertResponse(item)));
        } else {
          setData([]);
        }
        const { pagination } = tmp;
        setTotalCount(pagination.totalCount);
        if (currentPage !== pagination.currentPage) {
          setCurrentPage(pagination.currentPage);
        }
        if (limit !== pagination.limit) {
          setLimit(pagination.limit);
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, limit, search, sortBy, tag]);

  const getEvaluations = async (withLoading = true) => {
    setErrorMessage('');
    if (withLoading) {
      setLoading(true);
    }
    try {
      const params: any = { currentPage, limit, ...(getSortParams(sortBy)) };
      if (search) {
        params.search = search;
      }
      const tmp = await get<{ data: IProjectEvaluationsResponse[], pagination: any }>(`competitions/${tag}/evaluation`, params);
      // console.log(JSON.stringify(tmp.data, null, 2));
      if (tmp.data) {
        setData(tmp.data.map((item: any) => convertResponse(item)));
      } else {
        setData([]);
      }
      const { pagination } = tmp;
      setTotalCount(pagination.totalCount);
      if (currentPage !== pagination.currentPage) {
        setCurrentPage(pagination.currentPage);
      }
      if (limit !== pagination.limit) {
        setLimit(pagination.limit);
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const reloadMembers = async (withLoading = true) => {
    setMembersError('');
    if (withLoading) {
      setMembersLoading(true);
    }
    try {
      const tmpJuries = await get<{ data: IJury[] }>(`/competitions/${tag}/evaluation/juries`);
      if (tmpJuries?.data) {
        setJuries(tmpJuries.data);
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setMembersError(error.response.data.message);
      } else {
        setMembersError(error.message);
      }
    } finally {
      setMembersLoading(false);
    }
  };

  const handleOpenEvaluation = async (isReopen = false) => {
    try {
      await post(`competitions/${tag}/evaluation/open`);
      onChange();
    } catch (error) {
      message.error(`Failed to ${isReopen ? 're' : ''}open evaluation.`);
    }
  };

  const handleReopenEvaluation = async () => {
    await handleOpenEvaluation(true);
  };

  const handleCloseEvaluation = async () => {
    try {
      await post(`competitions/${tag}/evaluation/close`);
      onChange();
    } catch (error) {
      message.error('Failed to close evaluation.');
    }
  };

  const renderActionButton = () => {
    let btnType: 'primary' | 'danger' = 'primary';
    let clickMethod;
    let text;
    switch (evaluationStatus) {
      case 'INIT':
        clickMethod = () => handleOpenEvaluation();
        text = 'Open to juries';
        break;
      case 'OPEN':
        btnType = 'danger';
        clickMethod = () => handleCloseEvaluation();
        text = 'Close evaluation';
        break;
      case 'CLOSED':
        clickMethod = () => handleReopenEvaluation();
        text = 'Reopen evaluation';
        break;
      default: return <></>;
    }
    return <Button style={styles.btn} type={btnType} size='large' onClick={clickMethod}>{text}</Button>;
  };

  const renderMembersCard = () => {
    if (membersLoading) {
      return <Loading />;
    }
    if (membersError) {
      return (
        <ErrorMessage
          title='Failed to load jury members.'
          message={membersError}
          withRetry
          onRetry={() => reloadMembers(false)}
        />
      );
    }
    return (
      <JuryMembersCard
        tag={tag}
        juries={juries}
        onChange={() => {
          reloadMembers(false);
          getEvaluations(false);
        }}
        style={{ marginTop: 64 }}
      />
    );
  };

  const renderPaginationButtons = (marginTop = 0) => (
    <div style={{ ...styles.paginationContainer, marginTop }}>
      <Pagination
        current={currentPage}
        defaultPageSize={40}
        pageSize={limit}
        onChange={setCurrentPage}
        total={totalCount}
        style={{ marginTop }}
      />
    </div>
  );

  const renderWithPager = (content: ReactElement) => (
    <>
      {renderPaginationButtons(16)}
      {isLoading ? <Loading /> : content}
      {renderPaginationButtons(32)}
    </>
  );

  const renderPagination = () => {
    if (errorMessage) {
      return (
        <ErrorMessage
          title='Failed to load evaluations.'
          message={errorMessage}
          withRetry
          onRetry={getEvaluations}
        />
      );
    }
    if (!data.length) {
      return <NoResult />;
    }
    return renderWithPager(
      <>
        <div className='evaluation-table-desktop'>
          <EvaluationTable data={data} criteria={criteria} style={{ marginTop: 64 }} />
        </div>
        <div className='evaluation-table-mobile'>
          <EvaluationTableMobile data={data} criteria={criteria} style={{ marginTop: 64 }} />
        </div>
      </>,
    );
  };

  return (
    <div style={styles.root}>
      <MethodologyCard criteria={criteria} editable onEdit={() => setEditModalVisible(true)} />
      {renderMembersCard()}
      <div style={styles.titleContainer}>
        <div style={{ minWidth: 280 }}>
          <AutoSearchInput
            onSearch={(value) => {
              setSearch(value);
              setCurrentPage(1);
            }}
            style={{ width: '100%' }}
            placeholder='Type something...'
          />
          <div style={styles.selectorContainer}>
            <SortSelector value={sortBy} onChange={setSortBy} options={SORTERS} />
          </div>
        </div>
      </div>
      {renderPagination()}
      <div style={styles.btnContainer}>
        {renderActionButton()}
      </div>
      <InputCriteriaModal
        tag={tag}
        visible={editModalVisible}
        criteria={criteria}
        onOk={() => {
          setEditModalVisible(false);
          onChange();
        }}
        onCancel={() => setEditModalVisible(false)}
        evaluationStatus={evaluationStatus}
      />
    </div>
  );
};

interface IJuryStatus {
  isSubmitted: boolean;
  isAccepted: boolean;
}

export interface IJury {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  status: IJuryStatus;
  uniqueTag: string;
}

interface IProjectResponse {
  uniqueTag: string;
  id: string,
  name: string,
  image: string,
  pitchLink: string,
}

interface IProjectEvaluationsResponse {
  id: string,
  score: number,
  startup?: IProjectResponse;
  idea?: IProjectResponse;
  evaluations: {
    id: string,
    average: string,
    isAccepted: boolean;
    scores: {
      id: string;
      value: number;
      evaluationCriteria: {
        id: string
      };
    }[];
    user: {
      id: string;
      firstName: string;
      lastName: string;
      profileImage: string;
    }
  }[];
}

export interface IProjectEvaluations {
  id: string;
  project: IProjectResponse;
  isStartup: boolean;
  total: number;
  evaluations: {
    id: string;
    average: string;
    isAccepted: boolean;
    scores: {
      criteriaId: string;
      value: number;
    }[];
    user: {
      id: string;
      firstName: string;
      lastName: string;
      profileImage: string;
      uniqueTag: string;
    };
  }[];
}

interface Props {
  tag: string;
  criteria: ICriterion[];
  evaluationStatus: 'INIT' | 'OPEN' | 'CLOSED';
  onChange: () => any;
}

export default Evaluation;
