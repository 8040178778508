import moment from "moment";
import React, { useState, useEffect } from "react";
import { user } from "../../../libs/utils/user";

interface Props {
  data?: any
 }



const DiscoverCompetitions = ({data}:Props) => {

  const openView = (uniqueTag: string) => {
    const win = window.open(`/competitions/${uniqueTag}`, "_blank");
    win.focus();
  }
  const openCompList = () => {
    const win = window.open(`/competitions`, "_blank");
    win.focus();
  }
  const openViewWithApply = (uniqueTag: string) => {
      if (user()) {
        localStorage.setItem("url", window.location.href);
      }
      const win = window.open(`/competitions/${uniqueTag}`, "_blank");
      win.focus();
  }

  return (
    <div className="discover-card">
       <div className="card-title-container">
          <div className="dashboard-card-title">Discover competitions</div>
          <a onClick={openCompList} className="seeall">See all</a>
       </div>
          {data.length > 0 ?
           data.map((user:any, index: number)=>(
            <div className="discover-events-card" key={index}>
              <div>
                {
                  user.image !== "" ? (
                      <img className="discover-events-image" src={user.image } />
                  ):(
                    <img className="discover-events-image" src="/images/Pozi_cover.png" />
                  )
                }
              </div>
              <div className="discover-content-container">
                <div className="discover-title">{user.name}</div>
                  <div className="discover-info">
                  <div className="discover-deadline">
                        <div><img src="/images/date.svg" className="dashboard-img"/></div>
                        <div>{moment(user.deadline).format("YYYY.MM.DD")}</div>
                      </div>
                      <div className="discover-location">
                        <div><img src="/images/place.svg" className="dashboard-img"/></div>
                        <span>{user.location.city && user.location.city + ','} {user.location.country}</span>
                      </div>
                  </div>
                <div className="attend" onClick={()=> user.canJoin ? openViewWithApply(user.tag) : openView(user.tag)}>
                  <div>{user.canJoin? "Apply" : "View"}</div>
                  <img src="/images/user_info_icons/next.svg"/>
                </div>
              </div>
            </div>
             )
          )
        : "Nothing to show" }
    </div>
  );
};
export default DiscoverCompetitions;
