import React, { useEffect, useState } from 'react';
import {
  Button, message, Modal, Select,
} from 'antd';
import { Link } from 'react-router-dom';
import { IMyProjects } from '../Competition';
import { post } from '../../../../libs/utils/request';
import ConfirmationDialog from '../../../common/ConfirmationDialog';

const { Option } = Select;

const baseText = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: 20,
  color: '#282828',
};

const styles = {
  root: { padding: 16 },
  title: { ...baseText, marginBottom: 20 },
  subTitle: { ...baseText, fontWeight: 400 },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  label: {
    ...baseText,
    fontSize: 16,
    fontWeight: 600,
    color: '#6755CE',
    marginTop: 60,
    marginBottom: 12,
  },
  select: { width: 250, color: '#6755CE' },
  btnContainer: {
    width: '100%', display: 'flex', justifyContent: 'center', marginTop: 56,
  },
  btn: { fontWeight: 'bold' as const },
};

const SubmitProjectModal = ({
  tag, isStartup, visible, onSubmit, onCancel, myProjects,
}: Props) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);

  useEffect(() => {
    if (isStartup) {
      setProjects(myProjects.startups);
    } else {
      setProjects(myProjects.ideas);
    }
  }, [isStartup, myProjects]);

  useEffect(() => {
    if (visible) {
      setSelectedProject(null);
    }
  }, [visible]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await post(`/competitions/${tag}/submit`, { id: selectedProject, type: isStartup ? 'Startup' : 'Idea' });
      onSubmit();
    } catch (error) {
      message.error(`Failed to submit ${isStartup ? 'startup' : 'idea'}.`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      width={800}
    >
      <div style={styles.root}>
        <div style={styles.title}>
          {`Submit your ${isStartup ? 'startup' : 'idea'}`}
        </div>
        <div style={styles.subTitle}>
          Choose from the list, or
          {' '}
          <Link to={isStartup ? '/startups/new' : '/ideas'}>
            <span>create new</span>
          </Link>
          .
        </div>
        <div style={styles.content}>
          <div>
            <div style={styles.label}>
              {isStartup ? 'Startup' : 'Idea'}
            </div>
            <Select
              style={styles.select}
              onChange={setSelectedProject}
              placeholder='Choose from the list'
            >
              {projects.map((item) => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
            <div style={styles.btnContainer}>
              <Button
                style={styles.btn}
                type='primary'
                size='large'
                disabled={!selectedProject}
                onClick={() => setConfirmDialogVisible(true)}
                loading={isSubmitting}
              >
                {`Submit ${isStartup ? 'startup' : 'idea'}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        visible={confirmDialogVisible}
        onCancel={() => setConfirmDialogVisible(false)}
        onOk={() => {
          setConfirmDialogVisible(false);
          handleSubmit();
        }}
        title={`Submit ${isStartup ? 'startup' : 'idea'}`}
        description={(
          <div>
            {`Are you sure you want to submit this ${isStartup ? 'startup' : 'idea'}: `}
            <span style={{ fontWeight: 800 }}>
              {projects.find((item) => item.id === selectedProject)?.name}
            </span>
            ?
          </div>
        )}
        confirmBtnText='Submit'
      />
    </Modal>
  );
};

interface Props {
  tag: string;
  isStartup: boolean;
  visible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  myProjects: IMyProjects;
}

export default SubmitProjectModal;
