import React from 'react'
import {Button, Input, Select} from 'antd'
import {PlusOutlined, InboxOutlined} from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import TextArea from "antd/lib/input/TextArea";
import {getBaseUrl} from '../../libs/utils/request';

interface Props {
  data?: any | undefined
  edit?: undefined | true
  onSubmit: (data: any) => void
}

interface State {
  loading: boolean
}

export class CreateBlog extends React.Component<Props, State> {

  image: string | null = null
  imageName: string | null = null
  title: string | null = null
  text: string | null = null
  lead: string | null = null
  hashtags: string[] = []

  state: State = {
    loading: false
  }

  constructor(props: any) {
    super(props)
    if (this.props.edit) {
      const { data } = this.props
      this.title = data.title || null
      this.text = data.text || null
      this.lead = data.lead || null //TODO: lead
      this.hashtags = data.hashtags || []
      if (data.image && data.image.filename) {
        this.image = data.image.filename
      }
    }
  }

  render() {
    const isEdit = !!this.props.edit

    return <div className={'CreateBlog'}>
      <div className={'CreateBlog-spacer'} style={{
        gridColumn: '1',
        gridRow: '1 / 4 span'
      }}>
        <Dragger
          name={'photo'}
          multiple={false}
          showUploadList={false}
          action={`${getBaseUrl()}/image`}
          onChange={(info: any) => {
            const {status} = info.file
            if (status === 'done') {
              this.image = info.file.response.filename
              this.imageName = info.file.name
              this.forceUpdate()
            } else if (status === 'error') {
              this.image = null
              this.imageName = null
              this.forceUpdate()
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined/>
          </p>
          <p
            className="ant-upload-text">{this.imageName ? this.imageName : 'Click or drag file to this area to upload'}</p>
        </Dragger>
      </div>

      <Input
        defaultValue={this.title || ''}
        className={'CreateBlog-spacer'}
        style={{
          gridColumn: '2',
          gridRow: '1'
        }}
        placeholder="Title"
        size="large"
        onChange={(e) => {
          this.title = e.target?.value
        }}
      />

      <Input
        defaultValue={this.lead || ''}
        className={'CreateBlog-spacer'}
        style={{
          gridColumn: '3',
          gridRow: '1'
        }}
        placeholder={'Lead'}
        size="large"
        onChange={(e) => {
          this.lead = e.target?.value
        }}
      />

      <TextArea
        defaultValue={this.text || ''}
        style={{
          gridColumn: '2 / span 2',
          gridRow: '2 / span 2',
        }}
        autoSize={{
          minRows: 2
        }}
        className={'CreateBlog-spacer'}
        placeholder={'Text'}
        maxLength={10000}
        onChange={(e) => {
          this.text = e.target?.value
        }} />

      <Select
        defaultValue={this.hashtags || []}
        className={'CreateBlog-spacer'}
        mode="tags"
        style={{
          gridColumn: '2',
          gridRow: '4',
          width: '100%',
        }}
        placeholder="Hashtags"
        onChange={(hashtags: string[]) => {
          this.hashtags = hashtags
        }}/>

      <Button
        loading={this.state.loading}
        className={'CreateBlog-spacer'}
        style={{
          gridColumn: '3',
          gridRow: '4'
        }}
        type="primary"
        size="large"
        onClick={async () => {
          this.setState({ loading: true })
          await this.props.onSubmit({
            image: this.image,
            title: this.title,
            text: this.text,
            lead: this.lead,
            hashtags: this.hashtags
          })
          this.setState({ loading: false })
        }}>{isEdit ? 'Edit' : [<PlusOutlined/>,'Create a new']}</Button>
    </div>
  }
}
