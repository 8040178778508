import React from 'react';
import { Link } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { Dropdown, Menu, message } from 'antd';
import moment from 'moment';
import { SplitEnter } from '../../libs/utils/SplitEnter';
import { isAdmin, user } from '../../libs/utils/user';
import { CommentData } from './Comments';
import { deleteReq, get, patch } from '../../libs/utils/request';
import ConfirmationDialog from '../common/ConfirmationDialog';
import ReactLinkify from 'react-linkify';

interface Props {
  comment: CommentData
  patchURL: (id: string) => string
  onReply: () => void
  style?: any
  deleteURL:(id:string)=>string
  type?:string;
  source:string;
  onDeleteComment:()=>void
}

interface State {
  messages: string
  edited: boolean
  updatedAt: string
  editMode: boolean
  editable:boolean
  comments:any
  showConfirmationDialog:boolean
}

export class CommentBlockElement extends React.Component<Props, State> {
  state: State = {
    messages: this.props.comment.message,
    edited: this.props.comment.edited,
    updatedAt: this.props.comment.updatedAt,
    editMode: false,
    editable: false,
    comments: null,
    showConfirmationDialog: false,
  }

  handleCommentSubmit = async () => {
    this.setState({
      editMode: false,
    });
    try {
      const comment: any = await patch(this.props.patchURL(this.props.comment.id), { message: this.state.messages, type: this.props.type });
      this.setState({
        messages: this.state.messages.trim(),
        edited: comment.edited,
        updatedAt: comment.updatedAt,
      });
    } catch (err) {
      this.setState({
        messages: this.props.comment.message,
      });
      // console.log(err)
    }
  }

  handleCommentChange = (e: any) => {
    this.setState({
      messages: e.target.value,
    });
  }

  componentDidMount() {
    if (this.state.editMode === false) {
      if (this.props.comment.user.id === user().id) {
        this.setState({ editable: true });
      } else if (isAdmin()) {
        this.setState({ editable: true });
      } else {
        this.setState({ editable: false });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { id } = this.props.comment;
    const elementId = `${id}-comment`;
    if (!prevState.editMode && this.state.editMode) {
      document.getElementById(elementId).focus();
    }
  }

  deleteComment =async () => {
    await deleteReq(this.props.deleteURL(this.props.comment.id), { commentId: this.props.comment.id });
    await get(this.props.source).then((res) => {
      this.setState({ comments: res });
    });
    this.props.onDeleteComment && this.props.onDeleteComment();
  }

  commentsRender = () =>{
    const elementId = `${this.props.comment.id}-comment`;
    if(this.state.editMode){
      return <TextArea
      value={this.state.messages}
      autoSize
      id={elementId}
      className='input'
      onPressEnter={() =>
        this.handleCommentSubmit()}
      onChange={this.handleCommentChange}
    />
    }
    else{
      return  <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target='blank' href={decoratedHref} key={key}>{decoratedText}</a>
      )}
    >
      {SplitEnter(this.state.messages)}
    </ReactLinkify>
    
    }
  }

  render() {
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
    const {
      messages, editMode, edited, updatedAt,
    } = this.state;
    const { id, user: createdBy } = this.props.comment;
    

    const commentDropDown = (
      <Menu>
        {createdBy.id === user().id && (
          <Menu.Item onClick={() => {
            if (editMode === false) {
              this.setState({ editMode: true });
            }
          }}
          >
            <div className='share-menu-text'>edit</div>
          </Menu.Item>
        )}
        {(createdBy.id === user().id || isAdmin()) && (
          <Menu.Item onClick={() =>
            this.setState({ showConfirmationDialog: true })}
          >
            <div className='share-menu-text'>delete</div>
          </Menu.Item>
        )}
        {createdBy.id !== user().id && (
          <Menu.Item onClick={() => message.warning('Report not yet implemented.')}>
            <div className='share-menu-text'>report</div>
          </Menu.Item>
        )}

      </Menu>
    );

    return (
      <div className='CommentBlockElement' style={this.props.style}>
        <div
          className='comment-profile-img'
          style={{
            backgroundImage: `url(${createdBy.profileImage || '/images/user.svg'
          } )`,
          }}
        />
        <div className='message'>
          <p>
            <span><Link className='message-sender' to={`/entrepreneurs/${createdBy.uniqueTag}`}>{`${createdBy.firstName + ' ' +createdBy.lastName}`}</Link></span>
            {this.commentsRender()}

          </p>
          <div
            style={{ cursor: 'pointer', display: 'inline-block', color: 'var(--primary-blue)' }}
            onClick={(e) => {
              e.preventDefault();
              this.props.onReply();
            }}
          >
            Reply
          </div>
          <span className='date'>{(edited ? 'edited ' : '') + moment(updatedAt).fromNow()}</span>
        </div>
        <div>
          <Dropdown
            overlay={commentDropDown}
            trigger={['click']}
            placement='bottomCenter'
          >
            <div className='Comment-action' style={{ borderRadius: '50%' }}>
              <img src='/images/dots.svg' />
            </div>
          </Dropdown>
          <ConfirmationDialog
            visible={this.state.showConfirmationDialog}
            onCancel={() => this.setState({ showConfirmationDialog: false })}
            onOk={() => {
              this.deleteComment();
              this.setState({ showConfirmationDialog: false });
            }}
            title='Delete comment'
            description='Are you sure you want to delete this comment?'
            confirmBtnText='Delete'
            confirmBtnType='primary'
          />
        </div>
      </div>
    );
  }
}
