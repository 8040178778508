import React from "react";
import { ITheme } from "./Themes";
import "./Theme.css";
const Theme = ({ theme }: Props) => (
  <div className="elem-holder">
    <div className="elem-img-holder">
      <img src={theme.icon} alt="" />
    </div>
    <div className="elem-name">{theme.name}</div>
  </div>
);

interface Props {
  theme: ITheme;
}

export default Theme;
