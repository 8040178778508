import React from 'react'
import { get } from '../../libs/utils/request'
import { message, Table } from "antd";
import { Loading } from "../common/Loading";
import { Link } from "react-router-dom";

interface Props {
  onClose: () => void
  group: any
}

interface State {
  members: any
}

export class GroupMembers extends React.Component<Props, State> {
  state: State = {
    members: null
  }

  async componentDidMount() {
    try {
      const members = await get<any>(`/groups/members/${this.props.group.id}`)
      this.setState({ members })
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
    }
  }

  member(m: any) {
    const userFromStorage = sessionStorage.getItem('user')
    return <div style={{
      display: 'flex'
    }}>
      <img className={'GroupMembers-profile-image'} src={m.profileImage ? m.profileImage + '?w=48' : '/images/user.svg'} alt="" />
      <Link style={{
        marginLeft: '12px',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
      }} to={JSON.parse(userFromStorage).id ===  m.id ? '/profile' : '/entrepreneurs/' + m.uniqueTag} target={'_self'}>{`${m.firstName} ${m.lastName}`}</Link>
    </div>
  }

  render() {
    const { members } = this.state
    if (members === null) {
      return <Loading />
    }

    return <div style={{
      padding: '32px',
      maxHeight: 'calc(100vh - 120px)',
      overflow: 'auto'
    }}>
      <Table
        pagination={{ position: 'top' }}
        dataSource={members.map((i: any) => ({
          member: this.member(i)
        }))}
        columns={[
          {
            title: 'Members',
            dataIndex: 'member',
            key: 'member',
          }
        ]} />
    </div>
  }
}