import React from 'react';
import DetailListItem from './DetailListItem';

const styles = {
  root: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'center',
    marginTop: 32,
    gap: '10px 10px',
  },
};

const DetailList = ({
  location, duration, deadline, timezone, isOnline,
}: Props) => (
  <div style={styles.root}>
    <DetailListItem label='Location' value={location} icon='../../../../../../images/competitions/location.svg' />
    <DetailListItem label='Duration' value={duration} icon='../../../../../../images/competitions/duration.svg' />
    <DetailListItem label='Application deadline' value={deadline} icon='../../../../../../images/competitions/deadline.svg' />
    <DetailListItem label='Time zone' value={timezone} icon='../../../../../../images/competitions/timezone.svg' />
    {isOnline && <DetailListItem label='Competition type' value='Online' icon='../../../../../../images/competitions/online.svg' />}
  </div>
);

interface Props {
  location: string,
  duration: string,
  deadline: string,
  timezone: string,
  isOnline: boolean,
}

export default DetailList;
