import React, { useState, useEffect } from "react";
import ProfileProgress from "./components/ProfileProgress";
import DiscoverCompetitions from "./components/DiscoverCompetitions";
import CreatePost from "./components/CreatePost";
import DiscoverEvents from "./components/DiscoverEvents";
import UpcominingCompetition from "./components/UpcomingCompetition";
import NewUsers from "./components/NewUsers";
import LatestStartups from "./components/LatestStartups";
import LatestIdeas from "./components/LatestIdeas";
import MileStones from "./components/MileStones";
import FeedList from "../common/FeedList";
import { user } from "../../libs/utils/user";
import { get, post } from "../../libs/utils/request";
import { Input, message, Modal } from "antd";
import PostModal from "../NewsFeed/components/PostModal/PostModal";
import Masonry from "react-masonry-css";
import InformationGroup from "./components/InformationGroup";
import "./Dashboard.css";
import LatestOrganization from "./components/LatestOrganization";

const Dashboard = () => {
  const [selectedType, setSelectedType] = useState("All");
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editNews, setEditNews] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [events, setEvents] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [upcomingCompetition, setUpcomingCompetitions] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [latestStartups, setLatestStartups] = useState([]);
  const [latestIdeas, setLatestIdeas] = useState([]);
  const [latestOrganizations, setLatestOrganizations] = useState([]);
  const userFromStorage = sessionStorage.getItem("user");
  const profileProgress = userFromStorage
    ? JSON.parse(userFromStorage).progress
    : 0;
  const [feedListSize, setFeedListSize] = useState(600);
  const [email, setEmail] = useState("");
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // setTimeout(() => {
  //   if (
  //     !(
  //       sessionStorage.getItem("subscriptionClosed")
  //     )
  //   ) {
  //     setShowSubscriptionModal(true);
  //   }
  // }, 1 * 6000);
  // // , 5*60000);
  useEffect(() => {
    setUpData();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1440) setFeedListSize(650);
    else setFeedListSize(600);
  }, [window]);

  const setUpData = async () => {
    const dashBoardData: any = await get("/dashboard")
      .then((res: any) => {
        setNewUsers(res.newUsers);
        setCompetitions(res.competitions);
        setEvents(res.events);
        setUpcomingCompetitions(res.upcomingCompetition);
        setLatestStartups(res.latestStartups);
        setLatestIdeas(res.latestIdeas);
        setLatestOrganizations(res.latestOrganizations);
      })
      .catch((err) => {
        if (err.response.status === 403 && err.response.data.extendedUser)
          sessionStorage.setItem(
            "user",
            JSON.stringify(err.response.data.extendedUser)
          );
        if (
          !err.response.data.extendedUser.active &&
          !err.response.data.extendedUser.trial
        ) {
          window.location.replace("/trial-ended");
        }
      });
  };

  const deletePost = async (id: any) => {
    if (id) {
      try {
        await post("/news/delete", { id: id });
        message.success("Post deleted.");
        setRefreshNeeded(true);
      } catch (error) {
        message.error("Failed to delete post.");
      }
    }
  };

  // const sendSubscription = async () => {
  //   await post("/subscription", { email })
  //     .then((response) => {
  //       message.success(response, 5);
  //       let text = ((
  //         document.getElementById(
  //           "homepage-email-subscribe-input"
  //         ) as HTMLInputElement
  //       ).value = "");
  //       sessionStorage.setItem("subscriptionClosed", "true");
  //       setShowSubscriptionModal(false)
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       message.success("Subscribe failed", 5);
  //       message.error(e.response?.data?.message || "Network error");
  //     });
  // };

  return (
    <div className="wrapper">
      <div className="dashboard-left-side">
        <UpcominingCompetition data={upcomingCompetition} />
        <DiscoverCompetitions data={competitions} />
        <DiscoverEvents data={events} />
        <InformationGroup />
      </div>
      <div className="dashboard-mid-side">
        <div>
          <CreatePost
            editNews={editNews ? editNews : ""}
            refreshNeeded={refreshNeeded}
            setRefreshNeeded={setRefreshNeeded}
          />
        </div>
        <div className="feedlist" id="feedList">
          <div className="dashboard-card-title">News feed </div>
          <FeedList
            url="news/list"
            selectedType={selectedType}
            refreshNeeded={refreshNeeded}
            setRefreshNeeded={setRefreshNeeded}
            onEdit={(item) => {
              setEditNews(item);
              setEditModalVisible(true);
            }}
            onDelete={(id) => {
              deletePost(id);
              setIdForDelete(id);
              setDeleteDialogVisible(true);
            }}
            id="feedList"
            feedListMaxWidth={feedListSize}
          />
        </div>
      </div>
      <div className="dashboard-right-side">
        {user() && profileProgress < 100 && <ProfileProgress />}
        <MileStones />
        <NewUsers data={newUsers} />
        <LatestStartups data={latestStartups} />
        <LatestIdeas data={latestIdeas} />
        <LatestOrganization data={latestOrganizations} />
      </div>
      <PostModal
        visible={editModalVisible}
        onOk={() => {
          setEditModalVisible(false);
          setRefreshNeeded(true);
        }}
        onCancel={() => setEditModalVisible(false)}
        postUrl="/news/post"
        patchUrl="/news/edit"
        news={editNews}
      />
      {/* {showSubscriptionModal && (
        <p>
          <Modal
            className="registration-modal-view"
            visible={true}
            destroyOnClose
            footer={null}
            width={"min-content"}
            onCancel={() => {
              setShowSubscriptionModal(false);
              sessionStorage.setItem("subscriptionClosed", "true");
            }}
            closeIcon={
              <img
                src="/images/popup/cross.png"
                className="subscription-popup-close-icon"
              />
            }
          >
            <div className="home-page-popup-container">
              <div className="homepage-popup-image-container">
                <img src="/images/popup/Turcsan.png" />
              </div>
              <div className="homepage-description-container">
                <div className="homepage-description-container-title">
                  Choose Wisely!
                </div>
                <div className="homepage-description-container-description">
                  Start your week with a fresh dose of startup news, updates,
                  hints & tips served by our lovely team.
                  <br /> <br />
                  It’s time for you to be up-to-date. Subscribe to our
                  newsletter:
                </div>
                <div className="homepage-description-container-input">
                  {windowSize.innerWidth > 590 ? (
                    <Input.Group compact>
                      <Input
                        style={{
                          width: "calc(100% - 150px)",
                          borderTopLeftRadius: "13px",
                          borderBottomLeftRadius: "13px",
                        }}
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary"
                        onClick={() => sendSubscription()}
                      >
                        Subscribe
                      </button>
                    </Input.Group>
                  ) : (
                    <>
                      <Input
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        type="button"
                        className="ant-btn ant-btn-primary"
                        onClick={() => sendSubscription()}
                      >
                        Subscribe
                      </button>
                    </>
                  )}
                </div>
                <div className="homepage-description-container-link">
                  <a>
                    By subscribing to the newsletter, you accept our Privacy
                    Policy.
                  </a>
                </div>
                <div className="homepage-description-container-oneline">
                  Working together with Top Leaders and Organisations
                </div>
                <div className="homepage-description-container-logos">
                  <img src="/images/popup/nbx.png" />
                  <img src="/images/popup/Institute.png" />
                  <img src="/images/popup/Draper2.png" />
                  <img src="/images/popup/Draper.png" />
                  <img src="/images/popup/women.png" />
                  <img src="/images/popup/worldCup.png" />
                  <img src="/images/popup/TalCatran.png" />
                </div>
              </div>
            </div>
          </Modal>
        </p>
      )} */}
    </div>
  );
};
export default Dashboard;
