import React from "react";
import { useEffect, useState } from "react";
import { get } from "../../libs/utils/request";
import DraftsCard from "../Competitions/DraftsCard";

const OrganizationsDraft=()=>{
    const [drafts, setDrafts] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    useEffect(() => {
      (async () => {
          const draftsTmp = await get<{ drafts: any[] }>('organizations/draft/all');
          setDrafts(draftsTmp.drafts);
       
      })();
    }, []);
  
  
    return (
     <div className="startup-draft">
           {!!drafts.length && ( 
            <DraftsCard type={'organization'} drafts={drafts} collapsed={collapsed} setCollapsed={setCollapsed} />
           )} 
  
     </div>
    );
}

export default OrganizationsDraft