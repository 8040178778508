import get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/hu';
import { user } from '../utils/user';

type TranslationMap = {
  language: string,
  texts: Object
}

type CountryInfo = {
  language: string,
  info: { [key: string]: string }
}

const availableCountryInfo: CountryInfo[] = [
  {
    language: 'en',
    info: require('./countries/en.json'),
  },
  {
    language: 'hu',
    info: require('./countries/hu.json'),
  },
  {
    language: 'dev',
    info: {},
  },
];

const availableTranslations: TranslationMap[] = [
  {
    language: 'en',
    texts: require('./en.json'),
  },
  {
    language: 'dev',
    texts: {},
  },
];
const defaultLanguage = 'en';

let appLanguage = localStorage.getItem('language');

if (!appLanguage) {
  localStorage.setItem('language', defaultLanguage);
  localStorage.setItem('dataLanguage', defaultLanguage);
  appLanguage = defaultLanguage;
}
const languageIsAvailable = availableTranslations.some((t) => t.language === appLanguage);
if (!languageIsAvailable) {
  appLanguage = defaultLanguage;
}
moment.locale(appLanguage);

export const currentLanguage = () => appLanguage;

export const availableLocales = () => availableTranslations.filter((t) => t.language !== 'dev').map((t) => t.language);

const getTranslation = (key: string, args: {[field: string]: any} = {}): string => {
  if (appLanguage === 'dev') {
    return key;
  }
  try {
    let text = get(availableTranslations.find((translationmap) => translationmap.language === appLanguage).texts, key) || get(availableTranslations.find((translationmap) => translationmap.language === 'en').texts, key);
    if (text !== undefined) {
      Object.keys(args).forEach((key: string) => {
        const value = args[key];
        const regexp = new RegExp(`\{${key}\}`);
        text = text.replace(regexp, value);
      });
      return text;
    }
  } catch (error) {
    // console.log(error)
  }

  return key;
};

const i18n = getTranslation;
export default i18n;

export const getCountryName = (code: string) => {
  if (!code) {
    throw Error('No country code');
  }
  const countryInfo: CountryInfo = availableCountryInfo.find((countryInfo) => countryInfo.language === appLanguage);
  const countryName = countryInfo.info[code.toUpperCase()];
  if (!countryName) {
    return code;
  }
  return countryName;
};

export const getCountryData = (language: string = user().appLanguage) => {
  const data = availableCountryInfo.find((countryInfo) => countryInfo.language === language);
  if (data) {
    return Object.keys(data.info).map((key) => data.info[key]);
  }
  return [];
};

export const tableLocale = () => ({
  filterConfirm: getTranslation('general.table.filter.confirm'),
  filterReset: getTranslation('general.table.filter.reset'),
  emptyText: getTranslation('general.table.filter.emptyText'),
});

export const datePickerLocale = () => ({
  lang: {
    placeholder: getTranslation('general.datePicker.placeholder'),
    rangePlaceholder: [
      getTranslation('general.datePicker.startDate'),
      getTranslation('general.datePicker.endDate'),
    ],
    today: getTranslation('general.datePicker.today'),
    now: getTranslation('general.datePicker.now'),
    ok: getTranslation('general.datePicker.ok'),
    clear: getTranslation('general.datePicker.clear'),
    timeSelect: getTranslation('general.datePicker.timeSelect'),
    dateSelect: getTranslation('general.datePicker.dateSelect'),
    dateTimeFormat: 'YYYY.MM.DD HH:mm',
    dateFormat: 'YYYY.MM.DD',
    yearFormat: 'YYYY',
    monthFormat: 'MMMM',
  },
});

export const setLanguage = (language: string) => {
  appLanguage = language;
  localStorage.setItem('language', language);
};
