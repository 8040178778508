import React from 'react';
import { IStartup } from '../../Startups/Startups';
import { IIdea } from '../../Ideas/Ideas';
import { StartupCard, IdeaCard } from '../../../../../CardView/ProjectCard';

const styles = {
  root: {
    width: '100%',
    marginBottom: 60,
  },
  awardText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 30,
    color: '#BD9E75',
    marginBottom: 20,
  },
  icon: {
    height: 40,
    marginLeft: 30,
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 20,
    color: '#000',
    marginBottom: 27,
  },
};

const WinnerCard = ({
  awardName, awardDescription, isStartup, startup, idea, onChange,
}: Props) => {
  const handleChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <div style={styles.root}>
      <div style={styles.awardText}>
        {awardName}
        <img style={styles.icon} src='../../../../../../images/competitions/award_gold.svg' alt='' />
      </div>
      <div style={styles.descriptionText}>{awardDescription}</div>
      {isStartup && (
        <StartupCard startup={startup} onChange={handleChange} />
      )}
      {!isStartup && (
        <IdeaCard key={idea.id} idea={idea} onChange={handleChange} />
      )}
    </div>
  );
};

interface Props {
  awardName: string;
  awardDescription: string;
  isStartup: boolean;
  startup?: IStartup;
  idea?: IIdea;
  onChange?: () => void;
}

WinnerCard.defaultProps = {
  startup: null,
  idea: null,
  onChange: null,
};

export default WinnerCard;
