import React, { CSSProperties, useEffect, useState } from 'react';
import { Upload } from 'antd';
import { getBaseUrl } from '../../libs/utils/request';

const ImageUploader2 = (props: Props) => {
  const [fileList, setFileList] = useState([]);
  const {
    images, acceptedFormats, onChange, type, children, styles,
  } = props;

  useEffect(() => {
    const toFileList = (urlOrList: any) => {
      const getItem = (url: string) => ({
        uid: url.slice(-10, -4),
        name: 'unknown',
        status: 'done',
        url,
      });
      return type === 'single' ? [getItem(urlOrList)] : urlOrList.map(getItem);
    };
    setFileList(images ? toFileList(images) : []);
  }, [images, type]);

  const beforeUpload = (file: any): Promise<void> => new Promise((resolve, reject) => {
    setFileList([]);
    if (file.size / 1024 / 1024 < 10) {
      resolve(file);
    } else {
      // TODO onError
      reject();
    }
  });

  const toUrlOrList = (list: any): string | string[] => {
    if (type === 'single') {
      if (!list.length) {
        return undefined;
      }
      return list[0].url || list[0].response?.url;
    }
    return list.map((file: any) => file.url || file.response?.url);
  };

  const handleChange = (obj: any) => {
    const { file } = obj;
    const list = obj.fileList;
    const imageUrls = toUrlOrList(list);
    setFileList(list);
    if (file.response) {
      onChange(imageUrls);
    }
  };

  return (
    <div style={styles}>
      <Upload
        name='photo'
        action={`${getBaseUrl()}/image`}
        accept={acceptedFormats}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        showUploadList={false}
      >
        {children}
      </Upload>
    </div>
  );
};

interface Props {
  images: string | string[]
  type: 'single' | 'list'
  onChange: any
  acceptedFormats?: string
  children: any
  styles?: CSSProperties
}

ImageUploader2.defaultProps = {
  acceptedFormats: '.png,.jpg',
  styles: {},
};

export default ImageUploader2;
