import React from 'react'
import {Button, Input, message, Select} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import ImageUploader from '../common/ImageUploader'
import {get, post} from '../../libs/utils/request'
import User from '../../user/User'
import './GroupEdit.css'
import { Loading } from '../common/Loading'
import {Interest, RegistrationData} from "../Registration/Registration";

interface Props {
  onClose: () => void
  group: any
  onUpdate: (data: any) => void
}

interface State {
  loading: boolean
//  regData: any
  image?: string
  previewImage?: string
  data?: any
}

/* { id, name, description, image, previewImage } */
export class GroupEdit extends React.Component<Props, State> {

  state: State = {
    loading: false,
//    regData: null
    image: '',
    previewImage: '',
    data:null,
  }

  name: string = this.props.group.name
  description: string = this.props.group.description
  tag:string=this.props.group.uniqueTag

  constructor(props: any) {
    super(props)

    //this.init()
  }
/*
  async init() {
    try {
      this.setState({
        regData: await get<RegistrationData>('/register/init')
      })
    } catch (err) {
      console.log(err)
    }
  }
*/

  async componentDidMount(){
    try {
      const data: any = await get(`/groups/get/${this.tag}`)
      this.setState({
        data
      })
    } catch (e) {
      message.error('Failed to fetch')
    }
  }
  async save() {
    try {
      this.setState({
        loading: true
      })
      const data: any = await post<User>(`/groups/edit`, {
        id: this.props.group.id,
        name: this.name,
        description: this.description,
        image: this.state.image===''? this.state.data.image:this.state.image,
        previewImage: this.state.previewImage || this.state.data.image
      })
      this.props.onUpdate(data)
      this.props.onClose()
    } catch (e) {
      message.error('Failed to save')
    }
    this.setState({
      loading: false
    })
  }

  render() {
    return <div className={'GroupEdit'}>
      <div className="headline">
        <h3 className="title">Edit group</h3>
      </div>
      <div className={'body'}>
          <div className={'fields'}>
            <Input
              placeholder="Name"
              value={this.name}
              size="large"
              onChange={(e) => {
                this.name = e.target?.value
                this.forceUpdate()
              }}
            />
            <TextArea
              style={{
                marginTop:'16px'
              }}
              placeholder={"Description"}
              value={this.description}
              autoSize
              maxLength={1000}
              onChange={(e) => {
                this.description = e.target?.value
                this.forceUpdate()
              }} />
          </div>
          <div className={'images'}>
            <ImageUploader
              images={this.state.image}
              uploadText={'Upload main image'}
              type={'single'}
              onChange={(image: string) => {
                this.setState({ image })
              }}
              showCropper = {true}
              cropperData = {{width: 200 , height: 200 }}
            />
          </div>
          <Button
            onClick={() => {
              this.save()
            }}
            loading={this.state.loading}
            type="primary"
            size="large"
            block
            style={{
              width: '200px',
              marginTop: '16px',
              marginBottom: '50px',
            }}>Save</Button>
      </div>
    </div>
  }
}
