import React from 'react';
import Card from '../../../../../common/card/Card';
import Theme from './Theme';

const styles = {
  root: {
    width: '100%',
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    paddingTop: 24,
  },
};

const Themes = ({ themes }: Props) => (
  <Card style={styles.root}>
    {themes.map((item) => (
      <Theme key={item.id} theme={item} />
    ))}
  </Card>
);

export interface ITheme {
  id: string;
  name: string;
  icon: string;
}

interface Props {
  themes: ITheme[];
}

export default Themes;
