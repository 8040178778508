import { message } from "antd";
import moment from "moment";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import '../Dashboard.css';

const InformationGroup = () => {
  return (
    <div className="dashboard-information-group">
      <div className="dashboard-information-column">
        <a className="dashboard-information-item" href={"https://www.linkedin.com/company/pozi-io/"} target="_blank">
          About
        </a>
        <CopyToClipboard
          text="hello@pozi.io"
          onCopy={() => {
            message.success("hello@pozi.io copied to clipboard");
          }}
        >
           <a className="dashboard-information-item" href="mailto:hellous3@bme.hu">Contact us</a>
        </CopyToClipboard>

        <Link
          className="dashboard-information-item"
          to={"/privacy-policy"}
          target="_blank"
        >
          Privacy Policy
        </Link>
      </div>
      <div className="dashboard-information-column">
        <Link
          className="dashboard-information-item"
          to={"/terms-and-conditions"}
          target="_blank"
        >
          Privacy and Terms
        </Link>
        <a className="dashboard-information-item"   onClick={() => window.open('http://eepurl.com/hoPlND', '__blank')}>
          Newsletter
        </a>
        <Link className="dashboard-information-item" to={"/rss"} target="_blank">
          RSS
        </Link>

      </div>
      <div className="dashboard-information-column">
        <div >
          <img src="/images/poziiologo.svg" alt="" />
        </div>
        <span className="dashboard-information-item">Pozi ©{moment().year()}</span>
      </div>
    </div>
  );
};

export default InformationGroup;
