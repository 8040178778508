import React, { Component } from 'react'
import './Privacy.css'

class PrivacyPolicy extends Component {
	render() {
		return (

			<div className="PrivacyPolicy">
				<div className="Content-container">
					<h1>PRIVACY POLICY</h1>
					<p><span>&nbsp;The operator of the www.pozi.io website (hereinafter referred to as: Website), the Pozi Network Zrt. (Registered seat: 3-5. B&eacute;csi St., H-1023 Budapest, Hungary, Registration number: 01-10-141468, Tax ID number: HU27188756, Court of registry: Budapest-Capital Regional Court (Hungary); hereinafter referred to as: Foundation/Service Provider) informs Users below regarding the data processing conducted in relation to the Website and to the activities conducted by the Service Provider pursuant to the regulations of Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation, hereafter as: GDPR), and with Act CXII of 2011 on the right to information self-determination and the freedom on information (hereinafter as: Infotv.)</span></p>
					<p><strong>&nbsp;Terms and definitions</strong></p>
					<p><span>&nbsp;For the purposes of the present Notice, the terms below shall mean the following:</span></p>
					<p><span>Personal data: any information relating to an identified or identifiable natural person (hereinafter referred to as: data subject); an identifiable person meaning one who can be identified directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that person.;</span></p>
					<p><span>Data processing: any operation or set of operations performed upon personal data or sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction;</span></p>
					<p><span>Data controller: the natural or legal person, government body, agency or any other organisation, which determines the purposes and means of the data processing either solely or jointly with others; if the purposes and means of the data processing is regulated by EU or state laws, such EU or state laws may set out the data processor to be appointed, or may set out the specific aspects per which the data processor is to be appointed;</span></p>
					<p><span>Data processor: the natural or legal person, government body, agency or any other organisation, which processes personal data on behalf of the data controller;&nbsp;</span></p>
					<p><span>Data erasure: the complete physical destruction of the media containing the data;</span></p>
					<p><span>Data forwarding: the making available of the data to a given third party;</span></p>
					<p><span>Data deletion: rendering the data incomprehensible in a way that allows for no reinstation thereof;</span></p>
					<p><span>User: the person visiting, browsing the Website (Data subject);</span></p>
					<p><span>Online interface: the Website operated by the Foundation (www.pozi.io).</span></p>
					<p><span>&nbsp;</span><strong>General provisions</strong></p>
					<p><span>The Website of the Foundation may be accessed by anyone &ndash; without having to hand over their personal data &ndash; and may freely gain information thereon without restriction.</span></p>
					<p><span>Regarding any data processing that may occur on the Website, the Service Provider shall be deemed as data controller. Regarding the provision of Services, the Service Provider shall also be deemed as the data controller.</span></p>
					<p><span>Users are solely liable for the data handed over to them and the contents the upload, for which Service Provider expressly denies any liability.</span></p>
					<p><span>Service Provider is entitled to amend the present privacy policy unilaterally, at any time. Service Provider issues the amendments of the present privacy policy by publishing them on the Website, in a separate menu item. Users are kindly requested to carefully consult the privacy policy upon any visit to the Website.</span></p>
					<p><span>The present privacy policy is continuously available on the Website. Users may open, view, print or save the present privacy policy, but may not amend or alter it, this is the sole prerogative of the Service Provider.</span></p>
					<p><span>Categories of personal data processed by Service Provider, the purpose, legal basis, mode and timeframe of processing&nbsp;</span></p>
					<p><span>&nbsp;The legal bases for our data processing are the following:</span></p>
					<ol>
						<li><span>a) </span> <span>GDPR Article 6 (1) a), where the processing is based on the informed consent of the data subject (hereafter referred to as: Consent);</span></li>
						<li><span>b) </span> <span>GDPR Article 6 (1) b), where processing is necessary for the performance of a contract to which the data subject is party (hereafter referred to as: Conclusion of Contract)</span></li>
						<li><span>c) </span> <span>GDPR Article 6 (1) c) where data processing is necessary for the fulfilment of or compliance with a legal obligation of the data controller (e.g. obligations with tax statues &ndash; hereafter referred to as: Compliance)</span></li>
						<li><span>d) </span> <span>GDPR Article 6 (1) d), where processing is necessary in order to protect the vital interests of the data subject or of another natural person (hereafter referred to as: Vital Interest);</span></li>
						<li><span>e) </span> <span>GDPR Article 6 (1) e), where processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller (hereafter referred to as: Public Interest);</span></li>
						<li><span>f) </span> <span>GDPR Article 6 (1) f) where data processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, (hereinafter referred to as: Lawful Interest)</span></li>
					</ol>
					<p><span>&nbsp;Data processing regarding direct marketing and newsletters&nbsp;</span></p>
					<p><span>&nbsp;We inform the Users that we send newsletters to persons having expressly subscribed to these newsletters. Users may subscribe to the newsletters on the Website by checking the appropriate checkbox and clicking the appropriate button.&nbsp;</span></p>
					<p><span>Subscribers to the newsletter may freely unsubscribe at any time by clicking the unsubscribe link found at the end of every newsletter.</span></p>
					<p><span>Service Provider will send no further newsletters to any persons that have unsubscribed from them.</span></p>
					<p><span>After having revoked consent, consent may be re-given at any time. Consent is not a prerequisite condition to the usage of any Service. E-mail address and name must be provided when giving consent, without which the giving of consent is not possible. Revocation of consent shall also require the provision of an e-mail address and name for the purposes of identification.&nbsp;</span></p>
					<p><span>Data subject</span></p>
					<p><span>Data category</span></p>
					<p><span>Data source</span></p>
					<p><span>Purpose of data processing</span></p>
					<p><span>Legal basis of data processing</span></p>
					<p><span>Timeframe of processing, time of deletion</span></p>
					<p><span>Newsletter subscriber</span></p>
					<p><span>Name</span></p>
					<p><span>Data subject (User)</span></p>
					<p><span>Sending of electronic direct marketing messages, newsletters</span></p>
					<p><span>GDPR Article 6 (1) a): consent&nbsp;</span></p>
					<p><span>Until revocation of consent</span></p>
					<p><span>E-mail address</span></p>
					<p><span>Data subject (User)</span></p>
					<p><span>Sending of electronic direct marketing messages, newsletters</span></p>
					<p><span>GDPR Article 6 (1) a): consent&nbsp;</span></p>
					<p><span>Until revocation of consent</span></p>
					<p><span>&nbsp;&nbsp;</span></p>
					<p><span>Processing of automatically collected data regarding the Website</span></p>
					<p><span>Data subject</span></p>
					<p><span>Data category</span></p>
					<p><span>Data source</span></p>
					<p><span>Purpose of data processing</span></p>
					<p><span>Legal basis of data processing</span></p>
					<p><span>Timeframe of processing, time of deletion</span></p>
					<p><span>User visiting the Website</span></p>
					<p><span>IP address, country, type and version number of browser, device and operating system used, language settings, time of visit</span></p>
					<p><span>Data subject (User)</span></p>
					<p><span>Statistics,</span></p>
					<p><span>Website development, User identification and recognition</span></p>
					<p><span>GDPR Article 6 (1) f), necessary for the purposes of the legitimate interests pursued by the controller</span></p>
					<p><span>2 years from time of visit</span></p>
					<p><span>IP address, country, type and version number of browser, device and operating system used, language settings, time of visit</span></p>
					<p><span>Data subject (User)</span></p>
					<p><span>Statistics,</span></p>
					<p><span>Website development, User identification and recognition</span></p>
					<p><span>GDPR Article 6 (1) f), necessary for the purposes of the legitimate interests pursued by the controller</span></p>
					<p><span>2 years from time of visit</span></p>
					<p><span>&nbsp;Service Provider uses cookies and other various programs in order to ascertain the Website&rsquo;s Users&rsquo; preferences and conduct regarding the Website, and to develop the Website based thereon. Service Provider creates anonymous statistics of the Website visits.</span></p>
					<p><span>The above data processing is the lawful business interest of the Service Provider since this serves to enable the Service Provider to improve the Website and to make it more secure. The scope of the collected data is not significant, these are only collected and processed by the Service Provider, with Service Provider collecting no behavioural preferences, and conducting no automated decision making based thereon, with no personalised offer being sent by the Service Provider to Users based thereon. Concordantly, this data processing does not affect User&rsquo;s fundamental rights and freedoms adversely.</span></p>
					<p><span>Further information is set out in the Cookie Policy, which Users can find on our website.</span></p>
					<p><strong>Processing of job applicants&rsquo; data</strong></p>
					<p><span>The CV&rsquo;s and other data forwarded by those applying for a job at the Service Provider are processed as follows:&nbsp;</span></p>
					<p><span>We inform you that by forwarding your CV and job application to the Foundation, User (applicant) consents to the Foundation processing their personal data for the purposes of recruiting, communicating an offer, for contacting and identification, and to the Foundation sending messages and notifications to them via their given contact information.</span></p>
					<p><span>Service Provider processes the data given by the data subject during the course of application to a given position, as well as the data collected by the Foundation for the duration of the recruiting procedure, with all data being deleted upon the conclusion of the recruiting procedure.</span></p>
					<p><span>Should the recruiting procedure be drawn out and exceed a year&rsquo;s time, the personal data shall only be processed by the Foundation for a year after them having been handed in by the data subject.</span></p>
					<p><span>Users have the opportunity however to allow the Foundation to store and process their data for the purposes of future recruiting and sending of job offers regardless of the position which they apply to. For this, the Foundation shall ask for express consent. Should User give their consent thereto, the Foundation shall then be entitled to process their data for an additional 2 years for the purposes set out in the present section.</span></p>
					<p><span>Should the Foundation enter into an employment agreement with the User, these data shall then be subject to and governed by the timeframe set out in the employees&rsquo; data policy, of which the Foundation shall inform User upon the conclusion of their employment agreement.</span></p>
					<p><span>The legal bases for the data processing is set out by the Service Provider below, per data types and data processing purposes:</span></p>
					<p>&nbsp;</p>
					<p><span>Data category</span></p>
					<p><span>Data source</span></p>
					<p><span>Purpose of data processing</span></p>
					<p><span>Legal basis of data processing</span></p>
					<p><span>CV data (degree, alma mater, previous positions, professional experience, hobbies, etc.)&nbsp;</span></p>
					<p><span>Applicant data subject</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Contact keeping</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Name</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Contact keeping</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Address</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Contact keeping</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Date and place of birth</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Nationality</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Contact keeping</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Telephone number</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Contact keeping</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>E-mail</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Contact keeping</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Photo</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Language proficiency</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p>&nbsp;</p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Publications, presentations, projects</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>Contact keeping</span></p>
					<p><span>Identification</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>References</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Social media information</span></p>
					<p><span>Applicant data subject Head-hunter, employment agent&nbsp;</span></p>
					<p><span>CV databases</span></p>
					<p><span>Recruiting</span></p>
					<p><span>Job offer communication</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>Use of data publicly available on social media platforms&nbsp;</span></p>
					<p><span>In case of job applications, the Foundation may view the User&rsquo;s (applicant&rsquo;s) information on social media platforms, e. g. Facebook, LinkedIn profiles, their activity, posts, comments thereon in order to judge whether the applicant is suitable for the given position. The Foundation shall only view the publicly accessible information on these social media platforms, with the Foundation conducting no searches in closed groups or other semi-restricted sections. Moreover, the Foundation shall not save nor store the social media profiles of the applicants, neither shall they make notes thereof. No sensitive or special categories of data regarding the User are processed by the Foundation, including their social media platform data. The Foundation shall only view data on social media platforms that are of importance to the position being applied for.</span></p>
					<p>&nbsp;</p>
					<p><span>Provision and revocation of consent</span></p>
					<p>&nbsp;</p>
					<p><span>Regarding the processing of personal data for the above purposes, the User (applicant) gives their consent freely by actively submitting their application to the position. The User may revoke their consent at any time by sending an e-mail to hello@pozi.io, where the following data are mandatory and required: name, date of birth and e-mail address; in order to enable the Foundation to ascertain whose data is to be deleted.</span></p>
					<p><span>In case of a User&rsquo;s consent being revoked, all of their processed data shall be deleted. The deletion obligation shall extend to data stored both electronically and on paper.</span></p>
					<p>&nbsp;</p>
					<p><span>Notifications on the success of an application</span></p>
					<p>&nbsp;</p>
					<p><span>After the conclusion of a recruitment procedure, applicants are notified by the Foundation via e-mail whether the Foundation wishes to enter into an employment relationship with them, or not.</span></p>
					<p>&nbsp;</p>
					<p><span>Data provided by completing forms&nbsp;</span></p>
					<p>&nbsp;</p>
					<p><span>In order to achieve their goals, the Foundation strives to reach as many domestic enterprises and enterprising individual as possible via the Website. To this end, the User has the opportunity to fill out the form found on the Website at </span><a href="https://www.pozi.io/contactus"><span>https://www.pozi.io/contactus</span></a><span> in order to get in touch with the Foundation, thus helping the efforts of the Foundation.</span></p>
					<p>&nbsp;</p>
					<p><span>Data category</span></p>
					<p><span>Data source</span></p>
					<p><span>Purpose of data processing</span></p>
					<p><span>Legal basis of data processing</span></p>
					<p><span>Name</span></p>
					<p><span>Data subject User</span></p>
					<p><span>Statistics, marketing contact (with a personalised offer)</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>E-mail address</span></p>
					<p><span>Data subject User</span></p>
					<p><span>Statistics, marketing contact (with a personalised offer)</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p><span>Users relationship with the startup community (regarding the type, name and website of the enterprise they are involved in)&nbsp;</span></p>
					<p><span>Data subject User</span></p>
					<p><span>Statistics, marketing contact (with a personalised offer)</span></p>
					<p><span>GDPR Article 6 (1) a): consent</span></p>
					<p>&nbsp;</p>
					<p><span>Purpose of data processing</span></p>
					<p><span>The scope of the collected and processed data is not substantial, these are used by the Service Provider to produce and to analyse anonymous statistics, moreover to conduct marketing communication, by which the User is contacted by the Foundation with a personalised offer based on the data provided by them.</span></p>
					<p><span>Timeframe of data processing</span></p>
					<p><span>The Foundation shall only store data provided via the form for a maximum of 2 years from their provision, following which they shall be deleted.</span></p>
					<p>&nbsp;</p>
					<p><span>Provision and revocation of consent</span></p>
					<p><span>Regarding the processing of personal data for the above purposes, the User gives their consent freely by actively filling out and submitting the form to the Foundation. The User may revoke their consent at any time by sending an e-mail to hello@pozi.io, where the following data are mandatory and required: name, date of birth and e-mail address; in order to enable the Foundation to ascertain whose data is to be deleted.</span></p>
					<p><span>In case of a User&rsquo;s consent being revoked, all of their processed data shall be deleted.&nbsp;</span></p>
					<p><span>The deletion obligation shall extend to data stored both electronically and on paper.</span></p>
					<p><span>Data controller and data processors</span></p>
					<p><span>&nbsp;Regarding the data specified under point III, the data controller is the Service Provider:</span></p>
					<p><span>Pozi Network Zrt.</span></p>
					<p><span>Registered seat: 3-5. B&eacute;csi St., H-1023 Budapest, Hungary</span></p>
					<p><span>Registration number: 01-10-141468</span></p>
					<p><span>Tax ID number: HU27188756&nbsp;</span></p>
					<p><span>Court of registry: Budapest-Capital Regional Court (Hungary)</span></p>
					<p><span>E-mail address: hello@pozi.io</span></p>
					<p>&nbsp;</p>
					<p><span>On behalf of the Service Provider, the data of the User may be accessed by the employees of service provider to the extent that is necessary for the carrying out of their tasks. Access rights to personal data are regulated in a strict internal policy.</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>Data processors</span></p>
					<p><span>Service Provider engages various enterprises for the processing and storing of User&rsquo;s data, with whom Service Provider concludes data processing agreements. The following data processors conduct data processing regarding the User&rsquo;s data:</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>Name and address of data processor</span></p>
					<p><span>Purpose of data processing</span></p>
					<p><span>Categories of processed data</span></p>
					<p><span>MailChimp (675 Ponce de Leon Ave NE, Suite 5000; Atlanta, GA 30308 USA)</span></p>
					<p><span>Newsletter sending service</span></p>
					<p><span>Data specified under point III. 2.</span></p>
					<p><span>Google LLC. (USA, Google Data Protection Office, 1600</span></p>
					<p><span>Amphitheatre Pkwy Mountain View, California 94043</span></p>
					<p><span>- Google Analytics)</span></p>
					<p><span>Google Analytics service</span></p>
					<p><span>Data specified under point III. 2.</span></p>
					<p><span>Strikingly Inc. (120 Clipper Drive Belmont, CA 94002 United States</span></p>
					<p><span>Hosting service</span></p>
					<p><span>Data specified under point III. 2.</span></p>
					<p><span>Typeform Ltd. (Barcelona, ES (HQ)</span></p>
					<p><span>163 Carrer de Bac de Roda)&nbsp;</span></p>
					<p><br /><br /></p>
					<p><span>Online form service</span></p>
					<p><span>Data specified under point III. 4.</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>From the above data processors, the US-based companies Google LLC and MailChimp are all members of the USA- EU Privacy Shield List created on the basis of the adequacy decision of the European Commission according to Article 45 of GDPR and decision 2016/1260 of the European Commission. This means that data transfer to these entities shall not be deemed as data transfer to third countries outside the European Union and the affected persons&rsquo; specific consent is not required for the data transfer to these entities to be allowed. These entities have undertaken to ensure their compliance with the GDPR.</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>US-based data processors engaged by the Service Provider that are not on the Privacy Shield List:</span></p>
					<p>&nbsp;</p>
					<p><span>Strikingly Inc. (120 Clipper Drive Belmont, CA 94002 United States) is a United States-based company, which is not found on the Privacy Shield List, with no binding corporate rules or compliance regulations of the European Committee being in place regarding the United States, as well as no adequate guarantees per Article 46 of the GDPR being given; thus, data forwarding to them may only take place based on specific consent per Article 49 (1) a) of the GDPR. Based on the above, data subjects are hereby informed of the following possible risks:</span></p>
					<p>&nbsp;</p>
					<p><span>it cannot be ensured that the given enterprise complies with GDPR regulations,</span></p>
					<p><span>it cannot be ensured that the given enterprise observes data security provisions,</span></p>
					<p><span>it cannot be ensured that the given enterprise provides an adequate level of security regarding the processing of personal data,</span></p>
					<p><span>it cannot be ensured that the legal regulations governing the given enterprise ensure the protection of fundamental rights and the protection of personal data,</span></p>
					<p><span>it cannot be ensured that the given enterprise is under the supervision of a competent and independent supervisory authority or body that supervises the data processing of the given enterprise,</span></p>
					<p><span>it cannot be ensured that the country in which the given enterprise operates is in observance of their international law obligations on the protection of personal data,</span></p>
					<p><span>it cannot be ensured that the data subject is able to effectively and satisfactorily exercise their data privacy rights in the country in which the given enterprise operates.</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>The Foundation is entitled and obligated to hand over personal data in their possession and lawful storage to the competent authorities where they are compelled to by applicable law or a final authority decision. For any such data forwarding, and for any consequences thereto, the Foundation may not be held liable. No other data forwarding is conducted by the Foundation.&nbsp;</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>Data privacy directives observed by the Foundation&nbsp;</span></p>
					<p><span>&nbsp;The Foundation respects the legally protected rights of their Users and of those who visit the online interfaces operated by them.</span></p>
					<p><span>&nbsp;The personal data that is directly necessary for the usage of the services of the Foundation is processed by the Foundation per the consent of the data subjects, and strictly for the purposes pertaining thereto. The Foundation uses the personal data of the Users specified under point III hereto only per the means and purposes set out in the present Privacy Policy.</span></p>
					<p><span>&nbsp;The Foundation as data controller undertakes to process the data in their possession per the provisions of the GDPR, of the Infotv. and other relevant legal regulations, and the regulations of the present Privacy Policy, and to refrain from making them available to any third parties not including those specified in the present Privacy Policy. The use of statistical compilations of data that do not contain the names or identifying data of Users in any way serve as an exception to the present point, as these do not constitute data processing, nor data forwarding.</span></p>
					<p><span>&nbsp;The Foundation shall, in certain situations &ndash; e.g. official court or police inquiries, legal procedures regarding copyright, property or other disputes or the suspicion thereof concerning infringements upon the lawful interest of the Foundation, the endangerment of their provision of services, as well as per court or authority warrants &ndash;, as well as based on the User&rsquo;s prior express consent, make User&rsquo;s data available to third parties.</span></p>
					<p><span>&nbsp;The Foundation shall make every reasonable effort to ensure that the processing and management of the Users&rsquo; data is given the protection set out by applicable law, to that end, the Foundation operate security systems.</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>Protection of personal data</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>The Foundation complies with their obligations deriving from the applicable privacy regulations by:</span></p>
					<p>&nbsp;</p>
					<p><span>keeping personal data up to date;</span></p>
					<p><span>safely storing and deleting them;</span></p>
					<p><span>not collecting or storing excess amounts of data;</span></p>
					<p><span>protecting personal data from loss, violation, unauthorised access or publication, as well as ensuring that adequate technical measures are in place, protecting personal data.</span></p>
					<p><span>The Foundation carries out adequate technical and organisational measures in order to protect the Users&rsquo; personal data from accidental or unlawful destruction, loss or modification, as well as unlawful communication or access &ndash; especially where network communication thereof is a part of the processing &ndash;, and to protect data form any unlawful form of processing.</span></p>
					<p><span>Accordingly, the Foundation emplaces various levels of access rights over the data, which ensures that the data are only accessed by persons having adequate clearance, who are required to access the data in order to carry out their job or to fulfil their related obligations.</span></p>
					<p>&nbsp;</p>
					<p><span>Rights of the User</span></p>
					<p><span>Pursuant to the data protection legislation in place, the data subject is entitled to:</span></p>
					<p><span>request access to their personal data,</span></p>
					<p><span>request corrections regarding their personal data,</span></p>
					<p><span>request deletion of their personal data,</span></p>
					<p><span>request the restriction of their personal data,</span></p>
					<p><span>object to the processing of their personal data,</span></p>
					<p><span>request the porting of their personal data,</span></p>
					<p><span>revoke their consent regarding data processing</span></p>
					<p><span>file a complaint regarding any grievances.</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>a)</span> <span>Right of access</span></li>
					</ol>
					<p><span>The data subject is entitled to receive feedback from the data controller on whether their personal data is being processed or not, and if so, to request access to their personal data.</span></p>
					<p><span>The data subject is entitled to request copies of their personal data being processed. For the purposes of identification, the data controller may request additional information from the subject, and &ndash; with the exception of the first copy being handed out &ndash; to charge any warranted administrative fees that further copies may entail.&nbsp;</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>b)</span> <span>Right of correction</span></li>
					</ol>
					<p><span>The data subject is entitled to request any of their erroneous personal data to be rectified by the data controller. Based on the given data processing purpose, the data subject may be entitled to request incomplete personal data to be amended.</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>c)</span> <span>Right of deletion (&bdquo;right to be forgotten&rdquo;)</span></li>
					</ol>
					<p><span>The data subject is entitled to request the data controller to delete their personal data, and the data controller shall delete these. In any such case, the data controller will not be able to provide any further services to the User.&nbsp;</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>d)</span> <span>Right of restriction</span></li>
					</ol>
					<p><span>The data subject is entitled to request the restriction of their personal data. In this case, the data controller shall mark the affected personal data, which shall only be processed for certain specific purposes.</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>e)</span> <span>Right to objection&nbsp;</span></li>
					</ol>
					<p><span>The data subject is entitled to object at any time, for any reasons of their own, to the processing of their personal data per Article 6 (1) e) or f) of the general data protection regulation, including the profiling based on said regulations, and to request that the data controller no longer process their personal data.</span></p>
					<p>&nbsp;</p>
					<p><span>Moreover, where the User&rsquo;s data is processed by the Foundation per lawful interest, User is entitled to object to their data being processed per this basis.</span></p>
					<p>&nbsp;</p>
					<p><span>Additionally, User is entitled to request human intervention in specific cases of automated decision making. We inform Users that data controller employs no automated decision making mechanisms.</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>f)</span> <span>Right to data portability</span></li>
					</ol>
					<p><span>The data subject is entitled to request that their given personal data be provided to them in an articulated, widely recognised, computer readable format (i.e. digital format) from the data processor, and is entitled moreover &ndash; where technically possible &ndash; to request these data to be forwarded to another data controller without the Foundation hindering this.</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>g)</span> <span>Right to revoke consent</span></li>
					</ol>
					<p><span>Where the processing of User&rsquo;s personal data is conducted per their consent, User may revoke their consent at any time via the link found in newsletters, or by changing their website-profile or mobile device settings. Revocation of consent does not affect the legality of consent-based data processing conducted prior to the revocation of consent.</span></p>
					<p>&nbsp;</p>
					<p><span>If User revokes their consent given to the Foundation, the services provided by the Foundation may partially or wholly be unavailable to be provided.</span></p>
					<p>&nbsp;</p>
					<ol>
						<li><span>h)</span> <span>Right to file a complaint with the supervisory authority</span></li>
					</ol>
					<p><span>If the User believes that their personal data have been infringed upon, they may file a complaint with the local data privacy supervisory authority, primarily per their residence, their place of work or in the member state where the purported breach had taken place.</span></p>
					<p>&nbsp;</p>
					<p><span>In Hungary, they may turn to the National Authority for Data Protection and Freedom of Information: H-1125, Hungary, Budapest, 22/C. Szil&aacute;gyi Erzs&eacute;bet fasor; telephone: +36-1 391-1400; telefax: +36-1 391-1410; e-mail: ugyfelszolgalat@naih.hu).</span></p>
					<p>&nbsp;</p>
					<p><span>Contact keeping</span></p>
					<p>&nbsp;</p>
					<p><span>Should the User wish to exercise their data privacy rights or to lodge a complaint, they may contact the Foundation&rsquo;s appointed colleague via an e-mail sent to the e-mail address below. Moreover, User may seek out the Foundation by way of mail sent to the postal address below.</span></p>
					<p>&nbsp;</p>
					<p><span>E-mail: hello@pozi.io</span></p>
					<p><span>Postal address: 3-5. B&eacute;csi St., H-1023 Budapest, Hungary</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>Miscellaneous provisions</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>In case of any data privacy incidents, the Foundation shall notify the supervising authority per the applicable legal regulations, within 72 hours from having gained knowledge of the incident; and shall keep records thereof. The Foundation shall notify Users thereof in cases specified by law.</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>The data processor shall regularly check their online platforms and the information published thereon and shall make every reasonable effort to ensure the information thereon are current and factual. However, User may find information on these online platforms that are out of date. The Foundation accepts no material liability for this information.</span></p>
					<p><span>&nbsp;</span></p>
					<p><span>Visitors of the Foundation&rsquo;s online platforms and Users may visit other websites from the Foundation&rsquo;s online platforms that are not operated by the Foundation. The Foundation accepts no liability regarding the correctness of data found thereon, the contents of these pages, and for the security of any data provided there by visitors of the Foundation&rsquo;s online platforms and Users. Thus, when using these websites, please take special care in consulting the privacy policies of the respective operating companies found thereon.</span></p>
					<p><span>&nbsp;The present Privacy Policy shall enter into force on the 1st of July 2020!</span></p>
					<p>&nbsp;</p>
				</div>
			</div>
		);
	}
}

export default PrivacyPolicy;