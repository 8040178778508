import React, { CSSProperties } from 'react';
import { Spin } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

const styles = {
  root: {
    width: '100%',
    paddingTop: '50px',
    paddingBottom: '50px',
    textAlign: 'center' as const,
  },
  spinner: { fontSize: 24, color: 'var(--primary-blue)' },
};

export const Loading = ({ style }: Props) => (
  <div style={{ ...styles.root, ...style }}>
    <Spin indicator={<LoadingOutlined style={styles.spinner} spin />} />
  </div>
);

interface Props {
  style?: CSSProperties;
}

Loading.defaultProps = {
  style: {},
};
