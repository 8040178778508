/* eslint-disable */
export enum SubPageEnum {
  summary = '',
  participants = 'participants',
  startups = 'startups',
  ideas = 'ideas',
  feed = 'feed',
  winners = 'winners',
  jury = 'jury',
  evaluation = 'evaluation',
}
