import React from 'react'
import { get } from '../../libs/utils/request'
import { Button, Table, message } from 'antd'
import { Loading } from '../common/Loading'
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import { InvitationModal } from './InvitationModal'
import { Helmet } from 'react-helmet';

interface Props {
  match: any
  history: any
}

interface State {
  invitations: any
  modal: boolean
}

class InvitationsPage extends React.Component<Props, State> {

  state: State = {
    invitations: null,
    modal: false
  }

  async componentDidMount() {
    await this.list()
  }

  async list() {
    try {
      this.setState({
        invitations: await get('/invitation/list')
      })
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      message.error('failed to fetch invitations')
    }
  }

  render() {
    const { invitations } = this.state
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  

    if (invitations === null) {
      return <div className="Content-container">
        <Loading />
      </div>
    }

    return <div className="Content-container">
      <Helmet>
        <title>Invitations | pozi.io</title>
      </Helmet>
      <div style={{ width: '100%' }}>
        <h3 style={{
          fontSize: '1.5rem',
          fontWeight: 900,
          marginTop: '1.5em',
        }}>Invitations</h3>
        <Button
          onClick={() => 
            this.setState({ modal: true })}
          loading={false}
          type="primary"
          size="large"
          block
          style={{
            width: '200px',
            marginBottom: '50px',
          }}>Invite</Button>
      </div>
      <Table dataSource={invitations.map((i: any) => ({
        ...i,
        invitedUser: i.invitedUser
          ? <Link to={'/entrepreneurs/' + i.invitedUser.uniqueTag} target={'_blank'}>{`${i.invitedUser.firstName} ${i.invitedUser.lastName}`}</Link>
          : 'Not registered user',
        visited: i.visited ? <CheckSquareOutlined /> : <CloseOutlined />
      }))} columns={[
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Registered user',
          dataIndex: 'invitedUser',
          key: 'invitedUser',
        },
        {
          title: 'Link visited',
          dataIndex: 'visited',
          key: 'visited',
        },
      ]} />
      <InvitationModal
        open={this.state.modal}
        onClose={() => this.setState({ modal: false })}
        onInvitations={(invitations) => {
          this.setState({
            invitations
          })
        }}
      />
    </div>
  }

}

export default InvitationsPage
