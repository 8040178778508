import React from 'react'
import {get} from '../../libs/utils/request'


interface Props {
  onChange: (groupTypes: any[]) => void
}

interface State {
  groupTypes: any[] | null
  selected: any[]
}

export class InterestGroupFilter extends React.Component<Props, State> {
  timeout: any = null

  state: State = {
    groupTypes: null,
    selected: []
  }

  async componentDidMount() {
    const groupTypes = await get<any[]>('/interests/groups')
    this.setState({groupTypes})
  }

  change(role: any) {
    this.setState((prevState: State) => {
      const includes = prevState.selected.map(r => r.id).includes(role.id)

      const newState = {
        selected: includes
          ? prevState.selected.filter(r => r.id !== role.id)
          : [...prevState.selected, role]
      }

      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.props.onChange(newState.selected)
      }, 800)

      return newState
    })
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  render() {
    const {groupTypes} = this.state
    if (groupTypes === null) {
      return null
    }

    return groupTypes.map((r,i) => {
      const selected = this.state.selected.map(r => r.id).includes(r.id)
      return <div
        key={`${r.id}_${i}`}
        className={'GroupType'}
        onClick={() => this.change(r)}
        style={{
          background: selected ? 'var(--primary-blue)' : '#fff',
          color: selected ? '#fff' : undefined
        }}
      >
        <div style={{display: 'block',width:'100%'}}>
          <div style={{
            display: 'inline-block',
            backgroundImage: `url(/images/group_types/${r.id}.svg)`,
            width: '32px',
            height: '32px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}/>
          <div>{r.name}</div>
        </div>
      </div>
    })
  }
}
