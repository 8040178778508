import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Icon,
  Input,
  Modal,
  message,
  Menu,
  Dropdown,
  Rate,
  Popover,
  Button,
  Form,
} from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { user } from '../../libs/utils/user';
import { post } from '../../libs/utils/request';
import IdeaEditorWithForm from './IdeaEditor';
import './IdeaCard.css';
import { Comments } from '../comments/Comments';
import { ShareMenu } from '../common/ShareMenu';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Social from '../CardView/components/Social';
import Team from '../Team/Team';

interface Props {
  idea: any;
  callback?: any;
  onImageChange?: any;
}
const styles = {
  label: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 800,
    color: '#282828',
    fontSize: 16,
  },
  root: {
    paddingRight: 14,
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    // backgroundColor: '#F00',
  },
  img: {
    maxHeight: 20,
    maxWidth: 20,
    width: 20,
    // backgroundColor: '#00F',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: 400,
  },

  description: { marginTop: 13, fontWeight: 300, whiteSpace: 'pre-wrap' as const },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 20px',
    marginTop: 38,
    marginBottom: 40,
  },
  btn: {
    fontWeight: 'bold' as const,
    minWidth: 150,
  },
  footer: {
    marginTop: '10px',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
};

const IdeaCard = (props: Props) => {
  const location = window.location.pathname;

  const [modalVisible, setModalVisible] = useState(false);
  const { idea, callback } = props;
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [isEdit, setIsEdit] = useState(false)

  const showModal = () => {
    setModalVisible(true);
    setIsEdit(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setIsEdit(false)
  };

  const increaseShare = async () => {
    await post(`/ideas/${idea.id}/share`);
    if (callback) {
      callback();
    }
  };

  const onRateChange = async (rating: number) => {
    try {
      await post('/rating/post', { rate: rating, id: idea.ratingGroup.id });
      callback();
    } catch (err) {
      
    }
  };

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [buttonType, setButtonType] = useState('');
  const userFromStorage = sessionStorage.getItem('user')
  const sessionUserId =  userFromStorage ? JSON.parse(userFromStorage).id : null
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  const onJoin = async () => {
    try {
      await post(`/ideas/${idea.id}/join`);
      message.success('Request sent.');
      callback();
    } catch (err) {
      // console.log(err);
    }
  };
  const onWithdraw = async () => {
    try {
      await post(`/ideas/${idea.id}/withdraw`);
      message.success('Request sent.');
      callback();
    } catch (err) {
      // console.log(err);
    }
  };
  const onLeave = async () => {
    try {
      await post(`/ideas/${idea.id}/withdraw`);
      message.success('Request sent.');
      callback();
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeTitle = (event: any) => {
    setTitle(event.target.value);
  };

  const handleChangeDesc = (event: any) => {
    setDesc(event.target.value);
  };

  const onAccept = async (id: string) => {
    try {
      const data = {
        userId: id,
        title,
        description: desc,
      };
      await post(`/ideas/${idea.id}/accept`, data);
      window.location.reload();
    } catch (err) {
      // console.log(err);
    }
  };
  const onDeny = async (id: string) => {
    const data = {
      userId: id,
    };
    post(`/ideas/${idea.id}/deny`, data);
    window.location.reload();
  };

  const Join = () => {
    setShowConfirmationDialog(true);
    setButtonType('join');
  }

  const loggedIn = !!user();
  const userIsMe = loggedIn && user().id === idea.user.id;
  const shareLinkBase = `${window.location.protocol}//${window.location.host}`;

  return (
    <div className='Idea-card'>
      {idea.user.firstName === 'Deleted User' ? (
        ''
      ) : (
        <>
          <div className='Idea-image'>
            <Link to={`/idea/${props.idea.uniqueTag}`}>
              {!!(userIsMe || idea.isMember)
                && <Icon type='edit' onClick={showModal} />}
              <img
                src={
                  idea.image
                    ? `${idea.image}?w=648&mode=1`
                    : '/images/idea_placeholder.png'
                }
                alt=''
              />
              {location === 'https://pozi.io/ideas'
                || location === 'https://demo.pozi.nextleap.io/ideas'
                || location === 'http://localhost:3000/ideas' ? (
                  <div className='image-overlay'>See more</div>
                ) : (
                  ''
                )}
            </Link>
          </div>

          <div className='Idea-body'>
            <div className='Idea-card-top'>
              <div className='title-with-button'>
                <h3 className='Idea-title'>
                  {idea.name}
                  {(idea.eventTags || []).reverse().map((i: any) => (
                    <div className='EventTag-card'>{i.name}</div>
                  ))}
                </h3>
                {(() => {
                  if (idea.canJoin) {
                    return (
                      <div className='button-holder'>
                        <Button
                          className='canJoin'
                          onClick={() => {
                              Join()
                          }}
                        >
                          Join
                        </Button>
                      </div>
                    );
                  }
                  if (idea.canWithdraw) {
                    return (
                      <div className='button-holder'>
                        <Button
                          className='withdraw'
                          onClick={() => {
                            setShowConfirmationDialog(true);
                            setButtonType('withdraw');
                          }}
                        >
                          Withdraw application
                        </Button>
                      </div>
                    );
                  }
                  if (idea.canLeave) {
                    return (
                      <div className='button-holder'>
                        <Button
                          className='leave'
                          onClick={() => {
                            setShowConfirmationDialog(true);
                            setButtonType('leave');
                          }}
                        >
                          Leave
                        </Button>
                      </div>
                    );
                  }
                  return null;
                })()}
              </div>

              <p className='Idea-description'>{idea.details}</p>
            </div>
            <div className='Idea-card-bottom'>
              <div
                style={{
                  ...styles.label,
                  marginTop: 46,
                  marginBottom: 18,
                }}
              >
                Category
              </div>
              <div className='Idea-interests'>

                {idea.interests.map((interest: any) => (
                  <>
                    <div style={styles.root}>
                      <div style={{
                        width: 20, height: 30, alignItems: 'start', marginRight: 5, marginTop: 4,
                      }}
                      >
                        {' '}
                        <img style={styles.img} src={interest.icon} alt='' />
                        {' '}
                      </div>
                      <div style={{ fontSize: '11px', alignItems: 'start', wordWrap: 'break-word' }}>{interest.name}</div>
                    </div>
                  </>
                ))}
              </div>

              {(!!idea.pitchLink || !!idea.additionalInfo) && (
                <div className='aditional-infos'>
                  <h3>Additional links</h3>
                  {!!idea.pitchLink && (
                    <Button
                      className='social-link-button'
                      type='link'
                      size='large'
                      onClick={() => {
                        window.open(idea.pitchLink, '__blank');
                      }}
                      style={{
                        color: '#6755CE',
                        fontWeight: 'bold',
                        marginRight: 100,
                      }}
                    >
                      <img
                        style={{
                          marginTop: '-3px',
                          height: 16,
                          width: 23,
                          marginRight: 4,
                        }}
                        src='../../images/project_highlight_icons/pitchlink.svg'
                        alt=''
                      />
                      Pitch link
                    </Button>
                  )}
                  {!!idea.additionalInfo && (
                    <Button
                      className='social-link-button'
                      type='link'
                      size='large'
                      onClick={() => {
                        window.open(idea.additionalInfo, '__blank');
                      }}
                      style={{
                        color: '#6755CE',
                        fontWeight: 'bold',
                      }}
                    >
                      <img
                        style={{
                          marginTop: '-3px',
                          height: 13,
                          width: 20,
                          marginRight: 4,
                        }}
                        src='../../images/project_highlight_icons/addInfo.svg'
                        alt=''
                      />
                      Additional information
                    </Button>
                  )}
                </div>
              )}
              <div className='Idea-user'>
                <h3>Founder</h3>
                <div className='Idea-user-image'>
                  <img
                    src={
                      idea.user.profileImage
                        ? `${idea.user.profileImage}?w=35`
                        : '/images/user.svg'
                    }
                    alt=''
                  />
                </div>
                <div className='Idea-user-details'>
                  <Link
                    to={
                      userIsMe
                        ? '/profile'
                        : `/entrepreneurs/${idea.user.uniqueTag}`
                    }
                  >
                    <div className='Idea-user-name'>
                      {idea.user.firstName + ' ' + idea.user.lastName}
                    </div>
                    <div className='Idea-user-role'>
                      {idea.user.role.name}
                      {' '}
                      -
                      {' '}
                      {moment(idea.createdAt).fromNow()}
                    </div>
                  </Link>
                </div>
              </div>
              {/* <Team teamMembers={idea.teamMembers}/> */}
              {idea.teamMembers !== undefined ? (
                <>
                  {location === '/ideas' ? (
                    ''
                  ) : idea.teamMembers.length > 0 ? (
                    <h3 className='Idea-card-title'>Team</h3>
                  ) : (
                    ''
                  )}
                  {location === '/ideas'
                    ? ''
                    : idea.teamMembers.length > 0
                      ? idea.teamMembers.map((member: any, i: number) => (
                        <div className='member' key={`${member.user.id}${i}`}>
                          <img
                            src={member.user.profileImage || '/images/user.svg'}
                            alt=''
                          />
                          <div className='member-text'>
                            <div className='member-text-name'>
                              {member.user && (
                                <Link
                                  to={sessionUserId !== member.user.id ? `/entrepreneurs/${member.user.uniqueTag}` : `/profile`}
                                >
                                  {member.user.firstName + ' ' + member.user.lastName}
                                </Link>
                              )}
                            </div>

                            <div className='member-text-title'>
                              {member.title}
                            </div>

                            <div className='member-text-desc'>
                              {member.description}
                            </div>
                          </div>
                        </div>
                      ))
                      : ''}
                </>
              ) : (
                ''
              )}
              {userIsMe && (idea.memberRequests !== undefined ? (
                <>
                  {location === '/ideas' ? (
                    ''
                  ) : idea.memberRequests.length > 0 ? (
                    <h3 className='Idea-card-title'>Team requests</h3>
                  ) : (
                    ''
                  )}
                  {location === '/ideas'
                    ? ''
                    : idea.memberRequests.length > 0
                      ? idea.memberRequests.map((member: any, i: number) => (
                        <>
                          <div className='member' key={`${member.user.id}${i}`}>
                            <img
                              src={member.user.profileImage || '/images/user.svg'}
                              alt=''
                            />
                            <div className='member-text'>
                              <div className='member-text-name'>
                                {member.user && (
                                  <Link
                                    style={{ display: 'block' }}
                                    to={sessionUserId !== member.user.id ? `/entrepreneurs/${member.user.uniqueTag}` : `/profile`}
                                  >
                                    {member.user.firstName + ' ' + member.user.lastName}
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <Input
                            onChange={handleChangeTitle}
                            placeholder='Title'
                            maxLength={28}
                          />
                          <Input
                            onChange={handleChangeDesc}
                            placeholder='Description'
                            maxLength={110}
                          />
                          <div id='requestsButtons' style={{ textAlign: 'center' }}>
                            <Button style={{ margin: '5px' }} type='primary' size='large' onClick={() => onAccept(member.user.id)}>
                              Accept
                            </Button>
                            <Button style={{ margin: '5px' }} type='primary' size='large' onClick={() => onDeny(member.user.id)}>
                              Deny
                            </Button>
                          </div>
                        </>
                      ))
                      : ''}
                </>
              ) : (
                ''
              ))}
              <div>
                {!(userIsMe || idea.requestPending || idea.isMember) && !!user() && user().active && (
                  <div style={styles.footer}>
                    <Button style={{ ...styles.btn }} type='primary' size='large' onClick={onJoin}>
                      Join
                    </Button>
                  </div>
                )}
              </div>
              {idea.ratingGroup && (
                <Social
                  averageRating={idea.ratingGroup.averageRating}
                  ratingCount={idea.ratingGroup.ratingCount}
                  userRating={idea.userRating}
                  ratingUrl='/rating/post'
                  commentable={idea.commentable}
                  commentGetUrl={`/comment/get/${idea.commentGroup.id}`}
                  commentPostUrl={`/comment/post/${idea.commentGroup.id}`}
                  commentsCount={idea.commentGroup.commentsCount}
                  shareCount={idea.shareCount}
                  shareLink={`${shareLinkBase}/idea/${idea.uniqueTag}`}
                  type='ideas'
                  onRateChange={onRateChange}
                  visible
                />
              )}
            </div>
          </div>

          <Modal
            bodyStyle={{ padding: '0' }}
            wrapClassName='Idea-modal'
            width={950}
            visible={modalVisible}
            footer={null}
            closable={false}
          >
            <IdeaEditorWithForm
              onClose={closeModal}
              callback={callback}
              ideaToEdit={idea}
              isEdit={isEdit}
            />
          </Modal>
          {(() => {
            if (buttonType === 'join') {
              return (
                <ConfirmationDialog
                  visible={showConfirmationDialog}
                  onCancel={() => setShowConfirmationDialog(false)}
                  onOk={() => {
                    setShowConfirmationDialog(false);
                    onJoin();
                  }}
                  title='Join team'
                  description='Are you sure you want to join to this team?'
                  confirmBtnText='Join'
                  confirmBtnType='primary'
                />
              );
            }
            if (buttonType === 'withdraw') {
              return (
                <ConfirmationDialog
                  visible={showConfirmationDialog}
                  onCancel={() => setShowConfirmationDialog(false)}
                  onOk={() => {
                    setShowConfirmationDialog(false);
                    onWithdraw();
                  }}
                  title='Withdraw application'
                  description='Are you sure you want to withdraw your application?'
                  confirmBtnText='Withdraw'
                  confirmBtnType='danger'
                />
              );
            }
            if (buttonType === 'leave') {
              return (
                <ConfirmationDialog
                  visible={showConfirmationDialog}
                  onCancel={() => setShowConfirmationDialog(false)}
                  onOk={() => {
                    setShowConfirmationDialog(false);
                    onLeave();
                  }}
                  title='Leave team'
                  description='Are you sure you want to leave this team?'
                  confirmBtnText='Leave'
                  confirmBtnType='danger'
                />
              );
            }
            return null;
          })()}
        </>
      )}
      {' '}
    </div>
  );
};

export default IdeaCard;
