import { StarFilled } from "@ant-design/icons";
import { Popover, Rate, Dropdown, message, Icon } from "antd";
import React, { useState } from "react";
import { user } from "../../../libs/utils/user";
import { Comments } from "../../comments/Comments";
import { ShareMenu } from "../../common/ShareMenu";
import "./Social.css"

function Social({
  ratingCount,
  averageRating,
  commentsCount,
  commentable,
  onRateChange,
  shareLink,
  commentPostUrl,
  commentGetUrl,
  userRating,
  shareCount,
  type,
  visible
}: Props
) {
  const [commentVisible, setCommentVisible] = useState(visible ? visible : false);
  return (
    <div className="social">
      <h3>Social</h3>
      <div className={ratingCount ? "socialTextBoxmodified" : "socialTextBox"}>
        <div className="socialText" >
          {ratingCount ? (
            <>
              {averageRating}
              <StarFilled className="star" />
              {`from ${ratingCount} users`}
            </>
          ) : (
            "0 rating"
          )}
        </div>
        <div className="socialText">
          {`${commentsCount} comment${commentsCount > 1 ? "s" : ""}`}
        </div>
        <div className="socialText">{`${shareCount} shares `}</div>
      </div>

      <div
        className="Idea-actions"
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-hidden="true"
      >
        {user() ? (
          <Popover
            content={
              <Rate
                className="Idea-action-rating"
                allowClear
                onChange={onRateChange}
                value={userRating || 0}
              />
            }
          >
            <div className="Idea-action rate">
              <Icon type="star" theme="filled" />
            </div>
          </Popover>
        ) : (
          <div className="Idea-action rate">
            <Icon type="star" theme="filled" />
          </div>
        )}
        <div
          aria-hidden="true"
          className={`Idea-action comment ${!commentable && "disabled"}`}
          onClick={() => setCommentVisible(!commentVisible)}
        >
          <Icon type="message" theme="filled" />
        </div>
        <Dropdown
          overlay={<ShareMenu shareURL={shareLink} />}
          trigger={["click"]}
          placement="topCenter"
        >
          <div className="Idea-action share">
            <Icon type="share-alt" />
          </div>
        </Dropdown>
      </div>
      {commentVisible && commentable && !!user() && (
        <Comments
          style={{
            marginTop: "1.1em",
          }}
          postURL={commentPostUrl}
          commentsCount={commentsCount}
          commentable={commentable}
          source={commentGetUrl}
          onNewComment={() => {
            message.success("New comment");
          }}
          type={type}
        />
      )}
    </div>
  );
}

export default Social;

interface Props {
  ratingCount: number;
  averageRating: number;
  commentsCount: number;
  commentable: boolean;
  onRateChange: (rating: number) => void;
  shareLink: string;
  commentPostUrl: string;
  commentGetUrl: string;
  ratingUrl: string;
  userRating: number;
  shareCount: number;
  type: string;
  visible?: boolean;
}
