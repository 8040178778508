import React, { ReactElement, useState } from "react";
import { Button, Input, Modal } from "antd";
import FormikTextArea from "./form/FormikTextArea";

const styles = {
  title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 22,
    color: "#282828",
    marginBottom: 16,
  },
  description: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 16,
    color: "#282828",
    marginBottom: 16,
  },
  footer: {
    width: "100%",
    marginTop: 50,
    display: "flex",
    flexDirection: "row-reverse" as const,
    flexWrap: "wrap" as const,
    gap: "10px 10px",
  },
  actionBtn: {
    fontWeight: "bold" as const,
  },
  input: {
    width: "100%",
  },
};

const ConfirmationDialog = ({
  visible,
  onOk,
  onCancel,
  title,
  description,
  confirmBtnText,
  cancelBtnText,
  confirmBtnType,
  describe,
  htmlDescription,
}: Props) => {
  const [reason, setReason] = useState("");
  const disabled = describe === true && reason.length < 10;

  return (
    <Modal visible={visible} onCancel={onCancel} destroyOnClose footer={null}>
      {title && <div style={styles.title}>{title}</div>}
      {description && <div style={styles.description}>{description}</div>}
      {htmlDescription && <div dangerouslySetInnerHTML={{__html: htmlDescription}}></div>}
      {describe ? (
        <>
          <FormikTextArea
            style={styles.input}
            onChange={(e: any) => setReason(e.target.value)}
            name={undefined}
            value={reason}
            onBlur={undefined}
          ></FormikTextArea>

          <div style={styles.footer}>
            <Button
              style={{ ...styles.actionBtn, color: "#6755CE" }}
              type="link"
              size="large"
              onClick={onCancel}
            >
              {cancelBtnText}
            </Button>
            <Button
              style={{ ...styles.actionBtn, minWidth: 150 }}
              type={confirmBtnType}
              size="large"
              onClick={onOk}
              disabled={disabled}
            >
              Send message
            </Button>
          </div>
        </>
      ) : (
        <div style={styles.footer}>
          {!!onCancel && <Button
            style={{ ...styles.actionBtn, color: "#6755CE" }}
            type="link"
            size="large"
            onClick={onCancel}
          >
            {cancelBtnText}
          </Button>}
          <Button
            style={{ ...styles.actionBtn, minWidth: 150 }}
            type={confirmBtnType}
            size="large"
            onClick={onOk}
            disabled={disabled}
          >
            {confirmBtnText}
          </Button>
        </div>
      )}
    </Modal>
  );
};

interface Props {
  visible: boolean;
  onOk: () => void;
  onCancel?: () => void;
  title?: string;
  description?: string | ReactElement;
  confirmBtnText?: string;
  cancelBtnText?: string;
  confirmBtnType?:
    | "primary"
    | "link"
    | "default"
    | "ghost"
    | "dashed"
    | "danger";
  describe?: boolean;
  htmlDescription?: any;
}

ConfirmationDialog.defaultProps = {
  title: "",
  description: "",
  confirmBtnText: "OK",
  cancelBtnText: "cancel",
  confirmBtnType: "primary",
};

export default ConfirmationDialog;
