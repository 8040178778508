import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./ImageCropper.module.css";

interface Props {
  src: string;
  Cancel: () => void;
  onSave: (url: any) => void;
  cropperData: {
    width: any;
    height: any;
  };
  cropType?: string;
}
var croppedImage: any;

const ImageCropper = ({
  src,
  Cancel,
  onSave,
  cropperData,
  cropType,
}: Props) => {
  const [imageRef, setImageRef] = useState();
  const [cropConfig, setCropConfig] = useState({
    y: 0,
    x: 0,
    disabled: true,
    locked: true,
    width: cropperData.width,
    height: cropperData.height,
  });
  const uploadedImgRef: any = useRef();
  async function cropImage(crop: any) {
    if (imageRef && crop.width && crop.height) {
      croppedImage = await getCroppedImage(
        imageRef,
        crop,
        "croppedImage.jpeg" // destination filename
      );
      // calling the props function to expose
      // croppedImage to the parent component
    }
  }

  const getCroppedImage = (
    sourceImage: any,
    cropConfig: any,
    fileName: any
  ) => {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  useEffect(() => {
    if (imageRef) {
      cropImage(cropConfig);
    }
  }, [imageRef])
  return (
    <div className={styles.cropper}>
      <ReactCrop
        ref={uploadedImgRef}
        circularCrop={cropType && cropType === "cover" ? false : true}
        className={styles.crop}
        src={src}
        crop={cropConfig}
        ruleOfThirds
        onImageLoaded={(imageRef: any) => {
          setImageRef(imageRef);
          croppedImage = imageRef;
          let ratio = cropperData.width / cropperData.height;
          let width = Math.min(1300, imageRef.width);
          let height = width / ratio;
          
          if(imageRef.height < height){
            height = imageRef.height;
            width = ratio * height;
            }
            cropConfig.width = width;
            cropConfig.height = height;
            cropConfig.x = 0;
            cropConfig.y = 0;
        }}
        onComplete={(cropConfig: any) => cropImage(cropConfig)}
        onChange={(cropConfig: any) => setCropConfig(cropConfig)}
        crossorigin="anonymous" // to avoid CORS-related problems
      />

      <br></br>
      <Button
        style={{ marginRight: 10 }}
        type="ghost"
        onClick={() => {
          Cancel();
        }}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={() => {
          onSave(croppedImage);
          Cancel();
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default ImageCropper;
