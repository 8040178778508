import React from 'react';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

const styles = {
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 13,
    fontWeight: 900,
    color: '#6755CE',
    cursor: 'pointer',
  },
};

const ViewSelector = ({ isListView, onChange }: Props) => (
  <Dropdown
    trigger={['click']}
    overlay={(
      <Menu>
        <Menu.Item onClick={() => onChange(!isListView)}>
          {`${isListView ? 'Box view' : 'List view'}`}
        </Menu.Item>
      </Menu>
    )}
  >
    <div style={styles.text}>
      {`${isListView ? 'List view' : 'Box view'}`}
      {' '}
      <CaretDownOutlined />
    </div>
  </Dropdown>
);

/* eslint-disable no-unused-vars */
interface Props {
  isListView: boolean;
  onChange: (value: boolean) => void;
}

export default ViewSelector;
