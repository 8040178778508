import React from "react";
import styles from "./Roles.module.css";

interface Props {
  icon: any;
  name: string;
  roleId: any;
  onClick: (id: any) => void
  selectedRoleId:any
}

const RoleItem = ({ icon, name, roleId, onClick, selectedRoleId }: Props) => {
  
  return (
    <div className={selectedRoleId !== roleId ? styles.role : styles.selected_role} onClick={()=>{onClick(roleId)}}>
      <div className={styles.role_content_holder}>
        <div
          className={styles.role_image}
        >
          <img className={selectedRoleId !== roleId ?  styles.selected_icon :  styles.not_selected_icon } src={icon}/>
        </div>
        <div className={selectedRoleId !== roleId ? styles.role_text : styles.role_text_selected }>{name}</div>
      </div>
    </div>
  );
};

export default RoleItem;
