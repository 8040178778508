import React, { CSSProperties, useState } from 'react';
import { Select } from 'antd';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const cities = require('../../../../libs/cities/cities.json');

const styles = {
  root: {
    width: '100%',
  },
};

export const LocationSelector = ({
  value, onChange, placeholder, style,
}: Props) => {
  const [list, setList] = useState([]);
  const handleCitySearch = (search: string) => {
    if (search.length > 2) {
      setList(cities.filter((city: any) => city.name
        .toLowerCase()
        .includes(search.toLowerCase())));
    } else {
      setList([]);
    }
  };

  const cityFilterOption = (input: string, option: any) => option.props.children
    .toLowerCase()
    .includes(input.toLowerCase());

  return (
    <Select
      onSearch={handleCitySearch}
      filterOption={cityFilterOption}
      showSearch
      allowClear
      placeholder={placeholder}
      showArrow={false}
      defaultActiveFirstOption={false}
      style={{ ...styles.root, ...style }}
      value={value}
      onChange={onChange}
    >
      {
        list.map((city: any) => (
          <Select.Option
            key={`${city.name}, ${city.country}`}
            value={JSON.stringify(city)}
          >
            {`${city.name}, ${city.country}`}
          </Select.Option>
        ))
      }
    </Select>
  );
};

interface Props {
  value: string;
  onChange: any;
  placeholder?: string;
  style?: CSSProperties;
}

LocationSelector.defaultProps = {
  placeholder: 'Type the location...',
  style: {},
};
