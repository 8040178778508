// import "./Dashboard.css";
import React, { useState, useEffect } from "react";
interface Props {
  data: any
 }

const  MileStones = () => {

  const openStartup = () => {
    const win = window.open(`/startups`, "_blank");
    win.focus();
  }
  const openCreateNewStartup = () => {
    const win = window.open(`/new-startup`, "_blank");
    win.focus();
  }
  return (
    <div className="milestones-card">
        <div className="dashboard-card-title">Your milestones</div>
        <div className="milestones-container">
            <div className="milestone-first-startup">
                <div className="milestone-text"><img src="/images/ion_rocket.svg"/>Create your startup page!</div>
                <div className="milestone-getstarted">
                  <span onClick={openCreateNewStartup} >Get started
                    <img src="/images/user_info_icons/next.svg"/>
                  </span>
                </div>
                
            </div>
            <div className="milestone-first-idea">
                <div className="milestone-text"><img src="/images/clarity_lightbulb.svg"/>Give feedback for startups!</div>
                <div className="milestone-getstarted">
                  <span onClick={openStartup} >Get started 
                    <img src="/images/user_info_icons/next.svg"/>
                  </span>
                </div>
                
            </div>
        </div>
    </div>
  );
};
export default MileStones;
