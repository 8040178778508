import React, { CSSProperties } from "react";
import { Input } from "antd";
import { FormikErrors } from "formik";

const styles = {
  root: { width: "100%" },
  errorBox: { marginTop: 2, minHeight: 28 , marginBottom: 5},
  errorText: {
    fontSize: 12,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#F5222D",
  },
};

const FormikTextArea = ({
  name,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  style,
  disabled,
  autoSize,
  helper,
  maxLength,
  prefix,
  className
}: Props) => (
  <div>
    <Input.TextArea
      className={className}
      prefix={prefix}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{ ...styles.root, ...style }}
      placeholder={placeholder}
      disabled={disabled}
      autoSize={autoSize}
      maxLength={maxLength}
    />
    <div className="area-subscription-required">{helper}</div>
    {/* {error && <div style={styles.errorBox}>
      <div style={styles.errorText}>{error}</div>
    </div>} */}
    <div style={styles.errorBox}>
      <div style={styles.errorText}>{error}</div>
    </div>
  </div>
);

interface Props {
  name: string;
  value: string;
  onChange: any;
  onBlur: any;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  autoSize?: { minRows?: number; maxRows?: number } | boolean;
  helper?: string;
  maxLength?: number;
  prefix?: any;
  className?: string;
}

FormikTextArea.defaultProps = {
  error: "",
  placeholder: "",
  style: {},
  disabled: false,
  autoSize: { minRows: 10, maxRows: 16 },
};

export default FormikTextArea;
