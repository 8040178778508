import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { IUser } from "../Competition/Competition";
import "../Competition/Competition.css";
import "./OwnerBox.css";

const OwnerBox = ({ owner, user, createdAt, ownerBoxTitle, organizer, hideTitle }: Props) => {
  const sessionUser = sessionStorage.getItem('user');
  const sessionUserId = sessionUser ? JSON.parse(sessionUser).id : null;
  return (
    <div className="ownerBox">
      {!hideTitle ? <h3>{ownerBoxTitle ? ownerBoxTitle : 'Owner'}</h3> : ""}
      <div className="userBox">
        {!owner.isDeleted ? <Link to={owner.url}>
          <div className="owner-profile-img" style={{
            backgroundImage: `url(${owner.logo || "/images/user.svg"} )`,
          }}>
          </div>
        </Link>
          :<div className="owner-profile-img" style={{
            backgroundImage: `url(${owner.logo || "/images/user.svg"} )`,
          }}>
          </div>
        }
        <div>
          {!owner.isDeleted ? <Link to={owner.url}>
            <div className="userName">{owner.name ? owner.name : owner.firstName + " " + owner.lastName}</div>
          </Link>
            :<div className="userName">{owner.name}</div>
          }
          <div className="userRol">
            {owner.role && owner.role.name}
            {!!createdAt && ` - ${moment(createdAt).fromNow()}`}
          </div>
        </div>

      </div>
    </div>
  )
};

interface Props {
  user?: IUser;
  createdAt?: string;
  style?: CSSProperties;
  ownerBoxTitle?: string;
  organizer?: any;
  hideTitle?: boolean;
  owner?: any;
}

OwnerBox.defaultProps = {
  createdAt: null,
  style: {},
};

export default OwnerBox;
