import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IFeed } from './Competition/components/Feed/Feed';
import { get } from '../../libs/utils/request';
import { Loading } from '../common/Loading';
import NewsCard from '../NewsFeed/components/NewsCard/NewsCard';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
    marginTop: 60,
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center' as const,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
};

const shareLinkBase = `${window.location.protocol}//${window.location.host}`;
const NewsCardPage = () => {
  const [news, setNews] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { tag, id } = useParams();

  useEffect(() => {
    (async () => {
      setErrorMessage('');
      setLoading(true);
      try {
        const tmp = await get<IFeed>(
          `competitions/${tag}/feed/${id}`,
        );
        if (tmp) {
          setNews(tmp);
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [id, tag]);

  const getNews = async (withLoading = true) => {
    setErrorMessage('');
    if (withLoading) {
      setLoading(true);
    }
    try {
      const tmp = await get<IFeed>(
        `competitions/${tag}/feed/${id}`,
      );
      if (tmp) {
        setNews(tmp);
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  if (errorMessage) {
    return (
      <>
        <div style={styles.errorText}>Failed to load competitions:</div>
        <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
          {errorMessage}
        </div>
        <div
          style={styles.errorButtonText}
          onClick={() => getNews()}
          aria-hidden='true'
        >
          retry
        </div>
      </>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={styles.root}>
      <NewsCard
        news={news}
        onChange={() => getNews(false)}
        isTop
        isBottom
        style={{ minWidth: 600 }}
        withMenu={false}
        shareLink={`${shareLinkBase}/competitions/${tag}/feed/${news.uniqueTag}`}
      />
    </div>
  );
};

export default NewsCardPage;
