import React, { useState, useEffect} from "react";
import {get } from "../app/libs/utils/request";



const UserContext = React.createContext({
    user: undefined,
});

export const UserContextProvider = (props) => {
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        getUser()
    }, []);


    const getUser = async () => {
        if (sessionStorage.getItem('user')) {
            setUser(JSON.parse(sessionStorage.getItem('user')))
        } else {
            if (localStorage.getItem('token')) {
                await get('/me').then((response) => {
                    if (response) {
                        setUser(response);
                        sessionStorage.setItem('user', JSON.stringify(response))
                    }
                }).catch((e)=>{
                    console.error(e);
                })
            }
        }
    };
    const contextValue = {
        user: user,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContext;
