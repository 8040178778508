import React, { useState } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Moment } from 'moment';
import { DateTimePicker } from '.';

export const FormDateTimePicker = ({
  form, fieldKey, rules, initialValue, required, requiredMessage, onChange,
}: Props) => {
  const [date, setDate] = useState(initialValue);
  const handleChange = (d: Moment) => {
    setDate(d);
    if (onChange) {
      onChange(d);
    }
  };
  return (
    <Form.Item>
      {form.getFieldDecorator(fieldKey, {
        rules: [...rules, { required, message: requiredMessage }],
        initialValue,
      })(
        <DateTimePicker value={date} onChange={handleChange} required={required} />,
      )}
    </Form.Item>
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  rules?: any[];
  initialValue?: Moment;
  required?: boolean;
  requiredMessage?: string;
  onChange?: (date: Moment) => void;
}

FormDateTimePicker.defaultProps = {
  rules: [],
  initialValue: null,
  required: false,
  requiredMessage: 'This field is required!',
  onChange: null,
};
