import { Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import styles from './NewProfileEditor.module.css';
interface Props {
    selectedElements: any;
    groupElement: any;
    Save: (selectedElements: any) => void;
    Cancel: (visible: boolean) => void;
    type: string;
    length: number;
}

const Chooser = ({ selectedElements, groupElement, Save, Cancel, type, length }: Props) => {
    const [group, setGroup] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedDetails, setSelectedDetails] = useState([])
    const [selectedRemain, setSelectedRemain] = useState(0)
    const [selectedItemId, setSelectedItemId] = useState(null)
    const [selectedSubtitle, setSelectedSubtitle] = useState("")
    const [disabledButton, setDisabledButton] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        if (selectedElements) {
            setSelectedDetails(selectedElements)
        }
        if(groupElement){
            setGroup(groupElement)
        }
    }, [selectedElements. groupElement])

    useEffect(() => {
        if (type === 'interest') {
            if (selectedDetails.length >= length) {
                setDisabledButton(false)
            } else { setDisabledButton(true) }
        } if (type === 'skill') {
            if (selectedDetails.length >= 1 && selectedDetails.length <= 5) {
                setDisabledButton(false)
            } else setDisabledButton(true)
        }
    }, [selectedDetails])
    useEffect(() => {
        if (disabledButton) {
            setError(true)
        } else setError(false)
    }, [disabledButton])

    const addItems = (selected: any) => {
        // if (!selectedDetails.includes(selected)) {
        //     console.log(selectedDetails);
        //     let item = selectedDetails
        //     setSelectedDetails([...selectedDetails, selected])
        //     setSelectedRemain(selectedRemain + 1)
        // }
        const found = selectedDetails.find(element => element.id === selected.id);
        if(found === undefined) {
            let item = selectedDetails
            setSelectedDetails([...item, selected])
            setSelectedRemain(selectedRemain + 1)
        }
        else {
            deleteItems(selected.id)
        }
    }

    const deleteItems = (id: any) => {
        setSelectedDetails(
            selectedDetails.filter((element: any) => (
                element.id !== id
            ))
        )
        setSelectedRemain(selectedRemain - 1)
    }
    return (
        <div>
            <div className={styles.selected_container}>
                {
                    selectedDetails &&
                    <div className={styles.selected_holder}>
                        {selectedDetails.map((selectedDetail: any) => (
                            <div className={styles.selected_item}>
                                <div>{selectedDetail.name}</div>
                                <div onClick={() => { deleteItems(selectedDetail.id) }}>
                                    <img
                                        className={styles.close_icon}
                                        src={'/images/close_black.svg'}
                                        alt=''
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                }

            </div>
            <div className={styles.select_items_container}>
                <div className="main-category">
                    <div className="title-category">Main topics</div>
                    <hr className="registration_hr" />
                    <div className="main-category-holder">
                        {group.map((groupElem: any) => (
                            <div
                                key={groupElem.id}
                                className={
                                    selectedItemId === groupElem.id
                                        ? "main-category-holder-selected"
                                        : "main-category-item"
                                }
                                onClick={() => {
                                    setSelectedCategory(groupElem.interests ? groupElem.interests : groupElem.skills),
                                        setSelectedItemId(groupElem.id),
                                        setSelectedSubtitle(groupElem.name);
                                }}
                            >
                                {groupElem.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.details_category}>
                    <div className="title-details">{selectedSubtitle}</div>
                    {selectedCategory && (
                        <div className={styles.details_select}>
                            {selectedCategory.map((selected: any) => (
                                <Checkbox
                                    key={selected.id}
                                    checked={selectedDetails.some(
                                        (item: any) => item.id == selected.id
                                    )}
                                    className="check-select"
                                    onChange={() => {
                                        addItems(selected);
                                    }}
                                >
                                    {selected.name}
                                </Checkbox>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {error &&
                <div>
                    {type === 'interest' && <span className={styles.error}>You have to select at least {length} interest</span>}
                    {type === 'skill' && <span className={styles.error}>You can select up to 5 skills.</span>}
                </div>
            }
            <hr className={styles.hr}/>
            <div className={styles.button_holder}>
                <Button
                    type='default'
                    className={styles.cancel_button}
                    onClick={() => {
                        Cancel(false)
                        setSelectedCategory([])
                    }
                    }>Cancel</Button>

                <Button
                    type='primary'
                    className={styles.primary_button}
                    disabled={disabledButton}
                    onClick={() => {
                        Save(selectedDetails);
                        Cancel(false);
                        setSelectedCategory([])
                    }
                    }>Save</Button>
            </div>
        </div>
    );
};

export default Chooser;
