import React from "react";
import { textOverflow } from "../../../libs/utils/textUtils";

interface Props {
  data: any;
}

const LatestOrganization = ({ data }: Props) => {
  const openView = (uniqueTag: string) => {
    const win = window.open(`/organizations/${uniqueTag}`, "_blank");
    win.focus();
  };
  const openAllOrganization = () => {
    const win = window.open(`/organizations`, "_blank");
    win.focus();
  };

  return (
    <div className="latest-startups-card">
      <div className="card-title-container">
        <div className="dashboard-card-title">Latest organizations</div>
        <a onClick={openAllOrganization} className="seeall">
          See all
        </a>
      </div>
      {data.map((elem: any) => {
        return (
          <div className="latest-box">
            <div className="latest-icon-name-interes">
              <div className="logo">
                <img
                  className="idea-default-logo"
                  src={elem.logo ? elem.logo : "/images/onePagerLogo.png"}
                />
              </div>
              <div className="latest-content">
                <div className="startup-name-box">
                  <div className="startup-name">
                  {textOverflow(elem.name,13)}
                  </div>
                  <div>
                    {elem.isPartner ? (
                      <div className="role-item">Pozi-io partner</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div onClick={() => openView(elem.tag)} className="view-text-box">
              <div className="view-text">View</div>
              <div>
                <img src="/images/user_info_icons/next.svg" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LatestOrganization;
