import React from 'react';
import { IPartner } from '../../../Competition';
import { getBaseUrl } from '../../../../../../libs/utils/request';

const styles = {
  root: {
    boxShadow: '3px 3px 14px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF',
    width: 214,
    height: 120,
    borderRadius: 3,
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  partnerName: {
    fontSize: 14,
    fontWeight: 900,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: '#282828',
    textAlign: 'center' as const,
    marginTop: 10,
    maxWidth: 214,
    overflowWrap: 'break-word' as const,
    wordWrap: 'break-word' as const,
    wordBreak: 'break-word' as const,
  },
};

const imageLinkBase = `${getBaseUrl()}/image`;
const PartnerCard = ({ partner }: Props) => (
  <div>
    <div
      className='zoom'
      aria-hidden='true'
      style={{
        ...styles.root,
        cursor: partner.partnerUrl ? 'pointer' : 'auto',
      }}
      onClick={() => {
        if (partner?.partnerUrl) {
          window.open(partner.partnerUrl, '_blank');
        }
      }}
    >
      <div style={{ ...styles.image, backgroundImage: `url(${imageLinkBase}/${partner.image.filename})` }} />
    </div>
    <div style={styles.partnerName}>{partner.name}</div>
  </div>
);

interface Props {
  partner: IPartner;
}

export default PartnerCard;
