import React from 'react';
import { IPartner } from '../../../Competition';
import PartnerCard from './PartnerCard';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '30px 30px',
  },
};

const PartnerList = ({ partners }: Props) => (
  <div style={styles.root}>
    {partners.map((partner) => (
      <PartnerCard key={partner.id} partner={partner} />
    ))}
  </div>
);

interface Props {
  partners: IPartner[];
}

export default PartnerList;
