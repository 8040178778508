import React , { useEffect, useState } from 'react';
import DraftsCard from '../Competitions/DraftsCard';
import { get } from '../../libs/utils/request';

interface Props{
  title?: string;
}



const StartupDraft = ({title}:Props) => {
  const [drafts, setDrafts] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    (async () => {
        const draftsTmp = await get<{ drafts: any[] }>('projects/draft/all');
        setDrafts(draftsTmp.drafts);
     
    })();
  }, []);


  return (
   <div className="startup-draft">
         {!!drafts.length && ( 
          <DraftsCard type={'startup'} drafts={drafts} collapsed={collapsed} setCollapsed={setCollapsed} title={title}/>
         )} 

   </div>
  );
};



export default StartupDraft;
