import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { get } from "../../libs/utils/request";
import { ArticleCard } from "../CardView/ProjectCard";

const MoreArticles = () => {
  const [isLoading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { tag } = useParams();
  const history = useHistory();

  useEffect(() => {
    getArticles();
  },[]);

  const getArticles = async (withLoading = true) => {
    setErrorMessage("");
    if (withLoading) {
      setLoading(true);
    }
    try {
      const tmp:any = await get(`/blog?limit=3&tag=${tag}`);
      setArticles(tmp.data);
    } catch (error) {
      if (error.response.status === 403 && error.response.data.extendedUser) {
        sessionStorage.setItem(
          "user",
          JSON.stringify(error.response.data.extendedUser)
        );
        if (
          !error.response.data.extendedUser.active &&
          !error.response.data.extendedUser.trial
        ) {
          window.location.replace("/trial-ended");
        }
      } else if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="startup-box-view other-articles" style={articles.length == 1 ? {width:"50%"} : {width:"100%"}}>
      {articles.length ? articles.map((article: any) => (
        <ArticleCard
          key={article.tag}
          article={article}
          onChange={() => getArticles()}
          onClick={() => {
            history.push(`/blog/${article.tag}`);
          }}
        />
      ))
    :
    <div>There are no articles yet</div>}
    </div>
  );
};

export default MoreArticles;
