import React, { CSSProperties, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from '@material-ui/core';
import { message, Switch } from 'antd';
import BorderCard from '../../../../../common/BorderCard';
import ItemWithIcon from '../../ItemWithIcon';
import './JuryMembersCard.css';
import { IJury } from '../Evaluation';
import { post } from '../../../../../../libs/utils/request';

const baseText = {
  fontSize: 16,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#282828',
};
const styles = {
  header: {
    padding: 10,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    alignItems: 'center',
    gap: '10px 20px',
  },
  headerTitle: {
    ...baseText,
    fontSize: 18,
    fontWeight: 900,
  },
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
  body: {
    width: '100%',
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'column' as const,
  },
  juryContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
  safeText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis',
    textAlign: 'center' as const,
  },
};

const JuryMembersCard = ({
  tag, juries, onChange, style,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [loadingSwitch, setLoadingSwitch] = useState(null);

  const handleSwitchChange = async (id: string, value: boolean) => {
    setLoadingSwitch(id);
    try {
      await post(`/competitions/${tag}/evaluation/juries/${id}/qualify`, { accept: value });
      onChange();
    } catch (error) {
      message.error('Failed to edit jury members.');
    } finally {
      setLoadingSwitch(null);
    }
  };

  return (
    <BorderCard isTop isBottom onClick={() => setCollapsed(!collapsed)} style={style}>
      <div style={styles.header}>
        <CaretRightOutlined
          rotate={collapsed ? 90 : 0}
          style={{ ...styles.arrowIcon, color: collapsed ? '#6755CE' : '#898989' }}
        />
        <div style={styles.headerTitle}>Your jury members</div>
      </div>
      <Collapse in={collapsed} timeout='auto' unmountOnExit>
        <div style={styles.body}>
          {juries.map((item, i) => (
            <BorderCard key={item.id} isTop={!i} isBottom={i === juries.length - 1}>
              <div style={styles.juryContainer}>
                <div style={{ width: 200 }}>
                  <ItemWithIcon
                    text={`${item.lastName} ${item.firstName}`}
                    icon={item.profileImage}
                    link={item.firstName !== 'Deleted User' ? `/entrepreneurs/${item.uniqueTag}` : ``}
                    textStyle={styles.safeText}
                  />
                </div>
                <div onClick={(e) => e.stopPropagation()} aria-hidden='true'>
                  <Switch
                    checked={item.status.isAccepted}
                    checkedChildren='Included'
                    unCheckedChildren='Excluded'
                    onChange={(e) => {
                      handleSwitchChange(item.id, e);
                    }}
                    loading={loadingSwitch === item.id}
                  />
                </div>
              </div>
            </BorderCard>
          ))}
        </div>
      </Collapse>
    </BorderCard>
  );
};

interface Props {
  tag: string,
  juries: IJury[],
  onChange: () => void,
  style?: CSSProperties;
}

JuryMembersCard.defaultProps = {
  style: {},
};

export default JuryMembersCard;
