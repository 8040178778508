import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col, Form, message, Row, 
} from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useHistory, Prompt, useParams } from 'react-router';
import { HtmlEditor, Toolbar, Editor } from '@aeaton/react-prosemirror';
import { plugins, toolbar } from '@aeaton/react-prosemirror-config-default';
import { Helmet } from 'react-helmet';
import {
  deleteReq, get, getBaseUrl, patch, post,
} from '../../libs/utils/request';
import FormInterests from '../common/form/FormInterests';
import User from '../../user/User';
import { Loading } from '../common/Loading';
import ConfirmationDialog from '../common/ConfirmationDialog';
import FormTextArea from '../common/form/FormTextArea';
import { isAdmin, user } from '../../libs/utils/user';
import { IArticle } from './Article';
import Cover from '../Competitions/components/Cover';
import FormLabel from '../Competitions/components/FormLabel';
import FormInput from '../common/form/FormInput';
import Card from '../common/card/Card';
import ImageToTextEditor from './ImageToTextEditor';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 100,
  },
  content: { width: 1100 },
  durationLabels: {
    marginTop: 0,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '20px',
  },
  inputLabel: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    color: '#282828',
  },
  googleLogo: {
    maxHeight: 12,
  },
  checkboxText: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 15,
    color: '#282828',
  },
  formContent: {
    maxWidth: 1000,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  inputText: {
    fontSize: 16,
    color: '#282828',
  },
  submitContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    marginTop: 100,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '20px 20px',
  },
  submitText: {
    marginTop: 100,
    marginBottom: 40,
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: 36,
    fontStyle: 'normal',
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  locationLabelContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 10,
    gap: '10px 10px',
  },
  descriptionContainer: {
    border: '1px solid #262626', borderRadius: 4, padding: 8, width: '100%', minHeight: 400,
  },
  rootTitle: {
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 10,
    paddingRight: 40,
    paddingLeft: 40,
    maxWidth: 594,
    marginLeft: 47,
    marginTop: -71,
    position: 'relative' as const,
  },
  contentTitle: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '29px',
    marginBottom: 13,
  },
  input: {
    fontWeight: 900,
    fontSize: 16,
    color: '#282828',
  },
};

const imageLinkBase = `${getBaseUrl()}/image`;

const NewArticle = ({ form, isEdit, isDraft, isCreating }: Props) => {
  const [coverImage, setCoverImage] = useState('');
  const [interestGroups, setInterestGroups] = useState([]);
  const [navigationBlocked, setNavigationBlocked] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [owner, setOwner] = useState(isCreating ? [JSON.parse(sessionStorage.getItem('user'))]: undefined)


  const [themes, setThemes] = useState([]);

  const [article, setArticle] = useState(null);
  const [createdAt, setCreatedAt] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const [description, setDescription] = useState('');

  const { tag, draftId } = useParams();
  const history = useHistory();

  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    window.onbeforeunload = navigationBlocked ? () => true : undefined;
    return () => {
      // eslint-disable-next-line no-undefined
      window.onbeforeunload = undefined;
    };
    
  }, [navigationBlocked]);

  useEffect(() => {
    (async () => {
      try {
        setErrorMessage('');
        setLoading(true);
        const regData = await get<any>('/register/init');
        if (Array.isArray(regData.interestGroups)) {
          setInterestGroups(regData.interestGroups);
        }
        let ownerArray =[]
        if(!isEdit){
          ownerArray.push(JSON.parse(sessionStorage.getItem('user')))
          setOwner(ownerArray)
        }
        if (isEdit) {
          let art: IArticle;
          if (!isDraft) {
            art = await get<IArticle>(`/blog/${tag}`);
          } else {
            art = await get<IArticle>(`/blog/draft/all/${draftId}`);
          }
          ownerArray.push(art.createdBy)

          setArticle(art);
          setCreatedAt(art.createdAt);
          setCoverImage(art.image);
          setOwner(ownerArray)
          setThemes(art.interests ? art.interests
            .map((item:any) => ({ id: item.id, name: item.name })) : []);
          // setEditorState(getEditorStateByHtml(comp.description));
          setDescription(art.description);
            setShortDescription(art.shortDescription);
          form.setFieldsValue({
            title: art.title,
            eventTag: art.tag,
            subTitle: art.subTitle ? art.subTitle : '',
            description: art.description,
            shortDescription: art.shortDescription,
            interests: art.interests.map((item:any) => ({ id: item.id, name: item.name })),
          });
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
        if(error?.response?.status === 404){
          setNavigationBlocked(false)
          message.error('We are sorry. This content might be deleted by the owners.', 5)
          history.push('/blog')
        }
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, tag]);

  const changeCover = (newImage: string) => {
    const url: string = coverImage;
    setCoverImage(newImage);
    if (url && !isEdit) {
      post('/image/delete', { url });
    }
  };

  const deleteArticle = async () => {
    try {
      if (isDraft) {
        await deleteReq(`/blog/draft/all/${draftId}`);
      } else {
        await deleteReq(`/blog/${tag}`);
      }
      history.replace('/blog');
    } catch (error) {
      message.error('Failed to delete blog.');
    }
  };

  const submitForm = () => {
    form.validateFields((err, values) => {
      if (err) {
        console.log(err)
        let error = Object.values(err).sort();
        document.getElementById('errors').innerHTML = "";
        document.getElementById('errors').innerHTML = "<h1>Please check these fields</h1>";
        error.map((errorMsg: any) => {
          document.getElementById('errors').append(errorMsg.errors[0].field.replace(/([a-z](?=[A-Z]))/g, '$1 ').replace(/_/g, " ").replace("name", "description").toLowerCase().replace(/([0-9])/gi, function (x: string) {
            return parseInt(x) + 1;
          }), "\n");
        })

      }
      let formattedDescription = "";
      let descContainer = document.querySelector(".ql-editor")
      const collection = descContainer.children;
        for (let i = 0; i < collection.length; i++) {
          formattedDescription += collection[i].outerHTML;
        }

      if(description.includes("span")){
        formattedDescription = description.replace(/span style="text-decoration:underline"/g, "u").replace(/span/g, 'u')
      }
      if(description.includes("<a href")){
        formattedDescription = description.replace(/<a href/g, "<a target='_blank' href")
      }

      if (!err) {
        
        const dto: any = {
          title: values.title,
          // country: location.country,
          // city: location.name,
          shortDescription: values.shortDescription,
          createdBy: article?.createdBy ? article.createdBy.id : JSON.parse(userFromStorage).id,
          subTitle: values.subTitle,
          description: formattedDescription,
          interests: values.interests.map((item: { id: string, name: string }) => item.id),
          image: coverImage,
        };
        if (isDraft) {
          dto.draftId = draftId;
        }
        setNavigationBlocked(false);
        setSubmitting(true);
        (async () => {
          setTimeout(async () => {
            try {
              if (isEdit) {
                if (isDraft) {
                  if(isAdmin()){
                    await post('/blog/create', { tag: values.eventTag, ...dto });
                    history.replace('/blog');
                  }
                  else{
                    message.error("We are sorry. You don't have permisson to create an article.", 5)
                    // await post('/blog/create', { tag: values.eventTag, ...dto });
                    // history.replace('/blog');
                  }
                  
                } else {
                  await patch(`/blog/${tag}`, { id: article.id, ...dto });
                  history.replace(`/blog/${tag}`);
                }
              } else {
                if(isAdmin()){
                  await post('/blog/create', { tag: values.eventTag, ...dto });
                }
                else{
                  message.error("We are sorry. You don't have permisson to create an article.", 5)
                  // await post('/competitions/create', { tag: values.eventTag, ...dto });
                }
                setSubmitting(false)

                history.replace('/blog');
              }
            } catch (error) {
              if (error.response?.data?.name === 'ValidationError' && Array.isArray(error.response?.data?.details)) {
                const msg = Object.values(error.response.data.details[0]).join(', ');
                setSubmitError(`Validation failed: ${msg}.`);
              }if (error.response?.data?.message) {
                setSubmitError(error.response.data.message);
              }else if(error.response?.data?.details[0].description){
                setSubmitError(`Validation failed: Descripton length must be greater than 300 and less than or equal to 5000 characters long.`);
                message.error(`Validation failed: Descripton length must be greater than 300 and less than or equal to 5000 characters long`)
              }
               else {
                setSubmitError(error.message);
              }
              setNavigationBlocked(false);
              setSubmitting(false);
              
            }
            finally{
              setSubmitting(false)
            }
          }, 300);
        })();
      }
    });
  };

  const saveDraft = (values: any) => {
    const { title } = values;
    if (!title || title.length < 3 || title.length > 100) {
      message.error('Please insert a valid title.');
    }
    else {
      let formattedDescription = "";
      let descContainer = document.querySelector(".ql-editor")
      const collection = descContainer.children;
        for (let i = 0; i < collection.length; i++) {
          formattedDescription += collection[i].outerHTML;
        }
      
      // const acceptType= competition.acceptType;
      const formatUsers = (list: User[]): string[] => list.map((item) => item.id);
      const dto = {
        title: values.title,
        createdBy: formatUsers(owner),
        subTitle: values.subTitle,
        shortDescription: values.shortDescription,
        description: description.includes("span") ? description.replace(/span style="text-decoration:underline"/g, "u").replace(/span/g, 'u') : formattedDescription,
        interests: values.interests.map((item: { id: string, name: string }) => item.id),
        image: coverImage,
      };

      setNavigationBlocked(false);
      setSubmitting(true);
      (async () => {
        setTimeout(async () => {
          try {
            if(isAdmin()){
              if (isEdit) {
                await patch(`/blog/draft/all/${draftId}`, { draftId, tag: values.eventTag, ...dto });
                history.replace('/blog');
              } else {
                await post('/blog/draft', { tag: values.eventTag, ...dto });
                history.replace('/blog');
              }}else{
                message.error("We are sorry. You don't have permisson to create an article draft.", 5)
                history.replace('/blog');
              }
          } catch (error) {
            if (error.response?.data?.name === 'ValidationError' && Array.isArray(error.response?.data?.details)) {
              const msg = Object.values(error.response.data.details[0]).join(', ');
              setSubmitError(`Validation failed: ${msg}.`);
            } else if (error.response?.data?.message) {
              setSubmitError(error.response.data.message);
            } else {
              setSubmitError(error.message);
            }
            setNavigationBlocked(false);
            setSubmitting(false);
          }
        }, 300);
      })();
    }
  };

  useEffect(()=>{
    console.log(description)
  })

  const codeBlock = document.getElementsByClassName('fa-code');
  for (let i = 0; i < codeBlock.length; i++) {
    codeBlock[i].parentElement.style.display = "none"
  }
  
  return (
    <div style={styles.root}>
      <Helmet>
        <title>{isEdit ? "Edit article":"Create article"}  | pozi.io</title>
      </Helmet>
      <Prompt
        when={navigationBlocked}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      {isLoading && <Loading />}
      {errorMessage && (
        <>
          <div style={styles.errorText}>Failed to load article:</div>
          <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
            {errorMessage}
          </div>
        </>
      )}
      <div style={{ ...styles.content, display: isLoading ? 'none' : 'block' }}>
        <Cover url={coverImage} onChange={changeCover} />
        <Card style={styles.rootTitle}>
          <div style={styles.contentTitle}>
            <div style={styles.title}>Choose a cool title for your article!</div>
            <FormInput
              form={form}
              fieldKey='title'
              placeholder='Title (required)'
              style={styles.input}
              required
              rules={[
                { min: 3, message: 'Title must be at least 3 characters.' },
                { max: 100, message: 'Title cannot be longer than 100 characters.' },
              ]}
              initialValue=''
            />
          </div>
        </Card>

        <div style={styles.formContent}>
        <Row>
            <FormLabel title='Subtitle' subtitle=''/>
            <Col xs={24} sm={24} md={24}>
              <FormInput
                form={form}
                fieldKey='subTitle'
                rules={[
                  { min: 3, message: 'Subtitle must be at least 3 characters.' },
                  { max: 100, message: 'Subtitle cannot be longer than 100 characters.' },
                ]}
                required
              />
            </Col>
          </Row>
          <Row>
            <FormLabel title='Lead' subtitle='(Short description cannot be longer than 200 characters)'/>
            <Col xs={24} sm={24} md={24}>
              <FormTextArea
                form={form}
                fieldKey='shortDescription'
                rules={[
                  { min: 0, message: '' },
                  { max: 200, message: 'Subtitle cannot be longer than 200 characters.' },
                ]}
                autoSize={{ minRows: 4 }}
              />
            </Col>
          </Row>

          <FormLabel title='Description'  subtitle=''/>
          {/* {!!description && ( */}
            <div className='description-preview' style={styles.descriptionContainer}>
              {/* <HtmlEditor
                schema={schema}
                plugins={exampleSetup({ schema }).concat(placeholderPlugin)}
                value={description}
                handleChange={setDescription}
                debounce={300}
              >
                <Toolbar toolbar={toolbar} />
                <Editor autoFocus />
              </HtmlEditor> */}
              {console.log(description)}
              <ImageToTextEditor value = {description}/>
            </div>
          {/* )} */}

          <FormLabel title='Themes of your article' subtitle='(Choose 5 or less options)' style={{ marginBottom: 25, marginTop: 76 }} />
          <FormInterests form={form} fieldKey='interests' interestGroups={interestGroups} initialValue={themes} />

          <div id="errors" style={{ whiteSpace: "break-spaces", color: "red", textAlign: "center" }}>

          </div>
          <div style={styles.submitContainer}>
            {!isEdit && (
              <div style={styles.submitText}>Are you ready?</div>
            )}
            <div style={styles.btnContainer}>
              {(isDraft || (!isEdit && !isDraft)) && (
                <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='primary' size='large' onClick={() => saveDraft(form.getFieldsValue())}>
                  Save draft
                </Button>
              )}
              <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='primary' size='large' onClick={submitForm}>
                {isDraft || (!isEdit && !isDraft) ? 'Publish' : 'Save changes'}
              </Button>
              {isEdit && (
                <Button style={{ fontWeight: 'bold', minWidth: 150 }} loading={isSubmitting} type='danger' size='large' onClick={() => setDeleteDialogVisible(true)}>
                  Delete article
                </Button>
              )}
            </div>
          </div>
          {submitError && (
            <Alert
              style={{ marginTop: 32 }}
              message='Failed to submit form'
              description={submitError}
              type='error'
              showIcon
            />
          )}
        </div>
      </div>
      <ConfirmationDialog
        visible={deleteDialogVisible}
        onOk={() => {
          setNavigationBlocked(false);
          setDeleteDialogVisible(false);
          deleteArticle();
        }}
        onCancel={() => setDeleteDialogVisible(false)}
        title='Delete article'
        description='Are you sure you want to delete article? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />
      
    </div>
  );
};

interface Props {
  form: WrappedFormUtils;
  isEdit?: boolean;
  isDraft?: boolean;
  isCreating?: boolean;
}

NewArticle.defaultProps = {
  isEdit: false,
  isDraft: false,
};

export default Form.create<Props>({ name: 'blog' })(NewArticle);
