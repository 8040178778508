import React, { ReactElement, useState, useRef, useEffect } from "react";
import { Button, Modal, Checkbox, Steps } from "antd";
import { get, patch } from "../../../libs/utils/request";
import { useHistory, useLocation } from "react-router";
import "../NewRegistration.css";
import RegistrationButtons from "./RegistrationButtons";
import { ga } from "react-ga";

interface Props {
  roles?: any;
  interests: any;
  onClick?: (elem: any) => void;
  next: () => any;
  back: () => any;
  submit: (details: any) => any;
  step?: string;
}

const NewInterests = ({ interests, next, back, submit }: Props) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedSubtitle, setSelectedSubtitle] = useState("");

  useEffect(()=>{
    ga('send', { 'hitType': 'pageview', 'page': 'registration/interests', 'title': 'Registration interests' });
  },[])

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
  }, [interests]);

  useEffect(() => {
    if (user && user.interestGroups?.length > 0) {
      setSelectedDetails(user.interests);
    }
  }, [next]);

  const addItems = (selected: any) => {
    if (
      !selectedDetails.find(function (item) {
        return item.id == selected.id;
      })
    ) {
      let item = selectedDetails;
      setSelectedDetails([...item, selected]);
    } else {
      deleteItems(selected.id);
    }
  };

  const deleteItems = (id: any) => {
    setSelectedDetails(selectedDetails.filter((element) => element.id !== id));
  };

  const onSubmit = async () => {
    const userDetails = {
      interests: selectedDetails,
    };
    submit(userDetails);
  };

  return (
    <div className="define-container">
      <div className="selected-items">
        {selectedDetails && (
          <div className="selected-item">
            {selectedDetails.map((selectedDetail: any) => (
              <div className="detail-item">
                <div>{selectedDetail.name}</div>
                <div
                  onClick={() => {
                    deleteItems(selectedDetail.id);
                  }}
                >
                  <img
                    className="close-icon"
                    src={"/images/close_black.svg"}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="select-items-container">
        <div className="main-category">
          <div className="title-category">Main topics</div>
          <hr className="registration_hr" />
          <div className="main-category-holder">
            {interests.map((interest: any) => (
              <div
                key={interest.id}
                className={
                  selectedItemId === interest.id
                    ? "main-category-holder-selected"
                    : "main-category-item"
                }
                onClick={() => {
                  setSelectedCategory(interest.interests),
                    setSelectedItemId(interest.id),
                    setSelectedSubtitle(interest.name);
                }}
              >
                {interest.name}
              </div>
            ))}
          </div>
        </div>
        <div className="details-category">
          <div className="title-details">{selectedSubtitle}</div>
          {selectedCategory && (
            <div className="details-select">
              {selectedCategory.map((selected: any) => (
                <Checkbox
                  key={selected.id}
                  checked={selectedDetails.some(
                    (item) => item.id == selected.id
                  )}
                  className="check-select"
                  onChange={() => {
                    addItems(selected);
                  }}
                >
                  {selected.name}
                </Checkbox>
              ))}
            </div>
          )}
        </div>
      </div>
      {selectedDetails.length > 0 && selectedDetails.length < 3 && (
        <div className="errors-frombe-onepager">
          Please select at least 3 interests
        </div>
      )}
      <RegistrationButtons
        roleName="Step4 (Interests)"
        showFinishButton={selectedDetails.length > 2 ? true : false}
        showBackbutton
        showSkipButton={
          selectedDetails.length === 0 || selectedDetails.length > 2
            ? true
            : false
        }
        next={next}
        back={back}
        save={() => {
          onSubmit();
        }}
      />
    </div>
  );
};

interface Props {
  title?: string;
  description?: string | ReactElement;
}

export default NewInterests;
