import React, { CSSProperties } from 'react';

const styles = {
  root: {
    fontSize: 16,
    fontStyle: 'normal',
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis',
    textAlign: 'center' as const,
    width: 60,
    backgroundColor: '#6755CE',
    borderRadius: 5,
    color: '#FFF',
    fontWeight: 900,
    paddingTop: 4,
    paddingBottom: 4,
  },
};

const ScoreCard = ({ value, isActive, style }: Props) => {
  const bgColor = isActive ? '#6755CE' : '#dbdbdb';
  return (
    <div style={{ ...styles.root, backgroundColor: bgColor, ...style }}>
      {value}
    </div>
  );
};

interface Props {
  value: string | number;
  isActive?: boolean;
  style?: CSSProperties;
}

ScoreCard.defaultProps = {
  isActive: true,
  style: {},
};

export default ScoreCard;
