import { DeleteOutlined } from "@ant-design/icons";
import { Field, FormikErrors } from "formik";
import React from "react";
import FormikInput from "../../common/form/FormikInput";

const SocialLinks = ({
  name,
  value,
  dropvalue,
  onChange,
  onBlur,
  error,
  dropName,
  deleteItem,
}: Props) => {
  return (
    <div className="register-social-container">
      <div className="social-dropdown-register">
        <Field
          as="select"
          className="social-dropdown"
          value={dropvalue}
          name={dropName}
        >
          <option value="">Select social media</option>
          <option value="LinkedIn">LinkedIn</option>
          <option value="Facebook">Facebook</option>
          <option value="Twitter">Twitter</option>
          <option value="Reddit">Reddit</option>
          <option value="Instagram">Instagram</option>
          <option value="Behance">Behance</option>
          <option value="GitHub">GitHub</option>
          <option value="Discord">Discord</option>
          <option value="Twitch">Twitch</option>
          <option value="YouTube">YouTube</option>
          <option value="Telegram">Telegram</option>
          <option value="Whatsapp">Whatsapp</option>
        </Field>
      </div>
      <div className="social-link">
        <FormikInput
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder="Type or copy your link here"
          prefix={<img src="/images/user_info_icons/link.svg" alt="" />}
        />
        <DeleteOutlined onClick={deleteItem} className="social-link-trash" />
      </div>
    </div>
  );
};

interface Props {
  deleteItem: () => void;
  type: any;
  name: string;
  dropName: string;
  value: string;
  dropvalue?: string;
  onChange: any;
  onBlur?: any;
  withError?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}

export default SocialLinks;
