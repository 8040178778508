import React, { useEffect, useState } from 'react';
import {
  Button, Form, message, Modal,
} from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import WinnerInputCard from './WinnerInputCard';
import { IAwardType, IProject, IWinner } from '../Winners';
import { post } from '../../../../../../libs/utils/request';
import { Loading } from '../../../../../common/Loading';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog';

const styles = {
  root: { marginLeft: 16, marginRight: 16 },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 24,
    color: '#282828',
    marginBottom: 40,
  },
  noResultText: {
    fontSize: 16,
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    marginTop: 40,
    color: '#282828',
  },
  addBtn: {
    fontWeight: 'bold' as const,
    marginTop: 30,
  },
  actionBtn: {
    fontWeight: 'bold' as const,
  },
  footer: {
    width: '100%',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
  icon: {
    height: 36,
    marginLeft: 30,
  },
};

const isFormValid = (form: WrappedFormUtils, fieldKeys: string[], callback: any) => {
  form.validateFields(fieldKeys, (errors) => {
    callback(!errors);
  });
};

const getFieldKeys = (n: number): string[] => {
  const res = [];
  for (let i = 0; i < n; i += 1) {
    res.push(`winner_applicationId_${i}`, `winner_awardId_${i}`, `winner_description_${i}`);
  }
  return res;
};

const WinnersInputModal = ({
  visible, projectList, awardTypes, onOk, onCancel, form, tag, initialCount, winners,
}: Props) => {
  const [count, setCount] = useState(initialCount);
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);

  useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      setCount(winners.length);
      setTimeout(() => {
        winners.forEach((item, i) => {
          form.setFieldsValue({
            [`winner_applicationId_${i}`]: item.application.id,
            [`winner_awardId_${i}`]: item.awardType.id,
            [`winner_description_${i}`]: item.description,
          });
        });
        setLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winners, visible]);

  const addItem = async () => {
    isFormValid(form, getFieldKeys(count), (result: boolean) => {
      if (result) {
        setCount(count + 1);
      }
    });
  };

  const deleteItem = (index: number) => {
    if (count > 0) {
      for (let i = index; i < count - 1; i += 1) {
        form.setFieldsValue({
          [`winner_applicationId_${i}`]: form.getFieldValue(`winner_applicationId_${i + 1}`),
          [`winner_awardId_${i}`]: form.getFieldValue(`winner_awardId_${i + 1}`),
          [`winner_description_${i}`]: form.getFieldValue(`winner_description_${i + 1}`),
        });
      }
      setCount(count - 1);
    }
  };

  const handleCancel = () => {
    setCancelDialogVisible(true);
  };

  const onSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setSubmitting(true);
        const tmp = [];
        for (let i = 0; i < count; i += 1) {
          tmp.push({
            id: values[`winner_applicationId_${i}`],
            type: values[`winner_awardId_${i}`],
            description: values[`winner_description_${i}`],
          });
        }
        (async () => {
          try {
            await post(`/competitions/${tag}/winners`, tmp);
            message.success('Winners updated');
            onOk();
          } catch (error) {
            setSubmitting(false);
            message.error('Failed to submit winners');
          }
        })();
      }
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      width={700}
      footer={null}
      closable={!isSubmitting}
      bodyStyle={styles.root}
    >
      <div>
        <div style={styles.title}>
          Choose your awarded Startups/Ideas
          <img style={styles.icon} src='../../../../../../images/competitions/award_purple.svg' alt='' />
        </div>
        {isLoading && <Loading />}
        <div style={{ display: isLoading ? 'none' : 'block' }}>
          {!count ? (
            <div style={styles.noResultText}>Nothing to show.</div>
          ) : (
            <>
              {Array.from(Array(count).keys()).map((i) => (
                <WinnerInputCard
                  key={i.toString()}
                  projectList={projectList}
                  awardTypes={awardTypes}
                  form={form}
                  applicationFieldKey={`winner_applicationId_${i}`}
                  awardFieldKey={`winner_awardId_${i}`}
                  descriptionFieldKey={`winner_description_${i}`}
                  onDelete={() => deleteItem(i)}
                  disabled={isSubmitting}
                />
              ))}
            </>
          )}
        </div>
        <Button style={styles.addBtn} type='primary' size='default' onClick={addItem}>Add item</Button>
        <div style={styles.footer}>
          <Button
            style={{ ...styles.actionBtn, color: '#6755CE' }}
            type='link'
            size='large'
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            style={{ ...styles.actionBtn, minWidth: 150 }}
            type='primary'
            size='large'
            onClick={onSubmit}
            loading={isSubmitting}
          >
            Save
          </Button>
        </div>
      </div>
      <ConfirmationDialog
        onOk={() => {
          setCancelDialogVisible(false);
          onCancel();
        }}
        onCancel={() => setCancelDialogVisible(false)}
        title='Cancel edition'
        description='Your unsaved modifications will be discarded. Are you sure you want to leave?'
        confirmBtnText='Yes'
        visible={cancelDialogVisible}
      />
    </Modal>
  );
};

interface Props {
  form: WrappedFormUtils;
  winners: IWinner[];
  visible: boolean;
  projectList: IProject[];
  awardTypes: IAwardType[];
  onOk: () => void;
  onCancel: () => void;
  tag: string;
  initialCount?: number;
}

WinnersInputModal.defaultProps = {
  initialCount: 0,
};

export default Form.create<Props>({ name: 'winners' })(WinnersInputModal);
