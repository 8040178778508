import React from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Input, Checkbox, message, Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get, getBaseUrl, post } from '../../libs/utils/request';
import { UserSearch } from '../User/UserSearch';
import User from '../../user/User';
import { Interest, RegistrationData } from '../Registration/Registration';
import { Loading } from '../common/Loading';
import './CreateGroup.css';

interface State {
  loadingButton: boolean
  regData: any
  inviteList: User[]
}

export class CreateGroup extends React.Component<any, State> {
  state: State = {
    loadingButton: false,
    regData: null,
    inviteList: [],
  }

  image: string | null = null

  imageName: string | null = null

  isPortfolio = false

  isPublic = true

  name = ''

  tag = ''

  interests: any = []

  description = ''

  constructor(props: any) {
    super(props);

    this.init();
  }

  async init() {
    try {
      this.setState({
        regData: await get<RegistrationData>('/register/init'),
      });
    } catch (err) {
      // console.log(err)
    }
  }

  async create() {
    try {
      await post('/groups/create', {
        name: this.name,
        description: this.description,
        image: this.image,
        isPublic: this.isPublic,
        isPortfolio: this.isPortfolio,
        tag: this.tag,
        interests: this.interests,
        inviteList: this.state.inviteList.map((u) => u.id),
      });
      window.open('/groups', "_self")
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error');
    }
  }

  render() {
    if (this.state.regData === null) {
      return (
        <div className='CreateGroup'>
          <Loading />
        </div>
      );
    }
    return (
      <div className='CreateGroup'>
        <div className='title'>
          Create your group!
        </div>
        <div className='grid'>
          <div>
            <div>
              <Dragger
                name='photo'
                multiple={false}
                showUploadList={false}
                action={`${getBaseUrl()}/image`}
                onChange={(info: any) => {
                  const { status } = info.file;
                  if (status === 'done') {
                    this.image = info.file.response.filename;
                    this.imageName = info.file.name;
                    this.forceUpdate();
                  } else if (status === 'error') {
                    this.image = null;
                    this.imageName = null;
                    this.forceUpdate();
                  }
                }}
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  {
                    this.imageName
                      ? this.imageName
                      : 'Click or drag file to this area to upload'
                  }
                </p>
              </Dragger>
            </div>
          </div>
          <div>
            <div>
              <Input
                defaultValue={this.name || ''}
                placeholder='Name'
                size='large'
                onChange={(e) => {
                  this.name = e.target?.value;
                }}
              />
            </div>
            <div style={{ marginTop: '12px' }}>
              <TextArea
                defaultValue={this.description || ''}
                autoSize={{
                  minRows: 3,
                }}
                placeholder='Description'
                maxLength={8000}
                onChange={(e) => {
                  this.description = e.target?.value;
                }}
              />
            </div>
          </div>
        </div>
        <div className='appear'>
          <div style={{ marginBottom: '12px' }}>Where do you want your group to appear?</div>
          <Select
            onChange={(interests: any) => {
              this.interests = interests;
            }}
            style={{ width: '100%' }}
            mode='multiple'
            size='large'
            placeholder='Type interests...'
          >
            {
              this.state.regData.interestGroups.map((group:any) => (
                <Select.OptGroup key={group.id} label={group.name}>
                  {group.interests.map((interest: Interest) =>
                    <Select.Option key={interest.id}>{interest.name}</Select.Option>,
                  )}
                </Select.OptGroup>
              ))
            }
          </Select>
        </div>
        <div className='invites'>
          <div style={{ marginBottom: '12px' }}>Invite people before you start it!</div>
          <div style={{
            display: 'flex',
          }}
          >
            <div style={{ width: '100%' }}>
              <UserSearch
                multiple
                onChange={(inviteList) => {
                  this.setState({ inviteList });
                }}
                value={this.state.inviteList}
              />
            </div>
            <div style={{ width: '160px', marginLeft: '12px' }}>
              <Checkbox onChange={(e) => {
                this.isPublic = !e.target.checked;
              }}
              >
                Private group
              </Checkbox>
            </div>
          </div>
          <div style={{ width: '160px', marginTop: '12px' }}>
            <Checkbox onChange={(e) => {
              this.isPortfolio = e.target.checked;
            }}
            >
              Portfolio group
            </Checkbox>
          </div>
        </div>
        <div style={{
          marginBottom: '32px',
        }}
        >
          <div style={{ marginBottom: '12px' }}>Unique group TAG (can't be modified later)</div>
          <div style={{
            display: 'flex',
          }}
          >
            <Input
              defaultValue={this.tag || ''}
              placeholder='TAG'
              size='large'
              onChange={(e) => {
                this.tag = e.target?.value;
              }}
            />
          </div>
        </div>
        <div className='bottom'>
          <Button
            loading={this.state.loadingButton}
            type='primary'
            size='large'
            onClick={async () => {
              this.setState({ loadingButton: true });
              await this.create();
              this.setState({ loadingButton: false });
            }}
          >
            {[<PlusOutlined />, 'Create a new']}
          </Button>
        </div>
      </div>
    );
  }
}
