import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Dropdown, Icon, Menu, message, Popover, Rate } from "antd";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { ga } from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { get, post } from "../../libs/utils/request";
import { user } from "../../libs/utils/user";
import { Loading } from "../common/Loading";
import TitleCard from "./TitleCard";
import Themes from "../CardView/components/Themes";
import { IUser } from "../Competitions/Competition/Competition";
import UserView from "../Organization/components/UserView";
import DisplayTeamMember from "../Team/DisplayTeamMember";
import "./article.css";
import { Comments } from "../comments/Comments";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { ArticleCard } from "../CardView/ProjectCard";
import MoreArticles from "./MoreArticles";

const styles = {
  root: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 100,
  },
  notImplementedText: {
    fontSize: 20,
    fontWeight: "bold" as const,
    textAlign: "center" as const,
    marginTop: 72,
    color: "#282828",
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: "center" as const,
    fontWeight: 600,
    color: "#F00",
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: "center" as const,
    fontWeight: 400,
    textDecoration: "underline",
    cursor: "pointer",
    color: "#6755CE",
  },
  content: { width: 1300 },
  label: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 800,
    color: "#282828",
    fontSize: 16,
  },
  imageContainer: {
    width: "100%",
    height: 264,
    borderRadius: 10,
    marginTop: 66,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "0.2s",
  },
  cardContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    gap: "0 16px",
  },
};

const Article = () => {
  const [article, setArticle] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPinned, setIsPinned] = useState(false);
  const [commentVisible, setCommentVisible] = useState(true);
  const [articles, setArticles] = useState(null);

  const { tag } = useParams();
  const history = useHistory();

  useEffect(() => {
    ReactPixel.fbq("track", "ViewContent");
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const art: IArticle = await get<IArticle>(`/blog/${tag}`);
        // if (article === null) {
        //   console.log(article)
        // }
        setArticle(art);
        const tmp:any = await get("/blog");
        setArticles(tmp.data);
        setErrorMessage("");

      } catch (error) {
        if (error.response.status === 404) {
          message.error(
            "We are sorry. This content might be deleted by the owners.",
            5
          );
          history.push("/blog");
        }
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [tag]);

  const getArticle = async (withLoading = true) => {
    try {
      setErrorMessage("");
      if (withLoading) {
        setLoading(true);
      }
      const art: IArticle = await get<IArticle>(`/blog/${tag}`);
      // if (article === null) {
      //   console.log(article)
      // }
      setArticle(art);
    } catch (error) {
      if (error.response.status === 403 && error.response.data.extendedUser) {
        sessionStorage.setItem(
          "user",
          JSON.stringify(error.response.data.extendedUser)
        );
        if (
          !error.response.data.extendedUser.active &&
          !error.response.data.extendedUser.trial
        ) {
          window.location.replace("/trial-ended");
        }
      }
      if (error.response.status === 404) {
        message.error(
          "We are sorry. This content might be deleted by the owners.",
          5
        );
        history.push("/blog");
      }
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOrganizers = (): IUser[] => {
    if (article) {
      return [article.createdBy];
    }
    return [];
  };

  const handlePin = async () => {
    try {
      setIsPinned(!article.isPinned);
      await post(`/blog/${tag}/pin`).then(() => {
        getArticle();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const toggleComments = () => {
    setCommentVisible(!commentVisible);
  };

  const onRateChange = async (rating: number) => {
    try {
      if (article.ratingGroup) {
        await post("/rating/post", {
          rate: rating,
          id: article.ratingGroup.id,
        });
      }
      getArticle();
    } catch (err) {
      if (err.response.status === 403) {
        message.warning(
          `${
            user().progress
          }% of your profile is completed. To write a comment you need to get at least 70%`
        );
      } else {
        message.error("Rating failed");
      }
    }
  };

  const shareLinkBase = `${window.location.protocol}//${window.location.host}`;
  const shareMenu = (
    <Menu>
      <Menu.Item>
        <FacebookShareButton
          url={`${shareLinkBase}/blog/${article ? article.tag : tag}`}
        >
          <div className="share-menu-text">share on Facebook</div>
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item>
        <LinkedinShareButton
          url={`${shareLinkBase}/blog/${article ? article.tag : tag}`}
        >
          <div className="share-menu-text">share on LinkedIn</div>
        </LinkedinShareButton>
      </Menu.Item>
      <Menu.Item>
        <TwitterShareButton
          url={`${shareLinkBase}/blog/${article ? article.tag : tag}`}
        >
          <div className="share-menu-text">share on Twitter</div>
        </TwitterShareButton>
      </Menu.Item>
      <CopyToClipboard
        text={`${shareLinkBase}/blog/${article ? article.tag : tag}`}
        onCopy={() => {
          message.success("Link copied to clipboard");
        }}
      >
        <Menu.Item>
          <div className="share-menu-text">copy link</div>
        </Menu.Item>
      </CopyToClipboard>
    </Menu>
  );


  if (isLoading) {
    return <Loading />;
  }
  return (
    <div style={styles.root}>
      <Helmet>
        <title>{`${article.title} | blog | pozi.io`}</title>
      </Helmet>
      <div style={styles.content}>
        {errorMessage ? (
          <>
            <div style={styles.errorText}>Failed to load article:</div>
            <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
              {errorMessage}
            </div>
            <div
              style={styles.errorButtonText}
              onClick={() => getArticle()}
              aria-hidden="true"
            >
              retry
            </div>
          </>
        ) : (
          <>
            <div className="article-outer-box">
              <div style={styles.imageContainer}>
                <div
                  style={{
                    ...styles.image,
                    backgroundImage: `url(${
                      article.image || "/images/profile-background.png"
                    })`,
                  }}
                />
              </div>
              <TitleCard
                name={article.title}
                tag={article.tag}
                onAddPin={handlePin}
                pin={article.isPinned}
                createdBy={article.createdBy}
                subTitle={article.subTitle}
              />
              <div className="article-desc-container ql-editor">
                <div className="article-about-description">
                  <div
                    className="organization-about-description-desc"
                    dangerouslySetInnerHTML={{
                      __html: article.description
                        ? article.description.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br />"
                          )
                        : "",
                    }}
                  ></div>
                </div>
                {article.createdBy && (
                  <div className="written-by">
                    <h3>
                      <b>Written by</b>
                    </h3>
                    <div
                      className="display-member-article"
                      key={article.createdBy.id}
                    >
                      <DisplayTeamMember
                        member={article.createdBy}
                        i={0}
                        key={0}
                        createdAt={article.createdAt}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="article-about-others">
                  <div className="organization-about-interests">
                  <h3>
                    <b>Categories of the article</b>
                  </h3>
                    <div className="organization-about-interest-holder">
                      {article.interests.map((interest: any) => (
                        <div
                          className="organization-about-interest"
                          key={interest.id}
                        >
                          <img
                            src={interest.icon}
                            alt=""
                            className="organization-about-interest-icon"
                          />
                          <span key={interest.id}>{interest.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
            <div className="article-outer-box">
              <div className="project-social">
                <div className="project-social-info">
                  <div className="Idea-social-rating">
                    {Math.round(article.ratingGroup.averageRating * 10) / 10}{" "}
                    <Icon type="star" theme="filled" /> from{" "}
                    {article.ratingGroup.ratingCount} rating
                  </div>
                  <div className="project-social-comments">
                    {`${article.commentGroup.commentsCount} comments`}
                  </div>
                  <div className="project-social-shares">
                    {`${article.shareCount} shares`}
                  </div>
                </div>
                <div className="project-actions">
                  {!!user() ? (
                    <Popover
                      content={
                        <Rate
                          className="Idea-action-rating"
                          allowClear
                          onChange={onRateChange}
                          value={article.ratingGroup.userRating || 0}
                        />
                      }
                    >
                      <div className="Idea-action rate">
                        <Icon type="star" theme="filled" />
                      </div>
                    </Popover>
                  ) : null}
                  {!!user() ? (
                    <div
                      className={`Idea-action comment`}
                      onClick={toggleComments}
                    >
                      <Icon type="message" theme="filled" />
                    </div>
                  ) : null}
                  <Dropdown
                    overlay={shareMenu}
                    trigger={["click"]}
                    placement="topCenter"
                  >
                    <div className="Idea-action share">
                      <Icon type="share-alt" />
                    </div>
                  </Dropdown>
                </div>
                {!!user() && commentVisible && (
                  <Comments
                    postURL={`/comment/post/${article.commentGroup.id}`}
                    commentsCount={article.commentGroup.commentsCount}
                    source={`/comment/get/${article.commentGroup.id}`}
                    commentable={true}
                    onNewComment={() => {
                      getArticle();
                    }}
                    type="projects"
                  />
                )}
              </div>
            </div>
            <div>
              <p className="other-articles-title">
                <b>Other articles for you</b>
              </p>
            </div>
            <MoreArticles />
            <div className="other-articles-button">
            <button type="button" className="ant-btn ant-btn-primary"><a href="/blog">Show more</a></button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export interface IArticle {
  shortDescription: string;
  createdAt: any;
  description: string;
  interests: any;
  interestGroups: any;
  ratingGroup: any;
  createdBy: IUser;
  shareCount: number;
  commentGroup: any;
  subTitle: string;
  image: string;
  title: string;
  id: string;
  tag: string;
}

export default Article;
