import React from "react"
import { Message, MessageThread, MessageThreadData, MessageUser } from "./MessageInterface";
import TextArea from "antd/lib/input/TextArea";
import {Button, message, Modal, Upload} from 'antd';
import {get, postAsForm} from "../../libs/utils/request";
import { MessageScrollbox } from "./MessageScrollbox";
import eventbus from "../../libs/eventbus";
import moment from "moment";
import {AddUser} from "./AddUser";

interface Props {
	history: any
	userId: string | null
	threadId: string | null
	open: boolean
	onClose: () => void
	onUpdate: (threads: MessageThread[]) => void
}

interface State {
	fileList: any[]
	uploading: boolean
	thread: MessageThreadData | null
	users: MessageUser[]
	text: string
	loading: boolean
	addUser: boolean
}

export class MessageBox extends React.Component<Props, State> {

	state: State = {
		fileList: [],
		uploading: false,
		thread: null,
		users: [],
		text: '',
		loading: false,
		addUser: false
	};

	refreshTimer: any = null;

	onKeyPressed(e:any){
		 if (e.key === "Enter" && ! e.shiftKey){
			this.handleSubmit()
		}
	}

	async handleSubmit() {
		const { fileList, text } = this.state;
		if (fileList.length === 0 && text.length === 0) {
			return;
		}
		this.setState({
			uploading: true
		});
		const formData = new FormData();
		formData.append('userId', this.props.userId);
		formData.append('threadId', this.props.threadId);
		formData.append('message', text.trim());
		fileList.forEach(file => formData.append('files', file));
		try {
			const { message, threads }: any = await postAsForm('/message/upload', formData);
			this.setState((prev: State) => ({
				text: '',
				thread: {
					...prev.thread,
					messages: [
						...(prev.thread ? prev.thread.messages : []),
						message
					]
				},
				fileList: [],
				uploading: false
			}));
			this.props.onUpdate(threads);
		} catch (e) {
			this.setState({
				uploading: false
			});
			alert('Request failed');
			console.error(e);
		}
	}

	async load(props: Props) {
		if (this.refreshTimer !== null) {
			clearInterval(this.refreshTimer);
			this.refreshTimer = null;
		}
		try {
			this.setState({ loading: true })
			if (props.threadId) {
				const thread = await get<MessageThreadData>('/message/threads/' + props.threadId)
				eventbus.notify(eventbus.events.messagesCountRefresh)
				this.setState({
					thread,
					users: thread.users,
					loading: false
				})
			} else {
				this.setState({
					users: await get<MessageUser[]>('/message/user/' + props.userId),
					loading: false
				})
			}
			this.refreshTimer = setInterval(() => {
				this.loadNewMessages()
			}, 5400)
		} catch (e) {
			alert('Failed to fetch messages')
			console.error(e)
		}
	}

	async loadNewMessages() {
		const { thread, users } = this.state;
		if (thread === null || users === null || !thread.id) {
			return;
		}
		const me = users.find(u => u.isYou)
		const { messages } = thread;
		const date = messages
			.filter(m => m.userId !== me.id)
			.map(m => m.date)
			.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
			.slice(-1)[0]
		try {
			const newMessages: Message[] = await get<Message[]>(`/message/getNewMessages/${thread.id}/${date || moment().add(-10, 'minute').toISOString()}`);
			this.setState((prev: State) => ({
				thread: {
					...prev.thread,
					messages: [
						...(prev.thread ? prev.thread.messages : []),
						...newMessages
					]
				}
			}));
		} catch (e) {
			console.error(e);
		}
	}

	async componentWillUnmount() {
		if (this.refreshTimer !== null) {
			clearInterval(this.refreshTimer);
			this.refreshTimer = null;
		}
	}

	async componentWillMount() {
		await this.load(this.props);
	}

	async componentWillReceiveProps(nextProps: Props) {
		if (!this.state.thread || this.state.thread.id !== nextProps.threadId) {
			if (this.refreshTimer !== null) {
				clearInterval(this.refreshTimer);
				this.refreshTimer = null;
			}
			return this.load(nextProps);
		}
	}

	torles(i: number) {
		this.setState((prev: State) => ({
			fileList: prev.fileList.filter((v, j) => i !== j)
		}));
	}

	render() {
		let { thread, users, loading } = this.state

		if (loading) {
			return <div style={{
				textAlign: 'center',
				marginTop: '10%',
				color: 'rgb(68, 68, 68)',
				width: '100%',
				fontSize: '16px'
			}}>Loading..</div>
		}

		if (users.length == 0) {
			return null
		}

		const messages = thread !== null ? thread.messages.map(msg => ({
			...msg,
			sender: users.find(u => u.id === msg.userId)
		})) : [];

		users = users.every(u => u.isYou)
			? [users[0]]
			: users.filter(u => !u.isYou);

		const props: any = {
			beforeUpload: (file: any) => {
				this.setState((state: any) => ({
					fileList: [...state.fileList, file],
				}));
				return false;
			},
			fileList: []
		};

		return <div className={'MessageBox-main' + (this.props.open ? ' open' : '')}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<h2 className={'MessagesTextLabel'} onClick={() => this.props.onClose()}>
						Messages
					</h2>
					<h2 style={{ display: 'flex', alignItems: 'center' }}>
						<img src={users[0].profileImage || '/images/user.svg'} alt="" className={'Message-box-profile'} />
						{users.map(u => u.firstName + ' ' + u.lastName).join(', ')}
					</h2>
				</div>
				<div>
					<Button
						onClick={() => {
							this.setState({
								addUser: true,
							})
						}}
						loading={false}
						type="primary"
						size="large"
						block
						style={{
							width: '160px'
						}}>Add user</Button>
				</div>
			</div>
			<div className={'MessageBox Messages-boxShadow'}>
				<MessageScrollbox messages={messages} key={messages.length + (thread ? thread.id : '')} />
				<div style={{
					padding: '12px 32px 0px 32px'
				}}>
					<div>
						{this.state.fileList.map((f, i) => <div key={i} style={{
							display: 'inline-flex', margin: '4px 8px', wordBreak: 'break-all'
						}}>{f.name}<div onClick={() => this.torles(i)} className={'icon-remove'} style={{ backgroundImage: 'url(/images/remove.svg)'}}/></div>)}
					</div>
					<div style={{
						display: 'flex',
						maxHeight: '50vh',
						overflowY: 'auto'
					}}>
						<TextArea
							value={this.state.text}
							onChange={(e) => {
								this.setState({ text: e.target?.value })
							}}
							placeholder="..."
							autoSize={{ minRows: 1 }}
							onPressEnter={(e) => this.onKeyPressed(e)}
						/>
						<Button
							onClick={() => this.handleSubmit()}
							loading={this.state.uploading}
							type="primary"
							size="large"
							block
							style={{
								width: '100px',
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0
							}}>
							Send
                        </Button>
					</div>
					<Upload {...props}>
						<div className={'Messages-attachment'}>
							<div className={'icon-add-attachment'} style={{ backgroundImage: 'url(/images/add_attachment.svg)'}} />
							<div>
								Add attachment
                            </div>
						</div>
					</Upload>
				</div>
			</div>
			<Modal
				title="Add user"
				visible={this.state.addUser}
				onOk={() => { }}
				onCancel={() => this.setState({ addUser: false })}
				footer={[]}
			>
				<AddUser threadId={this.props.threadId} onUpdate={async () => {
					try {
						this.setState({ addUser: false })
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					} catch (e) {
						message.error('Add user request failed');
					}
				}} />
			</Modal>
		</div>;
	}
}
