import React, { CSSProperties } from 'react';

const styles = {
  root: {
    fontSize: 20,
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    marginTop: 72,
    color: '#282828',
  },
};

const NoResult = ({ text, style }: Props) => <div style={{ ...styles.root, ...style }}>{text}</div>;

interface Props {
  text?: string;
  style?: CSSProperties;
}

NoResult.defaultProps = {
  text: 'Nothing to show',
  style: {},
};

export default NoResult;
