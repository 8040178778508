// import "./Dashboard.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
interface Props {
  data: any
}

const NewUsers = ({ data }: Props) => {
  const history = useHistory();
  const sessionUser = sessionStorage.getItem('user');
  const sessionUserUniqueTag = sessionUser ? JSON.parse(sessionUser).uniqueTag : null;
  const openView = (uniqueTag: string) => {
    const win = window.open(uniqueTag !== sessionUserUniqueTag ? `/entrepreneurs/${uniqueTag}` : `/profile`, "_blank");
    win.focus();
  }
  const openUsers = () => {
    const win = window.open(`/users`, "_blank");
    win.focus();
  }

  return (
    <div className="newusers-card">
      <div className="card-title-container">
        <div className="dashboard-card-title"> Say hello to new users</div>
        <div onClick={openUsers} className="seeall">See all</div>
      </div>
      {
        data.map((user: any, index: number) => {
          return (
            <div key={index} className="new-user-content-holder">
              <div className="newuser-name">
                <img className="user-profile-pic" src={user.profileImage ? user.profileImage : "images/user.svg"} />
                <div className="new-user-name-rol">
                  <div>{user.firstName} {" " + user.lastName}</div>
                  {
                    user.role &&
                    <div className="new-user-rol">{user.role.name}</div>
                  }
                </div>
              </div>
              <div onClick={() => openView(user.uniqueTag)} className="view-text-box">
                <div className="view-text">View</div>
                <div>
                  <img src="/images/user_info_icons/next.svg" />
                </div>
              </div>

            </div>
          )
        })
      }
    </div>
  );
};
export default NewUsers;
