import React from 'react';

const styles = {
  root: {
    height: 40,
    borderRadius: 3,
    backgroundColor: '#898989',
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    color: '#FFF',
    fontWeight: 900,
    fontStyle: 'normal',
    cursor: 'pointer',
  },
  na: {
    backgroundColor: '#00F',
  },
};

const SectionButton = ({ name, isActive, onClick }: Props) => (
  <div
    style={{ ...styles.root, backgroundColor: isActive ? '#6755CE' : '#898989' }}
    onClick={onClick}
    aria-hidden='true'
  >
    {name}
  </div>
);

interface Props {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

export default SectionButton;
