import React, {Component, FormEvent} from "react";
import {Button, Col, Form, Input, message, Row} from "antd";
import {WrappedFormUtils} from "antd/lib/form/Form";
import "./ResetPassword.css"
import {post} from "../../libs/utils/request";

interface Props {
    match: any,
    form: WrappedFormUtils,
    history: any
}

interface State {
    error: string | null
    loading: boolean
}

class ResetPasswordComponent extends Component<Props, State> {

    state: State = {
        loading: false,
        error: null
    };

    constructor(props: Props) {
        super(props)
        const {resetToken, email} = props.match.params

        if (!resetToken || !email) {
            message.error('Invalid token')
            return
        }
    }

    handleSubmit(e: FormEvent) {
        e.preventDefault();
        const {resetToken, email} = this.props.match.params
        this.setState({
            loading: true
        })
        this.props.form.validateFields(async (errors, data) => {
            const { password1, password2 } = data;
            if (password1 !== password2) {
                message.error("Passwords doesn't match");
                this.setState({
                    loading: false
                })
                return;
            }
            try {
                await post<any>("/resetPassword", {resetToken, email, password: password1});
                message.success('Successful password change');
                this.props.history.push(`/login`)
            } catch (e) {
                message.error("Invalid tokens")
            }
            this.setState({
                loading: false
            })
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const {resetToken, email} = this.props.match.params

        if (!resetToken || !email) {
            return <div>Invalid token</div>
        }

        return <div className="ResetPassword Content-container">
            <div className="ResetPassword-form">
                <h3>Password reset</h3><br/>
                <Form onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
                    <Row>
                        <Col>
                            <Form.Item className="resetpassword-password1">
                                {getFieldDecorator('password1', {
                                    rules: [{ required: true, message: 'Please input your new password!' },{min:6, message:'Must contain at least 6 characters'}],
                                    validateTrigger: "onSubmit"
                                })(
                                    <Input
                                        prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/password.svg" alt="" />}
                                        type="password"
                                        placeholder="New password #1"
                                        size="large"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item className="resetpassword-password2">
                                {getFieldDecorator('password2', {
                                    rules: [{ required: true, message: 'Please input your new password!' },{min:6, message:'Must contain at least 6 characters'}],
                                    validateTrigger: "onSubmit"
                                })(
                                    <Input
                                        prefix={<img className="step-userinfo-input-icon" src="/images/user_info_icons/password.svg" alt="" />}
                                        type="password"
                                        placeholder="New password #2"
                                        size="large"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item className="ResetPassword-form-button">
                                <Button loading={this.state.loading} className="ResetPassword-button" type="primary" htmlType="submit" size="large" block>
                                    Set new password
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    }
}

export const ResetPassword = Form.create({ name: 'resetPassword' })(ResetPasswordComponent);
