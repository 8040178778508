import { Button } from "antd";
import React from "react";
import "./PackageCard.css"

const PackageCard = ({title, mainDescription, options, price, type}: Props) => {

    const unlimited = (mainDescription: string) =>{
        return(
            <p style={{marginBottom:"0"}}>{mainDescription}<br/> <b style={{fontWeight:"700", paddingBottom: "14px"}}>Unlimited</b></p>
        )
    }
    return(
         <p className={`${type}-package-card package-card`}>
            {type != "pro" && <p className={`${type}-dynamic-color package-card-header`}></p>}
            <div className="package-card-title">{title}</div>
            <div className={type !== "free" ? "package-card-title-desc" : "package-card-title-desc package-card-title-desc-free"}>{type === "enterprise" ? unlimited(mainDescription) : mainDescription}</div>
            <ul className="package-card-list">
                {options.map(option=>(
                    <li className={`${option.style}`}>{option.text}</li>
                ))}
            </ul>
            <div className="package-card-monthly">{price.month ? price.month + "$ / month" : "FREE"}</div>
            <div className="package-card-annual">{price.annual ? price.annual + "$ Billed annually" : ""}</div>
            <p className={price.annual ? "package-card-button-container-more" : "package-card-button-container"}>
                <button type="button" className={`${type}-dynamic-color ant-btn ant-btn-primary`}>
                    <a href="https://calendly.com/pozi/" target="_blank">Book a Demo!</a>
                </button>
            </p>
        </p>
    )

}

interface Props {
    title: string;
    mainDescription: string;
    options: {text:string, style: string}[];
    price: {
        month: string;
        annual: string;
    };
    type: string;
  }

export default PackageCard;