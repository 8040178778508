import React from "react";
import {
  Icon,
  Form,
  Input,
  Checkbox,
  Button,
  Select,
  Popconfirm,
  Alert,
  message,
} from "antd";
import isEqual from "lodash/isEqual";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { get, post, deleteReq } from "../../libs/utils/request";
import { Interest } from "../Registration/Registration";
import ImageUploader from "../common/ImageUploader";
import { Idea } from "./Ideas";
import "./IdeaEditor.css";
import { Moment } from "moment";
import User from "../../user/User";
import { ProjectTeam } from "../Project/ProjectTeam";
import { memberExpression } from "@babel/types";
import Team from "../Team/Team";
import e from "express";

interface Props {
  form: WrappedFormUtils;
  onClose?: any;
  callback?: any;
  ideaToEdit?: Idea;
  isNew? : boolean;
  isEdit?:boolean;
}

type IdeaTeamMember = {
  id: string;
  title: string;
  description: string;
  user: User;
};

export type IdeaType = {
  id: string;
  name: string;
  image: string;
  details: string;
  user: any; //TOTO
  commentable: boolean;
  comments: number;
  shareCount: number;
  eventTags: any[];
  rating: { average: number; count: number };
  userRating: number;
  pitchLink: string;
  teamMembers: IdeaTeamMember[];
};

interface State {
  isEditing: boolean;
  idea: IdeaType;
  membersToAdd: IdeaTeamMember[];
  loading: boolean;
  error: string;
  interests: Interest[];
  eventTags: any[];
  ideaImg: string;
}

const isValidUrl = (value: string) =>
  value.startsWith("http://") || value.startsWith("https://");
const urlValidator = (_: any, value: string) => {
  if (value && !isValidUrl(value)) {
    return Promise.reject(new Error("Please enter a valid url."));
  }
  return Promise.resolve();
};

class IdeaEditor extends React.Component<Props, State> {
  state: State = {
    idea: {
      id: undefined,
      user: {},
      name: "",
      image: "",
      details: "",
      commentable: true,
      comments: 0,
      shareCount: 0,
      eventTags: [],
      rating: { average: 0, count: 0 },
      userRating: 0,
      pitchLink: "",
      teamMembers: [],
    },
    ideaImg: "",
    isEditing: false,
    membersToAdd: [],
    loading: false,
    error: "",
    interests: [],
    eventTags: [],
  };

  async componentDidMount() {
    if (this.props.ideaToEdit) {
      this.setState({ ideaImg: this.state.idea.image,
      });
      this.props.form.setFieldsValue({
        name: this.props.ideaToEdit.name,
        details: this.props.ideaToEdit.details,
        shareable: this.props.ideaToEdit.shareable,
        commentable: this.props.ideaToEdit.commentable,
        interests: this.props.ideaToEdit.interests.map((i: Interest) => i.id),
        eventTags: this.props.ideaToEdit.eventTags.map((i: any) => i.id),
        pitchLink: this.props.ideaToEdit.pitchLink,
        image: this.props.ideaToEdit.image,
      });
    }
    try {
      const eventTags = await get<any[]>("/eventtag/list");
      this.setState({ eventTags });
    } catch (err) {
      // console.log(err);
    }

    try {
      const interestList = await get<Interest[]>("/interests");
      this.setState({ interests: interestList });
    } catch (err) {
      // console.log(err);
    }

    try {
      const idea = await get<Idea>(`/ideas/${this.props.ideaToEdit.id}`);
      this.setState({ idea });
    } catch (err) {
      // console.log(err);
    }
  }

  componentWillReceiveProps(newProps: Props) {
    if (!isEqual(newProps, this.props)) {
      this.setState({ ideaImg: newProps.ideaToEdit.image });
      this.props.form.setFieldsValue({
        name: newProps.ideaToEdit.name,
        details: newProps.ideaToEdit.details,
        shareable: this.props.ideaToEdit.shareable,
        commentable: this.props.ideaToEdit.commentable,
        interests: newProps.ideaToEdit.interests.map(
          (interest: Interest) => interest.id
        ), // map idea interests list to id list for the form
        image: this.props.ideaToEdit.image
      });
    }
  }

  onImageChange = (url: string) => {
    this.setState({ ideaImg: url });
  };

  interestsFilterOption = (input: string, option: any) => {
    return option.props.children.toLowerCase().includes(input.toLowerCase());
  };

  deleteIdea = async () => {
    await deleteReq(`/ideas/${this.props.ideaToEdit.id}`);
    this.props.onClose();
    window.location.replace('/ideas');
  };
  getTeamMembers = (value: any) => {
    this.setState(prevState => ({
      idea: {                   // object that we want to update
        ...prevState.idea,    // keep all other key-value pairs
        teamMembers: value       // update the value of specific key
      }
    }))
  }
  handleSubmit = (e: any) => {
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
    e.preventDefault();
    this.props.form.validateFields(async (errors, values) => {
      if (
        this.state.idea.teamMembers.length === 0 &&
        this.state.membersToAdd.length === 0
      ) {
        this.setState({ error: "Please add at least one team member!" });
      } else if (!errors) {
        this.setState({
          loading: true,
          error: undefined,
        });
        try {
          const ideaData = {
            ...this.props.ideaToEdit,
            ...values,
            teamMembers: [
              ...this.state.idea.teamMembers,
              ...this.state.membersToAdd,
            ],
            eventTags: this.state.eventTags,
            image: this.state.ideaImg === '' ? this.state.idea.image : this.state.ideaImg,
          };
          await post("/ideas", ideaData);
          this.setState({ loading: false, membersToAdd: [] });
          this.props.form.resetFields();
          this.props.onClose();
        } catch (err) {
          err.response?.data?.message &&
            message.error(err.response?.data?.message);
            

          // console.log(err);
          this.setState({
            error: "Server side error",
            loading: false,
          });
          if(err.response.status === 403){
            message.warning(`${profileProgress}% of your profile is completed. To write a comment you need to get at least 70%`)
          }
          if(err.response.status === 400){
            message.warning('The maximum length of category list is 5!')
          }
        } finally {
          if (this.props.callback) {
            this.props.callback();
          }
        }
      }

      this.setState({
        loading: false,
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { onClose } = this.props;
   
    return (
      <div className="Ideas-form-container">
        <div className="Ideas-form-headline">
          <h3 className="Ideas-form-title">Customize your awesome idea</h3>
          <Icon className="Ideas-form-close" type="close" onClick={onClose} />
        </div>

        <Form className="Ideas-form" onSubmit={this.handleSubmit}>
          <Form.Item className="Ideas-form-item name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Please enter a name with minimum 3 characters!", min: 3}],
              validateTrigger: "onSubmit",
            })(
              <Input
                placeholder="Choose a good name..."
                maxLength={37}
                size="large"
              />
            )}
          </Form.Item>

          <Form.Item className="Ideas-form-item interests">
            {getFieldDecorator("interests", {
              rules: [
                {
                  required: true,
                  type: "array",
                  message: "Select at least one",
                },
              ],
              validateTrigger: "onSubmit",
            })(
              <Select
                mode="multiple"
                size="large"
                maxTagCount={5}
                onChange={(e:any)=> e.length > 5 ? message.warning('The maximum length of category list is 5!') : '' }
                placeholder="Type category..."
                filterOption={this.interestsFilterOption}
              >
                {this.state.interests.map((interest) => (
                  <Select.Option key={interest.id} value={interest.id}>
                    {interest.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item className="Ideas-form-item details">
            {getFieldDecorator("details", {
              rules: [{ required: true, message: "Please add details!" }],
              validateTrigger: "onSubmit",
            })(
              <Input.TextArea
                placeholder="Type something..."
                autoSize={{ minRows: 5, maxRows: 15 }}
                maxLength={480}
              />
            )}
          </Form.Item>

          <Form.Item className="Ideas-form-item name">
            {getFieldDecorator("pitchLink", {
              rules: [{ validator: urlValidator }],
              validateTrigger: "onSubmit",
            })(<Input placeholder="Pitch link" size="large" />)}
          </Form.Item>

          <Form.Item className="Ideas-form-item name">
            {getFieldDecorator("additionalInfo", {
              rules: [{ validator: urlValidator }],
              validateTrigger: "onSubmit",
            })(<Input placeholder="Insert a link here" size="large" />)}
          </Form.Item>

          <div className="Ideas-form-item image">
            <ImageUploader
              images={this.state.ideaImg}
              uploadText="Upload idea cover recommended 500x200"
              type="single"
              onChange={(url: string) => {
                this.onImageChange(url);
              }}
              showCropper = {true}
              cropperData = {{width: 500 , height: 200 }}
              cropType="cover"
            />
            
          </div>

          <Form.Item className="Ideas-form-item shareable">
            {getFieldDecorator("shareable", {
              rules: [],
              initialValue: true,
              valuePropName: "checked",
            })(<Checkbox>Shareable</Checkbox>)}
          </Form.Item>

          <Form.Item className="Ideas-form-item commentable">
            {getFieldDecorator("commentable", {
              rules: [],
              initialValue: true,
              valuePropName: "checked",
            })(<Checkbox>Commentable</Checkbox>)}
          </Form.Item>

          {(this.props.ideaToEdit?.canDelete) && (
            <Popconfirm
              title="Are you sure delete this idea?"
              onConfirm={this.deleteIdea}
              okText="Yes"
              cancelText="No"
            >
              <Button className="Delete-button" type="danger">
                Delete idea
              </Button>
            </Popconfirm>
          )}

          <div style={{ gridRow: '7 / 7', gridColumn: '1/3', }}>
            <Team
              ideaEditor={true}
              isEdit={this.props.isEdit}
              teamMembers={this.state.idea.teamMembers}
              project={this.state.idea}
              Callback={this.getTeamMembers}
              isNew={this.props.isNew}
            />
          </div>
          <Form.Item className="Ideas-form-item submit">
            <Button
              loading={this.state.loading}
              className="Login-button"
              type="primary"
              htmlType="submit"
              size="large"
            >
              {this.props.ideaToEdit ? "Update" : "Share it"}
            </Button>
            {this.state.error && (
              <span className="Login-error">{this.state.error}</span>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const IdeaEditorWithForm = Form.create<Props>({ name: "ideaEditor" })(
  IdeaEditor
);
export default IdeaEditorWithForm;
