import React, { CSSProperties, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

const styles = {
  root: { width: '100%' },
};
const FormOnAfterInput = ({
  form, fieldKey, rules, placeholder, required, requiredMessage, style, onAfterTyping, initialValue,
}: Props) => {
  const [value, setValue] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (value.length >= 3) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(setTimeout(async () => {
        await onAfterTyping(value);
      }, 500));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Form.Item>
      {form.getFieldDecorator(fieldKey, {
        rules: [...rules, { required, message: requiredMessage }],
        initialValue,
      })(
        <Input
          style={{ ...styles.root, ...style }}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />,
      )}
    </Form.Item>
  );
};

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  rules?: any[];
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  style?: CSSProperties;
  onAfterTyping: any;
  initialValue?: string;
}

FormOnAfterInput.defaultProps = {
  required: false,
  rules: [],
  placeholder: '',
  requiredMessage: 'This field is required!',
  style: {},
  initialValue: '',
};

export default FormOnAfterInput;
