import { Button, message } from 'antd';
import React, { useState } from 'react';
import './CardTitle.css';
import { post } from '../../../libs/utils/request';
import Deadline_Location from './Deadline_Location';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { user } from '../../../libs/utils/user';

interface Props {
  title: string;
  subTitle: string;
  description?: string;
  canJoin?: boolean;
  type: string;
  id: string;
  canWithdraw?: boolean;
  canLeave?: boolean;
  country?: string;
  city?: string;
  deadline?: string;
  onChange?: () => void;
  applyButtonVisible?: boolean;
  leadButtonText?: string;
  handleApply?: () => void;
  isFollowing?: boolean;
  canFollow?: boolean;
  tag?: string;
  needRedirect?: boolean;
  redirectURL?: string;
}

function CardTitle({
  title,
  subTitle,
  description,
  canJoin,
  type,
  id,
  canWithdraw,
  canLeave,
  country,
  city,
  deadline,
  onChange,
  applyButtonVisible,
  leadButtonText,
  handleApply,
  isFollowing,
  canFollow,
  tag,
  needRedirect,
  redirectURL
}: Props) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [buttonType, setButtonType] = useState('');
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  const onJoin = async () => {
    try {
      await post(`/${type}/${id}/join`);
      message.success('Request sent.');
      onChange();
    } catch (err) {
      // console.log(err);
    }
  };
  const onWithdraw = async () => {
    try {
      await post(`/${type}/${id}/withdraw`);
      message.success('Request sent.');
      onChange();
    } catch (err) {
      // console.log(err);
    }
  };
  const onLeave = async () => {
    try {
      await post(`/${type}/${id}/withdraw`);
      message.success('Request sent.');
      onChange();
    } catch (err) {
      // console.log(err);
    }
  };
  const Join = () => {

    setShowConfirmationDialog(true);
    setButtonType('join');
  }

  const follow=()=>{
    setShowConfirmationDialog(true);
    setButtonType('follow');
  }

  const unFollow=()=>{
    setShowConfirmationDialog(true);
    setButtonType('unFollow');
  }

  const onFollow=async()=>{
    await post(`/organizations/${tag}/follow`);
    onChange()
  }

  const onUnFollow=async ()=>{
    await post(`/organizations/${tag}/unfollow`);
    onChange()
  }

  return (
    <div className='Card-header'>
      <div className='title-with-button'>
        <div className='title'>{title}</div>
        {(() => {
          if (canJoin && type !== 'competitions' && type !== 'events') {
            return (
              <div className='button-holder'>
                <Button
                  className='canJoin'
                  onClick={() => {
                    Join()
                  }}
                >
                  Join
                </Button>
              </div>
            );
          }
          if (canFollow||(!canFollow&&isFollowing) && type === 'organization' ) {
            return (
              <div className='button-holder'>
                {isFollowing===false?<Button
                  className='canJoin'
                  onClick={() => {
                    follow()
                  }}
                >
                  Follow
                </Button>:<Button
                  className='canJoin'
                  onClick={() => {
                    unFollow()
                  }}
                >
                  Unfollow
                </Button>
                }
              </div>
            );
          }
          if (canWithdraw) {
            return (
              <div className='button-holder'>
                <Button
                  className='withdraw'
                  onClick={() => {
                    setShowConfirmationDialog(true);
                    setButtonType('withdraw');
                  }}
                >
                  Withdraw application
                </Button>
              </div>
            );
          }
          if (canLeave) {
            return (
              <div className='button-holder'>
                <Button
                  className='leave'
                  onClick={() => {
                    setShowConfirmationDialog(true);
                    setButtonType('leave');
                  }}
                >
                  Leave
                </Button>
              </div>
            );
          }
          if (type === 'competitions' && canJoin && leadButtonText) {
            return (
              <div className='button-holder'>
                <Button
                  className='competitionJoin'
                  type='primary'
                  size='large'
                  onClick={()=>  
                    handleApply()}
                  >
                  {leadButtonText}
                </Button>
              </div>
            );
          }
          if (type === 'events' && canJoin && leadButtonText && !needRedirect) {
            return (
              <div className='button-holder'>
                <Button
                  className='eventJoin'
                  type='primary'
                  size='large'
                  onClick={handleApply}
                >
                  {leadButtonText}
                </Button>
              </div>
            );
          }
          if (type === 'events' && canJoin && leadButtonText && needRedirect) {
            return (
              <div className='button-holder'>
                <Button
                  className='eventJoin'
                  type='primary'
                  size='large'
                >
                  <a href={redirectURL} target="_blank" >{leadButtonText}</a>
                </Button>
              </div>
            );
          }
          return null;
        })()}
      </div>
      {subTitle ? <div className='subtitle'>{subTitle}</div> : ''}
      {description && <div className='description'>{description}</div>}

      {(type === 'events' || type === 'competitions' || type === 'homepage') && (
        <Deadline_Location deadline={deadline} country={country} city={city} />
      )}
      {(() => {
        if (buttonType === 'join') {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onJoin();
              }}
              title='Join team'
              description='Are you sure you want to join to this team?'
              confirmBtnText='Join'
              confirmBtnType='primary'
            />
          );
        }
        if (buttonType === 'withdraw') {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onWithdraw();
              }}
              title='Withdraw application'
              description='Are you sure you want to withdraw your application?'
              confirmBtnText='Withdraw'
              confirmBtnType='danger'
            />
          );
        }
        if (buttonType === 'leave') {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onLeave();
              }}
              title='Leave team'
              description='Are you sure you want to leave this team?'
              confirmBtnText='Leave'
              confirmBtnType='danger'
            />
          );
        }
        if (buttonType === 'follow') {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onFollow();
              }}
              title='Follow organization'
              description='Are you sure you want to follow this organization?'
              confirmBtnText='Follow'
              confirmBtnType='primary'
            />
          );
        }
        if (buttonType === 'unFollow') {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onUnFollow();
              }}
              title='Unfollow organization'
              description='Are you sure you want to unfollow this organization?'
              confirmBtnText='Unfollow'
              confirmBtnType='primary'
            />
          );
        }
        if(type=== 'homepage'){
          return(
              <a href={'/events/' + tag}>
                <div className='card-title-homepage-navigate'>
                  {'View ->'}
                </div>
              </a>
          )
        }
        return null;
      })()}
    </div>
  );
}

export default CardTitle;
