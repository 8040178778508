import React from 'react'
import { get, post } from '../../libs/utils/request'
import { message, Table, Button } from "antd";
import { Loading } from '../common/Loading';
import { Link } from "react-router-dom";

interface Props {
  groupId: any
}

export class MemberRequests extends React.Component<Props, any> {

  state: any = {
    requests: null
  }

  constructor(props: Props) {
    super(props)
    this.init()
  }

  async init() {
    try {
      const requests: any = await get('/groups/joinRequest/list', {
        groupId: this.props.groupId
      })

      this.setState({
        requests: requests.sort((a: any, b: any) => (0 + a.accepted + a.rejected) - (0 + b.accepted + b.rejected))
      })
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
    }
  }

  profile(request: any) {
    return <div style={{
      display: 'flex'
    }}>
      <img className={'GroupMembers-profile-image'} src={request.user.profileImage ? request.user.profileImage + '?w=48' : '/images/user.svg'} />
      <Link style={{
        marginLeft: '12px',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
      }} to={'/entrepreneurs/' + request.user.uniqueTag} target={'_blank'}>{`${request.user.firstName} ${request.user.lastName}`}</Link>
    </div>
  }

  async handle(id: string, type: 'accept' | 'reject') {
    try {
      await post('/groups/joinRequest/handle', {
        id,
        type
      })

      this.init()
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
    }
  }

  render() {
    const { requests } = this.state

    if (requests === null) {
      return <div>
        <Loading />
      </div>
    }

    return <div style={{
      padding: '32px',
      maxHeight: 'calc(100vh - 120px)',
      overflow: 'auto'
    }}>
      <Table
        pagination={{ position: 'top' }}
        dataSource={requests.map((i: any) => ({
          profile: this.profile(i),
          accept: !i.accepted && !i.rejected ? <Button
            type="primary"
            size="large"
            onClick={() => this.handle(i.id, 'accept')}
          >Accept</Button> : (i.accepted ? 'Accepted' : null),
          reject: !i.accepted && !i.rejected ? <Button
            type="primary"
            size="large"
            onClick={() => this.handle(i.id, 'reject')}
          >Reject</Button> : (i.rejected ? 'Rejected' : null)
        }))}
        columns={[
          {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
          },
          {
            title: 'Accept',
            dataIndex: 'accept',
            key: 'accept',
          },
          {
            title: 'Reject',
            dataIndex: 'reject',
            key: 'reject',
          }
        ]} />
    </div>
  }

}
