import React from "react";
import { Steps, Button, message, Form } from "antd";
import UserInfoWithForm from "./Registration_steps/UserInfo";
import Roles from "./Registration_steps/Roles";
import Interests from "./Registration_steps/Interests";
import Skills from "./Registration_steps/Skills";
import { get, post } from "../../libs/utils/request";
import "./Registration.css";
import eventbus from "../../libs/eventbus";
import User from "../../user/User";
import { Helmet } from "react-helmet";
import UserDetails from "./Registration_steps/userDetails";

interface Props {
  history: any;
}

export type Interest = {
  id: string;
  name: string;
  icon?: string;
  group?: InterestGroup;
};

export type InterestGroup = {
  name: string;
  interests: Interest[];
  icon: string;
  id: string;
};

export type Skill = {
  id: string;
  name: string;
};

export type SkillGroup = {
  name: string;
  skills: Skill[];
  id: string;
};

export type Role = {
  id: string;
  name: string;
  icon: string;
};

export type RegistrationData = {
  skillGroups: SkillGroup[];
  interestGroups: InterestGroup[];
  roles: Role[];
};

interface State {
  loading: boolean;
  current: number;
  regData: RegistrationData;
  selectedInterests: Interest[];
  selectedSkills: Skill[];
  selectedRoleId: string;
  userInfo: any;
  userImg: string;
  error?: string;
}

const styles = {
  headerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "center",
  },
  arrowBtn: {
    height: 40,
    cursor: "pointer",
  },
};

class Registration extends React.Component<Props, State> {
  state: State = {
    loading: false,
    current: 0,
    regData: {
      skillGroups: [],
      interestGroups: [],
      roles: [],
    },
    selectedInterests: [],
    selectedSkills: [],
    selectedRoleId: undefined,
    userInfo: {},
    userImg: "",
  };

  async componentDidMount() {
    const regData = await get<RegistrationData>("/register/init");
    this.setState({ regData: regData });
    console.log(regData);
  }

  componentWillMount() {
    
      this.props.history.push("/");
    
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.setState({
      loading: true,
      error: undefined,
    });
    try {
      await eventbus.validate(eventbus.events.validateRegistration);
      let placeId = null;
      try {
        placeId = JSON.parse(this.state.userInfo.city).placeId;
      } catch (error) {}
      const user: any = {
        invitation: sessionStorage.getItem("invitation"),
        firstName: this.state.userInfo.firstName,
        lastName: this.state.userInfo.lastName,
        email: this.state.userInfo.email,
        password: this.state.userInfo.password,
        birthday: this.state.userInfo.birthday,
        // country: "placeholder",
        // city: this.state.userInfo.city,
        location: { placeId },
        profileImage: this.state.userImg,
        oneLiner: this.state.userInfo.oneLiner,
        bio: this.state.userInfo.bio,
        role: this.state.selectedRoleId,
        skills: this.state.selectedSkills.map((skill) => skill.id),
        interests: this.state.selectedInterests.map((interest) => interest.id),
      };
      try {
        const result = await post<User>("/register", user);
        if (result) {
          sessionStorage.setItem("user", JSON.stringify(result));
          localStorage.setItem("token", result.token);
          this.props.history.push(`/notActivated`);
        }
      } catch (err) {
        // error after posting the user
        this.setState({
          error: `Registration unsuccessful.${
            err.response.status === 409
              ? " There is already a user with this email address."
              : err.response.status === 500
              ? " Internal server error"
              : " Network error"
          }`,
          loading: false,
        });
      }
    } catch (err) {
      // error after form validation
      this.setState({
        loading: false,
      });
    }
  };

  async next() {
    const { ok, message } = await eventbus.validate(
      eventbus.events.validateRegistration
    );
    if (ok) {
      this.setState({
        current: this.state.current + 1,
        error: undefined,
      });
    } else {
      this.setState({ error: message });
    }
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  handleRoleSelect = (id: string) => {
    this.setState({
      selectedRoleId: id,
    });
  };

  handleInterestsSelect = (interest: Interest) => {
    const selectedInterests = this.state.selectedInterests;
    if (selectedInterests.includes(interest)) {
      selectedInterests.splice(selectedInterests.indexOf(interest), 1);
    } else {
      selectedInterests.push(interest);
    }
    this.setState({ selectedInterests });
  };

  handleSkillSelect = (skill: Skill) => {
    const selectedSkills = this.state.selectedSkills;
    if (selectedSkills.includes(skill)) {
      selectedSkills.splice(selectedSkills.indexOf(skill), 1);
    } else {
      selectedSkills.push(skill);
    }
    this.setState({ selectedSkills: selectedSkills });
  };

  userInfoFinished = (userInfo: any) => {
    this.setState({ userInfo });
  };

  onImageChange = (url: string) => {
    this.setState({ userImg: url });
  };

  render() {
    const { Step } = Steps;

    const steps = [
      {
        title: "Personal data",
        content: (
          <UserInfoWithForm
            onFinished={this.userInfoFinished}
            onImageChange={this.onImageChange}
            formValues={this.state.userInfo}
            userImg={this.state.userImg}
          />
        ),
      },
      {
        title: "Roles",
        content: (
          <Roles
            next={() => {
              this.next();
            }}
            back={() => {
              this.prev();
            }}
            roles={this.state.regData.roles}
            onClick={this.handleRoleSelect}
            submit={() => this.prev()}
          />
        ),
      },
      {
        title: "Skills",
        content: (
          <Skills
            skillGroups={this.state.regData.skillGroups}
            selectedSkills={this.state.selectedSkills}
            onClick={this.handleSkillSelect}
          />
        ),
      },
      {
        title: "Interests",
        content: (
          <Interests
            interestGroups={this.state.regData.interestGroups}
            selectedInterests={this.state.selectedInterests}
            onClick={this.handleInterestsSelect}
          />
        ),
      },
    ];

    const { current } = this.state;

    return (
      <div className="Registration Content-container">
        <Helmet>
          <title>Registration | pozi.io</title>
        </Helmet>
        <div className="Registration-title">Registration</div>
        <div>
          {/* <UserDetails />  */}
          <div style={styles.headerContainer}>
            <div style={{ width: 40 }}>
              {current > 0 && (
                <img
                  src="../../images/arrow_left.svg"
                  style={styles.arrowBtn}
                  alt="Previous step"
                  onClick={() => this.prev()}
                />
              )}
            </div>
            <div className="Registration-steps-line">
              <Steps current={current}>
                {steps.map((item) => (
                  <Step key={item.title} />
                ))}
              </Steps>
            </div>
            <div style={{ width: 40 }}>
              {current < steps.length - 1 && (
                <img
                  src="../../images/arrow_right.svg"
                  style={styles.arrowBtn}
                  alt="Next step"
                  onClick={() => this.next()}
                />
              )}
            </div>
          </div>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <>
                {this.state.error && (
                  <div className="Registration-error">{this.state.error}</div>
                )}
              </>
            )}
            {current === steps.length - 1 && (
              <React.Fragment>
                <Button
                  loading={this.state.loading}
                  type="primary"
                  className="login-form-button"
                  size="large"
                  onClick={this.handleSubmit}
                >
                  Register
                </Button>
                {this.state.error && (
                  <div className="Registration-error">{this.state.error}</div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Registration;
