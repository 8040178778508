import React from 'react'
import { Component } from 'react'
import { Button } from 'antd'
import { UserSearch } from '../User/UserSearch'
import User from '../../user/User'
import {post} from '../../libs/utils/request'

interface State {
    value: User[]
}

interface Props {
    threadId: string | null
    onUpdate: () => void
}

export class AddUser extends Component<Props, State> {

    state: State = {
        value: undefined,
    }

    async add() {
        try {
            await post<any>('/message/threads/addUser', {
                threadId: this.props.threadId,
                userId: this.state.value[0].id
            })
            this.props.onUpdate()
        } catch (e) {
            alert('Request failed')
        }
    }

    render(): any {
        const { value } = this.state
        return <div>
            <UserSearch value={value} multiple={false} onChange={(value) => {
                this.setState({ value })
            }} />
            <div style={{ textAlign: 'right', paddingTop: '12px' }}>
                <Button
                    onClick={() => this.add()}
                    loading={false}
                    type="primary"
                    size="large"
                    block
                    style={{
                        width: '120px'
                    }}>Add</Button>
            </div>
        </div>
    }
}
