import React, { CSSProperties, useEffect, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ItemWithIcon from '../../ItemWithIcon';
import { IUserListData } from '../Participants';
import './MobileList.css';

const baseText = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: 16,
  color: '#282828',
};

const CARD_BORDER = '.5px solid #D1D1D1';

const cardStyles = {
  topCard: {
    borderTop: CARD_BORDER,
    borderLeft: CARD_BORDER,
    borderRight: CARD_BORDER,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  card: {
    borderTop: CARD_BORDER,
    borderLeft: CARD_BORDER,
    borderRight: CARD_BORDER,
  },
  bottomCard: {
    border: CARD_BORDER,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
};

const styles = {
  root: {
    width: '100%',
  },
  header: {
    width: '100%',
    height: 48,
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0px 10px',
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerStart: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    gap: '0px 16px',
  },
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
  body: {
    width: '100%',
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '8px 8px',
  },
  detailContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '0 10px',
  },
  detailTitle: {
    ...baseText,
    fontWeight: 800,
  },
  detailValue: baseText,
  btn: {
    fontWeight: 'bold' as const,
  },
};

const getLocation = (data: IUserListData): string => {
  if (data.user?.location?.country) {
    return data.user.location.country;
  }
};

const MobileList = ({
  listData, onAccept, onDeny, disabled, style,
}: Props) => {
  const [collapses, setCollapses] = useState([]);

  useEffect(() => {
    const l = Array(listData.length);
    for (let i = 0; i < listData.length; i += 1) {
      l[i] = false;
    }
    setCollapses(l);
  }, [listData.length]);

  const getCardStyle = (i: number) => {
    const isTop = i === 0;
    const isBottom = i === listData.length - 1;
    let res = isTop ? cardStyles.topCard : {};
    if (isBottom) {
      res = { ...res, ...cardStyles.bottomCard };
    }
    return { ...res, ...cardStyles.card };
  };

  const handleClick = (i: number) => {
    setCollapses((state) => {
      const tmp = [...state];
      tmp[i] = !tmp[i];
      return tmp;
    });
  };

  const getStatus = (item: IUserListData) => {
    if (item.isApproved) {
      return 'accepted';
    }
    return item.isDenied ? 'denied' : 'pending';
  };

  return (
    <div style={{ ...styles.root, ...style }}>
      {listData.map((item, i) => {
        const status = getStatus(item);

        return (

          <div
            key={item.user.id}
            style={{ ...styles.root, ...(getCardStyle(i)) }}
            onClick={() => handleClick(i)}
            aria-hidden='true'
          >{item.user.firstName === "Deleted User" ? '' : (
            <>

              <div style={styles.header}>
                <div style={styles.headerStart}>
                  <CaretRightOutlined
                    rotate={collapses[i] ? 90 : 0}
                    style={{ ...styles.arrowIcon, color: collapses[i] ? '#6755CE' : '#898989' }}
                  />
                  <div style={{ width: 200 }}>
                    <ItemWithIcon
                      icon={item.user.profileImage}
                      text={`${item.user.firstName} ${item.user.lastName}`}
                      withStatusIcon
                      status={status}
                      link={`/entrepreneurs/${item.user.uniqueTag}`}
                    />
                  </div>
                </div>
                <div className='participants-btn-head'>
                  {status !== 'accepted' && (
                    <Button
                      style={styles.btn}
                      type='primary'
                      onClick={(e) => {
                        e.stopPropagation();
                        onAccept(item.user.id);
                      }}
                      disabled={disabled}
                    >
                      Accept
                    </Button>
                  )}
                  {status !== 'denied' && (
                    <Button
                      style={styles.btn}
                      type='danger'
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeny(item.user.id);
                      }}
                      disabled={disabled}
                    >
                      Deny
                    </Button>
                  )}
                </div>
              </div>
              <Collapse in={!!collapses[i]} timeout='auto' unmountOnExit>
                <div style={styles.body}>
                  <div style={styles.detailContainer}>
                    <div style={styles.detailTitle}>Role:</div>
                    <div style={styles.detailValue}>{item.user.role.name}</div>
                  </div>
                  <div style={styles.detailContainer}>
                    <div style={styles.detailTitle}>Country:</div>
                    <div style={styles.detailValue}>{getLocation(item)}</div>
                  </div>
                  <div className='participants-btn-body'>
                    {status !== 'accepted' && (
                      <Button
                        style={styles.btn}
                        type='primary'
                        onClick={(e) => {
                          e.stopPropagation();
                          onAccept(item.user.id);
                        }}
                        disabled={disabled}
                      >
                        Accept
                      </Button>
                    )}
                    {status !== 'denied' && (
                      <Button
                        style={styles.btn}
                        type='danger'
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeny(item.user.id);
                        }}
                        disabled={disabled}
                      >
                        Deny
                      </Button>
                    )}
                  </div>
                </div>
              </Collapse>
            </>
          )}
          </div>
        );
      })}
    </div>
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  listData: IUserListData[];
  onAccept: (id: string) => void;
  onDeny: (id: string) => void;
  disabled: boolean;
  style?: CSSProperties;
}

MobileList.defaultProps = {
  style: {},
};

export default MobileList;
