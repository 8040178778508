import { Button, Checkbox, Divider, Form, message, Modal, Tooltip } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { Formik } from "formik";
import _, { values } from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Prompt, useHistory, useLocation, useParams } from "react-router";
import {
  deleteReq,
  get,
  getBaseUrl,
  patch,
  post,
} from "../../libs/utils/request";
import { isAdmin, user } from "../../libs/utils/user";
import FormikInput from "../common/form/FormikInput";
import FormikTextArea from "../common/form/FormikTextArea";
import FormInterests from "../common/form/FormInterests";
import {
  FormLocationSelector,
  LocationSelector2,
} from "../common/form/LocationSelector";
import ImageUploader from "../common/ImageUploader";
import Cover from "../Competitions/components/Cover";
import PartnerLogos from "../Competitions/components/PartnerLogos/PartnerLogos";
import NewInterests from "../Registration/Registration_steps/NewInterests";
import SocialLinks from "../Registration/Registration_steps/SocialLinks";
import Chooser from "../User/Chooser";
import { UserSearch } from "../User/UserSearch";
import "./NewOrganization.css";
import * as Yup from "yup";
import { HtmlEditor, Toolbar, Editor } from "@aeaton/react-prosemirror";
import {
  plugins,
  schema,
  toolbar,
} from "@aeaton/react-prosemirror-config-default";
import styles from "../User/NewProfileEditor.module.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Loading } from "../common/Loading";

const imageLinkBase = `${getBaseUrl()}/image`;

export async function digestMessage(message: any) {
  message = message + "pozi";
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
}

const NewOrganization = ({ form, isEdit, isCreating }: Props) => {
  const [navigationBlocked, setNavigationBlocked] = useState(true);
  const [coverImage, setCoverImage] = useState("");
  const [socialCount, setSocialCount] = useState(1);
  const [interestGroups, setInterestGroups] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [partnerLogos, setPartnerLogos] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [logoToAdd, setLogoToAdd] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEditmode, setIsEditmode] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [canDelete, setCanDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isTagEdited, setTagEdited] = useState(false);
  const [selectedSubtitle, setSelectedSubtitle] = useState("");
  const [creator, setCreator] = useState(null);
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [modified, setModified] = useState(false);
  const [owner, setOwner] = useState(isCreating ? [JSON.parse(sessionStorage.getItem('user'))]: undefined);
  const [createdBy, setCreatedBy ] = useState(JSON.parse(sessionStorage.getItem('user')))
  const [loaded, setLoaded] = useState(false)

  const location = useLocation();
  const { tag } = useParams();
  // const tag = location.state;
  const history = useHistory();

  const [formValue, setFormValues] = useState({
    name: "",
    tag: "",
    location: {},
    shortDescription: "",
    socialLinks: [],
    videoUrl: "",
  });

  useEffect(()=>{
    setLoaded(true)
  },[])

  useEffect(() => {
    if (isCreating && !isAdmin()) {
      chechHash();
    }
  }, []);

  const chechHash = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const hash = await digestMessage(user.uniqueTag);
    if (hash !== tag) {
      setNavigationBlocked(false);
      history.push("/");
    }
  };

  const getInterestList = async () => {
    // load interests for editing if they are not already loaded
    if (interestGroups.length === 0) {
      try {
        const allInterests: any = await get<any[]>("/register/init");
        setInterestGroups(allInterests.interestGroups);
      } catch (err) {
        // console.log(err);
      }
    }
  };

  const getDraftById = async (id?: string) => {
    try {
      let draftId;
      if (tag === undefined) {
        draftId = location.pathname.split("/");
      }
      let ownerArray:any[] = [];
      if (!isEdit) {
        ownerArray.push(JSON.parse(sessionStorage.getItem("user")));
        setOwner(ownerArray);
      }
      // setLoading(true)
      await get(
        `/organizations/draft/all/${tag !== undefined ? tag : draftId[2]}`
      ).then((draft: any) => {
        if(draft.isOrganization){
          setNavigationBlocked(false)
          history.push(draft.isOrganization)
        }
        else{
        ownerArray.push(draft.createdBy);

        setDescription(draft.description);
        //   setCanDelete(user().id === draft.user.id);
        if (draft.image) {
          setCoverImage(draft.image);
        }
        if (draft.logo) {
          setLogoToAdd(draft.logo);
        }
        if (draft.partners.length > 0) {
          setPartnerLogos(
            draft.partners
              ? draft.partners.map((item: any) => ({
                  partnerUrl: item.partnerUrl,
                  name: item.name,
                  image: `${imageLinkBase}/${item.image.filename}`,
                }))
              : []
          );
        }
        if (draft.interests.length > 0) {
          setSelectedInterests(draft.interests.map((i: any) => i));
        }

        if (draft.description === "")
          setDescription(
            "Here, we recommend you introduce your organization to the startup community. Let's show them your primary field and how your services/products/programs can support them."
          );
        
        tagValidator(draft.tag);
        setTeamMembers(
          draft.teamMembers
            ? draft.teamMembers.map((item: any) => item.user)
            : []
        );

        setCreatedBy(draft.createdBy)
        setOwner(ownerArray);
        setCreator(draft.isOwner);
        setFormValues(draft);
        setLoaded(true)
      }});
    } catch (error) {
      if(error?.response.status === 404){
        setNavigationBlocked(false)
        message.error('We are sorry. This content might be deleted by the owners.', 5)
        history.push('/organizations')
      }
      else if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      // console.log('Error in get project');
      // console.log(err);
    }
  };

  const ownerChange = (e:any)=>{
    const myTeammembers = teamMembers.map(member=>{ return member});
    setOwner(e);

    let filteredMembers = myTeammembers;
    let filteredIds = teamMembers.map(member=>{ return member.id})
    
    if(!filteredIds.includes(e[0].id)){
      filteredMembers.push(e[0])
    }

    if(!filteredIds.includes(createdBy.id) && createdBy.deleted !== true){
      filteredMembers.push(createdBy)
    }
    setTeamMembers(filteredMembers)

  }

  useEffect(() => {
    if (!isEdit) {
      setDescription(
        "Here, we recommend you introduce your organization to the startup community. Let's show them your primary field and how your services/products/programs can support them."
      );
    }
  }, [isEdit]);

  const getOrganizationById = async (id?: string) => {
    try {
      let organization;
      if (tag === undefined) {
        organization = location.pathname.split("/");
      }
      const org: any = await get(
        `/organizations/${tag !== undefined ? tag : organization[2]}`
      );

      let userSocial = [];
      if (org.socialLinks?.length !== 0) {
        for (let i = 0; i < org.socialLinks?.length; i++) {
          userSocial.push({
            type: org.socialLinks[i].type.name,
            url: org.socialLinks[i].url,
          });
        }
      }
      let ownerArray:any[] = [];
      if (!isEdit) {
        ownerArray.push(JSON.parse(sessionStorage.getItem("user")));
        setOwner(ownerArray);
      }
      ownerArray.push(org.createdBy);

      setSocialCount(userSocial.length !== 0 ? userSocial.length : 1);
      setFormValues(org);

      setFormValues({ ...org, socialLinks: userSocial });
      setDescription(org.description);
      setTeamMembers(
        org.teamMembers ? org.teamMembers.map((item: any) => item.user) : []
      );

      setCreatedBy(org.createdBy)
      setOwner(ownerArray);
      setCreator(org.isOwner);

      if (org.logo) {
        setLogoToAdd(org.logo);
      }
      if (org.image) {
        setCoverImage(org.image);
      }
      if (org.partners.length > 0) {
        setPartnerLogos(
          org.partners.map((item: any) => ({
            partnerUrl: item.partnerUrl,
            name: item.name,
            image: `${imageLinkBase}/${item.image.filename}`,
          }))
        );
      }
      if (org.interests.length > 0) {
        setSelectedInterests(org.interests.map((i: any) => i));
        // setInteresList(project.interests.map((interests:any) => ({
        //   label: interests.name,
        //   value: interests.id,
        // })));
      }

      setLoaded(true)
    } catch (error) {
      if(error?.response.status === 404){
        setNavigationBlocked(false)
        message.error('We are sorry. This content might be deleted by the owners.', 5)
        history.push('/organizations')
      }
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      // console.log(err);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("edit-organization-draft")) {
      setIsDraft(true);
    }
    if (location.pathname.includes("new")) {
      setIsNew(true);
      setDescription(
        "Here, we recommend you introduce your organization to the startup community. Let's show them your primary field and how your services/products/programs can support them."
      );
    }
    if (
      location.pathname.includes("edit-organization") &&
      !location.pathname.includes("draft")
    ) {
      setIsEditmode(true);
    }
  }, []);

  useEffect(() => {
    getInterestList();

    if (isDraft) {
      getDraftById();
    }
    if (isEditmode) {
      getOrganizationById();
    } else {
    }
  }, [isDraft, isEditmode]);

  const onLogoChange = (url: string) => {
    setLogoToAdd(url);
    setModified(true);
  };

  const changeCover = (newImage: string) => {
    const url: string = coverImage;
    setCoverImage(newImage);
    if (url && !isEdit) {
      post("/image/delete", { url });
    }
    setModified(true);
  };

  const locationDisplay = (object: any) => {
    if (object.location && object.location.description) {
      return object.location.description;
    }
    if (object && object.location && object.location.city) {
      return `${object.location.country}, ${object.location.city}`;
    }
    if (object && object.location && !object.location.city) {
      return object.location.country;
    }
    return "";
  };

  const checkLink = (e: any) => {
    if (
      e.target.value.length > 7 &&
      !/^https?:\/\//.test(e.currentTarget.value)
    ) {
      e.currentTarget.value = "http://" + e.currentTarget.value;
    }
    setModified(true);
  };

  const deleteItem = (index: number, values: any) => {
    formValue.socialLinks.splice(index, 1);
    setSocialCount(socialCount - 1);
    if (formValue.socialLinks[index] === undefined) {
      values.socialLinks.splice(index, 1);
    }
    setModified(true);
  };

  const userFromStorage = sessionStorage.getItem("user");
  const profileProgress = userFromStorage
    ? JSON.parse(userFromStorage).progress
    : 0;

  const handleDraft = async (type: string, values: any, e: any) => {
    if (values.name.length > 3 && values.name.length < 50) {
      const draft = {
        ...values,
        description,
        image: coverImage,
        logo: logoToAdd,
        interests: selectedInterests.map((interest) => interest.id),
        teamMembers: teamMembers.map((member) => member.id),
        partners: partnerLogos,
        createdBy: owner
      };
      setNavigationBlocked(false);

      try {
        if (!isDraft) {
          const response: any = await post("/organizations/draft", draft).catch(
            (err) => {
              err?.response?.data?.details.map((detail: any) => {
                setErrorResponse(detail.location);
              });

              if (err.response.status === 403) {
                message.warning(
                  `${profileProgress}% of your profile is completed. To update the organization you need to get 100%`
                );
              }
            }
          );
        } else {
          const response: any = await patch(
            `/organizations/draft/all/${draft.id}`,
            draft
          ).catch((err) => {
            setErrorResponse(err?.response?.data.details);
            if (err.response.status === 403) {
              message.warning(
                `${profileProgress}% of your profile is completed. To update the organization draft you need to get 100%`
              );
            }
          });
        }
      } catch (error) {
        if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
        // console.log(err);
      }
      history.push("/organizations");
    } else {
      alert("Your title is invalid");
    }
  };

  const onSubmit = async (values: any, e: any) => {
    let formattedDescription = description
      if(description.includes("span")){
        formattedDescription = description.replace(/span style="text-decoration:underline"/g, "u").replace(/span/g, 'u')
      }
      if(description.includes("<a href")){
        formattedDescription = description.replace(/<a href/g, "<a target='_blank' href")
      }
    const organization = {
      ...values,
      description: formattedDescription,
      image: coverImage,
      logo: logoToAdd,
      interests: selectedInterests?.map((interest) => interest.id),
      teamMembers: teamMembers?.map((member) => member.id),
      partners: partnerLogos,
      createdBy: owner[0].id
    };
    setNavigationBlocked(false);
    if (isDraft) {
      delete Object.assign(organization, { draftId: organization.id }).id;
    }
    try {
      if (isEditmode) {
        const response: any = await patch(`/organizations/${tag}`, organization)
          .then(() => {
            history.push(`/organizations/${tag}`);
          })
          .catch((err) => {
            setErrorResponse(
              err.response.data.invalidSocialLinks[0].type +
                " " +
                err.response.data.message
            );
            setError("");
          });
      } else {
        tagValidator(formValue.tag);
        const response: any = await post("/organizations/create", organization)
          .then(() => {
            history.push(`/organizations`);
          })
          .catch((err) => {
            setErrorResponse(err?.response?.data.message)
            if (err.response.status === 403) {
              message.warning(
                `${profileProgress}% of your profile is completed. To create az organization you need to get at least 100%`
              );
            }
            if (err.response.data.invalidSocialLinks) {
              setErrorResponse(
                err.response.data.message +
                  " (" +
                  err.response.data.invalidSocialLinks[0].type +
                  ")"
              );
              setError("");
            }
          });
      }
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      // console.log(err);
    }
  };

  const checkFields = () => {
    if (selectedInterests.length < 1) {
      setError("Please select at least 1 interest");
    } else if (description.length < 300) {
      setError("Description must contain at least 300 characters");
    } else {
      setError("");
    }
  };

  const tagValidator = async (t: string) => {
    let valid = document.getElementById("valid-tag");
    await post("/organizations/validate-tag", { tag: t })
      .then((res) => {
        valid.innerHTML = "";
      })
      .catch((err) => {
        valid.innerHTML = err.response.data.message;
      });
    setModified(true);
  };

  const generateTag = async (name: string) => {
    if (!isTagEdited) {
      await post("/organizations/generate-tag", {
        name,
      }).then((res: any) => {
        setFormValues({ ...formValue, name: name, tag: res.tag });
      });
    }
  };

  const addItems = (selected: any) => {
    if (!selectedInterests.includes(selected)) {
      let item = selectedInterests;
      setSelectedInterests([...item, selected]);
    } else {
      deleteItems(selected.id);
    }
    setSelectedInterests;
    setModified(true);
  };

  const deleteItems = (id: any) => {
    setSelectedInterests(
      selectedInterests.filter((element: any) => element.id !== id)
    );
    setModified(true);
  };

  const deleteOrganization = async () => {
    if (isDraft) {
      let draftId;
      if (tag === undefined) {
        draftId = location.pathname.split("/");
      }
      await deleteReq(
        `organizations/draft/all/${tag !== undefined ? tag : draftId[2]}`
      ).then(() => {
        history.push("/organizations");
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          {isEdit ? "Edit organization" : "Create organization"} | pozi.io
        </title>
      </Helmet>
      <Prompt
        when={navigationBlocked}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {!loaded  ? <Loading /> :
        <div className="organization-body">
        <div onClick={() => setModified(true)}>
          <Cover url={coverImage} onChange={changeCover} />
          <div className="logo-uploader organizer-logo">
            <ImageUploader
              className="logo-uploader"
              uploadText="Upload logo Recommended 204x204 "
              images={logoToAdd}
              type="single"
              displayAllowed={false}
              onChange={(url: string) => {
                onLogoChange(url);
              }}
              onClick={() => setModified(true)}
              showCropper={true}
              cropperData={{ width: 230, height: 230 }}
            />
          </div>
        </div>
        <div>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .min(3, "Title must contain minimum 3 characters")
                .max(50, "The title is longer than 50 characters")
                .required("Title is required"),
              videoUrl: Yup.string().matches(
                /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)|^(?:<div[^>]+>)?<iframe src="https:\/\/(?:player\.)?vimeo\.com[^"]+"[^>]+><\/iframe>(?:<\/div>)?$/,
                "Please insert a valid video link"
              ),
              tag: Yup.string()
                .min(3, "Unique tag must contain minimum 3 characters")
                .max(20, "Unique tag is longer than 20 characters")
                .required("Unique tag is required"),
              shortDescription: Yup.string()
                .min(3, "One-liner must contain minimum 3 characters")
                .max(100, "One-liner can not be longer than 100 characters")
                .required("Short description is required"),
              selectedInterests: Yup.array()
                .min(1, "Minimum 1 interest")
                .max(5, "Maximum 5 interests"),
            })}
            enableReinitialize
            initialValues={formValue}
            onSubmit={(values, e) => {
              onSubmit(values, e);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              validateField,
              setErrors,
            }) => (
              <Form>
                <div className="organization-title-container">
                  <h1>Business organization’s name (required)</h1>
                  <FormikInput
                    name="name"
                    value={values.name}
                    onChange={(e: any) => {
                      if (e.currentTarget.value.length < 38) handleChange(e);
                    }}
                    onBlur={(e: any) => {
                      handleBlur(e);
                      setModified(true);
                      isNew && generateTag(e.currentTarget.value);
                    }}
                    placeholder="Title"
                    withError
                    error={errors.name && touched.name ? errors.name : ""}
                  />
                </div>
                <div className="organization-form-container">
                  <h1>Tell us something about the business organization</h1>
                  <div className="organization-input-container organization-location">
                    <h2>Location</h2>
                    <img
                      className="powered-by-google-onepager-edit"
                      src="/images/google/powered_by_google.png"
                      alt="Powered by Google"
                    />

                    <LocationSelector2
                      name="location"
                      placeholder={locationDisplay(values)}
                      value={locationDisplay(values)}
                      onChange={(e: any) => {
                        setFieldValue(
                          "location.placeId",
                          JSON.parse(e).placeId
                        );
                        setFieldValue(
                          "location.description",
                          JSON.parse(e).description
                        );
                      }}
                    />
                  </div>
                  <div className="organization-input-container">
                    <h2>One-liner (required)</h2>
                    <FormikInput
                      name="shortDescription"
                      value={values.shortDescription}
                      onChange={handleChange}
                      onBlur={(e: any) => {
                        handleBlur(e);
                        setModified(true);
                      }}
                      placeholder="Short description"
                      maxLength={480}
                      prefix={
                        <img src="/images/user_info_icons/actual_oneliner.svg" />
                      }
                      textStyle={'organization-interest-error'}
                      error={
                        errors.shortDescription && touched.shortDescription
                          ? errors.shortDescription
                          : ""
                      }
                    />
                  </div>
                  <div className="organization-input-container">
                    <h2>Description</h2>
                    {!!description && (
                      <div
                        className="organization-description-preview"
                        onClick={() => setModified(true)}
                      >
                        <HtmlEditor
                          schema={schema}
                          plugins={plugins}
                          value={description}
                          handleChange={setDescription}
                          debounce={300}
                        >
                          <Toolbar toolbar={toolbar} />
                          <Editor />
                        </HtmlEditor>
                      </div>
                    )}
                    {description.length < 300 && (
                      <div className="organization-interest-error">
                        Description must contain at least 300 character
                      </div>
                    )}
                    <ul>
                      <li>Introduce the organization (name, funfacts etc.)</li>{" "}
                      <li>Tell us about...</li>
                    </ul>
                  </div>
                  <div className="organization-input-container organization-social-media">
                    <h2>Social media</h2>
                    {_.times(socialCount, (i) => (
                      <>
                        <SocialLinks
                          type="string"
                          name={`socialLinks[${i}].url`}
                          value={
                            values.socialLinks ? values.socialLinks[i]?.url : ""
                          }
                          onChange={(e: any) => {
                            checkLink(e);
                            handleChange(e);
                            setModified(true);
                          }}
                          onBlur={(e: any) => {
                            handleBlur(e);
                            setModified(true);
                          }}
                          dropName={`socialLinks[${i}].type`}
                          dropvalue={
                            values.socialLinks
                              ? values.socialLinks[i]?.type
                              : ""
                          }
                          deleteItem={() => {
                            deleteItem(i, values);
                          }}
                        />
                      </>
                    ))}
                    {socialCount < 5 && (
                      <div
                        className="more-social-register"
                        onClick={() => setSocialCount(socialCount + 1)}
                      >
                        <img
                          src="/images/user_info_icons/more_social.svg"
                          alt=""
                        />
                      </div>
                    )}
                    {_.times(5, (i) => (
                      (!values.socialLinks[i]?.type && values.socialLinks[i]?.url) 
                      && <div className="organization-interest-error">{setError("Please select your social media type")}{setErrorResponse("Please select your social media type")}Please select your social media type</div>
                    ))}
                     {_.times(5, (i) => (
                      (values.socialLinks[i]?.type && !values.socialLinks[i]?.url) 
                      && <div className="organization-interest-error">{setError("Please insert your social media link")}{setErrorResponse("Please insert your social media link")}Please insert your social media link</div>
                    ))}
                  </div>
                  <div className="organization-input-container organization-unique-tag">
                    <h2>
                      Unique Virtual Competition Tag (Can’t be modified later)
                    </h2>
                    <FormikInput
                      name="tag"
                      value={values.tag}
                      onChange={(e: any) => {
                        tagValidator(e.currentTarget.value);
                        setTagEdited(true);
                        if (e.currentTarget.value.length < 38) handleChange(e);
                      }}
                      prefix={
                        <>
                          <img src="/images/user_info_icons/link.svg" />
                          <span className="organization-unique-tag-prefix">
                            https://pozi.io/organizations/
                          </span>
                        </>
                      }
                      onBlur={(e: any) => {
                        handleBlur(e);
                        setModified(true);
                      }}
                      placeholder="unique tag"
                      withError
                      error={errors.tag && touched.tag ? errors.tag : ""}
                    />
                    <div
                      id="valid-tag"
                      className="errors-frombe-onepager"
                    ></div>
                    Personalize your url:
                    <ul>
                      <li>
                        type in your personalized url after
                        “https://pozi.io/organizations/” part
                      </li>
                      <li>only use lowercase</li>
                      <li>only use letters from the english alphabet</li>
                      <li>do not use special characters</li>
                    </ul>
                  </div>
                  <div className="organization-input-container organization-video-upload">
                    <h2>Upload video</h2>
                    <FormikInput
                      name="videoUrl"
                      value={values.videoUrl}
                      onChange={handleChange}
                      onBlur={(e: any) => {
                        handleBlur(e);
                        setModified(true);
                      }}
                      placeholder="Type in or copy the link (https://)"
                      prefix={<img src="/images/organizations/video.svg" />}
                      withError
                      error={
                        errors.videoUrl && touched.videoUrl
                          ? errors.videoUrl
                          : ""
                      }
                    />
                    <span
                      style={
                        !errors.videoUrl
                          ? { marginTop: "-28px", position: "absolute" }
                          : { marginTop: "unset" }
                      }
                    >
                      Copy YouTube or Vimeo link.
                    </span>
                  </div>

                  <div className="organization-input-container organization-interests">
                    <h2>Define the organization’s interests</h2>
                    <div className={styles.selected_container}>
                      {selectedInterests && (
                        <div className={styles.selected_holder}>
                          {selectedInterests.map((selectedDetail: any) => (
                            <div className={styles.selected_item}>
                              <div>{selectedDetail.name}</div>
                              <div
                                onClick={() => {
                                  deleteItems(selectedDetail.id);
                                }}
                              >
                                <img
                                  className={styles.close_icon}
                                  src={"/images/close_black.svg"}
                                  alt=""
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="select-items-container">
                      <div className="main-category">
                        <div className="title-category">Main topics</div>
                        <hr className="registration_hr" />
                        <div className="main-category-holder">
                          {interestGroups.map((groupElem: any) => (
                            <div
                              key={groupElem.id}
                              className={
                                selectedItemId === groupElem.id
                                  ? "main-category-holder-selected"
                                  : "main-category-item"
                              }
                              onClick={() => {
                                setSelectedCategory(groupElem.interests),
                                  setSelectedItemId(groupElem.id),
                                  setSelectedSubtitle(groupElem.name);
                              }}
                            >
                              {groupElem.name}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="details-category">
                        <div className="title-details">{selectedSubtitle}</div>
                        {selectedCategory && (
                          <div className="details-select">
                            {selectedCategory.map((selected: any) => (
                              <Checkbox
                                key={selected.id}
                                checked={selectedInterests.some(
                                  (item: any) => item.id == selected.id
                                )}
                                className="check-select"
                                onChange={() => {
                                  addItems(selected);
                                }}
                              >
                                {selected.name}
                              </Checkbox>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="organization-interest-error">
                      {error.includes("interest") && error}
                    </div>
                  </div>

                  <div className="organization-input-container organization-partner-container">
                    <h2>Choose the organization’s partners</h2>
                    <div>
                      <PartnerLogos
                        value={partnerLogos}
                        onChange={(e: any) => {
                          setPartnerLogos(e);
                          setModified(true);
                        }}
                      />
                    </div>
                  </div>

                  {(isNew || isDraft || creator || isAdmin()) && (
                    <div className="organization-input-container organization-add-team">
                      <h2>Invite team members</h2>
                      <div>
                        <UserSearch
                          multiple
                          value={teamMembers}
                          onChange={(e) => {
                            setTeamMembers(e);
                            setModified(true);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {(isNew || isDraft || creator || isAdmin()) && (
                    <div className="organization-input-container organization-add-team">
                      <h2>Owner</h2>
                      <Tooltip
                        placement="topLeft"
                        title={
                          "With this step, you delegate the Owner status to another user, your role will change to team member."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                      <div>
                        <UserSearch
                          value={owner}
                          onChange={(e) => {
                            ownerChange(e);
                            setModified(true);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="organization-interest-error error-response-organization">
                  {errorResponse}
                </div>
                <div className="launch-buttons">
                  {!isEditmode && (
                    <Button
                      id="savebutton"
                      size="large"
                      className="launch-btn"
                      onClick={(e: any) => {
                        validateField("name");
                        if(!errors.videoUrl){
                          handleDraft("savedraft", values, e);
                        }
                        //   checkDraftErrors(errors,values)
                      }}
                    >
                      Save Draft
                    </Button>
                  )}

                  <Button
                    size="large"
                    onClick={() => history.push(`/organizations`)}
                    className="launch-btn"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="launch-btn"
                    onClick={(e: any) => {
                      setErrorResponse("")
                      checkFields();
                      if (error === "") {
                        handleSubmit(e);
                      }
                    }}
                    disabled={!modified}
                  >
                    {!isEditmode ? "Launch" : "Update"}
                  </Button>
                </div>
                {isDraft && (
                  <div
                    className="organization-delete-button"
                    onClick={deleteOrganization}
                  >
                    Delete business organization draft
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>}
    </div>
  );
};

interface Props {
  form: WrappedFormUtils;
  isEdit?: boolean;
  isDraft?: boolean;
  isCreating?: boolean;
}

export default Form.create<Props>({ name: "event" })(NewOrganization);
