import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import Cover from '../Cover';
import { IPartnerLogo } from './PartnerLogos';
import FormikInput from '../../../common/form/FormikInput';

const styles = {
  label: {
    marginBottom: 10,
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    color: '#282828',
  },
  image: { height: 190, borderRadius: 5, marginTop: 0 },
};

const isValidUrl = (value: string) => (value ? !!(value.startsWith('http://') || value.startsWith('https://')) : true);

const PartnerLogoModal = ({
  partnerLogo, visible, onSave, onCancel,
}: Props) => {
  const [partnerUrl, setPartnerUrl] = useState('');
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [oldImages, setOldImages] = useState([]);
  const [urlError, setUrlError] = useState('');
  const [nameError, setNameError] = useState('');

  useEffect(() => {
    if (visible) {
      setName(partnerLogo.name);
      setPartnerUrl(partnerLogo.partnerUrl);
      setImage(partnerLogo.image);
      setNameError('');
    }
  }, [partnerLogo, visible]);

  const handleOk = () => {
    if (!image) {
      message.error('Please upload an image.');
    } else if (!urlError && !nameError) {
      onSave({ image, name, partnerUrl }, oldImages);
      setOldImages([]);
    }
  };

  const handleCancel = () => {
    onCancel(oldImages);
    setOldImages([]);
  };

  const validateUrlField = (v: string) => {
    if (isValidUrl(v)) {
      if (urlError) {
        setUrlError('');
      }
    } else if (!urlError) {
      setUrlError('Please insert a valid URL.');
    }
  };

  const validateName = (v: string, required: boolean) => {
    const errors = [];
    if (required && !v) {
      errors.push('This field is required.');
    }
    if (v && v.length < 3) {
      errors.push('Name must be at least 3 characters.');
    }
    if (v && v.length > 30) {
      errors.push('Name cannot be longer than 15 characters.');
    }
    if (errors.length) {
      setNameError(errors[0]);
    } else {
      setNameError('');
    }
  };

  const changeImage = (s: string) => {
    if (image) {
      setOldImages([...oldImages, image]);
    }
    setImage(s);
  };

  return (
    <Modal
      title='Upload partner logo'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
    >
      <div style={styles.label}>Partner logo</div>
      <Cover
        url={image}
        onChange={changeImage}
        rootStyle={styles.image}
        imageStyle={{ backgroundSize: 'contain' }}
        deletable={false}
      />
      <div style={{ ...styles.label, marginTop: 32 }}>Name</div>
      <FormikInput
        name='name'
        value={name}
        onChange={(v: any) => {
          const { value } = v.target;
          setName(value);
          validateName(value, false);
        }}
        error={nameError}
      />
      <div style={{ ...styles.label, marginTop: 10 }}>Partner url</div>
      <FormikInput
        name='url'
        value={partnerUrl}
        onChange={(v: any) => {
          const { value } = v.target;
          setPartnerUrl(value);
          validateUrlField(value);
        }}
        error={urlError}
      />
    </Modal>
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  partnerLogo: IPartnerLogo;
  visible: boolean;
  onSave: (partnerLogo: IPartnerLogo, oldImages?: string[]) => void;
  onCancel: (oldImages?: string[]) => void;
}

export default PartnerLogoModal;
