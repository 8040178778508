import React, {createRef} from "react";
import {MessageContent} from "./MessageContent";
import {Message} from "./MessageInterface";

interface Props {
    messages: Message[]
}

export class MessageScrollbox extends React.Component<Props> {
    messages: any = createRef();
    key: any = null;

    scrollToBottom = () => {
        if (this.messages !== null && this.key !== this.props.messages.length) {
            this.key = this.props.messages.length;
            this.messages.scrollIntoView({behavior: "smooth"});
        }
    }

    async componentDidUpdate() {
        this.scrollToBottom();
    }

    async componentDidMount() {
        this.scrollToBottom();
    }

    render() {
        const { messages } = this.props;
        return <div className={'Messages Messages-uniqeScrollbar'} key={messages.length}>
            {
                messages.length === 0 ? <div style={{textAlign: 'center',marginTop: '10%',color:'#444'}}>No messages yet</div> : null
            }
            {messages.map((v, i) => <MessageContent key={i} messages={messages} i={i} />)}
            <div className={'clear'} ref={(el) => {
                this.messages = el;
            }} />
        </div>;
    }
}