import { Icon } from "antd";
import { url } from "inspector";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { user } from "../../libs/utils/user";
import User from "../../user/User";
import Project from "../Project/Project";
import "./MemberEdit.css";

interface Props {
  member: any;
  editTeamMember: (member: any) => void;
  deleteTeamMember: (id: string) => void;
  index: number;
  project?: Project;
  isNew?: boolean;
}

const MemberEdit = ({
  member,
  editTeamMember,
  deleteTeamMember,
  index,
  project,
  isNew,
}: Props) => {
  const [owner, setowner] = useState(false);
  const [error, setError] = useState(false);
  const titleRef = useRef();
  const descriptionRef = useRef();

  const getFields = (title?: string, description?: string) => {
    title ? (member.title = title) : member.title;
    description ? (member.description = description) : member.description;

    editTeamMember(member);
  };
  useEffect(() => {
    if ((project.user && member.user.id === project.user.id) || (isNew && member.user.id === user().id)) {
    setowner(true);
    }
  }, [member, project]);
  return (
    <div className={(index + 1) % 4 !== 0 ? "one-member" : "one-member-fourth"}>
      <div className="user-data-holder">
        <div className="profileimg-name">
          <div
            className="profile-img"
            style={{
              backgroundImage: `url(${
                member.user.profileImage || "/images/user.svg"
              } )`,
            }}
          >
          </div>
          <Link to={"/entrepreneurs/" + member.user.uniqueTag}>
            <div className="user-name"> {member.user.firstName + ' ' + member.user.lastName} </div>
          </Link>
        </div>
        {!owner && (
          <Icon
            className="delete-member-icon"
            type="close"
            onClick={() => deleteTeamMember(member.user.id)}
          />
        )}
      </div>
      <div className="input-holder">
        <div className="user-title-input">
          <input
            type="text"
            required={true}
            ref={titleRef}
            defaultValue={member.title}
            onBlur={(e) => {
              getFields(e.target.value, null);
              if (e.target.value.length === 0) setError(true);
            }}
            placeholder="Role"
            maxLength={28}
          />
        </div>
        <div className="user-description-input">
          <textarea
            rows={3}
            required={true}
            ref={titleRef}
            defaultValue={member.description}
            // onChange={() => console.log(descriptionRef)}
            onBlur={(e) => {
              getFields(null, e.target.value);
              if (e.target.value.length === 0) setError(true);
            }}
            placeholder="What are your teammate's responsibilities?"
            maxLength={100}
          />
        </div>
      </div>
    </div>
  );
};

export default MemberEdit;
