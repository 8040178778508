// @flow
import axios from 'axios';
import eventbus from '../eventbus';

export const getAuthenticationHeader = () => {
  const headers: any = {};
  const token = localStorage.getItem('token');

  if (token) {
    headers.token = token;
  }

  return headers;
};

export function getBaseUrl() {
  return process.env.REACT_APP_API_URL;
}

function getAxtiosInstance() {
  return axios.create({
    baseURL: getBaseUrl(),
    timeout: 60000,
    headers: getAuthenticationHeader(),
  });
}

export const post = function post<T>(uri: string, body?: any, config?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    getAxtiosInstance().post(uri, body, config).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      // console.error(error)
      if (!uri.includes('logout') && error.response && error.response.status === 401) {
        eventbus.notify(eventbus.events.logout);
      }
      reject(error);
    });
  });
};

export const postAsForm = function postAsForm<T>(uri: string, formData: FormData): Promise<T> {
  return post(uri, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const patch = function patch<T>(uri: string, body?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    getAxtiosInstance().patch(uri, body).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      // console.error(error)
      if (!uri.includes('logout') && error.response && error.response.status === 401) {
        eventbus.notify(eventbus.events.logout);
      }
      reject(error);
    });
  });
};

export const get = function get<T>(uri: string, params?: Object): Promise<T> {
  return new Promise((resolve, reject) => {
    getAxtiosInstance().get(uri, {
      params,
    }).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      // console.error(error)
      if (!uri.includes('logout') && error.response && error.response.status === 401) {
        eventbus.notify(eventbus.events.logout);
      }
      reject(error);
    });
  });
};

export const deleteReq = (uri: string, data?: Object) => new Promise((resolve, reject) => {
  getAxtiosInstance().delete(uri, {
    data,
  }).then((result) => {
    resolve(result.data);
  }).catch((error) => {
    // console.error(error)
    if (!uri.includes('logout') && error.response && error.response.status === 401) {
      eventbus.notify(eventbus.events.logout);
    }
    reject(error);
  });
});

export const download = async (uri: string, filename: string) => new Promise((resolve, reject) => {
  getAxtiosInstance().get(uri, {
    responseType: 'blob',
  }).then((result) => {
    // console.log(result)
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }).catch((error) => {
    // console.error(error)
    reject(error);
  });
});

function twoDigits(d: number) {
  if (d >= 0 && d < 10) return `0${d.toString()}`;
  if (d > -10 && d < 0) return `-0${(-1 * d).toString()}`;
  return d.toString();
}
