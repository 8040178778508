import React from 'react';
import { Input, message } from 'antd';
import { user } from '../../libs/utils/user';
import { post } from '../../libs/utils/request';
import { CommentData } from './Comments';

interface Props {
  postURL: string
  onNewComment?: () => void
  placeholder?: string
  reply?: CommentData
  style?: any
  type?: string;
}


export class CommentHead2 extends React.Component<Props, any> {
  comment = ''

  render() {
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress = userFromStorage ? JSON.parse(userFromStorage).progress : 0
        
    const sendComment = async () => {
      try {
        if (this.comment.replace(/\s/g, '').length) {
          await post(this.props.postURL, {
            message: this.comment,
            replyId: this.props.reply ? this.props.reply.id : undefined,
          });
        }
        this.comment = '';
        this.forceUpdate();
      } catch (err) {
      // console.log(err)
      }
      this.props.onNewComment && this.props.onNewComment();
}
    return (
      <div className='CommentHead2' style={this.props.style}>
        <div
          className='comment-profile-img'
          style={{
            backgroundImage: `url(${user().profileImage || '/images/user.svg'
          } )`,
          }}
        />
        <div className='message'>
          <p>
            <Input
              value={this.comment}
              placeholder={this.props.placeholder || 'Comment'}
              className='message-input'
              size='small'
              onPressEnter={() => {
                sendComment()
              }}
              onClick={()=> 
                 '' }
              onChange={(e) => {
                this.comment = e.target.value;
                this.forceUpdate();
              }}
            />
          </p>
        </div>
      </div>
    );
  }
}
