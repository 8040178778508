import React, { useState, useEffect } from "react";
import { ga } from "react-ga";
import { get, patch } from "../../../libs/utils/request";
import User from "../../../user/User";
import RoleItem from "../RoleItem";
import styles from "../Roles.module.css";
import RegistrationButtons from "./RegistrationButtons";

interface Props {
  roles?: any;
  onClick?: (elem: any) => void;
  next: () => any;
  back: () => any;
  submit: (details: any) => any;
  // user?: User;
  // selectedRoleId: any
}

const Roles = ({ roles, next, back, submit }: Props) => {
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(()=>{
    ga('send', { 'hitType': 'pageview', 'page': 'registration/roles', 'title': 'Registration roles' });
  },[])

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
  }, [selectedRoleId]);

  useEffect(() => {
    if (user && user.role !== null) {
      setSelectedRoleId(user.role.id);
    }
  }, [next]);

  const onSubmit = async () => {
    const userDetails = {
      role: selectedRoleId,
    };
    submit(userDetails);
    // await patch("/user", userDetails).then((res) => {
    //   sessionStorage.setItem("user", JSON.stringify(res));
    //   setUser(user)
    //   // setSelectedRoleId(user.role.id)
    // });
  };
  return (
    <>
      <div className={styles.roles_holder}>
        {roles.map((role: any) => (
          <RoleItem
            icon={role.icon}
            name={role.name}
            roleId={role.id}
            key={role.id}
            onClick={setSelectedRoleId}
            selectedRoleId={selectedRoleId}
          />
        ))}
      </div>
      <div className="errors-frombe-onepager" id="errors"></div>
      <RegistrationButtons
        roleName="Step2 (Roles)"
        showBackbutton={true}
        showNextbutton={true}
        showSkipButton={true}
        next={next}
        back={back}
        save={() => {
          onSubmit();
        }}
      />
    </>
  );
};

export default Roles;
