import { Button } from "antd";
import React from "react";
import "./AdditionalInfos.css";

interface Props {
  additionalInfo?: any;
  pitchLink?: any;
}

function AdditionalInfos({ additionalInfo, pitchLink }: Props) {
  return (
    <div className="aditional-infos">
      <h3>Links</h3>
      {!!pitchLink && (
        <Button
          className="pitch-link-button"
          type="link"
          size="large"
          onClick={() => {
            window.open(pitchLink, "__blank");
          }}
        >
          <img src="../../images/project_highlight_icons/pitchlink.svg" alt="" />
          Pitch link
        </Button>
      )}
      {!!additionalInfo && (
        <Button
          className="additionalInfo-link-button"
          type="link"
          size="large"
          onClick={() => {
            window.open(additionalInfo, "__blank");
          }}
        >
          <img src="../../images/project_highlight_icons/addInfo.svg" alt="" />
          Additional information
        </Button>
      )}
    </div>
  );
}

export default AdditionalInfos;
