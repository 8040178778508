import React, { CSSProperties } from 'react';
import { Form, Input } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

const styles = {
  root: {
    width: '100%',
  },
};

const FormInput = ({
  form, fieldKey, rules, placeholder, required, requiredMessage, initialValue, style, disabled,
}: Props) => (
  <Form.Item>
    {form.getFieldDecorator(fieldKey, {
      rules: [...rules, { required, message: requiredMessage }],
      initialValue,
    })(
      <Input
        style={{ ...styles.root, ...style }}
        placeholder={placeholder}
        disabled={disabled}
      />,
    )}
  </Form.Item>
);

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  rules?: any[];
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  initialValue?: string;
  style?: CSSProperties;
  disabled?: boolean;
}

FormInput.defaultProps = {
  required: false,
  rules: [],
  placeholder: '',
  requiredMessage: 'This field is required!',
  initialValue: '',
  style: {},
  disabled: false,
};

export default FormInput;
