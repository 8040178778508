import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { get, getAuthenticationHeader, getBaseUrl } from '../../../libs/utils/request';
import { Loading } from '../../common/Loading';

const baseText = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: 15,
  color: '#282828',
};

const styles = {
  title: {
    ...baseText,
    fontSize: 20,
    fontWeight: 900,
    textAlign: 'center' as const,
  },
  errorText: {
    fontSize: 15,
    textAlign: 'center' as const,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 15,
    marginTop: 10,
    textAlign: 'center' as const,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
};

const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const sleep = async (ms: number) => {
  await timeout(ms);
};

/* eslint-disable no-await-in-loop */
const downloadFile = async (tag: string, setError: any, onCancel: any) => {
  let running = true;
  try {
    setError(false);
    const { filename } = await get(`/events/${tag}/generate-summary`);
    const link = document.createElement('a');
    link.target = '_blank';
    // setTimeout(() => {
    //   running = false;
    //   setError(true);
    // }, 30000);


    while (running) {
      try {
        const response = await axios
          .get(`${getBaseUrl()}/events/${tag}/summary/${filename}`, {
            responseType: 'blob',
            headers: getAuthenticationHeader(),
          });
        link.href = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );
        link.download = `event_summary_${tag}_${moment().format('MMM-DD-YYYY')}`;
        link.click();
        onCancel();
        running = false;
      } catch (error) {
        await sleep(2000);
      }
    }
  } catch (error) {
    setError(true);
  }
};

const GenerateSummaryModal = ({ tag, visible, onCancel }: Props) => {
  const [isError, setError] = useState(false);
  useEffect(() => {
    if (visible) {
      downloadFile(tag, setError, onCancel);
    }
  }, [visible, tag, onCancel]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
    >
      {!isError ? (
        <>
          <div style={styles.title}>Generating your file</div>
          <Loading style={{ paddingTop: 40, paddingBottom: 20 }} />
        </>
      ) : (
        <>
          <div style={styles.errorText}>Failed to download summary</div>
          <div
            style={styles.errorButtonText}
            onClick={() => downloadFile(tag, setError, onCancel)}
            aria-hidden='true'
          >
            retry
          </div>
        </>
      )}
    </Modal>
  );
};

interface Props {
  tag: string;
  visible: boolean;
  onCancel: any;
}

export default GenerateSummaryModal;
