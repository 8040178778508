import React from "react"
import { Checkbox } from 'antd'
import eventbus from "../../../libs/eventbus"
import { SkillGroup, Skill } from "../Registration"

interface Props {
  skillGroups: SkillGroup[]
  selectedSkills: Skill[]
  onClick: any
}

interface State {
  selectedSkills: Skill[]
  selectedGroup: string
}

export default class Skills extends React.Component<Props, State> {

  state: State = {
    selectedSkills: this.props.selectedSkills,
    selectedGroup: this.props.skillGroups[0].id,
  }

  componentDidMount() {
    eventbus.addListener(
      eventbus.events.validateRegistration,
      "Skills",
      () => { return this.state.selectedSkills.length >= 5 },
      "Please select at least 5 skills"
    )
  }

  componentWillReceiveProps(newProps: Props) {
    if (newProps.selectedSkills !== this.props.selectedSkills) {
      this.setState({ selectedSkills: newProps.selectedSkills })
    }
  }

  componentWillUnmount() {
    eventbus.removeListener(eventbus.events.validateRegistration, "Skills")
  }

  handleGroupSelect = (id: string) => {
    this.setState({ selectedGroup: id })
  }

  render() {
    const { selectedSkills } = this.state
    const selectedGroup = this.props.skillGroups.find(group => group.id === this.state.selectedGroup)
    const skills = selectedGroup.skills

    const getCounterText = () => {
      if (selectedSkills.length === 5) {
        return 'Done';
      }
      const k = 5 - selectedSkills.length;
      return `Select more ${k} skill${k > 1 ? 's' : ''}`;
    };

    return (
      <div className="step step-skills">
        <div className="step-title">
          Choose 5 skills which represent you the most
      </div>
        <div className="step-skills-skills-container">
          <div className="skill-group-buttons">
            {
              this.props.skillGroups.map((group: SkillGroup) =>
                <div
                  className={`group-button ${group.id === this.state.selectedGroup && "selected"}`}
                  onClick={() => this.handleGroupSelect(group.id)}
                >
                  {group.name}
                </div>
              )
            }
          </div>
          <div style={{
            textAlign: 'center',
            fontSize: '15px',
            padding: '0px 0px 24px',
            color: selectedSkills.length === 5
                ? '#007600'
                : undefined
          }}>
            {getCounterText()}
          </div>
          <div className="skill-group-skills">
            {
              skills.map(skill =>
                <Checkbox
                  className="skill"
                  disabled={!selectedSkills.includes(skill) && selectedSkills.length === 5}
                  onChange={() => this.props.onClick(skill)}
                  checked={this.props.selectedSkills.includes(skill)}
                >
                  {skill.name}
                </Checkbox>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}