import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import moment from 'moment'
import React from 'react'
import "./Deadline_Location.css";

function Deadline_Location({ city, country, deadline }: Props) {
    return (
        <div className="deadline-location">
            <div className="deadline">
                <div className="deadline-icon-text">
                    <CalendarOutlined className="icon" />
                    <span>Application deadline: </span>
                </div>
                <span className="deadline-date">{moment(deadline).format("YYYY.MM.DD")}</span>
            </div>
            <div className="location">
                <EnvironmentOutlined className="icon" />
                <span>{country}{city ? ", " + city : ""}</span>
            </div>
        </div>
    )
}

export default Deadline_Location

interface Props {
    city?: string;
    country: string;
    deadline: string;
}