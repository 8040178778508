import React, { CSSProperties, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Form } from 'antd';
import { LocationSelector2 } from '.';

export const FormLocationSelector = ({
  form, fieldKey, rules, required, requiredMessage, placeholder, style, types, draftvalue
}: Props) => {
  const [value, setValue] = useState(draftvalue ? draftvalue : '');
  return (
    <Form.Item style={{marginLeft:"inherit"}}>
      {form.getFieldDecorator(fieldKey, {
        rules: [...rules, { required, message: requiredMessage }],
      })(
        <LocationSelector2
          onChange={setValue}
          value={value}
          placeholder={value.country ? value.country : 'Type your location'}
          style={{ ...style, margin: 0 }}
          types={types}
        />,
      )}
    </Form.Item>
  );
};

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  rules?: any[];
  required?: boolean;
  requiredMessage?: string;
  placeholder?: string;
  style?: CSSProperties;
  types?: string[];
  draftvalue?: any;
}

FormLocationSelector.defaultProps = {
  rules: [],
  required: false,
  requiredMessage: 'This field is required!',
  placeholder: '',
  style: {},
  types: null,
};
