import { CaretDownOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Pagination,message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { get } from "../../../../libs/utils/request";
import { CompetitionCard } from "../../../CardView/ProjectCard";
import { ICompetition } from "../../../Competitions/Competition/Competition";

const Competitions = (organization: any) => {
  const [competitions, setCompetitions] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState("Sort by");
  const [sorter, setSorter] = useState({ sort: "deadline", order: "DESC" });

  const { tag } = useParams();
  const history = useHistory();

  useEffect(() => {
    getCompetitions(currentPage);
  }, [sorter]);

  const getCompetitions = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    get(`/organizations/${tag}/competitions`, {
      currentPage: pageNumber,
      limit: 10,
      sort: sorter.sort,
      order: sorter.order,
    }).then((res: any) => {
      setCompetitions(res.data);
      setPagination(res.pagination);
    });
  };

  const createNew = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user.progress === 100) {
      localStorage.setItem(
        "organization",
        JSON.stringify(organization.organization)
      );
      window.location.replace(window.origin + "/new-competition");
    } else {
      message.warning(
        `${user.progress}% of your profile is completed. To create competition you need to get 100%`
      );
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setSorting("Application deadline (earlier)");
          setSorter({ sort: "deadline", order: "ASC" });
        }}
      >
        Application deadline (earlier)
      </Menu.Item>
      <Menu.Item
        key="0"
        onClick={() => {
          setSorting("Application deadline (later)");
          setSorter({ sort: "deadline", order: "DESC" });
        }}
      >
        Application deadline (later)
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setSorting("name (A-Z)");
          setSorter({ sort: "name", order: "ASC" });
        }}
      >
        name (A-Z)
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setSorting("name (Z-A)");
          setSorter({ sort: "name", order: "DESC" });
        }}
      >
        name (Z-A)
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {organization && (
        <div className="organization-competition-container">
          <div className="organization-competitions-title-container">
            <span>
              <h2 className="organization-competition-title">Competitions</h2>
              <span className="organization-competition-counter">
                ({pagination?.totalCount} {pagination?.totalCount > 1 || pagination?.totalCount == 0 ? 'competitions' : 'competition'})
              </span>
            </span>
            {organization.organization.isMember && (
              <Button type="primary" onClick={createNew}>
                Create new{" "}
              </Button>
            )}
          </div>
          {competitions.length < 1 && (
            <div className="organizations-nothing-to-show">Nothing to Show</div>
          )}
          {competitions.length > 0 && (
            <>
              <div className="organization-pagination">
                {pagination !== null && (
                  <Pagination
                    total={pagination.pageCount}
                    defaultPageSize={1}
                    current={pagination.currentPage}
                    onChange={getCompetitions}
                  />
                )}
              </div>
              <Dropdown overlay={menu} className="organization-sorter">
                <p>
                  {sorting} <CaretDownOutlined />
                </p>
              </Dropdown>
              <div className="organization-competition-content">
                {competitions.map((competition: any) => (
                  <CompetitionCard
                    leadButtonText={competition.leadButtonText}
                    key={competition.tag}
                    competition={competition}
                    onChange={() => getCompetitions(currentPage)}
                    onClick={() => {
                      history.push(`/competitions/${competition.tag}`);
                    }}
                  />
                ))}
              </div>
              <div className="organization-pagination">
                {pagination !== null && (
                  <Pagination
                    total={pagination.pageCount}
                    defaultPageSize={1}
                    current={pagination.currentPage}
                    onChange={getCompetitions}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}{" "}
    </>
  );
};

export default Competitions;
