import React, { ReactElement , useState, useRef, useEffect} from 'react';
import { Button, Modal, Checkbox, Steps, } from 'antd';
import { post , get, patch} from '../../libs/utils/request';
import { useHistory, useLocation } from 'react-router';
import './NewRegistration.css'
import Roles from './Registration_steps/Roles';
import UserDetails from './Registration_steps/userDetails';
// import User from './Registration_steps/UserInfo';
import NewSkills from './Registration_steps/newSkills';
import NewInterests from './Registration_steps/NewInterests';
import ReactPixel from 'react-facebook-pixel';

import User from '../../user/User';

const { Step } = Steps;

const NewRegistration = ({}: Props) => {
  const [skills, setSkills] = useState(null);
  const [roles, setRoles] = useState([]);
  const [interests, setInterests] = useState([]);
  const [user, setUser] = useState(null);
  const[error, setError] = useState('')
  const[checking, setChecking] = useState(true)
  const [current, setCurrent] = useState(0);
  const history = useHistory()

  useEffect(()=>{
    ReactPixel.fbq('track', 'CompleteRegistration');
  },[])

  const nextStep = async () => {
    setCurrent(current + 1);
  };

  const prevStep = async () => {
    setCurrent(current - 1);
  };

  const finishRegistration = async () => {
      history.push('/dashboard')
  }

  const steps = [
    {
      title: "Tell us something about you",
      content: (
        <UserDetails
        next={() => {if(checking===false&& error==='')nextStep();
          }}
          back={() => {}}
          submit={(userDetails) => {
            onSubmit(userDetails);
          }}
          error={error}
        />
      ),
    },
    {
      title: "Define your role by clicking one of the categories below",
      content: (
        <Roles
          next={() => {
            nextStep();
          }}
          back={() => {
            prevStep();
          }}
          roles={roles}
          submit={(UserDetails) => {
            onSubmit(UserDetails);
          }}
        />
      ),
    },
    {
      title: "Choose 5 skills which represent you the most!",
      content: (
        <NewSkills
          next={() => {
            nextStep();
          }}
          back={() => {
            prevStep();
          }}
          skills={skills}
          submit={(UserDetails) => {
            onSubmit(UserDetails);
          }}
        />
      )
    },
    {
      title: "Define your interests, choose minimum 3",
      content:(
        <NewInterests
          next={() => {
            finishRegistration();
            window.location.replace(window.location.protocol + "//" + window.location.host+'/notActivated')
          }}
          back={() => {
            prevStep();
          }}
          interests={interests}
          submit={(UserDetails) => {
            onSubmit(UserDetails);
          }}
        />
      )
    },
  ];

  const finish = async (url:string)=> {
    window.location.replace(url)
  }

  const onSubmit = async (userDetails: any) => {
    try {
      await patch("/user", userDetails)
        .then((user) => {
          setChecking(true)
          setError('')
          sessionStorage.setItem("user", JSON.stringify(user));
          if(current===0)
          setCurrent(current+1)
        })
        .catch((err) => {
          setChecking(false)
          err.response.data?.details?.map((e:any)=>{
          if(e.birthday)
            setError(e.birthday)
          })
          err.response.data?.invalidSocialLinks?.map((e:any)=>{
           if(e.url)
            setError(e.url+' url is invalid')
          else 
            if(e.type)
            setError(e.type+' link is invalid')
          })
          console.log(err)
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
  const regData =  get('/register/init')
  .then((result:any)=> {
    if(result.token){
    }
    setSkills(result.skillGroups)
    setRoles(result.roles)
    setInterests(result.interestGroups)
  })
},[current]);
  
  return (
    <>
      <div className="registration-container">
        <div className="registration-header">
          <div className="registration-title-container">
            <h1 className="registration-title">{steps[current].title}</h1>
          </div>
          <div className="step-container">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} />
              ))}
            </Steps>
          </div>
        </div>
        <div className="steps-content">{steps[current].content}</div>
      </div>
    </>
  );
};

interface Props {
  
  title?: string;
  description?: string | ReactElement;
}

export default NewRegistration;
