import React, { CSSProperties, useEffect, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import ItemWithIcon from '../../ItemWithIcon';
import { IIdeaListData } from '../Ideas';

const baseText = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: 16,
  color: '#282828',
};

const CARD_BORDER = '.5px solid #D1D1D1';

const cardStyles = {
  topCard: {
    borderTop: CARD_BORDER,
    borderLeft: CARD_BORDER,
    borderRight: CARD_BORDER,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  card: {
    borderTop: CARD_BORDER,
    borderLeft: CARD_BORDER,
    borderRight: CARD_BORDER,
  },
  bottomCard: {
    border: CARD_BORDER,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
};

const styles = {
  root: {
    width: '100%',
  },
  header: {
    width: '100%',
    height: 48,
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    gap: '0px 16px',
    paddingLeft: 16,
    paddingRight: 16,
  },
  arrowIcon: {
    fontSize: 18,
    color: '#6755CE',
    cursor: 'pointer',
  },
  body: {
    width: '100%',
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '8px 8px',
  },
  detailContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '0 10px',
  },
  detailTitle: {
    ...baseText,
    fontWeight: 800,
  },
  detailValue: baseText,
  btn: {
    fontWeight: 'bold' as const,
  },
};

const MobileList = ({
  listData, onDelete, onShortlist, disabled, style, evaluationStatus,
}: Props) => {
  const [collapses, setCollapses] = useState([]);

  useEffect(() => {
    const l = Array(listData.length);
    for (let i = 0; i < listData.length; i += 1) {
      l[i] = false;
    }
    setCollapses(l);
  }, [listData.length]);

  const getCardStyle = (i: number) => {
    const isTop = i === 0;
    const isBottom = i === listData.length - 1;
    let res = isTop ? cardStyles.topCard : {};
    if (isBottom) {
      res = { ...res, ...cardStyles.bottomCard };
    }
    return { ...res, ...cardStyles.card };
  };

  const handleClick = (i: number) => {
    setCollapses((state) => {
      const tmp = [...state];
      tmp[i] = !tmp[i];
      return tmp;
    });
  };

  return (
    <div style={{ ...styles.root, ...style }}>
      {listData.map((item, i) => (
        <div
          key={item.id}
          style={{ ...styles.root, ...(getCardStyle(i)) }}
          onClick={() => handleClick(i)}
          aria-hidden='true'
        >
          <div style={styles.header}>
            <CaretRightOutlined
              rotate={collapses[i] ? 90 : 0}
              style={{ ...styles.arrowIcon, color: collapses[i] ? '#6755CE' : '#898989' }}
            />
            <ItemWithIcon
              icon={item.idea.image}
              text={item.idea.name}
              link={`/idea/${item.idea.uniqueTag}`}
            />
          </div>
          <Collapse in={!!collapses[i]} timeout='auto' unmountOnExit>
            <div style={styles.body}>
              {!!item.idea.pitchLink && (
                <div style={styles.detailContainer}>
                  <div style={styles.detailTitle}>Pitch link:</div>
                  <div
                    style={{
                      ...styles.detailValue,
                      color: '#6755CE',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(item.idea.pitchLink, '_blank');
                    }}
                    aria-hidden='true'
                  >
                    link
                  </div>
                </div>
              )}
              <div style={styles.detailContainer}>
                <div style={styles.detailTitle}>Founder:</div>
                <div style={styles.detailValue}>{item.idea.user.firstName+' '+item.idea.user.lastName}</div>
              </div>
              <div style={styles.detailContainer}>
                <div style={styles.detailTitle}>Team:</div>
                <div style={styles.detailValue}>{item.membersCnt ? item.membersCnt : 'N/A'}</div>
              </div>
              <div style={styles.detailContainer}>
                <div style={styles.detailTitle}>Evaluation:</div>
                <div style={styles.detailValue}>{item.score?item.score:'N/A'}</div>
              </div>
              <div style={styles.detailContainer}>
                <div style={styles.detailTitle}>Shortlist:</div>
                <Checkbox
                  checked={item.isShortlisted}
                  style={{ margin: 0 }}
                  onChange={(e) => onShortlist(item.idea.id, !!e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={disabled || evaluationStatus === 'OPEN'}
                />
              </div>
              <div style={{ ...styles.detailContainer, marginTop: 16 }}>
                <Button
                  style={styles.btn}
                  type='danger'
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(item.idea.id);
                  }}
                  disabled={disabled}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  listData: IIdeaListData[];
  onDelete: (id: string) => void;
  onShortlist: (id: string, value: boolean) => void;
  disabled: boolean;
  evaluationStatus: 'INIT' | 'OPEN' | 'CLOSED';
  style?: CSSProperties;
}

MobileList.defaultProps = {
  style: {},
};

export default MobileList;
