import React from "react"
import {Button, Input, Pagination, message} from 'antd';
import Masonry from 'react-masonry-css'
import {get} from '../../libs/utils/request'
import "./Ideas.css"
import User from "../../user/User"
import {Interest} from "../Registration/Registration"
import IdeaEditorWithForm from './IdeaEditor'
import {Loading} from "../common/Loading";
import { user } from "../../libs/utils/user";
import {Helmet} from 'react-helmet';
import { SearchOutlined } from "@ant-design/icons";
import { IdeaCard } from "../CardView/ProjectCard";

const suffix = (
	<div style={{
		background: 'var(--secondary-blue)',
		borderRadius: '100%',
		height: '50px',
		width: '50px',
		textAlign: 'center',
		paddingTop: '15px',
		marginLeft: '2px',
		position: 'absolute'
	}}>
		<SearchOutlined
			style={{
				fontSize: 18,
				color: '#fff',
			}}
		/>
	</div>
);

export type Idea = {
  id: string
  createdAt: string
  name: string
  details: string
  image: string
  shareCount: number
  user: User  
  teamMembers:[]
  memberRequest:[]
  comments: number
  interests: Interest[]
  eventTags: any[]
  shareable: boolean
  commentable: boolean
  rating: { average: number, count: number }
  userRating: number
  pitchLink: string
  isMember:boolean
  commentGroup:any
  canDelete?: boolean
}

interface State {
  ideaList: Idea[]
  totalIdeas:Idea[]
  filteredList: Idea[]
  formOpened: boolean
  loading: boolean
  error: boolean
  noResult: boolean
  currentPage: number
  displayPagination: boolean
  teamMate:[]
}

class Ideas extends React.Component<{}, State> {

  state: State = {
    ideaList: [],
    totalIdeas:[],
    filteredList: [],
    formOpened: false,
    loading: true,
    error: false,
    noResult: false,
    currentPage: 1,
    displayPagination: true,
    teamMate:[],
  }

  async componentDidMount() {
    if(window.location.href === `${window.location.origin}/ideas/?create_new=true`){
      this.setState({formOpened: true});
      window.scrollTo(0, 0);
    }
    const param=localStorage.getItem('page')
    try {
      this.setState({currentPage:param?parseInt(param):1})
      this.getIdeas()
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      // console.log(err)
    }
  }

  getIdeas = async () => {
    try {
      const param=localStorage.getItem('page')
      const ideaList = await get<Idea[]>(`/ideas/list?page=${param!=null?param:1}&count=10`)
      const totalIdeas = await get<Idea[]>(`/ideas/list?page=1&count=1000`)
      this.setState({
        totalIdeas,
        ideaList,
        loading: false,
        filteredList: ideaList // only because of frontend side pagination handling. when we'll change to server side handling it needs to be an empty array!!
      })
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      // console.log(err)
    }
  }

  onFormClose = () => {
    this.setState({formOpened: false})
  }

  onFormOpen = () => {
    this.setState({
      formOpened: true,
      noResult: false,
      filteredList: this.state.ideaList.slice((this.state.currentPage * 10) - 10, (this.state.currentPage * 10)), // only because of frontend side pagination handling. when we'll change to server side handling it needs to be an empty array!!
    })
  }

  onSearch = (value: string) => {
    if (value) {
      const filteredIdeas = this.state.totalIdeas.filter(idea => JSON.stringify(idea).toLocaleLowerCase().includes(value.toLocaleLowerCase()))
      if (filteredIdeas.length > 0) {
        this.setState({filteredList: filteredIdeas, noResult: false, displayPagination: false})
      } else {
        this.setState({noResult: true, displayPagination: false})
      }
    } else {
      this.setState({
        filteredList: this.state.ideaList, // only because of frontend side pagination handling. when we'll change to server side handling it needs to be an empty array!!
        displayPagination: true,
        noResult: false
      })
    }
  }

  interestsFilterOption = (input: string, option: any) => {
    return option.props.children.toLowerCase().includes(input.toLowerCase())
  }

  onPageChange = (page: number) => {
    localStorage.setItem('page',page.toString())
    window.location.reload()
    this.setState({
      currentPage: page,
      filteredList: this.state.ideaList
    })
        
  }

  render() {
    // because of frontend side pagination handling currently we always display filteredList
    // const listToDisplay = this.state.filteredList.length > 0 ? this.state.filteredList : this.state.ideaList
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
    return (
      <div className="Content-container">
        <Helmet>
          <title>Ideas | pozi.io</title>
        </Helmet>
        <div className="Ideas">
          {
            this.state.formOpened ?
              <IdeaEditorWithForm
                onClose={this.onFormClose}
                callback={this.getIdeas}
                isNew={true}
              /> :
              <div className="Ideas-top-container">
                <h3 className="Ideas-top-title">Find awesome ideas</h3>
                <div className="Ideas-top-options">
                  {
                    !!user() && user().active && (
                    <div className="option-add">
                      <Button type="primary" size="large" 
                      onClick={()=>
                        this.onFormOpen()}>Create a new</Button>
                    </div>
                  )}
                 <Input
                 style={{maxWidth:"250px"}}
							className={'Users-search'}
							placeholder={'Type a name you like...'}
							// @ts-ignore
							onChange={e => this.onSearch(e.target?.value)}
							suffix={suffix}
							addonAfter={null}
						/>
                </div>
              </div>
          }
          {
            this.state.loading ? <Loading/> : null
          }
          {
            this.state.noResult ?
              <div className="no-result">
                We didn't found anything. Maybe it's a new idea?
              </div> :
              <>
                {
                  this.state.displayPagination &&
                  <div className="Ideas-pagination">
                      <Pagination current={this.state.currentPage} onChange={this.onPageChange}
                                  total={this.state.totalIdeas.length}/>
                  </div>
                }
                <Masonry
                  breakpointCols={{default: 2, 850: 1}}
                  className="Ideas-list"
                  columnClassName="Ideas-list-column">
                  {
                    this.state.filteredList.map((idea: any) =>
                      <IdeaCard
                      idea={idea}
                      key={idea.id}
                      onChange={this.getIdeas}
                      />
                    )
                  }
                </Masonry>
                {
                  this.state.displayPagination &&
                  <div className="Ideas-pagination-bottom">
                      <Pagination current={this.state.currentPage} onChange={this.onPageChange}
                                  total={this.state.totalIdeas.length}/>
                  </div>
                }
              </>
          }
        </div>
      </div>
    )
  }
}

export default Ideas
