/* eslint-disable */
import { Menu, message } from 'antd';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import React from 'react';
import {
  FacebookOutlined, LinkedinOutlined, TwitterOutlined, LinkOutlined,
} from '@ant-design/icons';
import './ShareMenu.css';

const styles = {
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 32,
    textAlign: 'center' as const,
  },
};

enum ShareType {
  facebook = 'facebook',
  linkedin = 'linkedin',
  twitter = 'twitter',
  copy = 'copy',
}

export const ShareMenu2 = ({ shareURL, onShare }: Props) => {
  const handleClick = (type: ShareType) => {

    if (onShare) {
      onShare(type);
    }
  };
  return (
    <Menu>
      <Menu.Item onClick={() => handleClick(ShareType.facebook)} style={styles.menuItem}>
        <FacebookShareButton url={shareURL}>
          <FacebookOutlined style={styles.icon} />
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item onClick={() => handleClick(ShareType.linkedin)} style={styles.menuItem}>
        <LinkedinShareButton url={shareURL}>
          <LinkedinOutlined style={styles.icon} />
        </LinkedinShareButton>
      </Menu.Item>
      <Menu.Item onClick={() => handleClick(ShareType.twitter)} style={styles.menuItem}>
        <TwitterShareButton url={shareURL}>
          <TwitterOutlined style={styles.icon} />
        </TwitterShareButton>
      </Menu.Item>
      <CopyToClipboard text={shareURL} onCopy={() => { message.success('Link copied to clipboard'); }}>
        <Menu.Item onClick={() => handleClick(ShareType.copy)} style={styles.menuItem}>
          <LinkOutlined style={styles.icon} />
        </Menu.Item>
      </CopyToClipboard>
    </Menu>
  );
};

interface Props {
  shareURL: string
  onShare?: (type: ShareType) => void
}

ShareMenu2.defaultProps = {
  onShare: null,
};
