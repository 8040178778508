import React from 'react'
import {Button, Input, Switch, InputNumber} from 'antd'
import {PlusOutlined, InboxOutlined} from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import {getBaseUrl} from '../../libs/utils/request';

interface Props {
  data?: any | undefined
  edit?: undefined | true
  onSubmit: (data: any) => void
}

interface State {
  offering: boolean
  loading: boolean
}

export class CreateJob extends React.Component<Props, State> {

  state: State = {
    offering: false,
    loading: false
  }

  image: string | null = null
  imageName: string | null = null
  title: string | null = null
  location: string | null = null
  salary: string | null = null
  description: string | null = null
  link: string | null = null

  constructor(props: any) {
    super(props)
    if (this.props.edit) {
      const { data } = this.props
      if (data.image && data.image.filename) {
        this.image = data.image.filename
      }
      this.title = data.title || null
      this.location = data.location || null
      this.salary = data.salary || null
      this.description = data.description || null
      this.link = data.link || null
      //TODO offering
    }
  }

  render() {
    const isEdit = !!this.props.edit

    return <div className={'CreateJob'}>
      <div className={'CreateJob-spacer'} style={{
        gridColumn: '1',
        gridRow: '1 / 2 span'
      }}>
        <Dragger
          className={'CreateJob-spacer'}
          name={'photo'}
          multiple={false}
          showUploadList={false}
          action={`${getBaseUrl()}/image`}
          onChange={(info: any) => {
            const {status} = info.file
            if (status === 'done') {
              this.image = info.file.response.filename
              this.imageName = info.file.name
              this.forceUpdate()
            } else if (status === 'error') {
              this.image = null
              this.imageName = null
              this.forceUpdate()
            }
          }}
          style={{gridArea: 'a'}}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined/>
          </p>
          <p
            className="ant-upload-text">{this.imageName ? this.imageName : 'Click or drag file to this area to upload'}</p>
        </Dragger>
      </div>

      <div className={'CreateJob-spacer'} style={{
        display: 'flex',
        gridColumn: '1',
        gridRow: '3',
        justifyContent: 'center'
      }}>
        <div>Searching</div>
        <Switch
          style={{
            margin: '0 12px'
          }}
          checked={this.state.offering}
          onChange={(offering) => {
            this.setState({
              offering
            })
          }}/>
        <div>Offering</div>
      </div>

      <Input
        defaultValue={this.title || ''}
        className={'CreateJob-spacer'}
        style={{
          gridColumn: '2',
          gridRow: '1'
        }}
        placeholder="Title"
        size="large"
        onChange={(e) => {
          this.title = e.target?.value
        }}
      />

      <Input
        defaultValue={this.location || ''}
        className={'CreateJob-spacer'}
        style={{
          gridColumn: '3',
          gridRow: '1'
        }}
        placeholder="Location"
        size="large"
        onChange={(e) => {
          this.location = e.target?.value
        }}
      />

      <InputNumber
        defaultValue={0}
        className={'CreateJob-spacer'}
        placeholder="Salary"
        style={{
          width: '100%',
          height: '40px',
          lineHeight: '40px',
          gridColumn: '4',
          gridRow: '1'
        }}
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/\$\s?|(,*)/g, '')}
        onChange={(v) => {
          if (v) {
            this.salary = '$ ' + v
          } else {
            this.salary = null
          }
        }}
      />

      <Input
        defaultValue={this.description || ''}
        className={'CreateJob-spacer'}
        style={{
          gridColumn: '2 / 3 span',
          gridRow: '2'
        }}
        placeholder="Description"
        size="large"
        onChange={(e) => {
          this.description = e.target?.value
        }}
      />

      <Input
        defaultValue={this.link || ''}
        className={'CreateJob-spacer'}
        style={{
          gridColumn: '2 / 2 span',
          gridRow: '3'
        }}
        placeholder="Link"
        size="large"
        onChange={(e) => {
          this.link = e.target?.value
        }}
      />

      <Button
        loading={this.state.loading}
        className={'CreateJob-spacer'}
        style={{
          gridColumn: '4',
          gridRow: '3'
        }}
        type="primary"
        size="large"
        onClick={async () => {
          this.setState({ loading: true })
          await this.props.onSubmit({
            image: this.image,
            title: this.title,
            location: this.location,
            salary: this.salary,
            description: this.description,
            link: this.link,
            offering: this.state.offering
          })
          this.setState({ loading: false })
        }}>{isEdit ? 'Edit' : [<PlusOutlined/>,'Create a new']}</Button>
    </div>
  }
}
