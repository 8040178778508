import React, { CSSProperties, useEffect, useState } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const styles = {
  root: { width: '100%' },
};
const FormValidatedInput = ({
  form, fieldKey, rules, placeholder, required, requiredMessage,
  style, validator, isEdited, onIsEdited, disabled,
}: Props) => {
  const [value, setValue] = useState('');
  const [isValid, setValid] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const validate = async (s: string) => {
      setLoading(true);
      try {
        await validator(s);
        setValid(true);
      } catch (error) {
        setErrorMessage(error.response.data.message);
        setValid(false);
      } finally {
        setLoading(false);
        setTypingTimeout(null);
      }
    };

    if (value.length >= 3) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(setTimeout(async () => {
        await validate(value);
      }, 500));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validator, value]);

  return (
    <Form.Item>
      {form.getFieldDecorator(fieldKey, {
        rules: [...rules, { required, message: requiredMessage }],
      })(
        <Input
          style={{ ...styles.root, ...style }}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            if (!isEdited) {
              setLoading(true);
              onIsEdited(true);
            }
            setValue(e.target.value);
          }}
          suffix={isLoading || !isEdited ? <></> : (
            <Tooltip placement='top' title={isValid ? '' : errorMessage}>
              {isValid ? (
                <CheckCircleOutlined style={{ color: '#2e7d32' }} />
              ) : (
                <ExclamationCircleOutlined style={{ color: '#f7454e' }} />
              )}
            </Tooltip>
          )}
        />,
      )}
    </Form.Item>
  );
};

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  rules?: any[];
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  style?: CSSProperties;
  validator: any;
  isEdited: boolean;
  onIsEdited: any;
  disabled?: boolean;
}

FormValidatedInput.defaultProps = {
  required: false,
  rules: [],
  placeholder: '',
  requiredMessage: 'This field is required!',
  style: {},
  disabled: false,
};

export default FormValidatedInput;
