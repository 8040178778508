/* eslint-disable */
import { Menu, message } from 'antd';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import React from 'react';

enum ShareType {
  facebook = 'facebook',
  linkedin = 'linkedin',
  twitter = 'twitter',
  copy = 'copy',
}

export const ShareMenu = ({ shareURL, onShare }: Props) => {
  const handleClick = (type: ShareType) => {
    if (onShare) {
      onShare(type);
    }
  };
  return (
    <Menu>
      <Menu.Item onClick={() => handleClick(ShareType.facebook)}>
        <FacebookShareButton url={shareURL}><div className='share-menu-text'>share on Facebook</div></FacebookShareButton>
      </Menu.Item>
      <Menu.Item onClick={() => handleClick(ShareType.linkedin)}>
        <LinkedinShareButton url={shareURL}><div className='share-menu-text'>share on LinkedIn</div></LinkedinShareButton>
      </Menu.Item>
      <Menu.Item onClick={() => handleClick(ShareType.twitter)}>
        <TwitterShareButton url={shareURL}><div className='share-menu-text'>share on Twitter</div></TwitterShareButton>
      </Menu.Item>
      <CopyToClipboard text={shareURL} onCopy={() => { message.success('Link copied to clipboard'); }}>
        <Menu.Item onClick={() => handleClick(ShareType.copy)}>
          <div className='share-menu-text'>copy link</div>
        </Menu.Item>
      </CopyToClipboard>
    </Menu>
  );
};

interface Props {
  shareURL: string
  onShare?: (type: ShareType) => void
}

ShareMenu.defaultProps = {
  onShare: null,
};
