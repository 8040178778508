import React, { ReactElement, useEffect, useState } from 'react';
import { message, Pagination } from 'antd';
import { get, post } from '../../../../../libs/utils/request';
import { havePermission, ISomeone, IUser } from '../../Competition';
import { Loading } from '../../../../common/Loading';
import UserGrid from '../UserGrid';
import AutoSearchInput from '../../../../common/AutoSearchInput';
import ViewSelector from '../ViewSelector';
import SortSelector, { ISorter } from '../SortSelector';
import MobileList from './compoents/MobileList';

const styles = {
  root: {
    maxWidth: 1200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 74,
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    flexWrap: 'wrap' as const,
    gap: '16px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 30,
    fontWeight: 900,
    color: '#000',
  },
  selectorContainer: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    gap: '0 10px',
  },
  label: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 800,
    color: '#282828',
    fontSize: 16,
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center' as const,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
  noResultText: {
    fontSize: 20,
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    marginTop: 72,
    color: '#282828',
  },
  grid: {
    marginTop: 53,
    justifyContent: 'center',
  },
};

const SORTERS: ISorter[] = [
  { value: 'accepted_A', text: 'Status (Accepted)' },
  { value: 'denied_A', text: 'Status (Denied)' },
  { value: 'name_A', text: 'Name (A to Z)' },
  { value: 'name_D', text: 'Name (Z to A)' },
  { value: 'role_A', text: 'Role (A to Z)' },
  { value: 'role_D', text: 'Role (Z to A)' },
  // { value: 'country_A', text: 'Country (A to Z)' },
  // { value: 'country_D', text: 'Country (Z to A)' },
  { value: 'registration date_A', text: 'Registration date (earlier)' },
  { value: 'registration date_D', text: 'Registration date (later)' },
];

const getSortParams = (value: string) => {
  const r = value.split('_');
  if (r.length === 2) {
    return { sort: r[0], order: r[1] === 'A' ? 'ASC' : 'DESC' };
  }
  return {};
};

const Participants = ({ tag, userRoles }: Props) => {
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [participants, setParticipants] = useState([]);
  const [participantList, setParticipantsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [limit, setLimit] = useState(50);
  const [listLoading, setListLoading] = useState(false);
  const [isListView, setListView] = useState(havePermission(['Organizer', 'Co-organizer'], userRoles));
  const [sortBy, setSortBy] = useState('registration date_D');

  useEffect(() => {
    (async () => {
      setErrorMessage('');
      setLoading(true);
      try {
        let pagination;
        const params: any = {
          currentPage,
          limit,
          ...(isListView ? getSortParams(sortBy) : {}),
        };
        if (search) {
          params.search = search;
        }
        if (!isListView) {
          const tmp = await get<{ data: ISomeone[], pagination: any }>(`competitions/${tag}/participants`, params);
          setParticipants(tmp.data.map((item) => item.user));
          pagination = tmp.pagination;
        } else {
          const tmp = await get<{ data: IUserListData[], pagination: any }>(`competitions/${tag}/participants/listview`, params);
          // console.log(JSON.stringify(tmp.data, null, 2));
          setParticipantsList(tmp.data);
          pagination = tmp.pagination;
        }
        setTotalCount(pagination.totalCount);
        if (currentPage !== pagination.currentPage) {
          setCurrentPage(pagination.currentPage);
        }
        if (limit !== pagination.limit) {
          setLimit(pagination.limit);
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, isListView, limit, search, sortBy, tag]);

  const getParticipants = async (withLoading = true) => {
    
    setErrorMessage('');
    if (withLoading) {
      setLoading(true);
    }
    try {
      let pagination;
      const params: any = {
        currentPage,
        limit,
        ...(isListView ? getSortParams(sortBy) : {}),
      };
      if (search) {
        params.search = search;
      }
      if (!isListView) {
        const tmp = await get<{ data: ISomeone[], pagination: any }>(`competitions/${tag}/participants`, params);
        setParticipants(tmp.data.map((item) => item.user));
        pagination = tmp.pagination;
      } else {
        const tmp = await get<{ data: IUserListData[], pagination: any }>(`competitions/${tag}/participants/listview`, params);
        // console.log(JSON.stringify(tmp.data, null, 2));
        setParticipantsList(tmp.data);
        pagination = tmp.pagination;
      }
      setTotalCount(pagination.totalCount);
      if (currentPage !== pagination.currentPage) {
        setCurrentPage(pagination.currentPage);
      }
      if (limit !== pagination.limit) {
        setLimit(pagination.limit);
      }
      
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const listIsEmpty = (): boolean => (isListView ? !participantList.length : !participants.length);

  const handleListAction = async (id: string, isAccept: boolean) => {
    const operation = isAccept ? 'accept' : 'deny';
    try {
      setListLoading(true);
      await post(`/competitions/${tag}/participants/${id}/${operation}`);
      await getParticipants(false);
    } catch (error) {
      message.error(`Failed to ${operation} participant.`);
    } finally {
      setListLoading(false);
    }
  };

  const renderPaginationButtons = (marginTop = 0) => (
    <div style={{ ...styles.paginationContainer, marginTop }}>
      <Pagination
        current={currentPage}
        defaultPageSize={40}
        pageSize={limit}
        onChange={setCurrentPage}
        total={totalCount}
        style={{ marginTop }}
      />
    </div>
  );

  const renderWithPager = (content: ReactElement) => (
    <>
      {renderPaginationButtons(16)}
      {isLoading ? <Loading /> : content}
      {renderPaginationButtons(42)}
    </>
  );

  const renderPagination = () => {
    if (errorMessage) {
      return (
        <>
          <div style={styles.errorText}>Failed to load competitions:</div>
          <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
            {errorMessage}
          </div>
          <div
            style={styles.errorButtonText}
            onClick={() => getParticipants(true)}
            aria-hidden='true'
          >
            retry
          </div>
        </>
      );
    }
    if (listIsEmpty() && !isLoading) {
      return <div style={styles.noResultText}>{`${search ? 'We didn\'t found anything.' : 'Nothing to show.'}`}</div>;
    }
    return renderWithPager(
      !isListView
        ? <UserGrid users={participants} style={styles.grid} />
        : (
          <MobileList
            listData={participantList}
            onAccept={(id) => handleListAction(id, true)}
            onDeny={(id) => handleListAction(id, false)}
            disabled={listLoading}
            style={{ marginTop: 48 }}
          />
        ),
    );
  };

  return (
    <div style={styles.root}>
      <div style={styles.titleContainer}>
        <div style={styles.title}>{isListView ? 'Participants' : 'Applied participants'}</div>
        <div style={{ minWidth: 280 }}>
          <AutoSearchInput
            onSearch={(value) => {
              setSearch(value);
              setCurrentPage(1);
            }}
            style={{ width: '100%' }}
            placeholder='Type something...'
          />
          {havePermission(['Organizer', 'Co-organizer'], userRoles) && (
            <div style={styles.selectorContainer}>
              <ViewSelector isListView={isListView} onChange={setListView} />
              {isListView && (
                <SortSelector
                  value={sortBy}
                  onChange={setSortBy}
                  options={SORTERS}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {renderPagination()}
    </div>
  );
};

export interface IUserListData {
  id: string;
  createdAt: string;
  isApproved: boolean;
  isDenied: boolean;
  user: IUser;
}

interface Props {
  tag: string;
  userRoles: string[];
}

export default Participants;
