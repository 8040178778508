import * as React from "react"
import { Layout } from 'antd'
import Header from "./components/Header/HeaderV2"
// import Footer from "./components/Footer/Footer"
import CompleteReminder from "./components/common/CompleteReminder"

import {Helmet} from 'react-helmet';
import FooterNew from "./components/Footer/FooterNew";


const { Content } = Layout

interface MainFrameProps extends React.Props<any> {

}

interface State {
  redirect: boolean
  loading: boolean
  hamburgerMenuIsOpen: boolean
  notification: boolean
}

export default class MainFrame extends React.Component<MainFrameProps, State> {

  constructor(props: MainFrameProps) {
    super(props)

    this.state = {
      redirect: false,
      loading: false,
      hamburgerMenuIsOpen: false,
      notification: false
    }

  }
  handleHamburgerMenu = (param: boolean) => {
      this.setState({hamburgerMenuIsOpen: param});
  }
  closeHamburgerMenu = () => {
      if(this.state.hamburgerMenuIsOpen) {
        this.setState({ hamburgerMenuIsOpen: false})
        this.setState({notification: false})
      }
  }

  notificationSetTrue = (param: boolean) => {
    this.setState({ notification : param })
  }
  render() {
    
    return (
      <Layout className="layout">
        <Helmet>
          <title>pozi.io</title>
        </Helmet>
        <Header hamburgerMenuIsOpen={this.state.hamburgerMenuIsOpen}  onOpen={this.handleHamburgerMenu} notification={this.notificationSetTrue} notificationParam={this.state.notification}/>
        <Content onClick= {()=> {this.closeHamburgerMenu();}}>
            {this.props.children}
        </Content>
        <CompleteReminder/>
        {window.location.pathname === '/dashboard' ? null : <FooterNew />}
      </Layout>
    )
  }
}