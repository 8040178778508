import React, { CSSProperties } from 'react';

const styles = {
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 15,
    color: '#282828',
  },
};

const getParts = (text: string) => {
  const data = text.split(' ');
  const result = [];
  let tmp: string[] = [];
  data.forEach((part) => {
    if (part.match(/^(https:\/\/\S|http:\/\/\S)/)) {
      if (tmp.length) {
        result.push({ text: `${result.length ? ' ' : ''}${tmp.join(' ')} `, isLink: false });
        tmp = [];
      }
      result.push({ text: part, isLink: true });
    } else {
      tmp.push(part);
    }
  });
  if (tmp.length) {
    result.push({ text: `${result.length ? ' ' : ''}${tmp.join(' ')}`, isLink: false });
  }
  return result;
};

/* eslint-disable react/no-array-index-key */
const TextWithLink = ({ text, textStyle }: Props) => (
  <div style={{ ...styles.text, ...textStyle }}>
    {getParts(text).map((item: any, i) => {
      if (item.isLink) {
        return (
          <span
            key={i}
            style={{ color: '#6755CE', cursor: 'pointer' }}
            onClick={() => window.open(item.text, '__blank')}
            aria-hidden='true'
          >
            {item.text}
          </span>
        );
      }
      return <span key={i}>{item.text}</span>;
    })}
  </div>
);

interface Props {
  text: string;
  textStyle?: CSSProperties;
}

TextWithLink.defaultProps = {
  textStyle: {},
};

export default TextWithLink;
