import { Button } from "antd";
import React from "react";
import { getBaseUrl } from "../../../../libs/utils/request";
import { textOverflow } from "../../../../libs/utils/textUtils";
import { user } from "../../../../libs/utils/user";
import { ProjectTeam } from "../../../Project/ProjectTeam";
import DisplayTeamMember from "../../../Team/DisplayTeamMember";
import Team from "../../../Team/Team";
import UserView from "../UserView";

const imageLinkBase = `${getBaseUrl()}/image`;
const About = ({ organization }: Props) => {
  const youtubeVideo =
    organization.videoType === "youtube" && organization.videoId
      ? organization.videoId
      : "";
  const vimeoVideo =
    organization.videoType === "vimeo" && organization.videoId
      ? organization.videoId
      : "";
  const vimeoEmbed =
    organization.videoType === "iframe" && organization.videoUrl
      ? organization.videoUrl
      : "";
  return (
    <div className="organization-about-container">
      <div className="organization-about-description">
        <div className="organization-about-description-title">
          Get to know it!
        </div>
        <div
          className="organization-about-description-desc"
          dangerouslySetInnerHTML={{
            __html: organization.description
              ? organization.description.replace(/(?:\r\n|\r|\n)/g, "<br />")
              : "",
          }}
        ></div>
      </div>
      <div className="organization-about-others">
        <div className="organization-about-video">
          <div className="">
            {/* eslint-disable-next-line react/no-danger */}
            {/* <div className='summary-description' style={{ fontSize: 16, marginTop: 20 }} dangerouslySetInnerHTML={{ __html: event.description }} /> */}
            {organization.videoType === "iframe" && (
              <div className="embed-video" id="embed-video">
                <div
                  className="embed-video-vimeo"
                  dangerouslySetInnerHTML={{ __html: vimeoEmbed }}
                ></div>
              </div>
            )}
            {organization.videoType === "youtube" && (
              <div className="embed-video" id="embed-video">
                <iframe
                  width="450"
                  height="250"
                  src={"https://www.youtube.com/embed/" + youtubeVideo}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
            {organization.videoType === "vimeo" && (
              <div className="embed-video" id="embed-video">
                <iframe
                  width="450"
                  height="250"
                  src={"https://player.vimeo.com/video/" + vimeoVideo}
                  title="{video_title}"
                  allowFullScreen
                  frameBorder="0"
                ></iframe>
              </div>
            )}
          </div>
        </div>
        <div className="organization-about-interests" style={organization.videoUrl===""?{marginTop:"unset"}:{marginTop:"20px"}}>
          <h2>Interests</h2>
          <div className="organization-about-interest-holder">
            {organization.interestGroups.map((group: any) => (
              <>
                  {group.interests.map((interest: any, idx: number) => (
                    <div className="organization-about-interest" key={group.id}>
                    <img
                      src={group.icon}
                      alt=""
                      className="organization-about-interest-icon"
                    />
                      <span key={interest.id}>
                        {interest.name} 
                      </span>
                      </div>
                  ))}
              </>
            ))}
          </div>
        </div>
      </div>
      { organization.createdBy && (
        <div className="organization-about-team">
          <h2>Team</h2>
          <UserView 
            id={organization.createdBy.id} 
            profileImage={organization.createdBy.profileImage} 
            name={organization.createdBy.firstName + " " + organization.createdBy.lastName} 
            role={organization.createdBy.role?.name} 
            oneLiner={organization.createdBy.oneLiner}       
            uniqueTag={organization.createdBy.uniqueTag}   
          />
          {organization.teamMembers.length > 0 && organization.teamMembers.filter((u:any)=>u.user.id !== organization.createdBy.id).map((u: any, i: any) => (
            <UserView
              id={u.user.id}
              profileImage={u.user.profileImage}
              name={u.user.firstName + " " + u.user.lastName}
              role={u.user.role?.name}
              oneLiner={u.user.oneLiner}
              uniqueTag={u.user.uniqueTag}
            />
            ))}
        </div>
      )}
      {organization.partners.length > 0 && (
        <div className="organization-about-partners">
          <h2>Partners</h2>
          <div className="organization-about-partner-container">
            {organization.partners.map((partner: any) => {
              return (
                <>
                  <div className="organizationpage-about-partners">
                    <a href={`${partner.partnerUrl}`} target="_blank">
                      <img
                        key={partner.id}
                        className=""
                        src={
                          partner.image
                            ? `${imageLinkBase}/${partner.image.filename}`
                            : ""
                        }
                      ></img>
                    </a>
                    <div className="organizationpage-about-partners-name">
                      {partner.name}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

interface Props {
  organization: any;
}

export default About;
