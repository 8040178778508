import { Collapse, Icon } from 'antd';
import React, { CSSProperties, useEffect } from 'react';
import eventbus from '../../../libs/eventbus';
import { InterestGroup, Interest } from '../Registration';

const { Panel } = Collapse;

const themeIncluded = (theme: any, list: any) => list
  .map((item: any) => item.id).includes(theme.id);

  const Interests = ({
  interestGroups, selectedInterests, onClick, withTitle, style,
}: Props) => {
  useEffect(() => {
    eventbus.addListener(
      eventbus.events.validateRegistration,
      'Interests',
      () => selectedInterests.length > 2,
      'Please select at least three interests',
    );
    return () => {
      eventbus.removeListener(eventbus.events.validateRegistration, 'Interests');
    };
  }, []);

  let disable=false

  

  const interests = interestGroups.map((group: InterestGroup) => (
    <Panel
      className='step-interests-panel'
      header={(
        <>
          <img className='step-interests-icon' src={group.icon} alt='role icon' />
          {group.name}
        </>
      )}
      key={group.name}
    >
      
      <div className='step-interests-interests'>
        {group.interests.map((interest) => (
          <button
            
            aria-hidden='true'
            className={`step-interests-interest ${themeIncluded(interest, selectedInterests) && 'selected'}`}
            disabled={!selectedInterests.includes(interest)&&selectedInterests.length===5}
            key={interest.id}
            onClick={() => onClick(interest)}
          >
            {interest.name}
          </button>
        ),
        )}
      </div>
    </Panel>
  ));

  return (
    <div className='step step-interests' style={style}>
      {withTitle && (
        <div className='step-title'>
          Define your interests
        </div>
      )}
      <div className='step-interests-interests-container'>
        <Collapse
          className='step-interests-collapse'
          expandIconPosition='right'
          expandIcon={({ isActive }) => <Icon className='step-interests-collapse-icon' type='caret-right' rotate={isActive ? 270 : 90} />}
          bordered={false}
        >
          {interests}
        </Collapse>
      </div>
    </div>
  );
};

interface Props {
  interestGroups: InterestGroup[]
  selectedInterests: Interest[]
  // eslint-disable-next-line no-unused-vars
  onClick: (interest: Interest) => void
  withTitle?: boolean,
  style?: CSSProperties
}

Interests.defaultProps = {
  withTitle: true,
  style: {},
};

export default Interests;
