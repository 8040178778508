import React from 'react'
import {post} from "../../libs/utils/request";

interface Props {
    match: any
  history: any
}

class InvitationPage extends React.Component<Props> {

    async componentDidMount() {
    const data = this.props.match.params.data

    try {
      await sessionStorage.setItem('invitation', data)
    } catch (e) {
      alert('storage disabled for the site')
      return
    }

    post("/invitation/setVisited", { data }).catch(() => {
      // not handled error because it will be not displayed
    })

    this.props.history.push('/invitation-registration');
  }

  render() {
    return <div />
  }

}

export default InvitationPage
