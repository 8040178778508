import React from "react";
import Slider from "./Slider";
import "./Packages.css";
import PackageCard from "./PackageCard";
import TestimonialCard from "./TestimonialCard";
import { Collapse } from "antd";
import Masonry from "react-masonry-css";
const { Panel } = Collapse;

const questions = [
  {
    key: 1,
    question: "How can I try POZI.io for free?",
    answer:
      "You can try all advanced features in POZI.io during a free trial. <br/><br/> Booking a demo and signing a contract is important to start a free trial, but you will not be charged anything if you cancel anytime before your billing period starts. We will also send you a reminder before your trial ends.",
  },
  {
    key: 2,
    question: "How does POZI.io's pricing work?",
    answer:
      "If you want to use the standard features of POZI.io, you can subscribe to our BASIC plan and pay a discounted price for the whole year or pay the full price monthly. <br/><br/> If you want to use POZI.io with your team and take advantage of all the advanced features and integrations, you can subscribe to our PRO or ENTERPRISE plan. Your subscription price will depend on the number of members in your team and the selected billing period.",
  },
  {
    key: 3,
    question: "What forms of payment do you accept?",
    answer:
      "You can use any debit or credit card to pay for a subscription. <br/><br/> If you want to use a different payment method, such as a bank transfer, cheque, or PayPal - please, write to us at hello@pozi.io.",
  },
  {
    key: 4,
    question: "How do I cancel my subscription or trial?",
    answer:
      "You can cancel your subscription plan or trial at any time - please, write to us at hello@pozi.io. You will receive an email confirmation after you cancel.",
  },
  {
    key: 5,
    question: "How do I change my subscription plan?",
    answer:
      "You can change your plan or billing period at any time - please, write to us at hello@pozi.io.",
  },
  {
    key: 6,
    question: "What is the difference between user roles in POZI.io?",
    answer:
      "<b>Guest users</b> or viewers can only view your open-public contents and comment on them. You can invite an unlimited number of Guest users to visit any of your organisations for free. <br/><br/> <b>Members</b> can create and delete One-Pagers, add, download, inspect, and comment on your private contents, like events, competitions, announcements, files. They cannot access billing or manage other users. You can invite members to all or selected projects for a fee according to your subscription plan type. <br/><br/> <b>Admins</b> have full access to all the features in POZI.io, including user management and billing. You can manage your users and change their roles at any time.",
  },
  {
    key: 7,
    question: "Does POZI.io offer educational or non-profit discounts?",
    answer:
      "We have special educational and non-profit discounts. Please, contact us at hello@pozi.io to learn more.",
  },
  {
    key: 8,
    question: "Can you send me an invoice or a purchase order?",
    answer:
      "You can download a copy of your invoice directly from POZI.io. If you need us to customize your invoice or issue a purchase order, please contact your account manager or write to us at hello@pozi.io.",
  },
];
const Sales = () => {
  return (
    <div className="sales-page-main-container">
      <div className="sales-first-box">
        <div className="sales-title-box">
          <p className="sales-first-box-title">
            The online platform to empower innovation
          </p>
          <p className="sales-first-box-description">
            The perfect tool to scout and scale startups and build your own
            innovation ecosystem.
          </p>
          <button
            type="button"
            className="ant-btn ant-btn-primary sales-first-box-button"
          >
            <a href="https://calendly.com/pozi/" target="_blank">
              Book a Demo!
            </a>
          </button>
        </div>
        <img
          className="sales-first-box-image"
          src="/images/sales/pozi-removebg1.png"
        />
      </div>
      <div className="sales-second-third-boxes-container">
        <div className="sales-second-box">
          <div className="sales-second-box-title">
            <p className="sales-second-box-title-title">
              We take care of every detail you need to build and manage your
              ecosystem.
            </p>
            <p className="sales-second-box-title-description">
              It’s time to get rid of your spreadsheets, let us show you how.
            </p>
          </div>
        </div>
        <div className="sales-third-box">
          <div className="sales-third-box-title">
            POZI.io’s platform is helping thousands of forward-thinking
            innovators to create the digital products & services of the future.
          </div>
          <div className="sales-third-box-description">
            <div className="sales-third-box-description-text">
              “Working with Pozi´s platform for events was a breath of fresh
              air! With robust toolsets and numerous functions, the platform
              allows us to organize dynamic events and interact with the startup
              community in a simple and efficient manner. Users can easily find
              different events, mentors and other activities while connecting
              with the ecosystem. Highly recommend Pozi´s platform for any
              company that organizes events and works with a startup community!”
            </div>
            <div className="testimonial-title-part">
              <img
                className="testimonial-image"
                src="/images/index_page/Henrique.png"
              />
              <div>
                <div className="testimonial-name">Henrique Pereira</div>
                <div className="testimonial-position">Draper Startup House</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-on-image">
        <img
          src="/images/sales/startup_world_cup_pozi.jpg"
          className="text-on-image-desktop"
        />
        <img
          src="/images/sales/startup_world_cup_pozi_mobil.png"
          className="text-on-image-mobile"
        />
        <div>
          <div className="text-on-image-title">
            Incubators, hubs, universities
          </div>
          <div className="text-on-image-description">
            We want you to consider us as an extension of your hands. With our
            platform:
            <ul className="text-on-image-list">
              <li>🔥 Reach your target audience fast and easy</li>
              <li>🔥 Create events and competitions</li>
              <li>🔥 Scout, Sort and Select the best teams</li>
              <li>🔥 Build and manage your portfolio</li>
              <li>🔥 Invite mentors, advisors or investors</li>
            </ul>
          </div>
          <button
            type="button"
            className="ant-btn ant-btn-primary sales-first-box-button"
          >
            <a href="https://calendly.com/pozi/" target="_blank">
              Book a Demo
            </a>
          </button>
        </div>
      </div>
      <div className="all-in-one">
        <div className="sales-all-in-one-title-card">
          <div className="sales-all-in-one-title">
            All-in-one ecosystem builder tool
          </div>
          <div className="sales-all-in-one-description">
            The platform gives you the tools that you need to bring structure to
            the messy processes of finding an idea, building a team, or
            launching a new venture. So you can enjoy the bumpy ride of
            innovation without worrying about all the things that can go wrong.
          </div>
        </div>
        <div className="sales-all-in-one-details-card">
          <div className="sales-all-in-one-left-card">
            <p>
              💁‍♂️ Our white-label solution <b>simplifies the workflow</b> of
              talent development for universities, hubs, incubators, and
              accelerators, making it a well-managed and documentable database.
            </p>
            <p>
              💁‍♂️ It offers a <b>mentoring system</b> and outstanding development
              opportunities for startups.
            </p>
            <p>
              💁‍♂️ The event organizer tools{" "}
              <b>enable targeted, industry-specific search</b> to scout, sort,
              and select the best startups.
            </p>
          </div>
          <div className="sales-all-in-one-right-card">
            <div>
              ❌ No complicated sheets. <br />
              ❌ No manuals for multiple sites.
              <br />❌ No unwieldy processes.
            </div>
            <div>
              ✔️ One ecosystem <br />
              ✔️ One platform <br />
              ✔️ That’s it.
            </div>
            <div className="sales-all-in-one-bottom-card">
              <TestimonialCard
                proposer={{
                  image: "/images/sales/Roland.svg",
                  name: "Roland Kovács",
                  position: "CEO of POZI.io",
                  description:
                    "“The current mortality rate of ideas in the startup world remains unacceptably low and this has to change. POZI.io aims to put a stop to wasting ideas and talents. The result of innovation should not be only an excel sheet.”",
                  navigationLink: "https://calendly.com/pozi/",
                }}
                navigationTitle={"Book a demo with me! ->"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sales-image-box">
        <img src="/images/sales/startup_world_cup_pozi2.jpg" />
      </div>
      <div className="what-we-offer">
        <div className="sales-what-we-offer-title">What we offer?</div>
        <div className="sales-what-we-offer-details">
          <div className="sales-what-we-offer-details-images">
            <img
              className="sales-what-we-offer-details-images1"
              src="/images/sales/Group.svg"
            />
            <img
              className="sales-what-we-offer-details-images2"
              src="/images/sales/offer3.svg"
            />
            <img
              className="sales-what-we-offer-details-images3"
              src="/images/sales/Group.svg"
            />
            <img
              className="sales-what-we-offer-details-images4"
              src="/images/sales/offer2.svg"
            />
            <img
              className="sales-what-we-offer-details-images5"
              src="/images/sales/offer1.svg"
            />
            <img
              className="sales-what-we-offer-details-images6"
              src="/images/sales/Group.svg"
            />
          </div>
          <div className="sales-what-we-offer-details-texts">
            <img src="/images/sales/rocket.svg" />
            <div className="sales-what-we-offer-details-texts-container">
              <div>
                <div className="sales-what-we-offer-details-texts-title">
                  Competition & Event management tool
                </div>
                <ul className="sales-what-we-offer-details-texts-list">
                  <li>
                    Event Planner Guide - plan like a pro with our foolproof
                    step by step planner;
                  </li>
                  <li>
                    Invite everyone to your events without excessive email
                    lists;
                  </li>
                  <li>
                    Live stream sharing - embed your video link into the
                    competition or event page, so all interested parties can
                    follow live;
                  </li>
                  <li>
                    Gather and filter startups through the application system -
                    no more spreadsheets, confusing lists, only a database you
                    need;
                  </li>
                  <li>
                    Personalized feed for communication and announcements;
                  </li>
                  <li>Payment gateway - sell tickets to your events;</li>
                  <li>Chat application with channels and rooms;</li>
                  <li>
                    Easy to use evaluation tool - to filter and select the best
                    startups for your portfolio
                  </li>
                  <li>
                    Database management tool - all participants, ideas &
                    startups stay visible and accessible;
                  </li>
                  <li>
                    Follow-up with mentors, get instant progress feedbacks;
                  </li>
                </ul>
              </div>
              <img
                className="sales-what-we-offer-details-mobile"
                src="/images/sales/offer3.svg"
              />
              <div>
                <div className="sales-what-we-offer-details-texts-title">
                  Startup validation
                </div>
                <ul className="sales-what-we-offer-details-texts-list">
                  <li>
                    You find a startup attractive but can’t decide to invest or
                    not? You can do a thorough due diligence. With more than
                    100+ mentors / advisors on our platform we can cover most
                    industries.
                  </li>
                </ul>
              </div>
              <img
                className="sales-what-we-offer-details-mobile"
                src="/images/sales/offer2.svg"
              />
              <div>
                <div className="sales-what-we-offer-details-texts-title">
                  Dealflow generation & Portfolio manager tool
                </div>
                <ul className="sales-what-we-offer-details-texts-list">
                  <li>
                    With the dynamic on-pagers you can be up-to-date about every
                    startup. Choose the most eligible startups based on your
                    preferences, and build your portfolio.
                  </li>
                </ul>
              </div>
              <img
                className="sales-what-we-offer-details-mobile"
                src="/images/sales/offer1.svg"
              />
              <div>
                <div className="sales-what-we-offer-details-texts-title">
                  Milestone system - Coming soon!
                </div>
                <ul className="sales-what-we-offer-details-texts-list">
                  <li>
                    With our in-build system, the users find themself in a
                    gamified environment to validate, build and scale startups.
                    The startups’ track record will be available for
                    organisations to browse. This makes it more easy to find the
                    right fit for you and assures a level of quality in each
                    case.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="package-packages-all-container">
        <div className="package-title-container">
          <div className="package-title">Packages</div>
          <div className="package-title-description">
            Our pricing plans designed to fit your needs. <br />
            Get started for Free. Change or cancel your plan anytime.
          </div>
        </div>
        <div className="packages">
          <PackageCard
            title={"FREE"}
            mainDescription={"up to 50 members"}
            options={[
              { text: "1 Admin", style: "" },
              { text: "Open: all content is visible publicly", style: "" },
              { text: "Event tool", style: "" },
              { text: "Video embed", style: "" },
              { text: "Social media links", style: "" },
              { text: "Interest categories", style: "" },
              { text: "FAQ", style: "" },
              { text: "Portfolio", style: "line-through" },
              { text: "Payment gate", style: "" },
              { text: "Competition tool", style: "line-through" },
              { text: "Advanced search console *", style: "line-through" },
              { text: "Onboarding support", style: "line-through" },
              { text: "Document storage in Cloud *", style: "line-through" },
              { text: "Data dashboard *", style: "line-through" },
              {
                text: "Interest based invitations to the users *",
                style: "line-through",
              },
              { text: "Marketing appearance", style: "line-through" },
            ]}
            price={{
              month: "",
              annual: "",
            }}
            type={"free"}
          />
          <PackageCard
            title={"BASIC"}
            mainDescription={"100 members +149$ / 1k extra"}
            options={[
              { text: "2 Admins", style: "" },
              { text: "Open: all content is visible publicly", style: "" },
              { text: "Event tool", style: "" },
              { text: "Video embed", style: "" },
              { text: "Social media links", style: "" },
              { text: "Interest categories", style: "" },
              { text: "FAQ, Email support", style: "" },
              { text: "Portfolio size up to 10", style: "" },
              { text: "Payment gate", style: "" },
              { text: "Competition tool", style: "line-through" },
              { text: "Advanced search console *", style: "line-through" },
              { text: "Onboarding support", style: "line-through" },
              { text: "Document storage in Cloud *", style: "line-through" },
              { text: "Data dashboard *", style: "line-through" },
              {
                text: "Interest based invitations to the users *",
                style: "line-through",
              },
              { text: "Marketing appearance", style: "line-through" },
            ]}
            price={{
              month: "399",
              annual: "4389",
            }}
            type={"basic"}
          />
          <PackageCard
            title={"PRO"}
            mainDescription={"1000 members +699$ / 1k extra"}
            options={[
              { text: "5 Admins", style: "higher-font" },
              {
                text: "Private (Portfolio, Posts, Events, Competitions)",
                style: "higher-font",
              },
              { text: "Event tool", style: "higher-font" },
              { text: "Video embed", style: "higher-font" },
              { text: "Social media links", style: "higher-font" },
              { text: "Interest categories", style: "higher-font" },
              { text: "FAQ, Email support", style: "higher-font" },
              { text: "Portfolio size up to 100", style: "higher-font" },
              { text: "Payment gate", style: "higher-font" },
              { text: "Competition tool", style: "higher-font" },
              { text: "Advanced search console*", style: "higher-font" },
              { text: "Onboarding support", style: "higher-font" },
              { text: "Document storage in Cloud *", style: "higher-font" },
              { text: "Data dashboard *", style: "higher-font" },
              {
                text: "Interest based invitations to the users *",
                style: "line-through higher-font",
              },
              {
                text: "Marketing appearance",
                style: "line-through higher-font",
              },
            ]}
            price={{
              month: "1499",
              annual: "16.489",
            }}
            type={"pro"}
          />
          <PackageCard
            title={"ENTERPRISE"}
            mainDescription={"Members"}
            options={[
              { text: "Unlimited Admins", style: "" },
              {
                text: "Private (Portfolio, Posts, Events, Competitions)",
                style: "",
              },
              { text: "Event tool", style: "" },
              { text: "Video embed", style: "" },
              { text: "Social media links", style: "" },
              { text: "Interest categories", style: "" },
              { text: "FAQ, Email support, Video call", style: "" },
              { text: "Portfolio size is unlimited", style: "" },
              { text: "Payment gate + multiple tiers", style: "" },
              { text: "Competition tool", style: "" },
              { text: "Advanced search console *", style: "" },
              { text: "Onboarding support", style: "" },
              { text: "Document storage in Cloud *", style: "" },
              { text: "Data dashboard *", style: "" },
              { text: "Interest based invitations to the users *", style: "" },
              { text: "Marketing appearance", style: "" },
            ]}
            price={{
              month: "TBD",
              annual: "",
            }}
            type={"enterprise"}
          />
        </div>
      </div>
      <div className="home-page-testimonials testimonials">
        <div className="home-page-testimonials-inner-box testimonials-inner-box">
          <img
            src="/images/sales/upper.svg"
            className="testimonial-first-image"
          />
          <Masonry
            breakpointCols={{ default: 2, 850: 1 }}
            className="home-page-testimonials-masonry"
            columnClassName="testimonials-list-column"
          >
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Ming.png",
                name: "Ming Xia Ho",
                position: "Investor",
                description:
                  "“Collaborating with POZI.io has been a blast! Not only is it a cool platform to bridge founders, their startup pitches, and investors; the team's passion also translates into the progress they are driving in the startup ecosystem, as they continue to build POZI! It has been a joy working alongside them to enable the startup community collectively. Thanks for inspiring!”",
                navigationLink: "/entrepreneurs/xia-ho",
              }}
              navigationTitle={"Find out more ->"}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Noemi.png",
                name: "Noémi Marencsák",
                position: "Founder",
                description:
                  "“Pozi.io is something amazing. An integrated platform which provides all possibilities to startups, organisations, investors and anyone interested in this innovative field. From the idea stage to already set up companies Pozi.io helps you get known by organising quality competitions, discussions, meetings and gatherings. Being a part of it gets you closer to your dreams and goals.”",
                navigationLink: "/entrepreneurs/noemi-marencsak-1",
              }}
              navigationTitle={"Find out more ->"}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Mark.png",
                name: "Mark Engelhardt",
                position: "Founder",
                description:
                  "“We did our first ever web3 pitch competition on Pozi.io's platform. Creating a profile and applying to the competition was pretty straightforward and thanks to the platform's notification and messaging abilities we never missed a piece of information for the event. When filling out your profile, they ask the right questions that would be important for not only anyone looking at your startup, but also for yourself to really think about how to explain what you do in the shortest form possible. Fantastic events, great UX, I'm a returning user. :)”",
                navigationLink: "/entrepreneurs/mark-engelhardt",
              }}
              navigationTitle={"Find out more ->"}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/Zsolt.png",
                name: "Zsolt Oleg Berta",
                position: "Advisor",
                description:
                  "“As a jury member of the DEMO day I appreciate POZI’s professionalism and commitment to build a the startup ecosystem, gather the investors, consultants and startup founders, facilitate and support the development of the projects.”",
                navigationLink: "/entrepreneurs/zsolt-berta",
              }}
              navigationTitle={"Find out more ->"}
            />
          </Masonry>
          <img
            src="/images/sales/bottom.svg"
            className="testimonial-second-image"
          />
        </div>
      </div>
      <div className="sales-questions">
        <div className="sales-questions-container">
          <div className="sales-questions-title">Got a question?</div>
          <div className="sales-questions-answers">
            <Collapse>
              {questions.map((question) => (
                <Panel header={question.question} key={question.key}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.answer,
                    }}
                  ></p>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
      <div className="sales-slider">
        <div className="partners-of-pozi-title">Partners of Pozi</div>
        <Slider />
      </div>
    </div>
  );
};

export default Sales;
