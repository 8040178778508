import React from 'react';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

const styles = {
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 13,
    fontWeight: 900,
    color: '#6755CE',
    cursor: 'pointer',
  },
  textDisabled: {
    color: '#dbdbdb',
    cursor: 'not-allowed',
  },
  selectedItem: {
    backgroundColor: '#6755CE',
    color: '#FFF',
  },
};

const SortSelector = ({
  value, onChange, options, disabled,
}: Props) => (
  <Dropdown
    trigger={['click']}
    disabled={disabled}
    overlay={(
      <Menu>
        {options.map((item) => (
          <Menu.Item
            key={item.value}
            onClick={() => onChange(item.value)}
            style={item.value === value ? styles.selectedItem : {}}
          >
            {item.text}
          </Menu.Item>
        ))}
      </Menu>
    )}
  >
    <div style={{ ...styles.text, ...(disabled ? styles.textDisabled : {}) }}>
      Sort by
      <CaretDownOutlined />
    </div>
  </Dropdown>
);

export interface ISorter {
  value: string,
  text: string,
}

/* eslint-disable no-unused-vars */
interface Props {
  value: string,
  onChange: (s: string) => void;
  options: ISorter[];
  disabled?: boolean;
}

SortSelector.defaultProps = {
  disabled: false,
};

export default SortSelector;
