import React from 'react'
import {Button, DatePicker, Input, Select} from 'antd'
import moment, {Moment} from 'moment'
import { PlusOutlined, InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import {getBaseUrl} from '../../libs/utils/request';

interface Props {
  data?: any | undefined
  edit?: undefined | true
  onSubmit: (data: any) => void
}

interface State {
  loading: boolean
}

export class CreateEvent extends React.Component<Props, State> {

  image: string | null = null
  imageName: string | null = null
  title: string | null = null
  description: string | null = null
  location: string | null = null
  fromDate: Moment | null = null
  toDate: Moment | null = null
  hashtags: string[] = []
  link: string | null = null

  state: State = {
    loading: false
  }

  constructor(props: any) {
    super(props)
    if (this.props.edit) {
      const { data } = this.props
      if (data.image && data.image.filename) {
        this.image = data.image.filename
      }
      this.title = data.title || null
      this.description = data.description || null
      this.location = data.location || null
      this.fromDate = data.fromDate ? moment(data.fromDate) : null
      this.toDate = data.toDate ? moment(data.toDate) : null
      this.hashtags = data.hashtags || []
      this.link = data.link || null
    }
  }

  render() {
    const isEdit = !!this.props.edit

    return <div className={'CreateEvent'}>
      <div className={'CreateEvent-spacer'} style={{
        gridColumn: '1',
        gridRow: '1 / 3 span'
      }}><Dragger
        name={'photo'}
        multiple={false}
        showUploadList={false}
        action={`${getBaseUrl()}/image`}
        onChange={(info: any) => {
          const {status} = info.file
          if (status === 'done') {
            this.image = info.file.response.filename
            this.imageName = info.file.name
            this.forceUpdate()
          } else if (status === 'error') {
            this.image = null
            this.imageName = null
            this.forceUpdate()
          }
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined/>
        </p>
        <p className="ant-upload-text">{this.imageName ? this.imageName : 'Click or drag file to this area to upload'}</p>
      </Dragger></div>

      <Input
        defaultValue={this.title || ''}
        className={'CreateEvent-spacer'}
        style={{
          gridColumn: '2 / 2 span',
          gridRow: '1'
        }}
        placeholder="Title"
        size="large"
        onChange={(e) => {
          this.title = e.target?.value
        }}
      />

      <Input
        defaultValue={this.description || ''}
        className={'CreateEvent-spacer'}
        style={{
          gridColumn: '4 / 2 span',
          gridRow: '1'
        }}
        placeholder="Description"
        size="large"
        onChange={(e) => {
          this.description = e.target?.value
        }}
      />

      <DatePicker
        defaultValue={this.fromDate || null}
        className={'CreateEvent-spacer CreateEvent-DatePicker'}
        placeholder={'From'}
        style={{
          gridColumn: '2',
          gridRow: '2'
        }}
        onChange={(v) => this.fromDate = v}/>
      <DatePicker
        defaultValue={this.toDate || null}
        className={'CreateEvent-spacer CreateEvent-DatePicker'}
        placeholder={'To'}
        style={{
          gridColumn: '3',
          gridRow: '2'
        }}
        onChange={(v) => this.toDate = v}/>

      <Input
        defaultValue={this.location || ''}
        className={'CreateEvent-spacer'}
        style={{
          gridColumn: '4 / span 2',
          gridRow: '2',
          width: '100%'
        }}
        placeholder="Location"
        size="large"
        onChange={(e) => {
          this.location = e.target?.value
        }}
      />

      <Input
        defaultValue={this.link || ''}
        className={'CreateEvent-spacer'}
        style={{
          gridColumn: '2',
          gridRow: '3'
        }}
        placeholder="Link"
        size="large"
        onChange={(e) => {
          this.link = e.target?.value
        }}
      />

      <Select
        defaultValue={this.hashtags || []}
        className={'CreateEvent-spacer'}
        mode="tags"
        style={{
          gridColumn: '3',
          gridRow: '3',
          width: '100%'
        }}
        placeholder="Hashtags"
        onChange={(hashtags: string[]) => {
          this.hashtags = hashtags
        }}
      />
      <Button
        loading={this.state.loading}
        className={'CreateEvent-spacer'}
        style={{
          gridColumn: '4 / span 2',
          gridRow: '3'
        }}
        type="primary"
        size="large"
        onClick={async () => {
          this.setState({ loading: true })
          await this.props.onSubmit({
            image: this.image,
            title: this.title,
            description: this.description,
            location: this.location,
            fromDate: this.fromDate,
            toDate: this.toDate,
            hashtags: this.hashtags,
            link: this.link
          })
          this.setState({ loading: false })
        }}>{isEdit ? 'Edit' : [<PlusOutlined/>,'Create a new']}</Button>
    </div>
  }
}
