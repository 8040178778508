import React, { useEffect, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Form, Tag } from 'antd';
import Interests from '../../Registration/Registration_steps/Interests';
import { Interest } from '../../Registration/Registration';

const styles = {
  tagContainer: {
    minHeight: 27,
    marginBottom: 16,
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
  },
  tag: {
    marginBottom: 4,
  },
};

const themeIncluded = (theme: any, list: any) => list.map((item: any) => item.id)
  .includes(theme.id);
const getIndexById = (theme: any, list: any) => list.map((item: any) => item.id)
  .findIndex((item: any) => item === theme.id);

const InterestsContainer = ({
  selected, onChange, interestGroups,
}: { selected: any[], onChange: any, interestGroups: any[] }) => {
  const handleInterestsSelect = (interest: Interest) => {
    const tmp = [...selected];
    if (themeIncluded(interest, tmp)) {
      tmp.splice(getIndexById(interest, tmp), 1);
    } else {
      tmp.push(interest);
    }
    onChange(tmp);
  };

  return (
    <Interests
      interestGroups={interestGroups}
      selectedInterests={selected}
      onClick={handleInterestsSelect}
      withTitle={false}
      style={{ marginTop: 0, marginBottom: 10 }}
    />
  );
};

const FormInterests = ({
  form, fieldKey, interestGroups, min, max, initialValue,
}: Props) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const validateInterests = (_: any, list: any[]) => {
    if (list.length < min) {
      return Promise.reject(new Error(`Please select minimum ${min} interests!`));
    }
    if (list.length > max) {
      return Promise.reject(new Error(`Please select less then ${max} interests!`));
    }
    return Promise.resolve();
  };

  const removeById = (id: string) => {
    const tmp = [...value];
    const index = tmp.findIndex((item) => item.id === id);
    if (index !== -1) {
      tmp.splice(index, 1);
      form.setFieldsValue({ [fieldKey]: tmp });
      setValue(tmp);
      form.validateFields([fieldKey]);
    }
  };

  return (
    <>
      <div style={styles.tagContainer}>
        {value.map((item) => (
          <Tag
            closable
            onClose={() => removeById(item.id)}
            key={item.id}
            color='#6755CE'
            style={styles.tag}
          >
            {item.name}
          </Tag>
        ))}
      </div>
      <Form.Item>
        {form.getFieldDecorator(fieldKey, {
          rules: [{ validator: validateInterests }],
          initialValue: [],
        })(
          <InterestsContainer
            selected={value}
            onChange={setValue}
            interestGroups={interestGroups}
          />,
        )}
      </Form.Item>
    </>
  );
};

interface Props {
  form: WrappedFormUtils,
  fieldKey: string;
  interestGroups: any[];
  min?: number;
  max?: number;
  initialValue?: any[];
}

FormInterests.defaultProps = {
  min: 1,
  max: 5,
  initialValue: [],
};

export default FormInterests;
