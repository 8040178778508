import moment from "moment";
import React from "react";
import ReactLinkify from "react-linkify";
import { Link } from "react-router-dom";
import {download, getBaseUrl} from "../../libs/utils/request";

export function MessageContent({messages,i}: { messages: any, i: number }): any {
    const msg = messages[i];
    const hideSender = msg.sender.isYou || ((i < messages.length - 1) && messages[i + 1].sender.id === msg.sender.id);
    const className = msg.sender.isYou ? 'Message-from-me' : 'Message-from-other';

    return [
        <div className={className} key={0}>
            {msg.message || (msg.file && msg.file.length > 0) ? <div className={'Message-sentDate'}>
                {moment(msg.date).format('YYYY.MM.DD')}
                <span style={{marginLeft: '6px'}}>{moment(msg.date).format('HH:mm')}</span>
            </div> : null}
            {msg.message ? <div className={'Message-innerText'}>
                {msg.message.includes('www')||msg.message.includes('http')||msg.message.includes('https')
                ? <ReactLinkify>{msg.message.split(/\r?\n/).map((m:any,i:number) => [m, <br key={i}/>])}</ReactLinkify>
                :msg.message.split(/\r?\n/).map((m:any,i:number) => [m, <br key={i}/>])}
            </div> : null}
        </div>,
        msg.file && msg.file.length > 0 ? msg.file.map((file:any) => <div className={className} key={1}>
            <div className={'Message-innerText'}>
                <a style={{display:'flex',alignItems:'center'}}
                   onClick={(e) => {e.preventDefault(); download(getBaseUrl() + file.url, file.filename)}}>
                    <div className={'icon-attachment'} style={{ backgroundImage: 'url(/images/attachment.svg)' }}/>{file.filename}
                </a>
            </div>
        </div>) : null,
        hideSender ? null : <div className={className} key={2}>
            <div className={'Message-sender'}>
                <img src={msg.sender.profileImage} alt="" className={'Message-content-profile'}/>
                <Link to={`entrepreneurs/${msg.sender.uniqueTag}`}>{msg.sender.firstName + ' ' + msg.sender.lastName}</Link>
            </div>
        </div>
    ]
}