import React, { CSSProperties } from 'react';
import UserCard from './UserCard';
import { IUser } from '../../Event/Event';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    gap: '40px 16px',
  },
  userCard: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 8,
    marginRight: 8,
  },
};

const UserGrid = ({ users, style }: Props) => (
  <div style={{ ...styles.root, ...style }}>
    {users.map((user) => (
      <UserCard key={user.id} user={user} />
    ))}
  </div>
);

interface Props {
  users: IUser[];
  style?: CSSProperties;
}

UserGrid.defaultProps = {
  style: {},
};

export default UserGrid;
