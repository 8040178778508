import { Button, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { get } from "../../../../libs/utils/request";
import { textOverflow } from "../../../../libs/utils/textUtils";
import "../../OrganizationPage.css";
import UserView from "../UserView";

const Followers = () => {
  const [followers, setFollowers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { tag } = useParams();
  useEffect(() => {
    get(`organizations/${tag}/followers`).then((res: any) => {
      setFollowers(res.data);
      setPagination(res.pagination);
      setCurrentPage(res.pagination.currentPage);
    });
  }, []);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="organization-follower-container">
      <h2 className="organization-followers-title">Followers</h2>
      {followers.length < 1 && (
        <div className="organizations-nothing-to-show">Nothing to Show</div>
      )}
      {followers.length > 0 && (
        <>
          <div className="organization-followers-pagination">
            <Pagination
              current={currentPage}
              onChange={onPageChange}
              total={pagination?.pageCount}
            />
          </div>
          {followers !== null && (
            <div className="organization-about-team">
              {followers.map((u: any) => (
                <UserView
                  id={u.id}
                  profileImage={u.profileImage}
                  name={u.firstName + " " + u.lastName}
                  role={u.role.name}
                  oneLiner={u.oneLiner}
                  uniqueTag={u.uniqueTag}
                />
              ))}
            </div>
          )}
          <div className="organization-followers-pagination">
            <Pagination
              current={currentPage}
              onChange={onPageChange}
              total={pagination?.pageCount}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Followers;
