import React from "react"
import { MessageList } from "./MessageList"
import { MessageBox } from "./MessageBox"
import { MessageThread } from "./MessageInterface"
import { get, post } from "../../libs/utils/request"
import { message, Modal } from "antd"
import { NewThread } from "./NewThread"
import moment from "moment"
import eventbus from "../../libs/eventbus"
import './Messages.css'
import {Helmet} from 'react-helmet';

interface State {
	threads: MessageThread[] | null
	threadId: string | null
	userId: string | null
	open: boolean
	modal: boolean
	count: number | null
}

interface Props {
	match: any
	history: any
}

class Messages extends React.Component<Props, State> {
	state: State = {
		open: false,
		threads: null,
		threadId: null,
		userId: null,
		modal: false,
		count: null
	}

	refreshTimer: any = null

	constructor(props: Props) {
		super(props)
		this.load(props)
	}

	componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
		this.load(nextProps)
	}

	getLastUpdate() {
		const { threads } = this.state

		if (threads === null || threads.length === 0) {
			return new Date(new Date().getTime() - 60000 * 10).toISOString()
		}

		return new Date(threads
			.filter(e => e.lastMessageDate !== undefined)
			.sort((a, b) => moment(a.lastMessageDate).diff(moment(b.lastMessageDate)))
			.slice(-1)[0].lastMessageDate).toISOString()
	}

	async refresh() {
		const lastUpdate = this.getLastUpdate()
		let threads: MessageThread[] = await get(`/message/threads/update/${lastUpdate}`)

		if (threads.length > 0) {
			threads = threads.sort((a, b) => moment(b.lastMessageDate).diff(moment(a.lastMessageDate)))
			const unSeen = threads.filter(t => !t.read).length

			if (this.state.count !== unSeen) {
				eventbus.notify(eventbus.events.messagesCountRefresh)
			}
			this.setState({ threads, count: unSeen })
		}
	}

	async getThreadId(props: Props): Promise<string | null> {
		const { userId } = props.match.params

		if (userId) {
			const { threadId } = await get<any>(`/message/threads/user/${userId}`)
			return threadId
		}

		return props.match.params.threadId
	}

	async load(props: Props) {
		if (this.refreshTimer !== null) {
			clearInterval(this.refreshTimer)
			this.refreshTimer = null
		}
		try {
			let threadId = await this.getThreadId(props)
			let threads = await get<MessageThread[]>('/message/threads')
			threads = threads.sort((a, b) => moment(b.lastMessageDate).diff(moment(a.lastMessageDate)))

			if (!props.match.params.userId && !threadId && threads.length > 0) {
				threadId = threads[0].id
			}

			const unSeen = threads.filter(t => !t.read).length

			if (this.state.count !== unSeen) {
				eventbus.notify(eventbus.events.messagesCountRefresh)
			}

			this.setState({
				count: unSeen,
				threads,
				threadId,
				userId: threadId || !props.match.params.userId ? null : props.match.params.userId
			})
			this.refreshTimer = setInterval(() => {
				this.refresh()
			}, 5400)
		} catch (error) {
			if(error.response.status === 403 && error.response.data.extendedUser){
				sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
				if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
				window.location.replace('/trial-ended')
			}}
			else
				alert('Failed to fetch messages')
			console.error(error)
		}
	}

	componentWillUnmount() {
		if (this.refreshTimer !== null) {
			clearInterval(this.refreshTimer)
			this.refreshTimer = null
		}
	}

	render() {
		const { threadId, userId, threads } = this.state

		return <div className={'Content-container'}>
			<Helmet>
				<title>Messages | pozi.io</title>
			</Helmet>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2.5em'}}>
				<MessageList
					open={!this.state.open}
					threads={threads}
					onModal={() => this.setState({ modal: true })}
					threadId={threadId}
					onClick={(message) => this.setState((prevState:State) => ({
						threads: prevState.threads ? [
							...prevState.threads.map(t => ({
								...t,
								read: t.id === message.id ? true : t.read
							}))
						] : null,
						threadId: message.id,
						open: true
					}))} />
				{!threadId && !userId
					? null
					: <MessageBox
						history={this.props.history}
						onUpdate={threads => this.setState({
							threads: threads.sort((a, b) => moment(b.lastMessageDate).diff(moment(a.lastMessageDate)))
						})}
						userId={userId}
						threadId={threadId}
						open={this.state.open}
						onClose={() => this.setState({ open: false })}
					/>}
			</div>
			<Modal
				title="New thread"
				visible={this.state.modal}
				onOk={() => { }}
				onCancel={() => this.setState({ modal: false })}
				footer={[]}
			>
				<NewThread onCreate={async (users, text) => {
					try {
						const { threadId } = await post<any>('/message/threads/new', { users, text })
						this.setState({ modal: false })
						setTimeout(() => {
							this.props.history.push("/messages/thread/" + threadId)
						}, 1000)
					} catch (e) {
						message.error('Thread creation failed');
					}
				}} />
			</Modal>
		</div>;
	}
}

export default Messages

