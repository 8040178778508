import React from 'react'
import {showDomain} from "../../libs/utils/showDomain";

interface Props {
  text: string
  link?: boolean
}

interface State {
  open: boolean
}

export function ShowLinks(text: string, maxLength: number = 0): any {
  const http_links: number[] = []
  let http = text.indexOf('http://', http_links.slice(-1)[0] || 0)
  while(http !== -1) {
    http_links.push(http)
    http = text.indexOf('http://', http_links.slice(-1)[0] + 1)
  }

  const https_links: number[] = []
  let https = text.indexOf('https://', https_links.slice(-1)[0] || 0)
  while(https !== -1) {
    https_links.push(https)
    https = text.indexOf('https://', https_links.slice(-1)[0] + 1)
  }

  if (http_links.length === 0 && https_links.length === 0) {
    return maxLength
      ? text.slice(0, maxLength)
      : text
  }

  const links = [...http_links, ...https_links]
    .sort((a, b) => a - b)
    .filter((v, i) => v === 0 || text.charAt(v - 1).toString().trim() === '')

  const parts: { text: string, link?: string | undefined }[] = []

  let k = 0
  Loop1: for (const i of links) {
    if (i > k) {
      parts.push({text: text.slice(k, i)})
    }
    if (i < k) {
      continue
    }

    for (let j = i + 1; j < text.length; j++) {
      if (text.charAt(j).toString().trim() === '') {
        parts.push({ link: text.slice(i, j), text: text.slice(i, j) })
        k = j
        continue Loop1
      }
    }
    k = text.length
    parts.push({ link: text.slice(i, text.length), text: text.slice(i, text.length) })
  }

  if (k < text.length) {
    parts.push({ text: text.slice(k, text.length) })
  }

  const result: any = []
  let used: number | null = maxLength ? 0 : null
  for (const part of parts) {
    if (used !== null && used >= maxLength) {
      break
    }

    if(part.link) {
      result.push(<a href={part.link}>{part.text.indexOf('https://') == 0
        ? showDomain(part.text.slice(8, part.text.length))
        : showDomain(part.text.slice(7, part.text.length))}</a>)
      if (used !== null) {
        used += part.text.length
      }
    } else {
      if (used === null) {
        result.push(part.text)
      } else {
        result.push(part.text.slice(0, maxLength - used))
        used += result.slice(-1)[0].length
      }
    }
  }

  return result
}

export class ReadMore extends React.Component<Props, State> {

  state: State = {
    open: false
  }

  stateChanger() {
    return <div style={{
      color: 'var(--primary-blue)',
      cursor: 'pointer'
    }} onClick={(e) => {
      e.preventDefault()
      this.setState((prevState: State) => ({
        open: !prevState.open
      }))
    }}>{this.state.open ? 'Show less' : 'Show more'}</div>
  }

  render() {
    const parts = this.props.text?.split(/\r?\n/) || []
    if ((parts.length === 1 && parts[0].length < 320)) {
      return ShowLinks(parts[0])
    }
    if (parts.length <= 3 && parts.reduce((s,i) => s + i.length, 0) < 320) {
      return parts.map((p,i) => i === parts.length - 1
        ? ShowLinks(p)
        : [ShowLinks(p), <br />])
    }

    if (parts.length === 1) {
      const text = parts[0]
      return <p>
        { this.state.open || text.length < 320
          ? ShowLinks(text)
          : ShowLinks(text, 300) }
        { text.length >= 320
          ? this.stateChanger()
          : null }
      </p>
    }

    return <p>
      {parts[0].length < 320 || this.state.open
        ? ShowLinks(parts[0])
        : ShowLinks(parts[0],300)}
      <br/>
      {this.state.open
        ? parts
          .slice(1,parts.length)
          .map((p,i) => i === parts.length - 2
            ? ShowLinks(p)
            : [ShowLinks(p), <br />])
        : null}
      {this.stateChanger()}
    </p>
  }

}