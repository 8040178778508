import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Formik } from 'formik';
import FormikTextArea from '../../../../common/form/FormikTextArea';
import FormikInput from '../../../../common/form/FormikInput';
import Cover from '../../../../Competitions/components/Cover';
import ImageUploader2 from '../../../../common/ImageUploader2';

const styles = {
  root: { marginLeft: 16, marginRight: 16 },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 22,
    color: '#282828',
    marginBottom: 30,
  },
  input: {
    width: '100%',
  },
  inputLabel: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '22px',
    color: '#282828',
    marginBottom: 10,
  },
  uploader: {
    maxWidth: 392,
    height: 140,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'dashed',
    borderWidth: 1,
    borderColor: '#282828',
    padding: 16,
  },
  uploadIcon: { fontSize: 32, marginBottom: 8 },
  uploaderText: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: 14,
    color: '#282828',
    textAlign: 'center' as const,
  },
  accentText: { fontWeight: 900, color: '#6755CE' },
  actionBtn: {
    fontWeight: 'bold' as const,
  },
  footer: {
    width: '100%',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
};

const isValidUrl = (value: string) => value.startsWith('http://') || value.startsWith('https://');

const formValidator = (values: any) => {
  // console.log('VALIDATOR CALLED');
  const errors: any = {};
  if (values.message.length > 1000) {
    errors.message = 'Message cannot be longer than 1000 characters.';
  }
  if (values.link) {
    if (!isValidUrl(values.link)) {
      errors.link = 'Please enter a valid URL.';
    }
  }
  if (!values.message && !values.link) {
    errors.message = 'Please insert a message, or a link.';
  }
  return errors;
};

const FORM_INIT = {
  message: '',
  link: '',
  test: '',
};

const PostForm = ({
  title, btnText, isSubmitting, onCancel, onSubmit, initialValue, cover, setCover,
}: Props) => (
  <Formik
    enableReinitialize
    initialValues={initialValue}
    validate={formValidator}
    onSubmit={(values) => onSubmit(values)}
  >
    {({
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => (
      <>
        <div style={styles.title}>{title}</div>
        <div style={styles.inputLabel}>Message</div>
        <FormikTextArea
          className="create-post-textarea"  
          name='message'
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder='Type your message...'
          autoSize = {{maxRows:15}}
          disabled={isSubmitting}
          error={errors.message}
          maxLength={999}
        />
      
        <div style={{ ...styles.inputLabel, marginTop: 10 }}>Cover</div>
        {cover ? (
          <Cover url={cover} onChange={setCover} rootStyle={{ marginTop: 0 }} />
        ) : (
          <ImageUploader2
            images={cover}
            type='single'
            onChange={setCover}
          >
            <div style={styles.uploader}>
              <UploadOutlined style={styles.uploadIcon} />
              <div style={styles.uploaderText}>
                Drop a picture here, or
                {' '}
                <span style={{ ...styles.uploaderText, ...styles.accentText }}>
                  upload from a folder
                </span>
                !
                <br />
                PNG or JPG, max. 10MB.
              </div>
            </div>
          </ImageUploader2>
        )}
        <div style={styles.footer}>
          <Button
            style={{ ...styles.actionBtn, color: '#6755CE' }}
            type='link'
            size='large'
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            style={{ ...styles.actionBtn, minWidth: 150 }}
            type='primary'
            size='large'
            onClick={() => handleSubmit()}
            loading={isSubmitting}
          >
            {btnText}
          </Button>
        </div>
      </>
    )}
  </Formik>
);

/* eslint-disable no-unused-vars */
interface Props {
  initialValue: any;
  title: string;
  btnText: string;
  isSubmitting: boolean;
  cover: string,
  setCover: (s: string) => void;
  onCancel: () => void;
  onSubmit: (s: any) => void;
}

export default PostForm;
