import { Button } from "antd";
import React from "react";
import { textOverflow } from "../../../libs/utils/textUtils";

interface Props {
  id: string;
  profileImage: string;
  name: string;
  role: string;
  oneLiner: string;
  uniqueTag?: string;
}

const UserView = ({
  id,
  profileImage,
  name,
  role,
  oneLiner,
  uniqueTag,
}: Props) => {
  const sessionUser = sessionStorage.getItem('user');
  const sessionUserId = sessionUser ? JSON.parse(sessionUser).id : null; 
  return(
    <div className="organization-users-box" key={id}>
      <div>
        <div className="organization-user-left">
          <div className="organization-users-image">
            <div
              className="organization-users-image-main"
              style={{
                backgroundImage: `url(${profileImage || "/images/user.svg"} )`,
              }}
            ></div>
          </div>
        </div>
        <div className="organization-user-right">
          <div>
            <div className="organization-users-name">{name}</div>
            <div className="organization-users-role">{role}</div>
            <div className="organization-users-bio">
              {textOverflow(oneLiner, 60)}
            </div>
          </div>
          <Button
            onClick={() => window.open(id !== sessionUserId ? `/entrepreneurs/${uniqueTag}` : `/profile`, "_blank")}
            loading={false}
            type="primary"
            size="large"
            block
          >
            View
          </Button>
        </div>
      </div>
    </div>
   )
};

export default UserView;
