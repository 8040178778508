import React, { ReactElement, useEffect, useState } from 'react';
import { Button, message, Pagination } from 'antd';
import Masonry from 'react-masonry-css';
import { havePermission, IMyProjects, IUser } from '../../Competition';
import { StartupCard } from '../../../../CardView/ProjectCard';
import { deleteReq, get, post } from '../../../../../libs/utils/request';
import { Loading } from '../../../../common/Loading';
import '../../Competition.css';
import AutoSearchInput from '../../../../common/AutoSearchInput';
import ViewSelector from '../ViewSelector';
import { ITheme } from '../../../../CardView/components/Themes';
import ConfirmationDialog from '../../../../common/ConfirmationDialog';
import SubmitProjectModal from '../SubmitProjectModal';
import SortSelector, { ISorter } from '../SortSelector';
import MobileList from './components/MobileList';
import { ICommentGroup } from '../../../../comments/Comments';

const styles = {
  root: {
    maxWidth: 1200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 74,
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    flexWrap: 'wrap' as const,
    gap: '16px 16px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 30,
    fontWeight: 900,
    color: '#000',
  },
  selectorContainer: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse' as const,
    gap: '0 10px',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  errorText: {
    fontSize: 20,
    marginTop: 72,
    textAlign: 'center' as const,
    fontWeight: 600,
    color: '#F00',
  },
  errorButtonText: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center' as const,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#6755CE',
  },
  noResultText: {
    fontSize: 20,
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    marginTop: 72,
    color: '#282828',
  },
  submitBtn: { fontWeight: 'bold' as const, minWidth: 150, marginTop: 16 },
};

const SORTERS: ISorter[] = [
  { value: 'shortlisted_A', text: 'Shortlisted (yes)' },
  { value: 'notShortlisted_A', text: 'Shortlisted (no)' },
  { value: 'name_A', text: 'Name (A to Z)' },
  { value: 'name_D', text: 'Name (Z to A)' },
  { value: 'team_A', text: 'Team (smallest)' },
  { value: 'team_D', text: 'Team (largest)' },
  { value: 'evaluation_A', text: 'Evaluation (lowest)' },
  { value: 'evaluation_D', text: 'Evaluation (highest)' },
  { value: 'registration date_A', text: 'Registration date (earlier)' },
  { value: 'registration date_D', text: 'Registration date (later)' },
];

const getSortParams = (value: string) => {
  const r = value.split('_');
  if (r.length === 2) {
    return { sort: r[0], order: r[1] === 'A' ? 'ASC' : 'DESC' };
  }
  return {};
};

const Startups = ({
  tag, userRoles, myProjects, isTeamMember, onChange, evaluationStatus,
}: Props) => {
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [startups, setStartups] = useState([]);
  const [startupList, setStartupList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isListView, setListView] = useState(havePermission(['Organizer', 'Co-organizer'], userRoles));
  const [listLoading, setListLoading] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [submitModalVisible, setSubmitModalVisible] = useState(false);
  const [sortBy, setSortBy] = useState('registration date_D');
  const [limit, setLimit] = useState(100);
  const [participant ,setParticipant] = useState(false)
  useEffect(() => {
    (async () => {
      const roles:any=await get(`competitions/${tag}/my-roles`)
        setParticipant(roles.isTeamMember)
      setErrorMessage('');
      setLoading(true);
      try {
        let pagination;
        const params: any = {
          currentPage,
          limit,
          ...(isListView ? getSortParams(sortBy) : {}),
        };
        if (search) {
          params.search = search;
        }
        if (isListView) {
          params.limit = 100;
          const tmp = await get<{ data: IStartupListData[], pagination: any }>(`competitions/${tag}/startups/listview`, params);
          // console.log('STARTUPS');
          // console.log(JSON.stringify(tmp, null, 2));
          setStartupList(tmp.data);
          setStartups([]);
          pagination = tmp.pagination;
        } else {
          const tmp = await get<any>(`competitions/${tag}/startups`, params);
          // console.log('STARTUPS');
          // console.log(JSON.stringify(tmp, null, 2));
          if (tmp.data) {
            setStartups(tmp.data.map((item: any) => ({
              ...item.startup,
              userRating: item.userRating ? item.userRating.rating : 0,
              interestGroups: item.startup.interests
                .map((int: any) => ({ id: int.id, name: int.name, icon: int.group.icon })),
            })));
          } else {
            setStartups([]);
          }
          setStartupList([]);
          pagination = tmp.pagination;
        }
        setTotalCount(pagination.totalCount);
        if (currentPage !== pagination.currentPage) {
          setCurrentPage(pagination.currentPage);
        }
        if (limit !== pagination.limit) {
          setLimit(pagination.limit);
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, isListView, limit, search, sortBy, tag]);

  const getStartups = async (withLoading = true) => {
   
    setErrorMessage('');
    if (withLoading) {
      setLoading(true);
    }
    try {
      let pagination;
      const params: any = {
        currentPage,
        limit,
        ...(isListView ? getSortParams(sortBy) : {}),
      };
      if (search) {
        params.search = search;
      }
      if (isListView) {
        
        const tmp = await get<{ data: IStartupListData[], pagination: any }>(`competitions/${tag}/startups/listview`, params);
        // console.log(JSON.stringify(tmp, null, 2));
        setStartupList(tmp.data || []);
        setStartups([]);
        pagination = tmp.pagination;
      } else {
        const tmp = await get<any>(`competitions/${tag}/startups`, params);
        // console.log(JSON.stringify(tmp, null, 2));
        if (tmp.data) {
          setStartups(tmp.data.map((item: any) => ({
            ...item.startup,
            userRating: item.userRating ? item.userRating.rating : 0,
            interestGroups: item.startup.interests
              .map((int: any) => ({ id: int.id, name: int.name, icon: int.group.icon })),
          })));
        } else {
          setStartups([]);
        }
        setStartupList([]);
        pagination = tmp.pagination;
      }
      setTotalCount(pagination.totalCount);
      if (currentPage !== pagination.currentPage) {
        setCurrentPage(pagination.currentPage);
      }
      if (limit !== pagination.limit) {
        setLimit(pagination.limit);
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const listIsEmpty = (): boolean => (isListView ? !startupList.length : !startups.length);

  const handleDelete = async (deleteId: string) => {
    try {
      setListLoading(true);
      startupList.map( async (startup)=>{
        if(startup.startup && startup.startup.id == deleteId){
          await deleteReq(`/competitions/${tag}/startups/${deleteId}`)
          await getStartups(false)
          return;
        }
        else if(startup.startupDraft && startup.startupDraft.id == deleteId){
          await deleteReq(`/competitions/${tag}/drafts/${deleteId}`)
          await getStartups(false)
          }
      })
    } catch (error) {
      console.log(error)
      message.error('Failed to delete startup.');
    } finally {
      setListLoading(false);
    }
  };

  const handleShortList = async (id: string, shortlist: boolean) => {
    try {
      setListLoading(true);
      await post(`/competitions/${tag}/startups/${id}/shortlist`, { shortlist });
      await getStartups(false);
    } catch (error) {
      message.error('Failed to shortlist startup.');
    } finally {
      setListLoading(false);
    }
  };

  const renderPaginationButtons = (marginTop = 0) => (
    <div style={{ ...styles.paginationContainer, marginTop }}>
      <Pagination
        current={currentPage}
        defaultPageSize={40}
        pageSize={limit}
        onChange={setCurrentPage}
        total={totalCount}
        style={{ marginTop }}
      />
    </div>
  );

  const renderWithPager = (content: ReactElement) => (
    <>
      {renderPaginationButtons(16)}
      {isLoading ? <Loading /> : content}
      {renderPaginationButtons(42)}
    </>
  );

  const renderPagination = () => {
    if (errorMessage) {
      return (
        <>
          <div style={styles.errorText}>Failed to load competitions:</div>
          <div style={{ ...styles.errorText, marginTop: 0, fontWeight: 400 }}>
            {errorMessage}
          </div>
          <div
            style={styles.errorButtonText}
            onClick={() => getStartups()}
            aria-hidden='true'
          >
            retry
          </div>
        </>
      );
    }
    if (listIsEmpty() && !isLoading) {
      return <div style={styles.noResultText}>{`${search ? 'We didn\'t found anything.' : 'Nothing to show.'}`}</div>;
    }
    return renderWithPager(
      isListView ? (
        // <StartupList
        //   listData={startupList}
        //   onDelete={(id) => {
        //     setDeleteId(id);
        //     setDeleteDialogVisible(true);
        //   }}
        //   onShortlist={handleShortList}
        //   disabled={listLoading}
        // />
        <MobileList
          listData={startupList}
          onDelete={(id) => {
            setDeleteId(id);
            setDeleteDialogVisible(true);
          }}
          onShortlist={handleShortList}
          disabled={listLoading}
          style={{ marginTop: 48 }}
          evaluationStatus={evaluationStatus}
        />
      ) : (
        <Masonry
          breakpointCols={{ default: 2, 850: 1 }}
          className='startup-box-view'
          columnClassName='startup-box-view-item'
        >
          {startups.map((startup: IStartup) => (
            <StartupCard key={startup.id} startup={startup} onChange={() => getStartups()} />
          ))}
        </Masonry>
      ),
    );
  };

  return (
    <div style={styles.root}>
      <div style={styles.titleContainer}>
        <div>
          <div style={styles.title}>{isListView ? 'Startups' : 'Applied startups'}</div>
          { participant===false && !havePermission(['Organizer', 'Co-organizer'], userRoles) && (
            <Button style={styles.submitBtn} type='primary' size='large' onClick={() => setSubmitModalVisible(true)}>
              Submit startup
            </Button>
          )}
        </div>
        <div style={{ minWidth: 280 }}>
          <AutoSearchInput
            onSearch={(value) => {
              setSearch(value);
              setCurrentPage(1);
            }}
            style={{ width: '100%' }}
            placeholder='Type something...'
          />
          {havePermission(['Organizer', 'Co-organizer'], userRoles) && (
            <div style={styles.selectorContainer}>
              <ViewSelector isListView={isListView} onChange={setListView} />
              {isListView && (
                <SortSelector
                  value={sortBy}
                  onChange={setSortBy}
                  options={SORTERS}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {renderPagination()}
      <ConfirmationDialog
        visible={deleteDialogVisible}
        onOk={() => {
          setDeleteDialogVisible(false);
          handleDelete(deleteId);
        }}
        onCancel={() => setDeleteDialogVisible(false)}
        title='Delete application'
        description='Are you sure you want to delete? This operation is not revertible.'
        confirmBtnText='Delete'
        confirmBtnType='danger'
      />
      <SubmitProjectModal
        tag={tag}
        isStartup
        visible={submitModalVisible}
        onSubmit={() => {
          setSubmitModalVisible(false);
          onChange();
        }}
        onCancel={() => setSubmitModalVisible(false)}
        myProjects={myProjects}
      />
    </div>
  );
};

interface Props {
  tag: string;
  userRoles: string[];
  myProjects: IMyProjects;
  isTeamMember: boolean;
  onChange: () => void;
  evaluationStatus: 'INIT' | 'OPEN' | 'CLOSED';
}

export interface IStartup {
  organizer: any;
  uniqueTag: any;
  id: string;
  createdAt?: string;
  name: string;
  subtitle: string;
  description: string;
  image: string;
  averageRating?: number;
  ratingCount?: number;
  commentsCount?: number;
  user: IUser;
  userRating: number | any;
  interestGroups?: ITheme[];
  interests: any;
  pitchLink: string;
  additionalInfo: string;
  status?: string;
  commentable: boolean;
  shareCount: number;
  commentGroup: ICommentGroup;
  canJoin: boolean;
  canLeave: boolean;
  canWithdraw: boolean;
  ratingGroup: any;
  owner?:any;
}

export interface IStartupListData {
  id: string;
  isShortlisted: boolean;
  score: number;
  startup: IStartup;
  membersCount: number;
  membersCnt: number;
  startupDraft?: IStartup;
}

export default Startups;
