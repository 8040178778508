import {
  Icon, Button, message, Input,
} from 'antd';
import React, { Component, useRef } from 'react';
import { Link } from 'react-router-dom';
import NewMember from './NewMember';
import User from '../../user/User';
// import { Project } from "./ProjectPage";
import { user } from '../../libs/utils/user';
import { get, post } from '../../libs/utils/request';
import styles from './MemberRequest.module.css';
import MemberRequestItem from './MemberRequestItem';

interface State {
    title: string;
    description: string;
    user: User;
    project: any;
}

interface Props {
    project: any
    onClick: () => void
}

export class MemberRequest extends Component<Props, State> {
    onAccept = async (id: string, title: string, description: string) => {
      try {
        const data = {
          userId: id,
          title,
          description,
        };
        await post(`/projects/${this.props.project.id}/accept`, data);
        this.props.onClick && this.props.onClick();
      } catch (err) {
        // console.log(err);
      }
    }

    onDeny = async (id: string) => {
      const data = {
        userId: id,
      };
      post(`/projects/${this.props.project.id}/deny`, data);
      const projectData = await get<any>(`/projects/${this.props.project.id}`);
      this.setState({ project: projectData });
      this.props.onClick && this.props.onClick();
    }

    render() {
      const loggedIn = !!user();

      return (
        <div className='project-team-container'>
          {this.props.project.memberRequests !== undefined && this.props.project.memberRequests.length > 0
            ? (
              <div className='card team'>
                {(loggedIn && this.props.project.user.id === user().id && this.props.project.memberRequests.length > 0)
                            && <h3 className='card-title'>Member requests</h3>}
                <div className={styles.member_container}>
                  {(loggedIn && this.props.project.user.id === user().id)
                                && this.props.project.memberRequests.length > 0
                    ? this.props.project.memberRequests.map((member: any, i: number) => (
                      <MemberRequestItem member={member} i={i} key={i} onAccept={this.onAccept} onDeny={this.onDeny} />

                    ))
                    : ''}
                </div>
              </div>
            ) : ''}
        </div>
      );
    }
}
