import React, { CSSProperties, useEffect, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Form } from 'antd';
import { TimezoneSelector } from '.';

export const FormTimezoneSelector = ({
  form, fieldKey, rules, required, requiredMessage, style, initialValue,
}: Props) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <Form.Item>
      {form.getFieldDecorator(fieldKey, {
        rules: [...rules, { required, message: requiredMessage }],
        initialValue,
      })(
        <TimezoneSelector value={value} onChange={setValue} style={style} />,
      )}
    </Form.Item>
  );
};

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  rules?: any[];
  required?: boolean;
  requiredMessage?: string;
  style?: CSSProperties,
  initialValue?: string,
}

FormTimezoneSelector.defaultProps = {
  rules: [],
  required: false,
  requiredMessage: 'This field is required!',
  style: {},
  initialValue: '',
};
