import User from '../../user/User'

export const user = (): User => {
	const userString: string = sessionStorage.getItem('user')

	return !userString
		? undefined
		: new User(userString)
}

export const isAdmin = () => {
	return hasPermission('admin')
}

export const hasPermission = (permission: string) => {
	const u = user()

	return u && u.userPermission
		? u.userPermission.some(i => i === permission)
		: false

}
