import React from "react";

export function SplitEnter(text: string): any {
    if (!text) {
        return text
    }
    const lines = text.split(/\r?\n/)
    return lines.length === 1
        ? text
        : lines.map((t,i) => i === lines.length - 1 ? t : [t, <br />])
}