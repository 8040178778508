import React, { CSSProperties } from 'react';
import {
  Row, Col,
} from 'antd';
import moment from 'moment';
import {
  CalendarOutlined, EnvironmentOutlined,
} from '@ant-design/icons';
import Card from '../common/card/Card';
import '../Competitions/CompetitionCard.css';

const styles = {
  root: {
    maxWidth: 850,
    backgroundColor: '#FFF',
    borderRadius: 10,
    zIndex:1,
    border: 1,
    borderColor: '#d1d1d1',
    borderStyle: 'solid',
  },
  imageRoot: {
    width: '100%',
    height: 143,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden',
    boxShadow: '10px 10px 24px #00000014',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  titleCard: {
    width: '100%',
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 32,
    paddingRight: 32,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    zIndex: 1,
  },
  title: {
    width: '100%',
    color: '#171717',
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 10,
    maxLines: 4,
    whiteSpace: 'pre-wrap' as const,
    overflow: 'hidden',
  },
  descriptionBox: { width: '100%', overflow: 'hidden', marginBottom: 16 },
  descriptionText: { fontWeight: 300, marginBottom: 0 },
  moreBox: {
    width: '100%',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row-reverse' as const,
  },
  moreText: {
    color: '#6755CE',
    fontWeight: 300,
    fontSize: 14,
    fontStyle: 'normal',
    cursor: 'pointer',
  },
  detailText: {
    color: '#282828',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: '15px',
  },
  detailIcon: { marginRight: 10, color: '#6755CE' },
};


const DraftCard = ({ draft, onClick, style, className }: Props) => (
  <div style={{ ...styles.root, ...style }} className={className}>
    <div className='zoom' onClick={onClick} aria-hidden='true'>
      <div style={styles.imageRoot}>
        <div style={{ ...styles.image, backgroundImage: `url(${draft.image ? draft.image : '/images/idea_placeholder.png'})` }} />
      </div>
      <Card style={styles.titleCard}>
        <div style={styles.title}>{draft.title}</div>
        <div style={styles.title}>{draft.subTitle}</div>
        <div style={styles.descriptionBox}>
          <div
            style={{ ...styles.title, ...styles.descriptionText }}
          >
            {draft.shortDescription}
          </div>
        </div>
        <Row gutter={[10, 10]}>
          {!!draft.deadline && (
            <Col sm={24} md={12}>
              <div style={styles.detailText}>
                <CalendarOutlined style={styles.detailIcon} />
                Application deadline:
                <span style={{
                  ...styles.detailText, color: '#6755CE', fontWeight: 900, marginLeft: 10,
                }}
                >
                  {moment(draft.deadline).format('YYYY.MM.DD')}
                </span>
              </div>
            </Col>
          )}
          {!!draft.location && (
            <Col md={24} lg={12}>
              <div style={{ ...styles.detailText, color: '#6755CE', fontWeight: 900 }}>
                <EnvironmentOutlined style={styles.detailIcon} />
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  </div>
);

interface Props {
  draft: any;
  onClick: () => void;
  style?: CSSProperties;
  className? : string;
}

DraftCard.defaultProps = {
  style: {},
};

export default DraftCard;
