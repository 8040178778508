import React, { CSSProperties } from 'react';
import { Form, Input } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

const styles = {
  root: {
    width: '100%',
  },
};

const FormTextArea = ({
  form, fieldKey, rules, placeholder, required,
  requiredMessage, initialValue, style, autoSize, disabled,
}: Props) => (
  <Form.Item>
    {form.getFieldDecorator(fieldKey, {
      rules: [...rules, { required, message: requiredMessage }],
      initialValue,
    })(
      <Input.TextArea
        autoSize={autoSize}
        placeholder={placeholder}
        style={{ ...styles.root, ...style }}
        disabled={disabled}
      />,
    )}
  </Form.Item>
);

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  rules?: any[];
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  initialValue?: string;
  style?: CSSProperties;
  autoSize?: { minRows?: number, maxRows?: number } | boolean;
  disabled?: boolean;
}

FormTextArea.defaultProps = {
  required: false,
  rules: [],
  placeholder: '',
  requiredMessage: 'This field is required!',
  initialValue: '',
  style: {},
  autoSize: { minRows: 10, maxRows: 16 },
  disabled: false,
};

export default FormTextArea;
