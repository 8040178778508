import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import ImageUploader2 from '../../../common/ImageUploader2';
import ImageCard from './ImageCard';
import { post } from '../../../../libs/utils/request';
import PartnerLogoModal from './PartnerLogoModal';

const styles = {
  uploader: {
    maxWidth: 392,
    height: 140,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'dashed',
    borderWidth: 1,
    borderColor: '#282828',
    padding: 16,
  },
  uploadIcon: { fontSize: 32, marginBottom: 8 },
  text: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: 14,
    color: '#282828',
    textAlign: 'center' as const,
  },
  accentText: { fontWeight: 900, color: '#6755CE' },
  imageList: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    marginTop: 64,
  },
};

const PartnerLogos = ({ value, onChange, onClick }: Props) => {
  const [modalData, setModalData] = useState({ image: '', name: '', partnerUrl: '' });
  const [modalVisible, setModalVisible] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const onUpload = (image: string) => {
    setModalData({ image, name: '', partnerUrl: '' });
    setModalVisible(true);
  };

  const onEdit = (i: number) => {
    setEditIndex(i);
    setModalData({ ...(value[i]) });
    setModalVisible(true);
  };

  const onSave = (data: IPartnerLogo, oldImages: string[]) => {
    if (editIndex !== -1) {
      const tmp = [...value];
      tmp[editIndex] = data;
      onChange(tmp);
      setEditIndex(-1);
    } else {
      onChange([...value, data]);
    }
    setModalVisible(false);
    if (oldImages?.length > 0) {
      oldImages.forEach((url) => {
        post('/image/delete', { url });
      });
    }
  };

  const onModalCancel = (oldImages: string[]) => {
    setEditIndex(-1);
    setModalVisible(false);
    if (oldImages?.length > 0) {
      const imgList = value.map((item) => item.image);
      oldImages
        .filter((item) => !imgList.includes(item))
        .forEach((url) => {
          post('/image/delete', { url });
        });
    }
  };

  const deleteImage = (i: number) => {
    const tmp = [...value];
    const url = tmp[i].image;
    tmp.splice(i, 1);
    onChange(tmp);
    post('/image/delete', { url });
  };

  return (
    <>
      <ImageUploader2
        images={value.map((item) => item.image)}
        type='single'
        onChange={(i: string) => onUpload(i)}
      >
        <div style={styles.uploader} onClick={onClick}>
          <UploadOutlined style={styles.uploadIcon} />
          <div style={styles.text}>
            Drop (a) picture(s) here, or
            {' '}
            <span style={{ ...styles.text, ...styles.accentText }}>upload from a folder</span>
            !
            <br />
            PNG or JPG, max. 10MB.
          </div>
        </div>
      </ImageUploader2>
      <div style={styles.imageList}>
        {value.map((item, i) => (
          <ImageCard
            key={item.image}
            image={item.image}
            onDelete={() => deleteImage(i)}
            onEdit={() => onEdit(i)}
          />
        ))}
      </div>
      <PartnerLogoModal
        partnerLogo={modalData}
        visible={modalVisible}
        onSave={onSave}
        onCancel={onModalCancel}
      />
    </>
  );
};

export interface IPartnerLogo {
  image: string,
  name: string,
  partnerUrl: string,
}

interface Props {
  value: IPartnerLogo[];
  onChange: any;
  onClick?: ()=>void
}

export default PartnerLogos;
