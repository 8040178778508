import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import GeneralCheckbox from '../../common/form/GeneralCheckbox';

const styles = {
  checkboxText: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontStyle: 'normal',
    fontSize: 15,
    color: '#282828',
  },
};

const CheckBoxGroup = ({ data, onChange }: { data: IApplicationTypes, onChange: any }) => (
  <div>
    {/* <GeneralCheckbox */}
    {/*  text='User' */}
    {/*  checked={data.user} */}
    {/*  onChange={(v: boolean) => onChange({ ...data, user: v })} */}
    {/*  style={{ marginRight: 6 }} */}
    {/*  textStyle={styles.checkboxText} */}
    {/* /> */}
    <GeneralCheckbox
      text='Idea'
      checked={data.idea}
      onChange={(v: boolean) => onChange({ ...data, idea: v })}
      style={{ marginRight: 6 }}
      textStyle={styles.checkboxText}
    />
    <GeneralCheckbox
      text='Startup'
      checked={data.startup}
      onChange={(v: boolean) => onChange({ ...data, startup: v })}
      style={{ marginRight: 6 }}
      textStyle={styles.checkboxText}
    />
  </div>
);

const FormApplicationType = ({ form, fieldKey, initialValue }: Props) => {
  const [data, setData] = useState(initialValue);

  useEffect(() => {
    setData(initialValue);
  }, [initialValue]);

  const validateApplicationTypes = (_: any, value: IApplicationTypes) => {
    if (Object.values(value).findIndex((item) => item === true) === -1) {
      return Promise.reject(new Error('Please select at least one.'));
    }
    return Promise.resolve();
  };

  return (
    <Form.Item>
      {form.getFieldDecorator(fieldKey, {
        rules: [{ validator: validateApplicationTypes }],
        initialValue,
      })(
        <CheckBoxGroup data={data} onChange={setData} />,
      )}
    </Form.Item>
  );
};

interface IApplicationTypes {
  user: boolean;
  idea: boolean;
  startup: boolean;
}

interface Props {
  form: WrappedFormUtils;
  fieldKey: string;
  initialValue?: IApplicationTypes;
}

FormApplicationType.defaultProps = {
  initialValue: { user: false, idea: false, startup: false },
};

export default FormApplicationType;
