import { Button, Input } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import FormikInput from "../common/form/FormikInput";
import FormikTextArea from "../common/form/FormikTextArea";
import styles from "./MemberRequest.module.css";

interface Props {
  member: any;
  i: number;
  onAccept: (id: string, title: string, description: string) => void;
  onDeny: (id: any) => void;
}

const MemberRequestItem = ({ member, i, onAccept, onDeny }: Props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [formValue, setformValue] = useState({
    title: "",
    description: "",
  });

  const handleTitleChange = (title?: string) => {
    setTitle(title);
  };
  const handleDescription = (description?: string) => {
    setDescription(description);
  };
  const onSubmit =  (values: any, e: any) => {
    onAccept(member.user.id, values.title, values.description)
    let reset = e.resetForm
    reset();
  }
  const validateForm = (values:any) =>{
    const errors: any = {};
    if(values.title === ""){
      errors.title = "This field is required!"
    }
    if(values.description === ""){
      errors.description = "This field is required!"
    }
    return errors
  }
  return (
    <div className={styles.member}>
      <Formik
        enableReinitialize
        validate={validateForm}
        initialValues={formValue}
        onSubmit={(values:any, e:any) => {
          
          onSubmit(values,e);
        }
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset
        }) => (
          <>
            <div key={member.user.id + "" + i} className={styles.member_header}>
              <div
                className={styles.teamMemberPhoto}
                style={{
                  backgroundImage: `url(${member.user.profileImage || "/images/user.svg"
                    } )`,
                }}
              ></div>

              <div className={styles.member_title}>
                {member.user && (
                  <Link
                    style={{ display: "block" }}
                    to={"/entrepreneurs/" + member.user.uniqueTag}
                  >
                    {member.user.firstName + ' ' + member.user.lastName}
                  </Link>
                )}
              </div>
            </div>
            <div className="member-text" style={{ marginTop: 10 }}>
              <div className={styles.input_title}>
                <FormikInput
                  value={values.title}
                  name="title"
                  onChange={handleChange}
                  placeholder="Role"
                  maxLength={28}
                  key={i}
                  error={errors.title}
                  withError
                />
              </div>
              <div className={styles.input_description}>
                <FormikTextArea
                  value={values.description}
                  name="description"
                  onBlur={handleBlur}
                  autoSize={{ minRows: 3, maxRows: 4}}
                  onChange={handleChange}
                  placeholder="What are your teammate's responsibilities?"
                  maxLength={100}
                  key={i}
                  error={errors.description}   
                />
              </div>
              <div id="requestsButtons" style={{ textAlign: "start" }}>
                <Button
                  className={styles.btn}
                  type="primary"
                  size="large"
                  onClick= {(e: any)  =>  {

                    handleSubmit(e)
                   
                  //  await setFieldValue("title", "")
                  //  await setFieldValue("description", "")
                  }
                  }
                >
                  Accept
                </Button>
                <Button
                  className={styles.btnDeny}
                  type="primary"
                  size="large"
                  onClick={() => onDeny(member.user.id)}
                >
                  Deny
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default MemberRequestItem;
