import React from 'react'
import { UserSearch } from '../User/UserSearch'
import User from '../../user/User'
import TextArea from 'antd/lib/input/TextArea'
import { Button, message } from 'antd'
import './GroupInvitation.css'
import { post } from '../../libs/utils/request'

interface Props {
  onClose: () => void
  group: any
}

interface State {
  value: User[]
  loading: boolean
}

export class GroupInvitation extends React.Component<Props, State> {

  state: State = {
    value: undefined,
    loading: false
  }

  message: string = ''

  async send() {
    try {
      const user = this.state.value
      if (user.length === 1 && user[0].id) {
        this.setState({ loading: true })
        await post('/groups/invite', {
          id: this.props.group.id,
          userId: user[0].id,
          message: this.message
        })
        this.setState({ loading: false })
        message.success(`You are invited ${user[0].firstName} ${user[0].lastName}`)
        this.setState({value: undefined})
      } else {
        message.error('User is not selected')
      }
    } catch (e) {
      this.setState({ loading: false })
      message.error(e.response?.data?.message || 'Network error')
      this.setState({value: undefined})
    }
  }

  render() {
    return <div className={'GroupInvitation'}>
      <div className="headline">
        <h3 className="title">Invitation to group</h3>
      </div>
      <div className="body">
        <UserSearch multiple={false} onChange={(value) => {
          this.setState({ value })
        }}  value={this.state.value}/>
        <TextArea
          style={{
            marginTop: '16px'
          }}
          placeholder={"Message"}
          autoSize
          maxLength={1000}
          onChange={(e) => {
            this.message = e.target?.value
          }} />
        <Button
          onClick={() => {
            this.send()
          }}
          loading={this.state.loading}
          type="primary"
          size="large"
          block
          style={{
            width: '200px',
            marginTop: '16px',
            marginBottom: '50px',
          }}>Send</Button>
      </div>
    </div>
  }
}
