import React from "react";
import {get} from "../../libs/utils/request";
import {Role} from "../Registration/Registration";

interface Props {
  onChange: (roles: Role[]) => void
}

interface State {
  roles: Role[] | null
  selected: Role[]
}

export class RoleFilter extends React.Component<Props, State> {
  timeout: any = null

  state: State = {
    roles: null,
    selected: []
  }

  async componentDidMount() {
    const roles = await get<Role[]>('/user/roles')
    this.setState({roles})
  }

  change(role: Role) {
    this.setState((prevState: State) => {
      const includes = prevState.selected.map(r => r.id).includes(role.id)

      const newState = {
        selected: includes
          ? prevState.selected.filter(r => r.id !== role.id)
          : [...prevState.selected, role]
      }

      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.props.onChange(newState.selected)
      }, 800)

      return newState
    })
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  render() {
    const {roles} = this.state
    if (roles === null) {
      return null
    }

    return roles.map(r => {
      const selected = this.state.selected.map(r => r.id).includes(r.id)
      return <div
        onClick={() => this.change(r)}
        style={{
          background: selected ? 'var(--primary-blue)' : '#fff',
          borderRadius: '9px',
          boxSizing: 'content-box',
          cursor: 'pointer',
          display: 'inline-block',
          width: '128px',
          height: '62px',
          padding: '8px',
          margin: '6px',
          color: selected ? '#fff' : undefined,
          textAlign: 'center',
          boxShadow: '-3px 2px 2px rgba(0, 0, 0, 0.04), 2px 4px 10px rgba(0, 0, 0, 0.04), '
            +'4px -8px 12px rgba(0, 0, 0, 0.03), 8px 16px 16px rgba(0, 0, 0, 0.03), 16px 32px 32px rgba(0, 0, 0, 0.02), 32px 64px 64px rgba(0, 0, 0, 0.02)'
        }}
      >
        <div style={{
          display: 'inline-block',
          backgroundImage: 'url(' + r.icon + ')',
          width: '32px',
          height: '32px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }} />
        <div>{r.name}</div>
      </div>
    })
  }
}
