import React, { Component } from "react";
import { message, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import "../../../User/UserSearch.css";
import { get, post } from "../../../../libs/utils/request";
import { base64decode, base64encode } from "../../../../libs/utils/textUtils";
import { isAdmin } from "../../../../libs/utils/user";

const { Option } = Select;

interface State {
  value: { key: string; label: string }[];
  data: { key: string; label: string }[];
  fetching: boolean;
}

interface Props {
  value?: any;
  multiple?: boolean;
  onChange?: (organization: any) => void;
  onClick?: () => void;
}

export class OrganizationSearch extends Component<Props, State> {
  state: State = {
    value: [],
    data: [],
    fetching: false,
  };

  lastFetchId = 0;

  constructor(props: Props) {
    super(props);
    this.onSearch = debounce(this.onSearch, 800);
  }

  componentDidMount() {
    this.onSearch("");
  }

  async onSearch(value: string) {
    this.setState({ data: [], fetching: true });
    try {
      const organizations: any =isAdmin() 
      ? await get("/organizations/all-organizations") 
      : await get("/organizations/my-organizations");
      this.setState({
        data: organizations.map((u: any) => ({
          key: base64encode(JSON.stringify(u)),
          label: `${u.name}`,
          logo:`${u.logo}`
        })),
        fetching: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  onChange(value: any) {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if(value.key==="new" && user.progress!==100){
      message.warning(`${user.progress}% of your profile is completed. To create organization you need to get 100%`)
    }
    value = this.props.multiple === true ? [value] : value;
    this.setState({
      value,
      // data: [],
      fetching: false,
    });
    if (this.props.onChange) {
      const newValue = JSON.parse(base64decode(value.key));
      this.props.onChange(newValue);
    }
  }

  render() {
    const { fetching, data, value } = this.state;

    let propValue: { key: string; label: string; logo: string };
    if (this.props.value !== undefined) {
      propValue = {
        key: base64encode(JSON.stringify(this.props.value)),
        label: `${this.props.value ? this.props.value.name : this.props.value}`,
        logo: `${this.props.value ? this.props.value.logo : this.props.value}`
      };
    }
    if (this.props.value?.length > 0) {
      this.props.value.map((value: any) => {
        propValue = {
          key: base64encode(JSON.stringify(value)),
          label: `${value ? value.name : value}`,
          logo: `${this.props.value ? this.props.value.logo : this.props.value}`
        };
      });
    }
    return (
      <Select
        showSearch
        mode={this.props.multiple === true ? "multiple" : undefined}
        labelInValue
        value={propValue}
        placeholder="Select organization"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        onSearch={(value: any) => this.onSearch(value)}
        onChange={(value: any) => this.onChange(value)}
        optionFilterProp="children"
        filterOption={false}
        style={{ width: "100%" }}
        showArrow={false}
      >
        {data.length > 0 &&
          data.map((u: any) => <Option key={u.key}>
            <img
							style={{
								width: 28,
								height: 28,
								objectFit: 'cover',
								borderRadius: '50%',
								marginRight: 4,
							}}
							src={u.logo ? `${u.logo}?w=28` : '/images/onePagerLogo.png'}
							alt=''
						/>{u.label}</Option>)}
      </Select>
    );
  }
}
