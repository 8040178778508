import React from 'react';
import Card from '../../common/card/Card';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    borderRadius: 5,
    padding: 10,
  },
  icon: {
    height: 25,
    marginRight: 10,
  },
  label: {
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 300,
    color: '#000',
  },
  value: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 900,
    color: '#000',
  },
};

const DetailListItem = ({ label, value, icon }: Props) => (
  <Card style={styles.root}>
    <img style={styles.icon} src={icon} alt='' />
    <div>
      <div style={styles.label}>{label}</div>
      <div style={styles.value}>{value}</div>
    </div>
  </Card>
);

interface Props {
  label: string;
  value: string;
  icon: string;
}

export default DetailListItem;
