import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, message, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Masonry from "react-masonry-css";
import { useHistory } from "react-router";
import { get } from "../../libs/utils/request";
import { isAdmin, user } from "../../libs/utils/user";
import { OrganizationCard } from "../CardView/ProjectCard";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { Loading } from "../common/Loading";
import { digestMessage } from "./NewOrganization";
import OrganizationsDraft from "./OrganizationsDraft";

const suffix = (
  <div
    style={{
      background: "var(--secondary-blue)",
      borderRadius: "100%",
      height: "50px",
      width: "50px",
      textAlign: "center",
      paddingTop: "15px",
      marginLeft: "2px",
      position: "absolute",
    }}
  >
    <SearchOutlined
      style={{
        fontSize: 18,
        color: "#fff",
      }}
    />
  </div>
);

const Organizations = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [displayPagination, setDisplayPagination] = useState(true);
  const [projectList, setProjectList] = useState(null);
  const [totalProjects, setTotalProjects] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();

  useEffect(() => {
    getOrganizations(currentPage);
  }, []);

  const onAddNew = async () => {
    if(user() && isAdmin()){
      const user = JSON.parse(sessionStorage.getItem('user'))
      const hash = await digestMessage(user.uniqueTag)
      history.push('/new-organization/'+hash)
    }
    //}else
      // setModalVisible(true)
      //window.location.replace("/")
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page);
    // getOrganizations(page)
  };

  const getOrganizations = async (page:any) => {
    try {
      await get(
        `/organizations?currentPage=${page}`
      ).then((res: any) => {
        setProjectList(res.data);
        setFilteredList(res.data);
        setPagination(res.pagination)
        setCurrentPage(res.pagination.currentPage)
        setTotalProjects(res.data);

      });
      setLoading(false);
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      // console.log(err)
    }
  };

  const onSearch = (value: string) => {
    if (value) {
      const filteredOrgs = totalProjects.filter((organization: any) =>
        JSON.stringify(organization.name && organization.description && organization.shortDescription)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      );
      if (filteredOrgs.length > 0) {
        setFilteredList(filteredOrgs);
        setNoResult(false);
        setDisplayPagination(false);
      } else {
        setNoResult(true);
        setDisplayPagination(false);
      }
    } else {
      setFilteredList(
        projectList.slice(currentPage * 10 - 10, currentPage * 10)
      );
      setNoResult(false);
      setDisplayPagination(true);
    }
  };

  const userFromStorage = sessionStorage.getItem("user");
  const profileProgress = userFromStorage
    ? JSON.parse(userFromStorage).progress
    : 0;

  return (
    <div className="Content-container">
      <Helmet>
        <title>Organizations | pozi.io</title>
      </Helmet>
      <div className="Ideas">
        <div className="Ideas-top-container">
          <h3 className="Ideas-top-title">Find awesome organizations</h3>
          <div className="Ideas-top-options">
              <div className="option-add">
                <Button
                  type="primary"
                  size="large"
                  onClick={() =>
                    onAddNew()
                  }
                >
                  Create a new
                </Button>
              </div>

            <Input
              style={{ maxWidth: "250px" }}
              className={"Users-search"}
              placeholder={"Type a name you like..."}
              // @ts-ignore
              onChange={(e) => onSearch(e.target?.value)}
              suffix={suffix}
              addonAfter={null}
            />
          </div>
          {user() === undefined ? "" : <OrganizationsDraft />}
        </div>
        {loading ? <Loading /> : null}
        {noResult ? (
          <div className="no-result">
            We didn't found anything. Maybe it's a new organization?
          </div>
        ) : (
          <>
            {(displayPagination && pagination) && (
              <div className="Ideas-pagination">
                <Pagination
                  current={currentPage}
                  onChange={onPageChange}
                  pageSize={pagination.totalCount}
                  total={pagination.totalCount}
                />
              </div>
            )}
            <Masonry
              breakpointCols={{ default: 2, 850: 1 }}
              className="Ideas-list"
              columnClassName="Ideas-list-column"
            >                  

              {filteredList !== null &&
                filteredList.map((organization: any) => (
                  <OrganizationCard
                    organization={organization}
                    key={organization.id}
                    onChange={()=>getOrganizations(currentPage)}
                  />
                ))}
            </Masonry>
            {(displayPagination && pagination) && (
              <div className="Ideas-pagination-bottom">
                <Pagination
                  current={currentPage}
                  onChange={onPageChange}
                  pageSize={pagination.totalCount}
                  total={pagination.totalCount}
                />
              </div>
            )}
          </>
        )}
      </div>
      <ConfirmationDialog
              visible={isModalVisible}
              onCancel={() => setModalVisible(false)}
              onOk={() => {setModalVisible(false)}}
              title='Create a new organization'
              htmlDescription={'Book a Demo with our team, write a short email to, <a href="mailto:hellous3@bme.hu" >hellous3@bme.hu</a>'}
            />
    </div>
  );
};

export default Organizations;
