import React, { useEffect, useState } from 'react';
import ScoreInput from './ScoreInput';
import { ICriterion } from '../../../Competition';
import ItemWithIcon from '../../ItemWithIcon';
import BorderCard from '../../../../../common/BorderCard';
import { IProjectEvaluation } from '../Jury';

const PROJECT_WIDTH = 200;
const PITCH_WIDTH = 50;
const AVG_WIDTH = 60;
const baseText = {
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 'normal' as const,
  color: '#282828',
};
const safeText = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center' as const,
};
const styles = {
  root: {
    width: '100%',
    marginTop: 40,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    justifyContent: 'space-between',
    // backgroundColor: '#00F',
    gap: '0 8px',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
  },
  startupHeader: {
    ...baseText,
    ...safeText,
    // backgroundColor: '#F00',
    width: PROJECT_WIDTH,
    fontWeight: 900,
    textAlign: 'left' as const,
  },
  startupCol: {
    width: PROJECT_WIDTH,
  },
  pitchHeader: {
    ...baseText,
    ...safeText,
    fontWeight: 900,
    width: PITCH_WIDTH,
  },
  pitchCol: {
    ...baseText,
    ...safeText,
    width: PITCH_WIDTH,
  },
  pitchColLink: {
    color: '#6755CE',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  inputHeader: {
    ...baseText,
    ...safeText,
    fontWeight: 900,
    width: 65,
  },
  inputCol: {
    width: 60,
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  avgHeader: {
    ...baseText,
    ...safeText,
    fontWeight: 900,
    width: AVG_WIDTH,
  },
  avgCol: {
    ...baseText,
    ...safeText,
    width: AVG_WIDTH,
    backgroundColor: '#6755CE',
    borderRadius: 5,
    color: '#FFF',
    fontWeight: 900,
    paddingTop: 4,
    paddingBottom: 4,
  },
  submittedText: {
    ...baseText,
    fontWeight: 'bold' as const,
  },
};

const calculateAvg = (scores: number[]): number => Math.round((scores
  .reduce((acc, k) => acc + k, 0)
  / Math.max(scores.length, 1)) * 100) / 100;

const JuryTable = ({
  data, criteria, values, handleChange, handleBlur, savedValues, formDisabled,
}: Props) => {
  const [averages, setAverages] = useState<any>({});

  useEffect(() => {
    const avgs: any = {};
    for (let i = 0; i < data.length; i += 1) {
      const nums = [];
      for (let j = 0; j < criteria.length; j += 1) {
        const v = values[`${data[i].id}_${criteria[j].id}`];
        if (v && v !== '') {
          const k = Number.parseInt(v, 10);
          if (!Number.isNaN(k)) {
            nums.push(k);
          }
        }
      }
      avgs[data[i].id] = calculateAvg(nums);
    }
    setAverages(avgs);
  }, [criteria, data, values]);

  const isChanged = (key: string, value: string): boolean => {
    if (typeof value !== 'string') {
      return false;
    }
    const sv = savedValues[key];
    return sv ? value !== sv : !!value.length;
  };

  return (
    <div style={styles.root}>
      <BorderCard isTop style={{ ...styles.row, paddingTop: 10, paddingBottom: 10 }}>
        <div style={styles.startupHeader}>Startup name</div>
        <div style={styles.pitchHeader}>Pitch link</div>
        <>
          {criteria.map((criterion) => (
            <div key={criterion.id} style={styles.inputHeader}>
              {criterion.title}
            </div>
          ))}
        </>
        <div style={styles.avgHeader}>Average</div>
      </BorderCard>
      {data.map((item, i) => (
        <BorderCard key={item.id} isBottom={i === data.length - 1} style={styles.row}>
          <div style={styles.startupCol}>
            <ItemWithIcon
              text={item.project.name}
              icon={item.project.image}
              link={`/${item.isStartup ? 'startups' : 'idea'}/${item.project.uniqueTag}`}
              textStyle={safeText}
            />
          </div>
          <div
            style={{
              ...styles.pitchCol,
              ...(item.project.pitchLink ? styles.pitchColLink : {}),
            }}
            onClick={() => {
              if (item.project.pitchLink) {
                window.open(item.project.pitchLink, '_blank');
              }
            }}
            aria-hidden='true'
          >
            {item.project.pitchLink ? 'link' : '-'}
          </div>
          <>
            {criteria.map((criterion) => {
              const id = `${item.id}_${criterion.id}`;
              const score = item.scores?.find((s) => s.criteriaId === criterion.id);
              return (
                <div key={id} style={styles.inputCol}>
                  {item.isSubmitted ? (
                    <div style={styles.submittedText}>
                      {score ? score.value : 'N/A'}
                    </div>
                  ) : (
                    <ScoreInput
                      min={1}
                      max={5}
                      name={id}
                      value={values[id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      unsaved={isChanged(id, values[id])}
                      disabled={formDisabled}
                    />
                  )}
                </div>
              );
            })}
          </>
          <div style={styles.avgCol}>{item.isSubmitted ? item.average : averages[item.id] || '-'}</div>
        </BorderCard>
      ))}
    </div>
  );
};

interface Props {
  data: IProjectEvaluation[];
  criteria: ICriterion[];
  values: any;
  handleChange: any;
  handleBlur: any;
  savedValues: any;
  formDisabled: boolean;
}

export default JuryTable;
