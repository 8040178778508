import React from 'react'
import {get, post} from '../../libs/utils/request'
import {message, Button, Modal} from 'antd'
import {Loading} from '../common/Loading'
import {Link} from 'react-router-dom'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {GroupEdit} from './GroupEdit'
import {GroupMembers} from './GroupMembers'
import {GroupInvitation} from './GroupInvitation'
import './GroupPage.css'
import {SearchBar} from "../common/SearchBar";
import {MemberRequests} from "./MemberRequests";
import GroupFeed from './GroupFeed';
import {Helmet} from 'react-helmet';

interface State {
  data: any
  onEdit: boolean
  onInvite: boolean
  onMembers: boolean
  onRequests: boolean
}

export class GroupPage extends React.Component<any, State> {
  

  state: State = {
    data: null,
    onEdit: false,
    onInvite: false,
    onMembers: false,
    onRequests: false
  }

  async componentDidMount() {
    const {id} = this.props.match.params

    try {
      const data: any = await get(`/groups/get/${id}`)

      this.setState({
        data
      })
    } catch (error) {
      if(error.response.status === 403 && error.response.data.extendedUser){
        sessionStorage.setItem("user", JSON.stringify(error.response.data.extendedUser))
        if(!error.response.data.extendedUser.active && !error.response.data.extendedUser.trial){
          window.location.replace('/trial-ended')
      }}
      message.error('Failed to fetch')
    }
  }

  async leave() {
    try {
      const data: any = await post(`/groups/leave`, {
        groupId: this.props.match.params.id
      })

      this.setState({
        data
      })

      message.info('You are left ' + data.name + ' group')
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
    }
  }

  async requestJoin() {
    try {
      const data: any = await post(`/groups/joinRequest/request`, {
        groupId: this.props.match.params.id
      })

      message.info(data.message)
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
    }
  }

  async join() {
    try {
      const data: any = await post(`/groups/join`, {
        groupId: this.props.match.params.id
      })

      this.setState({
        data
      })

      message.info('You are joined to ' + data.name + ' group')
    } catch (e) {
      message.error(e.response?.data?.message || 'Network error')
    }
  }

  render() {
    const userFromStorage = sessionStorage.getItem('user')
    const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
    const {data} = this.state

    if (data === null) {
      return <Loading/>
    }

    const { isAdmin, isMember, isPublic, isPortfolio } = data

    return data == null
      ? <Loading/>
      : <div className={'GroupPage Content-container'}>
        <Helmet>
          <title>{`${data.name} | group | pozi.io`}</title>
        </Helmet>
        <div className={'GroupPage-head'}>
          <div className={'GroupPage-left'}>
            <Link to={'/groups'}><ArrowLeftOutlined
              style={{marginRight: '8px'}}/>{'Back to groups'/*'Back to the ' + data.groupType.name + ' category'*/}
            </Link>
            <div style={{
              marginTop: '32px',
              fontSize: '1.5rem',
              fontWeight: 900
            }}>{data.name}</div>
            <p>{data.description}</p>
            <div className={'GroupPage-boxes'}>
              <div className={'GroupPage-box'}
                   style={{cursor: 'pointer'}}
                   onClick={() => this.setState({onMembers: true, onEdit: false, onInvite: false})}>
                <div className={'GroupPage-box-icon'} style={{
                  backgroundImage: 'url(/images/groups/members.svg)'
                }}/>
                <div className={'GroupPage-box-text'}>
                  <div>Members</div>
                  <div style={{fontWeight: 'bold'}}>{data.membersCount}</div>
                </div>
              </div>
              {data.admin
                ? <div className={'GroupPage-box'} style={{
                  minWidth: '164px'
                }}>
                  <div className={'GroupPage-box-icon'}
                       style={{
                         backgroundImage: 'url(/images/groups/admin.svg)'
                       }}/>
                  <div className={'GroupPage-box-text'}>
                    <div>Admin</div>
                    <div
                    onClick={() => window.open("/messages/user/" + data.admin.id)}
                      style={{
                        color: 'var(--primary-blue)',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                      }}>Send message
                    </div>
                  </div>
                </div>
                : null}
            </div>
            <SearchBar onSearch={(value) => {/*TODO*/}} />
          </div>
          <div className={'GroupPage-right'}>
            <div className={'GroupPage-image'}>
              <img src={data.image} alt={""}/>
            </div>
            <div className={'GroupPage-bar'}>
              {data.isAdmin ? <Button
                type="primary"
                size="large"
                onClick={() => this.setState({onEdit: true, onInvite: false, onMembers: false, onRequests: false})}>Edit</Button> : null}
              {data.isAdmin || data.isMember ? <Button
                type="primary"
                size="large"
                onClick={() => this.setState({onInvite: true, onEdit: false, onMembers: false, onRequests: false})}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <div className={'GroupPage-button-icon'} style={{
                    backgroundImage: 'url(/images/groups/invite.svg)'
                  }}/>
                  <div style={{display: 'inline-block'}}>Invite</div>
                </div>
              </Button> : null}
              {isAdmin
                ? <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    this.setState({onInvite: false, onEdit: false, onMembers: false, onRequests: true})
                  }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <div className={'GroupPage-button-icon'} style={{
                      backgroundImage: 'url(/images/groups/join.svg)'
                    }}/>
                    <div style={{display: 'inline-block'}}>Requests</div>
                  </div>
                </Button>
                : (isMember
                  ? <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      this.leave()
                    }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                      <div className={'GroupPage-button-icon'} style={{
                        backgroundImage: 'url(/images/groups/leave.svg)'
                      }}/>
                      <div style={{display: 'inline-block'}}>Leave</div>
                    </div>
                  </Button>
                  : <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      if (this.state.data && !this.state.data.isPublic) {
                        this.requestJoin()
                      } else {
                       profileProgress < 70 ?  message.warning(`${profileProgress}% of your profile is completed. To join a group you need to get at least 70%`) : this.join()
                      }
                    }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                      <div className={'GroupPage-button-icon'} style={{
                        backgroundImage: 'url(/images/groups/join.svg)'
                      }}/>
                      <div style={{display: 'inline-block'}}>Join</div>
                    </div>
                  </Button>)
              }
            </div>
          </div>
        </div>
        <div className={'GroupPage-body'}>
          {!isPortfolio && (isAdmin || isMember || isPublic)
            ? <GroupFeed groupId={data.id} />
            : null}
          {isPortfolio && (isAdmin || isMember || isPublic)
            // ? <PortfolioView group={data} groupId={this.props.match.params.id} />
            ? <GroupFeed groupId={data.id} />
            : null}
        </div>
        <Modal
          bodyStyle={{padding: '0'}}
          width={950}
          visible={this.state.onEdit}
          footer={null}
          onCancel={() => this.setState({onEdit: false})}
          closable={true}
        >
          <GroupEdit
            group={data}
            onUpdate={(data) => this.setState({data})}
            onClose={() => this.setState({onEdit: false})}/>
        </Modal>

        <Modal
          bodyStyle={{padding: '0'}}
          width={500}
          visible={this.state.onInvite}
          footer={null}
          onCancel={() => this.setState({onInvite: false})}
          closable={true}
        >
          <GroupInvitation
            group={data}
            onClose={() => this.setState({onInvite: false})}/>
        </Modal>

        <Modal
          bodyStyle={{padding: '0'}}
          width={400}
          visible={this.state.onMembers}
          footer={null}
          onCancel={() => this.setState({onMembers: false})}
          closable={true}
        >
          <GroupMembers
            group={data}
            onClose={() => this.setState({onMembers: false})}/>
        </Modal>

        <Modal
          bodyStyle={{padding: '0'}}
          width={400}
          visible={this.state.onRequests}
          footer={null}
          onCancel={() => this.setState({onRequests: false})}
          closable={true}
        >
          <MemberRequests groupId={this.props.match.params.id} />
        </Modal>
      </div>
  }

}
