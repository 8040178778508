import "./CompleteReminder.css";
import React, { useState, useEffect } from "react";
import { get } from '../../libs/utils/request';
import { user } from '../../libs/utils/user';

import { json } from "express";

const CompleteReminder = () => {
    const [percantage, setPercantage] = useState(null)
    const [isModalActive, setModalActive] =  useState(true)
    const [modalWasClosed, setModalClosed] = useState('')
    const [missingFields, setMissingFields] = useState([])
    const [fieldToComplete, setFieldToComplete] = useState("asdasdasdds")




    useEffect(() => {
        setModalClosed( sessionStorage.getItem('isModalClosed'))
        if(user()){
        const user:any =  get("/me").then((res:any)=>{
            setPercantage(res.progress)
            setMissingFields(res.missingFields)
            if(res.progress === 100)
              setModalActive(false)
        });
        }
      }, [user]);

    const onClose = () => {
        setModalActive(false)
        sessionStorage.setItem('isModalClosed',"true")
    }

    const countRemainPercantage = () => {
      if(missingFields.includes("skills") && missingFields.includes("interests") && missingFields.length > 5  ){
        return "50%"
      }
      if(missingFields.includes("skills") || missingFields.includes("interests") && missingFields.length < 5){
        return "75%"
      }
      if(!missingFields.includes("interests") && !missingFields.includes("skills") && missingFields.length < 5 ){
        return "100%"
      }
     
      else{
        return "100%"
      }
    }

    const showFieldToComplete = () => {
      if(missingFields.includes("skills") && missingFields.includes("interests") && missingFields.length > 5  ){
        return "personal data"

      }
      if(missingFields.includes("skills")){
        return "skills"

      }
      if(missingFields.includes("interests")){
        return "interests"
      }
      else{
        return "personal details"
      }
     
      
    }
  
  return (
      <>
       { isModalActive && modalWasClosed !== 'true' && percantage &&
        <div className="popup-container">
            <div className="popup-content">
                    <div className="popup-text"><p><span className="percantage">{percantage}%</span> of your profile has been completed. To get to {countRemainPercantage()}, complete your {showFieldToComplete()}.</p></div>
                    <div onClick={()=> onClose()}> 
                        <img className='popup-close-icon' src={'/images/close_black.svg'} alt=''/>
                    </div>    
            </div>
            <div className="complete"><a className="profile-link" href="/profile">Complete now</a></div>
         </div>
       } 
      </>
    
   
  );
};

export default CompleteReminder;
