import React, { CSSProperties, ReactElement } from 'react';

const createStyles = (borderColor: string) => {
  const CARD_BORDER = `.5px solid ${borderColor}`;
  return {
    root: { backgroundColor: '#FFF' },
    topCard: {
      borderTop: CARD_BORDER,
      borderLeft: CARD_BORDER,
      borderRight: CARD_BORDER,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    card: {
      borderTop: CARD_BORDER,
      borderLeft: CARD_BORDER,
      borderRight: CARD_BORDER,
    },
    bottomCard: {
      border: CARD_BORDER,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
  };
};

const BorderCard = ({
  isTop, isBottom, style, onClick, children, borderColor,
}: Props) => {
  const styles = createStyles(borderColor);
  const getCardStyle = () => {
    let res = isTop ? styles.topCard : {};
    if (isBottom) {
      res = { ...res, ...styles.bottomCard };
    }
    return { ...res, ...styles.card };
  };

  const handleClick = (e: any) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div style={{ ...styles.root, ...(getCardStyle()), ...style }} onClick={(e) => handleClick(e)} aria-hidden='true'>
      {children}
    </div>
  );
};

interface Props {
  isTop?: boolean;
  isBottom?: boolean;
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: any) => void;
  children: ReactElement | ReactElement[];
  borderColor?: string;
}

BorderCard.defaultProps = {
  isTop: false,
  isBottom: false,
  style: {},
  onClick: null,
  borderColor: '#D1D1D1',
};

export default BorderCard;
