import { DatePicker, Input, message, Modal } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { deleteReq, get, post } from "../../../libs/utils/request";
import ConfirmationDialog from "../../common/ConfirmationDialog";

const convertDate = (d: Moment, ): string => (moment(d))
  .toISOString();

const StartupListView = ({ startups, onDelete }: Props) => {
  const [note, setNote] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [dateSelector,setDateSelector]=useState(false)
  const [selectedId,setSelectedId]=useState('')
  const [newDate,setNewDate]=useState(null)
  const { tag } = useParams();

  const saveNote = (event: any, id: string) => {
    post(`/organizations/${tag}/portfolio/notes`, { id, notes: note }).then(()=>{
      message.success('Your note is saved')
      
      
    });
    const { target } = event;
        (target as HTMLElement).blur();
  };

  const deleteStartup = (id: string) => {
    deleteReq(`organizations/${tag}/portfolio`, { id }).then(() => {
      onDelete();
    });
  };


  const onChange=(date:any,dateString:string)=> {
    setNewDate(convertDate(date))
  }
  

  const saveDate=()=>{
      post(`/organizations/${tag}/portfolio/contact`,{id:selectedId, date:newDate?newDate:""}).then(()=>{onDelete()})
  }

  let ordinalNo=0;

  return (
    <>
      <table className="organization-listview-startup-table">
        <thead>
          <tr className="organization-listview-startup-columns">
            <th>Nr.</th>
            <th>Logo</th>
            <th>Startup name</th>
            <th>Founder name</th>
            <th>Market</th>
            <th>Status</th>
            <th>Contact</th>
            <th>Last contact</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {startups &&
            startups.map((startup: any) => {
              ordinalNo++;
              return (<>
                <tr className="organization-listview-startup-columns">
                  <td>{ordinalNo}</td>
                  <td>
                    <img
                      className="organization-listview-startup-logo"
                      src={`${startup.startup.logo}`}
                      alt=""
                    />
                  </td>
                  <td>{startup.startup.name}</td>
                  <td>
                    {startup.startup.user.firstName +
                      " " +
                      startup.startup.user.lastName}
                  </td>
                  <td>{startup.startup.market}</td>
                  <td>{startup.startup.status}</td>
                  <td>{startup.startup.email}</td>
                  <td className="organization-portfolio-date-selector" onClick={()=>{setSelectedId(startup.id);setDateSelector(true)}}>{startup.lastContact.slice(0,10)}</td>
                  <td>
                    <Input
                    className="note"
                      placeholder={"Press Enter to save your notes!"}
                      defaultValue={startup.notes}
                      onChange={(e) => setNote(e.target.value)}
                      onPressEnter={(e) => saveNote(e, startup.id)}
                    />
                  </td>
                  <td>
                    <img
                        className="organization-listview-delete-icon"
                      onClick={() => {
                        setDeleteId(startup.id);
                        setDeleteModal(true);
                      }}
                      src="/images/organizations/delete.svg"
                    />
                  </td>
                </tr>
              </>)
              
            })}
        </tbody>
      </table>
      <Modal
        visible={dateSelector}
        onCancel={() => setDateSelector(false)}
        onOk={() => {
            setDateSelector(false);
            saveDate()
        }}
        title="Set last contact date"
      >
        <DatePicker onChange={onChange} />
            
        
      </Modal>
      <ConfirmationDialog
        visible={deleteModal}
        onOk={() => {
          setDeleteModal(false);
          deleteStartup(deleteId);
        }}
        onCancel={() => setDeleteModal(false)}
        title="Delete competition"
        description="Are you sure you want to delete competition? This operation is not revertible."
        confirmBtnText="Delete"
        confirmBtnType="danger"
      />
    </>
  );
};

interface Props {
  startups: any;
  onDelete: () => void;
}
export default StartupListView;
