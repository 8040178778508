import { Formik } from "formik";
import React, { useState } from "react";
import FormikInput from "../common/form/FormikInput";
import * as Yup from "yup";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import styles from "./ForgottenPassword.module.css";
import { Button, message } from "antd";
import { post } from "../../libs/utils/request";

interface Props {
  history: any;
}

const ForgottenPassword = ({ history }: Props) => {
  const [initialValue, setInitialValue] = useState({
    email: "",
  });

  const onSubmit = async (email: any) => {
    try {
      await post<any>("/forgottenPassword", email );
      message.success('Email sent')
      history.push(`/`)
    } catch (e) {
      message.error("Request failed")
    }
  };

  return (
    <div className={styles.forgotten_pw}>
      <div className={styles.forgotten_pw_holder}>
        <div className={styles.forgotten_pw_header}>
          <h1> Password reset </h1>
        </div>
        <Formik
          initialValues={initialValue}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required("Email is required")
              .email("Enter valid email"),
          })}
          onSubmit={(values, actions) => onSubmit(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            validateForm,
          }) => (
            <>
              <div className={styles.content_holder}>
                <FormikInput
                  className={styles.input}
                  prefix={<MailOutlined />}
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="E-mail address"
                  error={errors.email && touched.email ? errors.email : ""}
                  withError
                  autofocus
                />
              </div>
              <div className={styles.content_holder}>
                <Button
                  className={styles.login_btn}
                  type='primary'
                  size='large'
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Send reset link!
                </Button>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgottenPassword;
