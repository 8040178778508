import React from 'react';
import { ITheme } from './Themes';

const styles = {
  root: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    // backgroundColor: '#F00',
  },
  img: {
    maxHeight: 36,
    maxWidth: 36,
    marginRight: 20,
    // backgroundColor: '#00F',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  themeName:{
    wordBreak: 'initial' as const
  }
};

const Theme = ({ theme }: Props) => (
  <div style={styles.root}>
    <img style={styles.img} src={theme.icon} alt='' />
    <div style={styles.themeName}>{theme.name}</div>
  </div>
);

interface Props {
  theme: ITheme;
}

export default Theme;
