import { Badge, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get, post } from "../../libs/utils/request";
import { user } from "../../libs/utils/user";
import { Loading } from "../common/Loading";
import { ago } from "../Messages/MessageList";
import "./Notifications.css";

function element(
  seen: () => void,
  saw: boolean,
  haveSeen: boolean,
  id: string,
  title: string,
  message: string,
  link: string,
  createdAt: string,
  image = "/images/user.svg"
) {
  return (
    <Link
      to={link}
      onClick={async () => {
        if (!haveSeen) {
          try {
            await post("/notification/seen", { id });
          } catch (e) {
            console.error(e);
            return;
          }
          seen();
        }
        // window.location.replace(link);
      }}
    >
      <div
        className="Notifications-element"
        style={{ background: haveSeen ? "#f9f9f9" : undefined }}
      >
        <div className="Notifications-element-image">
          <img src={image || "/images/user.svg"} alt="" />
        </div>
        <div>
          <div
            className="Notifications-element-title"
            style={{ fontWeight: haveSeen ? "normal" : "bold" }}
          >
            {title}
          </div>
          <div className="Notifications-element-messageBox">
            <div className="Notifications-element-message">{message}</div>
            <div style={{ display: "inline-block" }}>
              {ago(new Date(createdAt))}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

interface Props {
  style?: any;
  type?: string;
  notifications:
  | {
    id: string;
    createdAt: string;
    image: string | null | "";
    link: string;
    message: string;
    seen: boolean;
    title: string;
    type: string;
    saw: boolean;
  }[]
  | null;
  notificationCount: number;
}

const Notifications = ({
  style,
  type,
  notifications,
  notificationCount,
}: Props) => {
  const [notificationsState, setNotificationsState] = useState(null);
  const [bool, setBool] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (notifications.length > 0) {
      setNotificationsState(notifications);
    }
    if (notificationCount > 0 && bool) {
      setCount(notificationCount);
    }
    if (!bool) setBool(true)
  }, [notifications, notificationCount]);

  const setSeen = (id: string) => {
    let variable = notificationsState;
    variable.map((e: any) => (e.id === id ? { ...e, seen: true } : e));
    setNotificationsState(variable);
  };
  const overlay = () => {
    if(user()?.active)
    return (
      <div className="Notifications-uniqeScrollbar Notifications-box">
        {notificationsState == null ? (
          <Loading />
        ) : notificationsState.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            There are no notifications yet
          </div>
        ) : (
          notificationsState.map((n: any) =>
            element(
              () => setSeen(n.id),
              n.saw,
              n.seen,
              n.id,
              n.title,
              n.message,
              n.link,
              n.createdAt,
              n.image
            )
          )
        )}
      </div>
    );
    else{
      return(
        <span></span>
      )
    }
  };

  const loadNotification = () => {
    get("/ping").then((res: any)=>{
      setNotificationsState(res.result);
      setCount(res.notificationCount);
    })
    
    post("/notification/saw")
  }
  return (
    
      <Dropdown
        overlay={overlay()}
        trigger={["click"]}
        className="Notifications"
      >
        <div
          onClick={() => {
            user()?.active && loadNotification();
            setCount(0)
            setBool(false)
          }}>
          <Badge
            count={count}
            style={{ fontSize: "12px", backgroundColor: "rgb(226,49,92)" }}
          >
            <div
              id="badge"
              style={{ marginRight: "5px", cursor: "pointer" }}
            >
              {type === "white" ? (
                <div className="side-notification">
                  <img
                    className="white-notification"
                    src="/images/header-notification.svg"
                  />
                </div>
              ) : (
                <img src="/images/header-notification.svg" />
              )}
            </div>
          </Badge>
          {type === "white" && (
            <div className="sidebar-text">
              Notifications
            </div>
          )}
        </div>
      </Dropdown>
  );
};

export default Notifications;
