import React, { CSSProperties, useState } from 'react';
import { CameraFilled, DeleteFilled } from '@ant-design/icons';
import ImageUploader2 from '../../common/ImageUploader2';

const styles = {
  root: {
    cursor: 'pointer',
    width: '100%',
    height: 264,
    borderRadius: 10,
    marginTop: 66,
    position: 'relative' as const,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'absolute' as const,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: '0.2s',
  },
  hovered: {
    webkitFilter: 'blur(4px) brightness(66%)',
    filter: 'blur(4px) brightness(66%)',
    overflow: 'hidden',
  },
  cameraButton: {
    position: 'absolute' as const,
    top: 15,
    right: 0,
    marginBottom: 14,
    marginRight: 25,
  },
  centerBox: {
    position: 'absolute' as const,
    marginTop:-50,
    width: '30%',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-around',
  },
  btn: {
    width: 100,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  cameraButtonCenter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center',
  },
  cameraIcon: { color: '#FFF', fontSize: 31 },
  btnIcon: {
    color: '#FFF',
    fontSize: 64,
  },
  imageFormatText: {
    fontFamily: 'Inter',
    fontSize: 14,
    color: '#FFF',
    textAlign: 'center' as const,
  },
};

const Cover = ({
  url, onChange, rootStyle, imageStyle, deletable,
}: Props) => {
  const [hovered, setHovered] = useState(false);

  return (
    <ImageUploader2
      images={url}
      type='single'
      onChange={onChange}
      styles={{ ...styles.root, ...rootStyle }}
    >
      <div
        style={styles.content}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div
          style={{
            ...styles.image,
            ...imageStyle,
            backgroundImage: `url(${url || '/images/profile-background.png'})`,
            ...(hovered ? styles.hovered : {}),
          }}
        >
          {!hovered && (
            <div style={styles.cameraButton}>
              <CameraFilled style={styles.cameraIcon} />
            </div>
          )}
        </div>
        {hovered && (
          <div style={styles.centerBox}>
            <div style={styles.btnContainer}>
              <div style={styles.btn}>
                <CameraFilled style={styles.btnIcon} />
                <div style={styles.imageFormatText}>PNG or JPG, max. 10 MB, Recommended 1300x300</div>
              </div>
              {(deletable && url) && (
                <div
                  style={styles.btn}
                  aria-hidden='true'
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange('');
                  }}
                >
                  <DeleteFilled style={styles.btnIcon} />
                  <div style={styles.imageFormatText}>Delete</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </ImageUploader2>
  );
};

interface Props {
  url: string;
  onChange: any;
  rootStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  deletable?: boolean;
}

Cover.defaultProps = {
  rootStyle: {},
  imageStyle: {},
  deletable: true,
};

export default Cover;
