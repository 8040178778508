import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, validateYupSchema } from "formik";
import FormikInput from "../common/form/FormikInput";
import * as Yup from "yup";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { post } from "../../libs/utils/request";
import User from "../../user/User";
import styles from "./NewLogin.module.css";
import RegisterWallModal from "../common/RegiserWallModal";
import AuthContext from "../../../store/AuthContext";

function NewLogin(props: any) {
  const [initialValue, setInitialValue] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  var history = props.history;
  const authContext = useContext(AuthContext);

  const onSubmit = async (value: any) => {
    sessionStorage.setItem("user_email", value.email)
    await post<User>("/login", value).then((response) => {
      console.log(response);
        authContext.login(response)
    }).catch((e) => {
      console.log(e);
      message.error(e.response?.data?.message || "Network error");
      setErrorMessage(e.response?.data?.message || "Network error");
    })
  };

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (localStorage.getItem("token") && user && user.trial) {
      history.push("/notActivated");
    }
  }, [localStorage, sessionStorage]);

  useEffect(() => {
    if (sessionStorage.getItem('user_email')) {
      let user_email = sessionStorage.getItem('user_email');
      setInitialValue({
        email: user_email,
        password: ''
      })
    }
  }, [sessionStorage])
  return (
    <>
      <div className={styles.login_holder}>
        <div className={styles.login_header}>
          <h1>Log in to Pozi</h1>
        </div>
        <Formik
          initialValues={initialValue}
          enableReinitialize
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required("Email is required")
              .email("Enter valid email"),
            password: Yup.string().required("This field is required"),
          })}
          onSubmit={(values, actions) => onSubmit(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            validateForm,
          }) => (
            <>
              <form autoComplete="off">
                <div className={styles.content_holder}>
                  <FormikInput
                    className={styles.input}
                    prefix={<MailOutlined />}
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="E-mail address"
                    onPressEnter={() => handleSubmit()}
                    error={errors.email && touched.email ? errors.email : ""}
                    withError
                    autofocus
                  />
                </div>
                <div className={styles.content_holder}>
                  <FormikInput
                    className={styles.input}
                    prefix={<LockOutlined />}
                    name="password"
                    label="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    error={
                      errors.password && touched.password ? errors.password : ""
                    }
                    placeholder="Password"
                    withError
                    onPressEnter={() => handleSubmit()}
                  />
                </div>
                <div className={styles.content_holder}>
                  <Button
                    className={styles.login_btn}
                    type="primary"
                    size="large"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Log In
                  </Button>
                  <Link to={"/forgottenPassword"} className={styles.forgott_pw}>
                    I forgot my password!
                  </Link>
                </div>
              </form>
            </>
          )}
        </Formik>
        <div className={styles.registration_holder}>
          <h3>Didn’t register yet?</h3>
          <a
            className={styles.registration_link}
            onClick={() => {
              setShowRegisterModal(true);
            }}
          >
            Sign up!
          </a>
        </div>
        <RegisterWallModal
          title="Sign up for Pozi"
          onCancel={() => {
            setShowRegisterModal(false);
          }}
          visible={showRegisterModal}
        />
      </div>
      <div
        className={styles.bg_image}
        style={{ backgroundImage: `url(${"/images/login_image/rochet.svg"})` }}
      ></div>
    </>
  );
}

export default NewLogin;
