import React, { useEffect, useState } from "react";
import { Select, Spin } from 'antd';
import { get, post } from "../../../../../libs/utils/request";
import { base64decode, base64encode } from "../../../../../libs/utils/textUtils";


interface Props {
    value: any;
    onChange : (organization:any) => void;
    multiple: boolean;
}



export default function Partnership({value, onChange, multiple}:Props) {
    let propValue: { key: string, label: string, image: string }[] | { key: string, label: string, image: string };   
    const { Option } = Select;
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);

    const addOrganization =  async (e:any) => {
        try {
                setFetching(true)
              await post(`/organizations/search`, {
                name: e,
              }).then((res:any)=>{
                  setData(res.map((u:any) => ({ key: base64encode(JSON.stringify(u)), label: `${u.name} `, image: u.image })));
                  setFetching(false)
              });
          } catch (err) {
          console.log(err);
          }
    }

    const organizationChange = (value:any) => {
        value = multiple !== true ? [value] : value;
        const newValue = value.map((v: any) => JSON.parse(base64decode(v.key)));
        onChange(newValue);
		
    }
    
    if (value !== undefined) {
        if (multiple === true) {
            propValue = value.map((u:any) => ({
                key: base64encode(JSON.stringify(u)),
                label: u.name,
                image: u.image,
            }));
        } else {
            propValue = {
                key: base64encode(JSON.stringify(value[0])),
                label: `${value[0].name} `,
                image: value[0].image,
            };
        }
    } else {
        propValue = [];
    }
  return (
  <div>
      <Select
				 showSearch
                
				mode={multiple === true ? 'multiple' : undefined}
				labelInValue
				value={propValue}
				placeholder={`Search organization`}
				notFoundContent={fetching ? <Spin size='small' /> : null}
				filterOption={false}
				 onSearch={(e) => addOrganization(e)}
				onChange={(e:any) =>{organizationChange(e);} }
				style={{ width: '100%' }}
				showArrow={false}	
			>
				{data && data.map((u: any) => {
                    return <Option key={u.key}>{u.label}</Option>;
                 })}
			</Select>
         </div>)
}
