import React from "react";
import { Component } from "react";
import { Button, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UserSearch } from "../User/UserSearch";
import User from "../../user/User";

interface State {
    value: User[]
    text: string
}

interface Props {
    onCreate: (users: string[], text: string) => void
}

export class NewThread extends Component<Props, State> {
    state: State = {
        value: [],
        text: ''
    }

    onChange(value: any) {
        this.setState({
            value
        })
    }

    create() {
        const { text, value } = this.state
        if (value.length > 0) {
            this.props.onCreate(value.map(v => v.id), text)
        } else {
            message.error('User is not selected')
        }
    }

    render(): any {
        const { value } = this.state
        return <div>
            <UserSearch value={value} multiple={true} onChange={(value) => {
                this.setState({ value })
            }} />
            <div style={{ paddingTop: '12px' }}>
                <TextArea
                    value={this.state.text}
                    onChange={(e) => {
                        this.setState({ text: e.target?.value })
                    }}
                    placeholder="Message"
                    autoSize={{ minRows: 3 }}
                />
            </div>
            <div style={{ textAlign: 'right', paddingTop: '12px' }}>
                <Button
                    onClick={() => this.create()}
                    loading={false}
                    type="primary"
                    size="large"
                    block
                    style={{
                        width: '120px'
                    }}>Create</Button>
            </div>
        </div>
    }
}
