import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { textOverflow } from "../../../libs/utils/textUtils";

interface Props {
  data: any;
}

const LatestIdeas = ({ data }: Props) => {

  const openView = (uniqueTag: string) => {
    const win = window.open(`/idea/${uniqueTag}`, "_blank");
    win.focus();
  }
  const openAllStartup = () => {
    const win = window.open(`/ideas`, "_blank");
    win.focus();
  }

  return (
    <div className="latest-startups-card">
      <div className="card-title-container">
        <div className="dashboard-card-title">Latest ideas</div>
        <a onClick={openAllStartup} className="seeall">
          See all
        </a>
      </div>
      {data.map((user: any) => {
        return (
          <div className="latest-box">
            <div className="latest-icon-name-interes">
              <div className="logo">
                <img
                  className="idea-default-logo"
                  src={user.logo ? user.logo : "/images/onePagerLogo.png"}
                />
              </div>
              <div className="latest-content">
                <div className="startup-name-box">
                  <div className="startup-name">
                    <div>{textOverflow(user.name,13)}</div>
                  </div>
                  <div className="latest-roles">
                    {user.interests &&
                      (user.interests.length > 1 ? (
                        <div className="role-item">
                          {user.interests[0].name}| ...
                        </div>
                      ) : (
                        <div className="role-item">
                          {user.interests[0].name}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => openView(user.uniqueTag)}
              className="view-text-box"
            >
              <div className="view-text">View</div>
              <div>
                <img src="/images/user_info_icons/next.svg" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default LatestIdeas;
