import React, { useState } from 'react';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

const styles = {
  root: {
    boxShadow: '10px 10px 24px #00000014',
    backgroundColor: '#FFF',
    width: 214,
    height: 120,
    borderRadius: 3,
    marginRight: 25,
    marginBottom: 25,
    position: 'relative' as const,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeBtn: {
    cursor: 'pointer',
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#6755CE',
    position: 'absolute' as const,
    top: 0,
    right: 0,
    marginRight: -8,
    marginTop: -8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: { color: '#FFF', fontSize: 8, fontWeight: 900 },
  image: {
    position: 'absolute' as const,
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editBtn: {
    cursor: 'pointer',
    position: 'relative' as const,
    color: '#FFF',
    fontSize: 42,
  },
  hovered: {
    webkitFilter: 'brightness(50%)',
    filter: 'brightness(50%)',
    overflow: 'hidden',
  },
};

const ImageCard = ({ image, onDelete, onEdit }: Props) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      style={styles.root}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        style={{ ...styles.image, ...(hovered ? styles.hovered : {}), backgroundImage: `url(${image})` }}
      />
      {hovered && (
        <EditOutlined
          style={styles.editBtn}
          onClick={onEdit}
        />
      )}
      <div style={styles.closeBtn} onClick={onDelete} aria-hidden='true'>
        <CloseOutlined style={styles.closeIcon} />
      </div>
    </div>
  );
};

interface Props {
  image: string;
  onDelete: () => void;
  onEdit: () => void;
}

export default ImageCard;
