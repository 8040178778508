import React, { CSSProperties, useEffect, useState } from 'react';
import { message, Select } from 'antd';
// import './LocationSelector2.css';

const styles = {
  root: {
    width: '100%',
  },
};

export const LocationSelector2 = ({
  value, onChange, placeholder, style, types, suffixIcon, name, className
}: Props) => {
  const [list, setList] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [mapService, setMapService] = useState(null);

  useEffect(() => {
    setMap()
   
  }, []);

  const setMap = () =>{
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { google } = window;
    if (google?.maps) {
      // TODO filtering
      setMapService(new google.maps.places.AutocompleteService());
    }
  }

  const handleSearch = (search: string) => {
    if (mapService) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(setTimeout(async () => {
        if (search.length === 0) {
          setList([]);
        }
        if (search.length >= 3) {
          try {
            mapService.getPlacePredictions({ input: search, types },
              (predictions: IPrediction[], status: string) => {
                if (status === 'OK') {
                  if (Array.isArray(predictions)) {
                    setList(predictions
                      .map((item) => ({ description: item.description, placeId: item.place_id })));
                  }
                } else {
                  message.error('Failed to fetch locations.');
                }
              });
          } catch (error) {
            message.error('Failed to fetch locations.');
          }
        }
      }, 500));
    }
    else{
      setMap()
      console.log('No mapservice',mapService)
    }
  };

  return (
    <div className={className ? className : 'location-selector'}>
      <Select
        suffixIcon={suffixIcon}
        onSearch={handleSearch}
        showSearch
        placeholder={placeholder}
        showArrow={false}
        defaultActiveFirstOption={false}
        style={{ ...styles.root, ...style }}
        value={value}
        onChange={onChange}
      >
        {
          list.map((location: { description: string, placeId: string }) => (
            <Select.Option
              key={JSON.stringify(location)}
              value={JSON.stringify(location)}
            >
              {location.description}
            </Select.Option>
          ))
        }
      </Select>
    </div>
  );
};

interface IPrediction {
  // eslint-disable-next-line camelcase
  place_id: string;
  description: string;
}

interface Props {
  value: string;
  onChange: any;
  placeholder?: string;
  style?: CSSProperties;
  types?: string[];
  defaultValue? : any;
  suffixIcon?: any;
  name?:string
  className?: string;
}

LocationSelector2.defaultProps = {
  placeholder: 'Type the location...',
  style: {},
  types: null,
};
