import React from "react";
import { textOverflow } from "../../libs/utils/textUtils";
import { Link } from "react-router-dom";

interface Props {
  award: any;
}

const Award = ({ award }: Props) => {
  return (
    <div className="award">
      <div />
      <div className="award-title">
        <Link to={`/competitions/${award.competitionTag}`}>
          {textOverflow(award.competitionName, 20)}
        </Link>
      </div>
      <div>
        <div className="award-icon-name-holder">
          <img
            className="award-icon"
            src="../../../../images/competitions/award_gold.svg"
            alt=""
          />
          <div className="award-name">{award.awardType}</div>
        </div>
      </div>
      <div />
      <div className="award-description">{award.description}</div>
    </div>
  );
};

export default Award;
