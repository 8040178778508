import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { user } from "../../libs/utils/user";
import User from "../../user/User";
import ConfirmationDialog from "../common/ConfirmationDialog";
import NewMember from "../Project/NewMember";
import AddNewMember from "./AddNewMember";
import DisplayTeamMember from "./DisplayTeamMember";
import MemberEdit from "./MemberEdit";
import "./Team.css";

interface Props {
  ideaEditor?: boolean;
  teamMembers: any;
  isEdit?: boolean;
  isDraft?: boolean;
  Callback?: (elem: any) => void;
  isNew?: boolean;
  project?: any;
  loggedIn?: boolean;
  joined?: boolean;
  onJoin?: () => void;
  onWithdraw?: () => void;
  onLeave?: () => void;
  userId?: string;
}

const Team = ({
  ideaEditor,
  teamMembers,
  isEdit,
  isDraft,
  Callback,
  isNew,
  project,
  loggedIn,
  joined,
  onJoin,
  onWithdraw,
  onLeave,
}: Props) => {
  const [membersToEdit, setMembersToEdit] = useState([]);
  const [buttonType, setButtonType] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const userFromStorage = sessionStorage.getItem('user')
  const profileProgress =  userFromStorage ? JSON.parse(userFromStorage).progress : 0
  useEffect(() => {
    if(teamMembers.length > 0 )
    setMembersToEdit(teamMembers)
  }, [teamMembers])

  useEffect(() => {
    if (isNew && teamMembers.length === 0) {
      setMembersToEdit([
        {
          user: user(),
          title: "",
          description: "",
        },
      ])
    }
  }, [isNew]);
  const deleteTeamMember = (id: string) => {
    setMembersToEdit(membersToEdit.filter((m: any) => m.user.id !== id));
  };
  const editTeamMember = (elem: any) => {
    var foundIndex = membersToEdit.findIndex((x: any) => x.id == elem.id);
    membersToEdit[foundIndex] = elem;
  };

  const addNewTeamMember = (member: any) => {
    setMembersToEdit([
      ...membersToEdit.filter((m: any) => m.user.id !== member.user.id),
      member,
    ]);
  };
  const Join = () => {
    setButtonType("join");
    setShowConfirmationDialog(true);
  }

  useEffect(() => {
    if (isDraft || isEdit || isNew) Callback(membersToEdit);
  }, [membersToEdit]);

  return (
    <div className="Team">
      <div
        className="team-header"
        style={{ marginBottom: membersToEdit.length > 3 ? "30px" : "0px" }}
      >
        <h2>Team</h2>
        {loggedIn &&
          !(
            project.user.id === user().id ||
            project.isMember === true ||
            project.requestPending ||
            joined
          ) && (
            <div className="team-button-holder">
              <Button
                type="primary"
                className="team-btn"
                size="large"
                onClick={() => {
                  setButtonType("join");
                  setShowConfirmationDialog(true);
                }}
              >
                Join
              </Button>
            </div>
          )}
        {loggedIn && project.requestPending && (
          <div className="team-button-holder">
            <Button
              type="primary"
              className="btnWithdraw"
              size="large"
              onClick={() => {
                setButtonType("withdraw");
                setShowConfirmationDialog(true);
              }}
            >
              Withdraw application
            </Button>
          </div>
        )}
        {loggedIn &&
          project.isMember === true &&
          project.user.id !== user().id && (
            <div className="team-button-holder">
              <Button
                type="primary"
                className="team-btn"
                size="large"
                onClick={() => {
                  setButtonType("leave");
                  setShowConfirmationDialog(true);
                }}
              >
                Leave
              </Button>
            </div>
          )}
      </div>
      <div className={isEdit || isDraft || isNew ? "member-holder" : ""}>
        {(isEdit || isDraft || isNew) &&
          membersToEdit.map((m: any, index: any) => (
            <div className="member" key={index}>
              <MemberEdit
                key={index}
                member={m}
                index={index}
                editTeamMember={editTeamMember}
                deleteTeamMember={deleteTeamMember}
                project={project}
                isNew={isNew}
              />
            </div>
          ))}
        {(isEdit || isDraft || isNew) && (
          <div className="new-member-holder">
            <h3 className="new-member-title">Add new member:</h3>
            {ideaEditor && <div className="new-member-title-description">Press the "Add" button after completing the details.</div>}
            <AddNewMember
              onSubmit={(value: any) => {
                addNewTeamMember(value);
              }}
            />
          </div>
        )}
      </div>
      <div className="display-team-members">
        {!isEdit &&
          !isDraft && !isNew &&
          teamMembers.map((member: any, index: number) => (
            <div
              className='display-member'
              key={member.user.id + "" + index}
            >
              <DisplayTeamMember member={member} i={index} key={index} />
            </div>
          ))}
      </div>
      {(() => {
        if (buttonType === "join") {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onJoin();
              }}
              title="Join team"
              description="Are you sure you want to join to this team?"
              confirmBtnText="Join"
              confirmBtnType="primary"
            />
          );
        }
        if (buttonType === "withdraw") {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onWithdraw();
              }}
              title="Withdraw application"
              description="Are you sure you want to withdraw your application?"
              confirmBtnText="Withdraw"
              confirmBtnType="danger"
            />
          );
        }
        if (buttonType === "leave") {
          return (
            <ConfirmationDialog
              visible={showConfirmationDialog}
              onCancel={() => setShowConfirmationDialog(false)}
              onOk={() => {
                setShowConfirmationDialog(false);
                onLeave();
              }}
              title="Leave team"
              description="Are you sure you want to leave this team?"
              confirmBtnText="Leave"
              confirmBtnType="danger"
            />
          );
        }
        return null;
      })()}
    </div>
  );
};
export default Team;

interface Member {
  createdAt?: string;
  description: string;
  id: string;
  title: string;
  updatedAt?: string;
  user: User;
}
