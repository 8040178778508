import React, { CSSProperties } from 'react';

const styles = {
  root: {
    maxWidth: 300,
    backgroundColor: '#FFF',
    boxShadow: '3.38462px 3.38462px 13.5385px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-around',
    padding: 8,
    gap: '8px 8px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#282828',
    cursor: 'pointer',
  },
};

const TypeSelector = ({
  types, value, onChange, style,
}: Props) => {
  const getItemStyle = (s: string) => (s !== value ? styles.text : { ...styles.text, color: '#6755CE', fontWeight: 800 });
  const filterDisables = (sty: CSSProperties, s: string) => {
    if (s === 'Event' || s === 'Job') {
      return { ...styles.text, color: '#CCC', cursor: 'not-allowed' };
    }
    return sty;
  };
  const handleClick = (s: string) => {
    if (s !== value) {
      onChange(s);
    }
  };
  return (
    <div style={{ ...styles.root, ...style }}>
      {types.map((item) => (
        <div key={item} style={filterDisables(getItemStyle(item), item)} onClick={() => handleClick(item)} aria-hidden='true'>
          {item}
        </div>
      ))}
    </div>
  );
};

/* eslint-disable no-unused-vars */
interface Props {
  types: string[];
  value: string;
  onChange: (s: string) => void;
  style?: CSSProperties;
}

TypeSelector.defaultProps = {
  style: {},
};

export default TypeSelector;
