import React, { CSSProperties } from 'react';

const styles = {
  card: {
    boxShadow: '10px 10px 24px #00000014',
    backgroundColor: '#FFF',
  },
};

const Card = ({ style, className, children }: Props) => (
  <div className={className} style={{ ...styles.card, ...style }}>
    {children}
  </div>
);

interface Props {
  style?: CSSProperties,
  className?: string,
  children?: any,
}

Card.defaultProps = {
  style: {},
  className: '',
  children: <></>,
};

export default Card;
