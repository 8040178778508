import React from 'react'
import {Button, Select, Upload} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import {InboxOutlined, PlusOutlined} from '@ant-design/icons'
import {getBaseUrl} from '../../libs/utils/request';

const {Dragger} = Upload

interface Props {
  data?: any | undefined
  edit?: undefined | true
  onSubmit: (data: any) => void
}

interface State {
  loading: boolean
}

export class CreatePost extends React.Component<Props, State> {

  message: string | null = null
  hashtags: string[] = []
  image: string | null = null
  imageName: string | null = null

  state: State = {
    loading: false
  }

  constructor(props: any) {
    super(props)
    if (this.props.edit) {
      const { data } = this.props
      this.message = data.message || null
      this.hashtags = data.hashtags || []
      if (data.image && data.image.filename) {
        this.image = data.image.filename
      }
    }
  }

  render() {
    const isEdit = !!this.props.edit

    return <div className={'CreatePost'}>
      <TextArea
        defaultValue={this.message || ''}
        className={'CreatePost-a'}
        onChange={(e) => {
          this.message = e.target?.value
        }}
        placeholder="Message"
        autoSize={{minRows: 7}}
      />
      <Select
        defaultValue={this.hashtags || []}
        className={'CreatePost-c'}
        mode="tags"
        style={{width: '100%'}}
        placeholder="Hashtags"
        onChange={(hashtags: string[]) => {
          this.hashtags = hashtags
        }}
      />
      <Dragger
        className={'CreatePost-b'}
        name={'photo'}
        multiple={false}
        showUploadList={false}
        action={`${getBaseUrl()}/image`}
        onChange={(info: any) => {
          const {status} = info.file
          if (status === 'done') {
            this.image = info.file.response.filename
            this.imageName = info.file.name
            this.forceUpdate()
          } else if (status === 'error') {
            this.image = null
            this.imageName = null
            this.forceUpdate()
          }
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined/>
        </p>
        <p className="ant-upload-text">{this.imageName ? this.imageName : 'Click or drag file to this area to upload'}</p>
      </Dragger>
      <Button
        loading={this.state.loading}
        className={'CreatePost-d'}
        type="primary"
        size="large"
        onClick={async () => {
          this.setState({ loading: true })
          await this.props.onSubmit({
            image: this.image,
            message: this.message,
            hashtags: this.hashtags
          })
          this.setState({ loading: false })
        }}>{isEdit ? 'Edit' : [<PlusOutlined/>,'Create a new']}</Button>
    </div>
  }
}
