import { InputNumber } from 'antd';
import React, { useState } from 'react';
import ReactLinkify from 'react-linkify';
import { hasPermission, user } from '../../libs/utils/user';
import GenerateSummaryModal from './GeneratePdf';

const styles = {
  icon: {
    width: '36px',
    height: '36px',
    marginRight: '15px',
    marginTop: '6px',
  },
};

const highlightsHelper: any = {
  pitchLink: {
    title: 'Pitch Link',
    icon: '/images/project_highlight_icons/pitchlink.svg',
    type: 'pitchLink',
    link: true,
  },
  additionalInfo: {
    title: 'Additional Information',
    icon: '/images/project_highlight_icons/addInfo.svg',
    type: 'additionalInformation',
    link: true,
  },
  generate: {
    title: 'Generate PDF',
    icon: '/images/project_highlight_icons/download.svg',
    type: 'download',
    show: true,
    link: true,
  },
  history: {
    title: 'Onepager History',
    icon: '/images/project_highlight_icons/project.svg',
    type: 'history',
    show: true,
  },
  market: {
    title: 'Market',
    icon: '/images/project_highlight_icons/market.svg',
    placeholder: 'B2B, B2C, etc.',
  },
  status: {
    title: 'Status',
    icon: '/images/project_highlight_icons/status.svg',
    type: 'select',
    selectOptions: [
      'Pre-seed',
      'Seed',
      'Prototype',
      'Market ready',
      'Scale-up',
    ],
  },
  monthlyBurn: {
    title: 'Monthly net burn',
    icon: '/images/project_highlight_icons/monthlyBurn.svg',
    type: 'number',
  },
  capitalSeeking: {
    title: 'Capital seeking',
    icon: '/images/project_highlight_icons/capitalSeeking.svg',
    type: 'number',
  },
  website: {
    title: 'Website',
    icon: '/images/project_highlight_icons/website.svg',
    type: 'link',
  },
  email: {
    title: 'Email',
    icon: '/images/project_highlight_icons/email.svg',
    required: true,
    placeholder: 'Email is required',
    type: 'email',
  },
  phone: {
    title: 'Phone',
    icon: '/images/project_highlight_icons/phone.svg',
    required: true,
    placeholder: 'Phone number is required',
    type: 'phone',
  },
  country: {
    title: 'Country of origin',
    icon: '/images/project_highlight_icons/country.svg',
  },
};

interface Props {
    project:any;
}

const ProjectHighlights = (props: Props) => {
  const {
    project,
  } = props;

  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const loggedIn = !!user();

  const display = (value:any, type:string) => {
    if (type === 'number' && value) return <div>{`$${value.toLocaleString()}` }</div>;
    if (type === 'link' && value) return <ReactLinkify><a href={(value.includes('http://')||value.includes('https://')) ? value : "http://" + value} target='_blank' rel='noreferrer'>{value}</a></ReactLinkify>;
    if (type === 'year') {
 return (
      <InputNumber
  style={{ float: 'left' }}
  type='number'
  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
/>
    ); 
}
    if (type === 'pitchLink') {
      return <ReactLinkify><a href={value} target='_blank' rel='noreferrer'>View!</a></ReactLinkify>;
    }
    if (type === 'additionalInformation') {
      return <ReactLinkify><a href={value} target='_blank' rel='noreferrer'>Open link!</a></ReactLinkify>;
    }
    if (type === 'download') {
      return <p style={{ color: 'var(--primary-blue)', marginBottom: 0 }} onClick={() => setDownloadModalVisible(true)}>Download</p>;
    }
    if (type === 'history') {
      return (
        <div>V{project.version = 0 ? 1 : project.version}  {' '}  
          {' '}
          {project.updatedAt ? project.updatedAt.toString().substr(0, 10) : ''}
          {' '}
        </div>
      );
    }
    if (type === 'email') {
      return <a href={`mailto:${value}`}>{value}</a>;
    }
    // if (type === 'phone') {
    //   return <a href={`tel:${value}`}>{value}</a>;
    // }
    return <div>{value}</div>;
  };

  return (
    <>
      <GenerateSummaryModal
        tag={project.id}
        visible={downloadModalVisible}
        onCancel={() => setDownloadModalVisible(false)}
      />
      {Object.keys(highlightsHelper).map((key) => (
        <>
          {(project.highlights[key] || project[key] || highlightsHelper[key].show === true)
                && (
                  <>
                    {highlightsHelper[key].type === 'download' && !project.canDownload ? null
                      : (
                        <div className='highlight'>
                          <img src={highlightsHelper[key].icon} style={styles.icon} alt='' />
                          <div className='highlight-text'>
                            <div className='highlight-text-title'>
                              {highlightsHelper[key].title}
                            </div>
                            <div className='highlight-text-desc' style={highlightsHelper[key].link !== true ? { cursor: 'default' } : { cursor: 'pointer' }}>
                              {display(project.highlights[key] ? project.highlights[key] : project[key], highlightsHelper[key].type)}
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
        </>
      ))}
    </>
  );
};

export default ProjectHighlights;
