import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Pagination,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { useHistory, useParams } from "react-router";
import { get, post } from "../../../../libs/utils/request";
import { base64encode } from "../../../../libs/utils/textUtils";
import { StartupCard } from "../../../CardView/ProjectCard";
import StartupListView from "../StartupListView";

const { Option } = Select;

const Portfolio = (organization: any) => {
  const [startups, setStartups] = useState([]);
  const [startupsList, setStartupsList] = useState([]);
  const [startupsCount, setStartupsCount] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState("Sort by");
  const [limit, setLimit] = useState(100);
  const [view, setView] = useState("box view");
  const [sorter, setSorter] = useState({ sort: "name", order: "ASC" });
  const [startupModal, setStartupModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState([]);
  const [filtered, setFiltered] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const [selectedStartup, setSelectedStartup] = useState(null);

  const { tag } = useParams();
  const history = useHistory();

  useEffect(() => {
    organization.organization.isMember && setView("list view");
  }, []);

  useEffect(() => {
    if (view === "box view") getStartups(currentPage);
    if (view === "list view") getStartupDetails(currentPage);
  }, [sorter, view, limit]);

  const getStartups = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    get(`/organizations/${tag}/portfolio`, {
      currentPage: pageNumber,
      limit: 10,
      sort: sorter.sort,
      order: sorter.order,
    }).then((res: any) => {
      setStartups(res.data.map((startups: any) => startups.startup));
      setFiltered(res.data.map((startups: any) => startups.startup));
      setPagination(res.pagination);
      setStartupsCount(res.pagination.totalCount)
    });
  };

  const getStartupDetails = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    get(`/organizations/${tag}/portfolio/listview`, {
      currentPage: pageNumber,
      limit,
      sort: sorter.sort,
      order: sorter.order,
    }).then((res: any) => {
      setStartupsList(res.data.map((startups: any) => startups));
      setFilteredList(res.data.map((startups: any) => startups));
      setPagination(res.pagination);
    });
  };

  const addStartup = () => {
    post(`/organizations/${tag}/portfolio`, { id: selectedStartup.key }).then(
      () => {
        getStartups(currentPage);
        getStartupDetails(currentPage);
      }
    );
  };

  let lastFetchId = 0;

  const getProjects = async (e: any) => {
    lastFetchId += 1;
    if (e.length < 3) {
      setSearch([]);
      setFetching(false);
      return;
    }
    const fetchId = lastFetchId;

    setSearch([]);
    setFetching(true);
    try {
      const projects: any = await post(
        `/organizations/${tag}/portfolio/search`,
        {
          name: e.trim().split(" ").join("").toLowerCase(),
        }
      );
      if (fetchId === lastFetchId) {
        setSearch(
          projects.map((u: any) => ({
            key: `${u.id}`,
            label: `${u.name} `,
            image: `${u.image}`,
          }))
        );
        setFetching(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createNew = () => {
    setStartupModal(true);
  };

  const setNewStartup = (value: any) => {
    setSelectedStartup(value);
  };

  const onSearch = (value: string) => {
    if(view==='box view'){
      if (value) {
        const filteredStartups = startups.filter((project) =>
          JSON.stringify(project)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
        if (filteredStartups.length > 0) {
          setFiltered(filteredStartups);
        }
      } else {
        setFiltered(startups.slice(currentPage * 10 - 10, currentPage * 10));
      }
    }
    else if(view==='list view'){
      if (value) {
        const filteredStartupsList = startupsList.filter((project) =>
          JSON.stringify(project)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
        if (filteredStartupsList.length > 0) {
          setFilteredList(filteredStartupsList);
        }
      } 
    }
  };

  const sortmenu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setSorting("name (A-Z)");
          setSorter({ sort: "name", order: "ASC" });
        }}
      >
        name (A-Z)
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setSorting("name (Z-A)");
          setSorter({ sort: "name", order: "DESC" });
        }}
      >
        name (Z-A)
      </Menu.Item>
    </Menu>
  );

  const viewmenu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setView("box view");
        }}
      >
        box view
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setView("list view");
        }}
      >
        list view
      </Menu.Item>
    </Menu>
  );

  const limitmenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setLimit(50);
        }}
      >
        50 items
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setLimit(100);
        }}
      >
        100 items
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="organization-competition-container">
      <div className="organization-competitions-title-container">
        <span>
          <h2 className="organization-competition-title">Portfolio</h2>
          <span className="organization-competition-counter">

         
            ({startupsCount } {startupsCount > 1 || startupsCount == 0 ? 'startups' : "startup"})
         
          </span>
        </span>
        {organization.organization.isMember && (
          <Button type="primary" onClick={createNew}>
            Add new startup{" "}
          </Button>
        )}
      </div>
      {startups.length < 1 && (
        <div className="organizations-nothing-to-show">Nothing to Show</div>
      )}
      {startups.length > 0 && (
        <>
          <div className="organization-pagination">
            {pagination !== null && (
              <Pagination
                total={pagination.pageCount}
                defaultPageSize={1}
                current={pagination.currentPage}
                onChange={getStartups}
              />
            )}
          </div>
          <Input
            className="organization-portfolio-search"
            placeholder={"Type a name you like..."}
            // @ts-ignore
            onChange={(e) => onSearch(e.currentTarget.value)}
            suffix={
              <div className="organization-search-suffix">
                <SearchOutlined
                  style={{
                    fontSize: 18,
                    color: "#fff",
                  }}
                />
              </div>
            }
            addonAfter={null}
          />
          <div className="organization-portfolio-dropdown-container">
             {view === "list view" && 
            <>
              <Dropdown overlay={limitmenu} className="organization-sorter">
                  <p>
                    {limit} <CaretDownOutlined />
                  </p>
                </Dropdown>
            
            </>
            }
            <Dropdown overlay={sortmenu} className="organization-sorter">
              <p>
                {sorting} <CaretDownOutlined />
              </p>
            </Dropdown>
            {organization.organization.isMember && (
              <Dropdown
                overlay={viewmenu}
                className="organization-sorter organization-boxview"
              >
                <p>
                  {view} <CaretDownOutlined />
                </p>
              </Dropdown>
            )}
          </div>
          <div className="organization-competition-content">
            <Masonry className="organization-competition-content-box-view">
            {view === "box view" &&
              filtered &&
              filtered.map((startup: any) => (
                <StartupCard
                  key={startup.tag}
                  startup={startup}
                  onChange={() => getStartups(currentPage)}
                />
               ))}
               </Masonry>
            {view === "list view" && (
              filteredList && filteredList.length > 0 ? (
              <StartupListView
                startups={filteredList}
                onDelete={() => getStartupDetails(currentPage)}
              />
            ) : (
              "No startup"
            ))}
          </div>
          <div className="organization-pagination">
            {pagination !== null && (
              <Pagination
                total={pagination.pageCount}
                defaultPageSize={1}
                current={pagination.currentPage}
                onChange={getStartups}
              />
            )}
          </div>
        </>
      )}
      <Modal
        visible={startupModal}
        onCancel={() => setStartupModal(false)}
        onOk={() => {
          setStartupModal(false);
          addStartup();
          // onWithdraw();
        }}
        title="Add new Startup"
        destroyOnClose
      >
        <p>Add startup</p>
        <Select
          value={selectedStartup ? selectedStartup : startups}
          showSearch
          labelInValue
          onChange={(value: any) => setNewStartup(value)}
          placeholder={`Select startup to add`}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          optionFilterProp="children"
          filterOption={false}
          style={{ width: "100%" }}
          onSearch={(e: any) => getProjects(e)}
        >
          {search.map((u: any) => {
            return <Option key={u.key}>{u.label}</Option>;
          })}
        </Select>
      </Modal>
    </div>
  );
};

export default Portfolio;
